import React, { useState } from "react";
import { Loading } from "react-flaticons";
import { useSelector } from "react-redux";
import { apiConnector } from "../../../api/apiconnector";
import { authEndPoints } from "../../../api/api";
const { CHANGE_PASSWORD } = authEndPoints;

const ChangePassword = () => {
  const { user } = useSelector((state) => state.user);
  const [passwords, setPasswords] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(""); // Success state

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPasswords((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setError(""); // Clear error on input change
    setSuccess(""); // Clear success on input change
  };

  // Function to set error and clear it after 4 seconds
  const showError = (message) => {
    setError(message);
  };

  // Function to set success message and clear it after 4 seconds
  const showSuccess = (message) => {
    setSuccess(message);
    setTimeout(() => {
      setSuccess("");
    }, 4000);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Check if newPassword and confirmPassword match
    if (passwords.newPassword !== passwords.confirmPassword) {
      showError("New Password and Confirm Password do not match.");
      setLoading(false);
      return;
    }

    if (passwords.currentPassword === passwords.confirmPassword) {
      showError("Old Password and New Password can't be same.");
      setLoading(false);
      return;
    }

    let data = {
      password: passwords.currentPassword,
      newPassword: passwords.newPassword,
    };

    try {
      const response = await apiConnector("POST", CHANGE_PASSWORD, data);
      if (response.status === 200) {
        showSuccess("Password changed successfully!"); // Show success message
        setPasswords({
          currentPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
      } else {
        showError("An unexpected error occurred. Please try again.");
      }
    } catch (error) {
      if (error) {
        showError(error.response.data.message);
      } else {
        showError("An unexpected error occurred. Please try again.");
      }
      console.log("Error occurred:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex items-start justify-start">
      <div className="bg-white p-6 max-w-md w-full">
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <div className="flex items-center gap-2">
              <span className="text-title font-semibold min-w-40">
                Current Password:
              </span>
              <input
                type="password"
                name="currentPassword"
                value={passwords.currentPassword}
                onChange={handleChange}
                onFocus={() => setError("")} // Clear error on focus
                required
                className="border rounded p-2 w-full"
              />
            </div>
          </div>
          <div className="mb-4">
            <div className="flex items-center gap-2">
              <span className="text-title font-semibold min-w-40">
                New Password:
              </span>
              <input
                type="password"
                name="newPassword"
                value={passwords.newPassword}
                onChange={handleChange}
                onFocus={() => setError("")} // Clear error on focus
                required
                className="border rounded p-2 w-full"
              />
            </div>
          </div>
          <div className="mb-4">
            <div className="flex items-center gap-2">
              <span className="text-title font-semibold min-w-40">
                Confirm Password:
              </span>
              <input
                type="password"
                name="confirmPassword"
                value={passwords.confirmPassword}
                onChange={handleChange}
                onFocus={() => setError("")} // Clear error on focus
                required
                className="border rounded p-2 w-full"
              />
            </div>
          </div>
          {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
          {success && (
            <p className="text-green-500 text-sm mb-4">{success}</p>
          )}{" "}
          {/* Success message */}
          <div className="flex justify-end">
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:bg-gray-500 w-[11rem] text-center"
              disabled={loading}
            >
              {loading ? (
                <Loading size={24} className="mr-auto ml-auto" />
              ) : (
                "Update Password"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
