import { toast } from "react-toastify";
import { keyResultEndPoints } from "api/api";
import { apiConnector } from "api/apiconnector";
import {
  updateKeyResult,
  appendKeyResult,
  removeKeyResult,
  setActiveKeyResultId,
  setKeyResultShowDetailViewDrawer,
  setKeyResults,
} from "../slices/keyResultSlice";
import { addObjectiveKeyResults } from "./objectivesAPI";
import { updateFeatureKeyResult } from "../slices/featureSlice";

const {
  ADD_KEY_RESULT,
  GET_ALL_KEY_RESULTS,
  UPDATE_KEY_RESULT,
  UPDATE_KEY_RESULT_FEATURES,
  REMOVE_KEY_RESULT_FEATURES,
  UPDATE_KEY_RESULT_IDEAS,
  REMOVE_KEY_RESULT_IDEAS,
  DELETE_KEY_RESULT,
  ADD_KEY_RESULT_TAGS,
} = keyResultEndPoints;

export const createKeyResult = (title, status, objective) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector("POST", ADD_KEY_RESULT(), {
        title,
        status,
        objective,
      });

      if (response.status === 201 && response.data) {
        dispatch(appendKeyResult(response.data.keyResult));
        dispatch(
          addObjectiveKeyResults(objective, [response.data.keyResult._id])
        );
        toast.success("Key Result added successfully");
      } else {
        toast.error("Failed to add key result");
      }
    } catch (error) {
      console.error("Error adding key result:", error);
      toast.error("Error adding key result");
    }
  };
};

export const getAllKeyResults = (page = 1, limit = 100) => {
  return async (dispatch) => {
    try {
      const queryParams = new URLSearchParams({ page, limit }).toString();
      const response = await apiConnector(
        "GET",
        `${GET_ALL_KEY_RESULTS()}?${queryParams}`
      );
      if (response.status === 200 && response.data) {
        dispatch(setKeyResults(response.data.keyResults));
      } else {
        toast.error("Failed to fetch objectives");
      }
    } catch (error) {
      console.error("Error fetching objectives:", error);
      toast.error("Error fetching objectives");
    }
  };
};

export const updateKeyResultData = (
  keyResultId,
  updatedData,
  isUpdated,
  restOption,
  keyResult
) => {
  return async (dispatch) => {
    let newKeyResult = {};
    if (keyResult && restOption) {
      // Creating a deep copy of the objective to avoid mutating the original object
      newKeyResult = JSON.parse(JSON.stringify(keyResult));
      switch (isUpdated) {
        case "status":
          newKeyResult.status = restOption;
          break;
        case "health":
          newKeyResult.health = updatedData.health;
          break;

        default:
          break;
      }
      // Dispatch an optimistic update to the UI
      dispatch(
        updateKeyResult({
          updatedKeyResult: newKeyResult,
        })
      );
    }
    try {
      const response = await apiConnector(
        "PUT",
        `${UPDATE_KEY_RESULT(keyResultId)}`,
        updatedData
      );

      if (response.status === 200 && response.data) {
        dispatch(
          updateKeyResult({ updatedKeyResult: response.data.keyResult })
        );
        toast.success("Key Result updated successfully");
      } else {
        toast.error("Failed to update key result");
      }
    } catch (error) {
      console.error("Error updating key results:", error);
      toast.error("Error updating key results");
    }
  };
};

export const updateKeyResultFeatures = (keyResultId, features) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector(
        "PUT",
        `${UPDATE_KEY_RESULT_FEATURES(keyResultId)}`,
        {
          features,
        }
      );
      if (response.status === 200 && response.data) {
        dispatch(
          updateKeyResult({
            updatedKeyResult: response.data.keyResult,
            isUpdated: "features",
          })
        );
        dispatch(
          updateFeatureKeyResult({
            updatedFeatureIds: response.data.keyResult.features,
            updatedKeyResultId: keyResultId,
          })
        );
        toast.success("Key Result updated successfully");
      } else {
        toast.error("Failed to update key results");
      }
    } catch (error) {
      console.error("Error updating key results:", error);
      toast.error("Error updating key results");
    }
  };
};

export const removeKeyResultFeatures = (keyResultId, features) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector(
        "DELETE",
        `${REMOVE_KEY_RESULT_FEATURES(keyResultId)}`,
        {
          features,
        }
      );
      if (response.status === 200 && response.data) {
        dispatch(
          updateKeyResult({
            updatedKeyResult: response.data.keyResult,
            isUpdated: "features",
          })
        );
        dispatch(
          updateFeatureKeyResult({
            updatedFeatureIds: response.data.keyResult.features,
            updatedKeyResultId: keyResultId,
          })
        );
        toast.success("Key Result updated successfully");
      } else {
        toast.error("Failed to update key results");
      }
    } catch (error) {
      console.error("Error updating key results:", error);
      toast.error("Error updating key results");
    }
  };
};

export const updateKeyResultIdeas = (keyResultId, ideas) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector(
        "PUT",
        `${UPDATE_KEY_RESULT_IDEAS(keyResultId)}`,
        {
          ideas,
        }
      );
      if (response.status === 200 && response.data) {
        dispatch(
          updateKeyResult({
            updatedKeyResult: response.data.keyResult,
            isUpdated: "ideas",
          })
        );
        toast.success("Key Result updated successfully");
      } else {
        toast.error("Failed to update key results");
      }
    } catch (error) {
      console.error("Error updating key results:", error);
      toast.error("Error updating key results");
    }
  };
};

export const removeKeyResultIdeas = (keyResultId, ideas) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector(
        "DELETE",
        `${REMOVE_KEY_RESULT_IDEAS(keyResultId)}`,
        {
          ideas,
        }
      );
      if (response.status === 200 && response.data) {
        dispatch(
          updateKeyResult({
            updatedKeyResult: response.data.keyResult,
            isUpdated: "ideas",
          })
        );
        toast.success("Key Result updated successfully");
      } else {
        toast.error("Failed to update key results");
      }
    } catch (error) {
      console.error("Error updating key results:", error);
      toast.error("Error updating key results");
    }
  };
};

export const updateKeyResultTags = (keyResultId, tags, fullTags, item) => {
  return async (dispatch) => {
    let newKeyResult = {};
    if (item) {
      newKeyResult = JSON.parse(JSON.stringify(item));
      newKeyResult.tags = fullTags;
      // Dispatch an optimistic update to the UI
      dispatch(
        updateKeyResult({
          updatedKeyResult: newKeyResult,
          isUpdated: "tags",
        })
      );
    }
    try {
      const response = await apiConnector(
        "PUT",
        ADD_KEY_RESULT_TAGS(keyResultId),
        {
          tags,
        }
      );
      if (response.status === 200 && response.data) {
        dispatch(
          updateKeyResult({
            updatedKeyResult: response.data.keyResult,
            isUpdated: "tags",
          })
        );
        toast.success("Key Result updated successfully");
      } else {
        toast.error("Failed to update key results");
      }
    } catch (error) {
      console.error("Error updating key result tags:", error);
      toast.error("Error updating key result tags");
    }
  };
};

export const deleteKeyResult = (keyResultId) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector(
        "DELETE",
        DELETE_KEY_RESULT(keyResultId)
      );

      if (response.status === 200 && response.data) {
        dispatch(setActiveKeyResultId(null));
        dispatch(setKeyResultShowDetailViewDrawer(false));
        dispatch(removeKeyResult(keyResultId));
        toast.success("Key Result deleted successfully");
      }
    } catch (error) {
      toast.error(`Error deleting idea: ${error.message}`);
    }
  };
};
