/* eslint-disable react/prop-types */
import React from "react";
import { Rating as MuiRating } from "@mui/material";
import { FaCircle } from "react-icons/fa";

const Importance = ({ value, handleOptionClick, readOnly = false }) => {
  return (
    <MuiRating
      value={value}
      icon={<FaCircle color="#6076EB" />}
      emptyIcon={<FaCircle />}
      sx={{
        fontSize: "12px",
        "& .MuiRating-icon": {
          width: "0.85rem",
        },
      }}
      onChange={(event, newValue) => {
        if (newValue !== null) {
          handleOptionClick(newValue);
        }
      }}
      readOnly={readOnly}
    />
  );
};

export default Importance;
