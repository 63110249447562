import React from "react";
import { getAllIdeas } from "reduxStore/operations/ideasAPI";
import LinkDetailViewTab from "./linkDetailViewTab/LinkDetailViewTab";
import IconWrapper from "../IconWrapper";
import { fieldIconMapping } from "utils";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveIdeaId,
  setIdeaShowDetailViewDrawer,
} from "reduxStore/slices/ideaSlice";
import { setDetailViewIdeas } from "reduxStore/slices/ideaSlice";
import { useLocation } from "react-router-dom";
import { handleResetActiveIds } from "../../../utils/handleResetActiveIds";
import { resetActiveIds } from "../../../utils/resetActiveIds";

const LinkIdeasDetailViewTab = (props) => {
  const dispatch = useDispatch();
  const { ideas } = useSelector((state) => state.ideas);
  const { pathname } = useLocation();
  return (
    <LinkDetailViewTab
      {...props}
      fetchAllItems={getAllIdeas}
      itemNameField="title"
      itemIconComponent={
        <IconWrapper
          icon={React.createElement(fieldIconMapping.idea)}
          iconColor="text-ideas-icon"
        />
      }
      linkedItemsKey="ideas"
      searchPlaceholder="Search ideas..."
      onLinkedItemClick={(ideaId) => {
        if (pathname === "/ideas") {
          resetActiveIds({
            dispatch,
            resetIdea: false,
          });
        }
        if (pathname.includes("/roadmaps")) {
          resetActiveIds({ dispatch });
        }
        handleResetActiveIds(dispatch, pathname);
        dispatch(setDetailViewIdeas(ideas));
        dispatch(setActiveIdeaId(ideaId));
        dispatch(setIdeaShowDetailViewDrawer(true));
      }}
    />
  );
};

export default LinkIdeasDetailViewTab;
