/* eslint-disable react/prop-types */
import React, { useState } from "react";
import IconsHeader from "components/common/detailView/DetailViewRightSidebarIconsHeader";
import Loader from "components/common/Loader";
import FeatureDetailViewRightDetailSidebar from "./rightSidebarSections/detail/FeatureDetailViewRightDetailSidebar";
import FeatureDetailViewRightLogSidebar from "./rightSidebarSections/log/FeatureDetailViewRightLogSidebar";

const FeatureDetailViewRightSidebar = ({ item = null }) => {
  const [selectedIcon, setSelectedIcon] = useState("detail");
  return (
    <div className="flex flex-col px-2 mt-6 w-full text-primary h-full">
      {!item ? (
        <div className="flex items-center justify-center h-screen">
          <Loader   />
        </div>
      ) : (
        <>
          <IconsHeader
            selectedIcon={selectedIcon}
            setSelectedIcon={setSelectedIcon}
          />
          <hr className="mt-2 mb-5" />
          <div className="h-[720px]">
            {selectedIcon === "detail" && (
              <FeatureDetailViewRightDetailSidebar item={item} />
            )}
            {selectedIcon === "log" && (
              <FeatureDetailViewRightLogSidebar item={item} />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default FeatureDetailViewRightSidebar;
