import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getCookie } from "utils/cookieUtils";
import { setLoading } from "reduxStore/slices/authSlice";
import Loader from "../common/Loader";
import { companyRegister } from "../../reduxStore/operations/authAPI";
import { useSearchParams } from "react-router-dom";

const CompanyRegister = () => {
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [errorMessages, setErrorMessages] = useState({});
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const Usertoken = searchParams.get("token");
  console.log(Usertoken);

  const validateForm = () => {
    let errors = {};

    if (!name) {
      errors.name = "Name is required.";
    }
    if (!companyName) {
      errors.companyName = "Company name is required.";
    }

    setErrorMessages(errors);

    return Object.keys(errors).length === 0;
  };

  const handleCompanyRegister = (e) => {
    e.preventDefault();
    if (validateForm()) {
      dispatch(setLoading(true));
      dispatch(companyRegister(name, companyName, Usertoken, navigate));
    }
  };

  useEffect(() => {
    const token = getCookie("jwt");
    if (token) {
      navigate("/"); // Redirect to home page if user is already logged in
    }
  }, [navigate]);

  return (
    <div className="flex flex-col gap-4 items-center justify-center min-h-screen bg-gray-100">
      <div className=" relative bg-white p-8 rounded shadow-md w-full max-w-sm">
        <div className="  absolute top-[-120px] right-0 w-full  flex items-center justify-center  ">
          <img
            src="/logo.png  "
            alt="logo"
            className=" w-0 h-0  xl:w-[200px] xl:h-[100px] "
          />{" "}
        </div>
        <h2 className="text-2xl mb-6 text-center">Welcome onboard!</h2>
        <form onSubmit={handleCompanyRegister}>
          <div className="mb-4">
            <label className="block text-gray-700">Name</label>
            <input
              type="text"
              className={`w-full p-2 border ${
                errorMessages.name ? "border-red-500" : "border-gray-300"
              } rounded mt-1`}
              value={name}
              placeholder="Name"
              onChange={(e) => setName(e.target.value)}
            />
            {errorMessages.name && (
              <p className="text-red-500 text-sm mt-1">{errorMessages.name}</p>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-gray-700">Company Name</label>
            <input
              type="text"
              className={`w-full p-2 border ${
                errorMessages.companyName ? "border-red-500" : "border-gray-300"
              } rounded mt-1`}
              value={companyName}
              placeholder="Company Name"
              onChange={(e) => setCompanyName(e.target.value)}
            />
            {errorMessages.companyName && (
              <p className="text-red-500 text-sm mt-1">
                {errorMessages.companyName}
              </p>
            )}
          </div>

          <button
            type="submit"
            className="w-full bg-blue-500 text-white p-2 rounded mt-4 hover:bg-blue-600"
          >
            {loading ? <Loader size="20" color="white" /> : "Take me in"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default CompanyRegister;
