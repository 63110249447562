/* eslint-disable react/prop-types */
import React from "react";
import { CrossSmall } from "react-flaticons";
import Importance from "../../Importance";
import IconWrapper from "../../IconWrapper";
import { fieldIconMapping } from "utils";

const ImportanceButton = ({
  updatedImportance,
  dropdownState,
  removeSelection,
  toggleDropdown,
  handleOptionClick,
}) => {
  return (
    <button
      className={`relative flex items-center gap-x-1 border border-gray-300 ${
        dropdownState.isImportanceDropdownOpen
          ? "bg-button-active_10"
          : "hover:bg-row-background"
      }  p-1.5 rounded-md`}
      onClick={() => toggleDropdown("isImportanceDropdownOpen")}
    >
      {updatedImportance ? (
        <div className="flex items-center gap-x-1">
          <div title="Importance" className="rounded">
            <IconWrapper
              icon={React.createElement(fieldIconMapping.importance)}
              iconColor="text-title_50"
              size={14}
            />
          </div>
          <Importance value={updatedImportance} readOnly={true} />
        </div>
      ) : (
        "Change Importance"
      )}
      {updatedImportance && (
        <div
          className="cursor-pointer hover:bg-button-hover p-0.5 rounded-full"
          onClick={(e) => {
            e.stopPropagation();
            removeSelection("importance");
          }}
        >
          <CrossSmall size={12} />
        </div>
      )}
      {dropdownState.isImportanceDropdownOpen && (
        <div
          className={`absolute bg-white border-2 rounded-lg z-10 p-2 cursor-default shadow-lg max-h-[320px] overflow-auto custom-scrollbar right-0 bottom-full w-32`}
        >
          {[1, 2, 3, 4, 5].map((importance) => (
            <div
              key={importance}
              className="p-2 rounded hover:bg-topbar-button-hover cursor-pointer"
              onClick={() => {
                handleOptionClick("importance", importance);
              }}
            >
              <Importance value={importance} readOnly={true} />
            </div>
          ))}
        </div>
      )}
    </button>
  );
};

export default ImportanceButton;
