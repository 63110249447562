/* eslint-disable react/prop-types */
import React from "react";
import IconText from "components/common/IconText";
import { fieldIconMapping } from "utils";
import Importance from "components/common/Importance";

const ImportanceSection = ({
  value,
  with_icon = true,
  handleImportanceOptionClick,
  position = "gap-x-2.5",
}) => {
  const handleLocalImportanceOptionClick = (option) => {
    handleImportanceOptionClick(option);
  };

  return (
    <div className={`flex ${position} items-center text-title_50`}>
      {with_icon && (
        <IconText
          icon={React.createElement(fieldIconMapping.importance)}
          text="Importance"
          iconColor="#696969"
          iconSize={16}
          hoverable={false}
          gap_x="gap-x-2"
        />
      )}
      <Importance
        value={value}
        handleOptionClick={handleLocalImportanceOptionClick}
      />
    </div>
  );
};

export default ImportanceSection;
