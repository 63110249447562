/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  RiProgress5Line,
  RiProgress8Fill,
  RiProgress8Line,
} from "react-icons/ri";
import { Minus } from "react-flaticons";
import { transformFiltersToObject } from "utils";
import Sidebar from "components/core/Sidebar";
import Topbar from "components/core/topbar/Topbar";
import Loader from "components/common/Loader";
import IconWrapper from "components/common/IconWrapper";
import { capitalizeFirstLetter, fieldIconMapping, truncateText } from "utils";
import { ideaTopbarButtonFunctions } from "utils/topbarButtonFunctions";
import {
  setIdeaActiveProperties,
  setIdeaViewType,
} from "reduxStore/slices/ideaSlice";
import {
  setActiveRoadmapId,
  setRoadmapShowDetailViewDrawer,
} from "reduxStore/slices/roadmapsSlice";
import { TbCircleDashed } from "react-icons/tb";
import IdeasRoadmapsComponent from "./IdeasRoadmapsComponent";
import { particularRoadmapTopbarTabFunctions } from "utils/topbarTabFunctions";
import { fetchAllViews } from "reduxStore/operations/viewsAPI";
import AllDetailView from "components/common/AllDetailView";

const RoadmapIdeasDetailViewComponent = ({
  roadmap,
  showBreadcrumb = true,
}) => {
  const dispatch = useDispatch();
  const { statuses, timeFrames, tags } = useSelector((state) => state.company);
  const { viewType, isLoading, isViewsLoading } = useSelector(
    (state) => state.ideas
  );
  const { roadmapIdViews } = useSelector((state) => state.roadmaps);

  const { accounts } = useSelector((state) => state.accounts);
  const [ideaStatuses, setIdeaStatuses] = useState([]);
  const [searchbarTerm, setSearchbarTerm] = useState("");
  const [currentViewType, setCurrentViewType] = useState(null);
  const [tabs, setTabs] = useState(null);
  const crumbs = [
    {
      icon: (
        <IconWrapper
          icon={React.createElement(fieldIconMapping.roadmap)}
          iconColor="text-roadmaps-icon"
        />
      ),
      name: "Roadmaps",
      path: `/roadmaps`,
    },
    {
      name: truncateText(roadmap.title, 20),
      path: `/roadmaps/${roadmap._id}`,
    },
  ];

  useEffect(() => {
    setIdeaStatuses(statuses.idea);
  }, [statuses]);

  const filteredTimeFrames = useMemo(() => {
    if (roadmap?.manualTimeFrame?.startDate) {
      return (timeFrames ?? []).filter(
        (timeFrame) =>
          new Date(timeFrame.endDate) <
            new Date(roadmap.manualTimeFrame.endDate) &&
          new Date(timeFrame.endDate) >
            new Date(roadmap.manualTimeFrame.startDate)
      );
    } else {
      return roadmap?.timeFrames || timeFrames;
    }
  }, [roadmap?.manualTimeFrame, timeFrames, roadmap?.timeFrames]);

  useEffect(() => {
    if (viewType !== "kanban") {
      dispatch(setIdeaViewType("kanban"));
      setCurrentViewType("kanban");
    }
  }, [viewType]);

  useEffect(() => {
    dispatch(fetchAllViews("roadmapId", null, roadmap._id));
  }, [dispatch, roadmap]);

  useEffect(() => {
    if (roadmapIdViews) {
      const transformedLocalViews = roadmapIdViews.map((view) => ({
        ...view,
        filters: transformFiltersToObject(view.filters),
      }));
      setTabs([...transformedLocalViews]);
    }
  }, [roadmapIdViews]);

  const topbarFunctions = ideaTopbarButtonFunctions(dispatch);
  const topbarTabFunctions = particularRoadmapTopbarTabFunctions(dispatch);
  const setActiveProperties = (
    sortOption,
    selectedColumns,
    groupByOption,
    filters
  ) => {
    dispatch(
      setIdeaActiveProperties({
        sortOption,
        selectedColumns,
        groupByOption,
        filters,
      })
    );
  };

  const statusParked =
    (ideaStatuses ?? []).filter((item) => item.type === "parked") ?? [];
  const statusToDo =
    (ideaStatuses ?? []).filter((item) => item.type === "to_do") ?? [];
  const statusInProgress =
    (ideaStatuses ?? []).filter((item) => item.type === "in_progress") ?? [];
  const statusDone =
    (ideaStatuses ?? []).filter((item) => item.type === "done") ?? [];

  const groupByDropdownOptions = [
    { label: "Default", icon: Minus },
    ...(viewType !== "kanban"
      ? [{ label: "Time frame", icon: fieldIconMapping["time_frame"] }]
      : []),
    { label: "Status", icon: fieldIconMapping["status"] },
    { label: "Product", icon: fieldIconMapping["product"] },
  ];

  const getFilteredIdeas = () => {
    const predefinedFields = [
      "status",
      "importance",
      "score",
      "product",
      "accounts",
      "tags",
      "assignee",
      // "roadmap",
      "feature",
    ];

    const filteredOptions = predefinedFields.map((field) => ({
      label: capitalizeFirstLetter(field),
      icon: fieldIconMapping[field] || Minus,
    }));
    return [...filteredOptions];
  };

  // Determine whether to show dropdown options based on ideas
  const showButtonDropdownOptions = getFilteredIdeas();

  const getFilterOptions = (withTimeFrame = true) =>
    [
      {
        label: "Status",
        icon: fieldIconMapping.status,
        subOptions: [
          ...statusParked.map((item) => ({
            ...item,
            iconColor: item.color,
            icon: TbCircleDashed,
          })),
          ...statusToDo.map((item) => ({
            ...item,
            iconColor: item.color,
            icon: RiProgress8Fill,
          })),
          ...statusInProgress.map((item) => ({
            ...item,
            iconColor: item.color,
            icon: RiProgress5Line,
          })),
          ...statusDone.map((item) => ({
            ...item,
            iconColor: item.color,
            icon: RiProgress8Line,
          })),
        ],
      },
      withTimeFrame
        ? {
            label: "Time Frame",
            icon: fieldIconMapping.time_frame,
            subOptions: (filteredTimeFrames ?? []).map((timeFrame) => ({
              ...timeFrame,
              icon: fieldIconMapping.timeFrames,
              iconColor: timeFrame.color,
            })),
          }
        : null,
      {
        label: "Product",
        icon: fieldIconMapping.product,
        subOptions: (roadmap?.products ?? []).map((product) => ({
          ...product,
          iconColor: product.color,
        })),
      },
      {
        label: "Importance",
        icon: fieldIconMapping.importance,
        subOptions: [1, 2, 3, 4, 5].map((importance) => ({
          _id: importance,
          label: importance.toString(),
        })),
      },
      {
        label: "Accounts",
        icon: fieldIconMapping.accounts,
        subOptions: (accounts ?? []).map((account) => ({
          ...account,
          label: account.name,
        })),
      },
      {
        label: "Assignee",
        icon: fieldIconMapping.assignee,
      },
      {
        label: "Tags",
        icon: fieldIconMapping.tags,
        subOptions: tags ?? [],
      },
    ].filter(Boolean); // Filter out null values if withTimeFrame is false

  const filterOptions = getFilterOptions();
  const allFilterOptions = getFilterOptions(false);

  // const sortOptions = [
  //   "Default",
  //   "Status",
  //   "Product",
  //   "Age",
  //   "Importance",
  //   "Score",
  //   "Assignee",
  // ].map((label) => ({
  //   label,
  // }));

  const sortOptions = [
    { label: "Created", icon: Minus },
    { label: "Status", icon: fieldIconMapping.status },
    { label: "Product", icon: fieldIconMapping.product },
    // { label: "Age", icon: fieldIconMapping.age },
    { label: "Importance", icon: fieldIconMapping.importance },
    { label: "Score", icon: fieldIconMapping.score },
    { label: "Assignee", icon: fieldIconMapping.assignee },
  ];

  return (
    <div className="flex font-inter w-screen h-screen tracking-wide cursor-default">
      <Sidebar />
      {(isLoading && isViewsLoading && currentViewType !== null) ||
      tabs === null ? (
        <div className="absolute inset-0 flex items-center justify-center">
          <Loader />
        </div>
      ) : (
        <div className="flex flex-col min-w-[80%] w-[100%]">
          <Topbar
            title="Ideas"
            tabs={tabs}
            groupByDropdownOptions={groupByDropdownOptions}
            showButtonDropdownOptions={showButtonDropdownOptions}
            filterOptions={filterOptions}
            sortOptions={sortOptions}
            topbarFunctions={topbarFunctions}
            topbarTabFunctions={topbarTabFunctions}
            setActiveProperties={setActiveProperties}
            with_different_view_option={false}
            activeState="ideas"
            showBreadcrumb={showBreadcrumb}
            breadCrumbs={crumbs}
            handleRightSidebarClick={() => {
              dispatch(setActiveRoadmapId(roadmap._id));
              dispatch(setRoadmapShowDetailViewDrawer(true));
            }}
            withShareOption={true}
            withTopSidebarOption={true}
            with_group_by_option={false}
            searchTerm={searchbarTerm}
            onSearchChange={(value) => setSearchbarTerm(value)}
            roadmap={roadmap}
          />
          <IdeasRoadmapsComponent
            searchTerm={searchbarTerm}
            roadmap={roadmap}
            filterOptions={allFilterOptions}
          />
        </div>
      )}
      <AllDetailView />
    </div>
  );
};

export default RoadmapIdeasDetailViewComponent;
