/* eslint-disable react/prop-types */
import React from "react";
import { Trash } from "react-flaticons";
import IconText from "../../IconText";

const DeleteButton = ({ handleDelete, itemsToDelete }) => {
  return (
    <IconText
      icon={<Trash />}
      text={"Delete"}
      onClick={handleDelete}
      gap_x="gap-x-2"
      iconTextBgColor={itemsToDelete ? "bg-red-500" : "border"}
      hoverColor={
        itemsToDelete ? "hover:bg-red-600" : "hover:bg-row-background"
      }
      textColor={itemsToDelete ? "text-white" : "text-title_50"}
      iconColor={itemsToDelete ? "text-white" : "text-title_50"}
      textSize="text-xs"
      px="px-1.5"
      py="py-1.5"
    />
  );
};

export default DeleteButton;
