/* eslint-disable react/prop-types */
import React from "react";
import IconText from "./IconText";
import { rgbColor } from "utils";

const DropdownItem = ({
  option,
  hoverColor = "hover:bg-topbar-button-hover",
  handleOptionClick = () => {},
  py = "py-2",
  px = "px-2",
  textSize = "text-sm",
  iconSize = 16,
  selectedSubOptionLength = 0,
  textBgColor = "",
  textColor = "text-primary",
  dropDownBgColor = "",
  iconColor = "",
  gap_x = "gap-x-3",
  rightIcon = null,
}) => {
  return (
    <div
      className={`flex items-center justify-between   ${
        dropDownBgColor && "p-1 -mx-1 -my-0.5"
      } ${hoverColor} rounded cursor-pointer`}
      onClick={() => handleOptionClick(option)}
    >
      {option.icon ? (
        <div className={dropDownBgColor && "bg-white w-fit rounded"}>
          <IconText
            icon={
              React.isValidElement(option.icon)
                ? option.icon
                : React.createElement(option.icon)
            }
            iconTextBgColor={
              dropDownBgColor ? rgbColor(dropDownBgColor, 0.2) : null
            }
            text={option.label}
            isASidebarComponent={false}
            textSize={textSize}
            iconSize={iconSize}
            hoverable={false}
            textBgColor={(option?.withBackgroundColor ?? true) && textBgColor}
            iconColor={iconColor}
            isTextActive={selectedSubOptionLength > 0}
            gap_x={gap_x}
            textColor={textColor}
            py={"rounded " + py}
            px={px}
          />
        </div>
      ) : (
        <div
          className={`flex justify-between w-full rounded break-words ${
            (option?.withBackgroundColor ?? true) && textBgColor
          }`}
        >
          <div className={dropDownBgColor && "rounded w-fit bg-white"}>
            <div
              className={`${px} ${py} rounded ${textSize}`}
              style={{
                backgroundColor: dropDownBgColor
                  ? rgbColor(dropDownBgColor, 0.2)
                  : null,
              }}
            >
              {option.label}
            </div>
          </div>
        </div>
      )}
      {selectedSubOptionLength > 1 && (
        <div className="rounded-full px-1 mr-2 py-0.25 bg-filter-extra text-white text-xs">
          {selectedSubOptionLength}
        </div>
      )}
      {rightIcon && (
        <div className="ml-2">
          {React.isValidElement(rightIcon)
            ? rightIcon
            : React.createElement(rightIcon)}
        </div>
      )}
    </div>
  );
};

export default DropdownItem;
