import { createSlice } from "@reduxjs/toolkit";
import { isObject, transformFiltersToObject } from "utils";

const initialState = {
  features: [],
  selectedColumns: ["status", "release", "score", "product", "tags"],
  filters: {},
  sortOption: { order: "asc", field: "created" },
  kanbanViews: [],
  listViews: [],
  groupByOption: "default",
  viewType: "classiclist",
  activeFeatureId: null,
  showDetailViewDrawer: false,
  modernListExists: false,
  isLoading: false,
  isViewsLoading: false,
  detailViewFeatures: [],
};

const priorityMap = {
  status: 1,
  release: 2,
  score: 3,
  product: 4,
  tags: 5,
  assignee: 6,
  roadmaps: 7,
  objectives: 8,
  key_result: 9,
};

const featuresSlice = createSlice({
  name: "features",
  initialState,
  reducers: {
    setDetailViewFeatures(state, action) {
      state.detailViewFeatures = action.payload;
    },
    setFeatures(state, action) {
      state.features = action.payload;
      state.isLoading = false;
    },
    setFeatureRoadmaps(state, action) {
      const { releaseIds, roadmaps } = action.payload;

      const releaseIdSet = new Set(releaseIds);

      state.features.forEach((feature, index) => {
        const releaseId = feature?.release?._id;
        const productId = feature?.product?._id;

        if (releaseId && releaseIdSet.has(releaseId)) {
          const roadmap = roadmaps[releaseId];

          if (roadmap && roadmap.length > 0) {
            const productRoadmaps = roadmap.filter((r) =>
              r.products.includes(productId)
            );

            if (productRoadmaps.length > 0) {
              state.features[index].roadmaps = productRoadmaps.map(
                (r) => r._id
              );
            } else {
              state.features[index].roadmaps = [];
            }
          } else {
            state.features[index].roadmaps = [];
          }
        } else if (feature.release === null) {
          state.features[index].roadmaps = [];
        }
      });
    },
    updateFeature(state, action) {
      const { updatedFeature, isUpdated } = action.payload;
      const index = state.features.findIndex(
        (feature) => feature._id === updatedFeature._id
      );

      if (index !== -1) {
        if (isUpdated === "score") {
          state.features[index].score = updatedFeature.score;
        } else if (isUpdated === "ideas") {
          state.features[index].ideas = updatedFeature.ideas;
        } else if (isUpdated === "tags") {
          state.features[index].tags = updatedFeature.tags;
        } else if (isUpdated === "release") {
          state.features[index].release = updatedFeature.release;
        } else if (isUpdated === "status") {
          state.features[index].status = updatedFeature.status;
        } else if (isUpdated === "product") {
          state.features[index].product = updatedFeature.product;
        } else if (isUpdated === "title") {
          state.features[index].title = updatedFeature.title;
        } else if (isUpdated === "description") {
          state.features[index].description = updatedFeature.description;
        } else {
          state.features[index] = updatedFeature;
        }
      }
    },
    updateFeatureKeyResult(state, action) {
      const { updatedFeatureIds, updatedKeyResultId } = action.payload;

      updatedFeatureIds.forEach((featureId) => {
        const index = state.features.findIndex(
          (feature) => feature._id === featureId
        );

        if (index !== -1) {
          const keyResults = state.features[index].keyResults;

          const keyResultIndex = keyResults.indexOf(updatedKeyResultId);

          if (keyResultIndex !== -1) {
            keyResults.splice(keyResultIndex, 1);
          } else {
            keyResults.push(updatedKeyResultId);
          }
        }
      });

      state.features.forEach((feature) => {
        if (!updatedFeatureIds.includes(feature._id)) {
          feature.keyResults = feature.keyResults.filter(
            (keyResultId) => keyResultId !== updatedKeyResultId
          );
        }
      });
    },
    updateFeatureObjective(state, action) {
      const { updatedFeatureIds, updatedObjectiveId } = action.payload;

      updatedFeatureIds.forEach((featureId) => {
        const index = state.features.findIndex(
          (feature) => feature._id === featureId
        );

        if (index !== -1) {
          const objectives = state.features[index].objectives;

          const objectiveIndex = objectives.indexOf(updatedObjectiveId);

          if (objectiveIndex !== -1) {
            objectives.splice(objectiveIndex, 1);
          } else {
            objectives.push(updatedObjectiveId);
          }
        }
      });

      state.features.forEach((feature) => {
        if (!updatedFeatureIds.includes(feature._id)) {
          feature.objectives = feature.objectives.filter(
            (objectiveId) => objectiveId !== updatedObjectiveId
          );
        }
      });
    },
    appendFeature(state, action) {
      const newFeature = action.payload;
      // state.features.unshift(newFeature);
      state.features.push(newFeature);
    },
    removeFeature(state, action) {
      const deletedFeatureId = action.payload;
      const index = state.features.findIndex(
        (feature) => feature._id === deletedFeatureId
      );
      if (index !== -1) {
        state.features.splice(index, 1);
      }
    },
    removeFeatures(state, action) {
      const { featureIds } = action.payload;
      featureIds.forEach((featureId) => {
        const index = state.features.findIndex(
          (feature) => feature._id === featureId
        );
        if (index !== -1) {
          state.features.splice(index, 1);
        }
      });
    },
    updateFeatures(state, action) {
      const { updatedFeatures, isUpdatedArray } = action.payload;

      console.log(isUpdatedArray);

      updatedFeatures.forEach((updatedFeature) => {
        const index = state.features.findIndex(
          (feature) => feature._id === updatedFeature._id
        );

        if (index !== -1) {
          if (isUpdatedArray.length > 0) {
            isUpdatedArray.forEach((updateKey) => {
              state.features[index][updateKey] = updatedFeature[updateKey];
            });
          } else {
            state.features[index] = updatedFeature;
          }
        }
      });
    },
    toggleFeatureColumn(state, action) {
      const column = action.payload;

      if (state.selectedColumns.includes(column)) {
        state.selectedColumns = state.selectedColumns.filter(
          (col) => col !== column
        );
      } else {
        const columnPriority = priorityMap[column];
        let insertIndex = state.selectedColumns.length;

        for (let i = 0; i < state.selectedColumns.length; i++) {
          const currentPriority = priorityMap[state.selectedColumns[i]];
          if (columnPriority < currentPriority) {
            insertIndex = i;
            break;
          }
        }

        state.selectedColumns.splice(insertIndex, 0, column);
      }
    },
    setFeatureFilters(state, action) {
      state.filters = action.payload;
    },
    updateFeatureFilter(state, action) {
      const { key, value } = action.payload;

      if (!state.filters[key]) {
        state.filters[key] = [];
      }

      const existingIndex = state.filters[key].findIndex(
        (v) => JSON.stringify(v) === JSON.stringify(value)
      );

      if (existingIndex !== -1) {
        state.filters[key] = state.filters[key].filter(
          (v) => JSON.stringify(v) !== JSON.stringify(value)
        );
      } else {
        state.filters[key].push(value);
      }

      if (state.filters[key].length === 0) {
        delete state.filters[key];
      }
    },
    clearFeatureFilters(state) {
      state.filters = {};
    },
    setFeatureSortOption(state, action) {
      state.sortOption = action.payload;
    },
    setFeatureActiveProperties(state, action) {
      const { filters, groupByOption, sortOption, selectedColumns } =
        action.payload;

      const finalFilters = isObject(filters)
        ? filters
        : transformFiltersToObject(filters);

      state.filters = finalFilters || state.filters;
      state.groupByOption = groupByOption || state.groupByOption;
      state.sortOption = sortOption || state.sortOption;
      state.selectedColumns = selectedColumns || state.selectedColumns;
    },
    setFeatureViews(state, action) {
      const { views, viewTypeName } = action.payload;
      if (
        viewTypeName === "classiclist" ||
        viewTypeName === "modernlist" ||
        viewTypeName === "list"
      ) {
        state.listViews = views;
      } else {
        state.kanbanViews = views;
      }
      state.isViewsLoading = false;
    },
    appendFeatureView(state, action) {
      const { newlyAddedView, viewTypeName } = action.payload;
      if (
        viewTypeName === "list" ||
        viewTypeName === "modernlist" ||
        viewTypeName === "classiclist"
      ) {
        state.listViews.push(newlyAddedView);
      } else {
        state.kanbanViews.push(newlyAddedView);
      }
    },
    removeFeatureView(state, action) {
      const { deletedViewId, viewTypeName } = action.payload;
      let index = -1;
      if (
        viewTypeName === "list" ||
        viewTypeName === "modernlist" ||
        viewTypeName === "classiclist"
      ) {
        index = state.listViews.findIndex((view) => view._id === deletedViewId);
        if (index !== -1) {
          state.listViews.splice(index, 1);
        }
      } else {
        index = state.kanbanViews.findIndex(
          (view) => view._id === deletedViewId
        );
        if (index !== -1) {
          state.kanbanViews.splice(index, 1);
        }
      }
    },
    updateFeatureView(state, action) {
      const { updatedView, viewTypeName } = action.payload;
      let index = -1;
      if (
        viewTypeName === "list" ||
        viewTypeName === "modernlist" ||
        viewTypeName === "classiclist"
      ) {
        index = state.listViews.findIndex(
          (view) => view._id === updatedView._id
        );
        if (index !== -1) {
          state.listViews[index] = updatedView;
        }
      } else {
        index = state.kanbanViews.findIndex(
          (view) => view._id === updatedView._id
        );
        if (index !== -1) {
          state.kanbanViews[index] = updatedView;
        }
      }
    },
    setFeatureGroupByOption(state, action) {
      state.groupByOption = action.payload;
    },
    setFeatureViewType(state, action) {
      state.viewType = action.payload;
      state.isViewsLoading = false;
    },
    setActiveFeatureId(state, action) {
      state.activeFeatureId = action.payload;
    },
    setFeatureShowDetailViewDrawer(state, action) {
      state.showDetailViewDrawer = action.payload;
    },
    setIsFeatureLoading(state, action) {
      state.isLoading = action.payload;
    },
    setIsFeatureViewLoading(state, action) {
      state.isViewsLoading = action.payload;
    },
    addFeatureIdea(state, action) {
      const { features, ideaId } = action.payload;

      const featureSet = new Set(features);
      state.features?.forEach((feature) => {
        if (featureSet.has(feature?._id)) {
          const ideaSet = new Set(feature.ideas);
          ideaSet.add(ideaId);

          feature.ideas = Array.from(ideaSet);
        }
      });
    },

    removeFeatureIdea(state, action) {
      const { features, ideaId } = action.payload;

      const featureSet = new Set(features);
      state.features?.forEach((feature) => {
        if (featureSet.has(feature?._id)) {
          feature.ideas = feature.ideas.filter((id) => id !== ideaId);
        }
      });
    },
    updateFeatureStatus(state, action) {
      const updatedStatus = action.payload;

      state.features?.forEach((feature) => {
        if (feature?.status?._id === updatedStatus._id) {
          feature.status = updatedStatus;
        }
      });
    },
    replaceFeatureStatus(state, action) {
      const { statusId, replaceStatus } = action.payload;

      state.features?.forEach((feature) => {
        if (feature?.status?._id === statusId) {
          feature.status = replaceStatus;
        }
      });
    },
  },
});

export const {
  setFeatures,
  setFeatureRoadmaps,
  setDetailViewFeatures,
  updateFeature,
  updateFeatureKeyResult,
  updateFeatureObjective,
  appendFeature,
  removeFeature,
  removeFeatures,
  updateFeatures,
  toggleFeatureColumn,
  setFeatureFilters,
  updateFeatureFilter,
  clearFeatureFilters,
  setFeatureSortOption,
  setFeatureActiveProperties,
  setFeatureViews,
  updateFeatureView,
  appendFeatureView,
  removeFeatureView,
  setFeatureGroupByOption,
  setFeatureViewType,
  setActiveFeatureId,
  setFeatureShowDetailViewDrawer,
  setIsFeatureLoading,
  setIsFeatureViewLoading,
  addFeatureIdea,
  removeFeatureIdea,
  updateFeatureStatus,
  replaceFeatureStatus,
} = featuresSlice.actions;

export const featuresReducer = featuresSlice.reducer;
