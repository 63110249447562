import React from "react";
import SettingsTopbar from "./SettingsTopbar";
import { RiBillFill } from "react-icons/ri";
import { CreditCard } from "react-flaticons";

const Billing = () => {
  return (
    <div>
      <SettingsTopbar
        customTab={"Billing"}
        title="Billing"
        icon={<CreditCard />}
      />
      <div className="flex justify-center items-center h-[80vh] ">
        <p className=" text-lg text-gray-500 ">
          Special pricing coming your way very soon
        </p>
      </div>
    </div>
  );
};

export default Billing;
