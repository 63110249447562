import {
  updateRelease,
  appendRelease,
  removeRelease,
  setReleases,
} from "../slices/companySlice";
import { apiConnector } from "api/apiconnector";
import { releaseEndPoints } from "api/api";
import { setObjectives } from "../slices/objectiveSlice";
import { setRoadmaps } from "../slices/roadmapsSlice";
import { setFeatureRoadmaps, setFeatures } from "../slices/featureSlice";
import { setIdeas } from "../slices/ideaSlice";
import { setKeyResults } from "../slices/keyResultSlice";
import { toast } from "react-toastify";

const {
  ADD_COMPANY_RELEASE,
  EDIT_COMPANY_RELEASE,
  DELETE_COMPANY_RELEASE,
  GET_COMPANY_RELEASES,
  GET_RELEASE_ROADMAPS,
} = releaseEndPoints;

export const getCompanyReleases = () => {
  return async (dispatch) => {
    try {
      const response = await apiConnector("GET", GET_COMPANY_RELEASES());
      if (response.status === 200) {
        dispatch(setReleases(response.data));
      }
    } catch (error) {
      console.error("Error getting releases", error);
    }
  };
};

export const getReleaseRoadmaps = (releaseIds) => {
  return async (dispatch) => {
    try {
      const query = releaseIds.join(",");
      const response = await apiConnector("GET", GET_RELEASE_ROADMAPS(query));

      if (response.status === 200) {
        dispatch(
          setFeatureRoadmaps({
            releaseIds: releaseIds,
            roadmaps: response.data.groupedRoadmaps,
          })
        );
      }
    } catch (error) {
      // Check if the error has a response and if the status is 404
      if (error.response && error.response.status === 404) {
        dispatch(
          setFeatureRoadmaps({
            releaseIds: releaseIds,
            roadmaps: error.response.data.groupedRoadmaps,
          })
        );
      } else {
        // Handle other errors
        console.error("Error getting releases", error);
      }
    }
  };
};

export const addReleaseToCompany = (label, startDate, endDate) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector("POST", ADD_COMPANY_RELEASE(), {
        label,
        startDate,
        endDate,
      });
      if (response.status === 201) {
        dispatch(appendRelease(response.data));
      } else {
        console.error("Failed to add release:", response.statusText);
      }
    } catch (error) {
      console.error("Error deleting release:", error);
    }
  };
};

export const editReleaseInCompany = (releaseId, label, startDate, endDate) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector(
        "PUT",
        EDIT_COMPANY_RELEASE(releaseId),
        {
          label,
          startDate,
          endDate,
        }
      );
      if (response.status === 200) {
        dispatch(setObjectives(null));
        dispatch(setRoadmaps(null));
        dispatch(setFeatures(null));
        dispatch(setIdeas(null));
        dispatch(setKeyResults(null));
        dispatch(updateRelease(response.data.release));
      } else {
        console.error("Failed to delete tag:", response.statusText);
      }
    } catch (error) {
      console.error("Error deleting tag:", error);
    }
  };
};

export const deleteReleaseOfCompany = (releaseId) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector(
        "DELETE",
        DELETE_COMPANY_RELEASE(releaseId)
      );
      if (response.status === 200) {
        dispatch(setObjectives(null));
        dispatch(setRoadmaps(null));
        dispatch(setFeatures(null));
        dispatch(setIdeas(null));
        dispatch(setKeyResults(null));
        dispatch(removeRelease(releaseId));
      } else {
        console.error("Failed to delete release:", response.statusText);
      }
    } catch (error) {
      console.error("Error deleting release:", error);
    }
  };
};
