/* eslint-disable react/prop-types */
import React from "react";
import { objectiveHealths } from "utils/objectiveUtils";
import IconText from "components/common/IconText";
import { capitalizeFirstLetter } from "utils";

const HealthRowSection = ({ health, dropdownState, toggleDropdown }) => {
  const healthIconColor = {
    off_track: "#FF9D66",
    on_track: "#84DF81",
    at_risk: "#FF6678",
  };
  return (
    <div
      className={`p-1 w-fit rounded hover:bg-gray-200 
      ${dropdownState.isHealthDropdownOpen && "bg-button-active_10"}
      `}
      onClick={(e) => {
        e.stopPropagation();
        toggleDropdown("isHealthDropdownOpen");
      }}
    >
      <IconText
        icon={React.createElement(objectiveHealths()[0].icon)}
        text={capitalizeFirstLetter(health ?? "")}
        iconColor={healthIconColor[health]}
        textColor={"text-title_50"}
        px=""
        py=""
        hoverColor=""
        gap_x="gap-x-1"
        textSize="text-xs"
        iconSize="12"
      />
    </div>
  );
};

export default HealthRowSection;
