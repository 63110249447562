/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import IconsHeader from "components/common/detailView/DetailViewRightSidebarIconsHeader";
import StatusSection from "components/common/detailView/detailViewRightSidebarSections/StatusSection";
import TagsSection from "components/common/detailView/detailViewRightSidebarSections/TagsSection";
import AssigneeSection from "components/common/detailView/detailViewRightSidebarSections/AssigneeSection";
import Loader from "components/common/Loader";
import { generateStatusArray } from "utils/status";
import { fieldIconMapping, toSnakeCase } from "utils";
import {
  updateKeyResultTags,
  updateKeyResultData,
} from "reduxStore/operations/keyResultsAPI";
import Progress from "components/common/Progress";
import IconText from "components/common/IconText";
import HealthSection from "components/common/detailView/detailViewRightSidebarSections/HealthSection";
import { getKeyResultProgress } from "utils/progressUtils";
import { getHealthBgColor } from "utils/objectiveUtils";

const KeyResultDetailViewRightSidebar = ({ item = null }) => {
  const { tags, statuses } = useSelector((state) => state.company);
  const { features } = useSelector((state) => state.features);
  const { ideas } = useSelector((state) => state.ideas);
  const [selectedIcon, setSelectedIcon] = useState("detail");
  const dispatch = useDispatch();
  // const [allKeyResultStatuses, setAllKeyResultStatuses] = useState(null);
  // const [statusArray, setStatusArray] = useState(null);

  // useEffect(() => {
  //   setAllKeyResultStatuses(statuses.key_result);
  // }, [statuses]);

  // useEffect(() => {
  //   if (allKeyResultStatuses !== null) {
  //     setStatusArray(generateStatusArray(allKeyResultStatuses));
  //   }
  // }, [allKeyResultStatuses]);

  const keyResultFeatures = useMemo(() => {
    return (features ?? []).filter((feature) =>
      (item?.features ?? []).includes(feature._id)
    );
  }, [features, item?.features]);

  const keyResultIdeas = useMemo(() => {
    return (ideas ?? []).filter((idea) =>
      (item?.ideas ?? []).includes(idea._id)
    );
  }, [ideas, item?.ideas]);

  const keyResultProgress = getKeyResultProgress(
    keyResultIdeas,
    keyResultFeatures
  );

  return (
    <div className="flex flex-col px-2 mt-6 w-full text-primary">
      {!item ? (
        <div className="flex items-center justify-center h-screen">
          <Loader />
        </div>
      ) : (
        <>
          <IconsHeader
            selectedIcon={selectedIcon}
            setSelectedIcon={setSelectedIcon}
          />
          <hr className="mt-2 mb-5" />
          <div className="flex flex-col gap-y-2">
            {/* <StatusSection
              item={item?.status ?? {}}
              statusArray={statusArray}
              position={"gap-x-10"}
              handleStatusOptionClick={(option) => {
                dispatch(updateKeyResultData(item._id, { status: option._id }));
              }}
            /> */}
            <div className="flex items-center gap-x-2.5">
              <IconText
                icon={React.createElement(fieldIconMapping.progress)}
                text={"Progress"}
                hoverable={false}
                iconSize={16}
                gap_x="gap-x-2"
              />
              <div>
                <Progress
                  value={keyResultProgress ?? 0}
                  color={getHealthBgColor(item?.health)}
                />
              </div>
            </div>
            <HealthSection
              itemLabel={item?.health ?? ""}
              position="gap-x-10"
              handleHealthOptionClick={(option) => {
                let isUpdated = "health";
                let { icon, ...restOption } = option;
                dispatch(
                  updateKeyResultData(
                    item._id,
                    {
                      health: toSnakeCase(option.label),
                    },
                    isUpdated,
                    restOption,
                    item
                  )
                );
              }}
            />
            <TagsSection
              item={item}
              tags={tags ?? []}
              handleTagOptionClick={(option) => {
                dispatch(
                  updateKeyResultTags(
                    option.itemId,
                    option.tags,
                    option.fullTags,
                    item
                  )
                );
              }}
              position="gap-x-14"
            />
            <AssigneeSection
              itemLabel={item?.assignee?.name ?? ""}
              position={"gap-x-7"}
            />
            <hr className="my-6" />
          </div>
        </>
      )}
    </div>
  );
};

export default KeyResultDetailViewRightSidebar;
