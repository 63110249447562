import React from "react";
import ObjectiveKanbanCard from "./ObjectiveKanbanCard";
import KanbanColumn from "components/common/kanban/KanbanColumn";

const ObjectiveKanbanColumn = (props) => {
  return (
    <KanbanColumn
      {...props}
      renderCardComponent={({
        item,
        products,
        innerRef,
        draggableProps,
        dragHandleProps,
        statusArray,
      }) => (
        <ObjectiveKanbanCard
          objective={item}
          products={products}
          innerRef={innerRef}
          draggableProps={draggableProps}
          dragHandleProps={dragHandleProps}
          statusArray={statusArray}
          // setActiveObjectiveCard={props.setActiveObjectiveCard} // If you need to pass this prop
        />
      )}
    />
  );
};

export default ObjectiveKanbanColumn;
