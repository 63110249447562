/* eslint-disable react/prop-types */
import React, { useState } from "react";
import IconsHeader from "components/common/detailView/DetailViewRightSidebarIconsHeader";
import Loader from "components/common/Loader";
import ObjectiveDetailViewRightDetailSidebar from "./rightSidebarSections/detail/ObjectiveDetailViewRightDetailSidebar";
import ObjectiveDetailViewRightLogSidebar from "./rightSidebarSections/log/ObjectiveDetailViewRightLogSidebar";
const ObjectiveDetailViewRightSidebar = ({ item = null }) => {
  const [selectedIcon, setSelectedIcon] = useState("detail");

  return (
    <div className="flex flex-col px-2 mt-6 w-full text-primary h-full">
      {!item ? (
        <div className="flex items-center justify-center h-screen">
          <Loader />
        </div>
      ) : (
        <>
          <IconsHeader
            selectedIcon={selectedIcon}
            setSelectedIcon={setSelectedIcon}
          />
          
          <hr className="mt-2 mb-5" />
          {selectedIcon === "detail" && (
            <ObjectiveDetailViewRightDetailSidebar item={item} />
          )}
          {selectedIcon === "log" && (
            <ObjectiveDetailViewRightLogSidebar item={item} />
          )}
        </>
      )}
    </div>
  );
};

export default ObjectiveDetailViewRightSidebar;
