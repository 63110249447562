/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { CrossSmall, Following } from "react-flaticons";

const AccountPopupOverlay = ({ type, accountData = {}, onClose, onSubmit }) => {
  const [name, setName] = useState(accountData.name || "");
  const [email, setEmail] = useState(accountData.email || "");
  const [value, setValue] = useState(accountData.value || "");
  const [errors, setErrors] = useState({
    name: false,
    email: false,
    value: false,
  });

  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = () => {
    const newErrors = {
      name: !name.trim(),
      email: !isEmailValid(email),
      value: !value,
    };

    setErrors(newErrors);

    const hasErrors = Object.values(newErrors).some((error) => error);
    if (hasErrors) {
      // Show errors for 3 seconds, then clear them
      setTimeout(() => {
        setErrors({ name: false, email: false, value: false });
      }, 3000);
      return;
    }

    if (type === "add") {
      onSubmit({ name, email, value });
    } else if (type === "edit") {
      onSubmit(accountData._id, { name, email, value });
    }

    onClose();
  };

  const handleOverlayClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div
      className="fixed inset-0 bg-add-popup-background bg-opacity-50 flex justify-center z-50"
      onClick={onClose}
    >
      <div
        className=" border border-gray-300 h-fit top-24 bg-white p-5 rounded-md relative min-w-[500px] w-auto max-w-3xl"
        onClick={handleOverlayClick}
      >
        <div className="flex flex-col">
          <div className="flex justify-between pb-1 ">
            <h2 className="flex gap-2 items-center text-gray-600 pt-1  pb-2 ">
              <Following size={18} />
              <span>Account</span>
            </h2>
            <button
              onClick={onClose}
              className="self-start rounded p-0.5 hover:bg-button-hover"
            >
              <CrossSmall color="#374957" />
            </button>
          </div>
          <div className="flex flex-col gap-2">
            <input
              type="text"
              placeholder={errors.name ? "Enter name" : "Account name"}
              value={name}
              onChange={(e) => setName(e.target.value)}
              className={`w-full   text-[16px] py-2 px-1 rounded-sm focus:outline-none ${
                errors.name ? "shadow-red-400 shadow-[0px_0px_3px_1px]" : ""
              }
            `}
            />
            <input
              type="email"
              placeholder={errors.email ? "Enter email" : "Account email"}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={`w-full   text-[16px] py-2 px-1 rounded-sm focus:outline-none ${
                errors.email ? "shadow-red-400 shadow-[0px_0px_3px_1px]" : ""
              }
            `}
            />
            <input
              type="number"
              placeholder={errors.value ? "Enter Value ($) " : "Value ($)"}
              value={value}
              onChange={(e) => setValue(e.target.value)}
              className={`w-full   text-[16px] py-2 px-1 rounded-sm focus:outline-none ${
                errors.value ? "shadow-red-400 shadow-[0px_0px_3px_1px]" : ""
              }`}
            />
          </div>
        </div>
        <div className="w-[100%] h-[1px] bg-gray-200 mt-3 mb-2 "></div>
        <div className=" text-end">
          <button
            onClick={handleSubmit}
            // className="px-1.5 py-1 text-xs bg-add-popup-button rounded text-white"
            className="p-2 bg-add-popup-button rounded text-white w-fit text-sm ml-auto"
          >
            {type === "add" ? "Add Account" : "Update"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AccountPopupOverlay;
