/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { generateStatusArray } from "utils/status";
import AddRoadmapTypeSelection from "./AddRoadmapTypeSelection";
import AddRoadmapColumnSelection from "./AddRoadmapColumnSelection";
import { toast } from "react-toastify";

const AddRoadmapPopupOverlay = ({
  option,
  name,
  setIsNameEditable,
  setIsWarn,
}) => {
  const [status, setStatus] = useState(null);
  const [forRoadmap, setForRoadmap] = useState("Release");

  const { statuses, products } = useSelector((state) => state.company);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedReleases, setSelectedReleases] = useState(new Set());
  const [selectedProducts, setSelectedProducts] = useState(new Set());
  const [selectedTimeFrames, setSelectedTimeFrames] = useState(new Set());
  const [nextClicked, setNextClicked] = useState(false);

  const handleStatusOptionClick = (option) => {
    setStatus(option);
  };

  useEffect(() => {
    if (selectedProducts.size === 0) {

      setSelectedProducts((prevSelected) => {
        const newSelected = new Set(prevSelected);

        products.forEach((product) => {
          newSelected.add(product._id);
        });

        return newSelected;
      });
    }
  }, [selectedProducts]);

  const resetSelectedColumns = () => {
    setSelectedReleases(new Set());
    setSelectedTimeFrames(new Set());
  };

  const handleProductOptionClick = (option) => {
    setSelectedProducts((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(option)) {
        newSelected.delete(option);
      } else {
        newSelected.add(option);
      }
      return newSelected;
    });
  };

  const handleTimeDropdownOptionClick = (option) => {
    setEndDate(null);
    setStartDate(null);
    if (forRoadmap === "Release") {
      setSelectedReleases((prevSelected) => {
        const newSelected = new Set(prevSelected);
        if (newSelected.has(option)) {
          newSelected.delete(option);
        } else {
          newSelected.add(option);
        }
        return newSelected;
      });
    } else {
      setSelectedTimeFrames((prevSelected) => {
        const newSelected = new Set(prevSelected);
        if (newSelected.has(option)) {
          newSelected.delete(option);
        } else {
          newSelected.add(option);
        }
        return newSelected;
      });
    }
  };

  const [roadmapStatuses, setRoadmapStatuses] = useState([]);

  useEffect(() => {
    setRoadmapStatuses(statuses.roadmap);
  }, [statuses]);

  const statusArray = useMemo(
    () => generateStatusArray(roadmapStatuses),
    [roadmapStatuses]
  );

  useEffect(() => {
    setStatus(statusArray[0]);
  }, [statusArray]);

  return (
    <div className="flex flex-col gap-y-2">
      {option.addOptions.includes("forRoadmap") && !nextClicked && (
        <AddRoadmapTypeSelection
          forRoadmap={forRoadmap}
          setForRoadmap={setForRoadmap}
          onNextClick={() => {
            if (name !== "") {
              setNextClicked(true);
              setIsNameEditable(false);
            }
            setIsWarn(true);
            setTimeout(() => {
              setIsWarn(false);
            }, 3000);
          }}
          resetSelectedColumns={resetSelectedColumns}
        />
      )}
      {nextClicked && (
        <div className="flex flex-col gap-y-2">
          <AddRoadmapColumnSelection
            option={option}
            status={status}
            handleStatusOptionClick={handleStatusOptionClick}
            handleProductOptionClick={handleProductOptionClick}
            forRoadmap={forRoadmap}
            startDate={startDate}
            endDate={endDate}
            selectedProducts={selectedProducts}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            selectedTimeFrames={selectedTimeFrames}
            selectedReleases={selectedReleases}
            handleTimeDropdownOptionClick={handleTimeDropdownOptionClick}
          />
          <hr />
          <div className="ml-auto text-sm">
            <button
              className="p-2 mr-2 border rounded hover:bg-button-hover"
              onClick={() => {
                setNextClicked(false);
                setIsNameEditable(true);
              }}
            >
              Back
            </button>
            <button
              className="p-2 bg-add-popup-button rounded text-white"
              onClick={() => {
                if (
                  selectedReleases.size !== 0 ||
                  selectedTimeFrames.size !== 0
                ) {
                  option?.onAddClick({
                    title: name,
                    forRoadmap: forRoadmap,
                    products:
                      forRoadmap !== "Strategy"
                        ? selectedProducts.size > 0
                          ? Array.from(selectedProducts)
                          : products
                        : products,
                    releases: Array.from(selectedReleases) ?? null,
                    timeFrames: Array.from(selectedTimeFrames) ?? null,
                    manualTimeFrame: {
                      startDate: startDate ? new Date(startDate) : null,
                      endDate: endDate ? new Date(endDate) : null,
                    }, // Ensure this is an object
                    status: status?._id ?? null,
                  });
                } else {
                  toast.error("Select Columns First");
                  return;
                }
              }}
            >
              Add {option.label}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddRoadmapPopupOverlay;
