/* eslint-disable react/prop-types */
import React from "react";
import IconText from "components/common/IconText";
import ProfileIcon from "components/common/ProfileIcon";
import ProductSection from "components/common/detailView/detailViewRightSidebarSections/ProductSection";
import { useDispatch, useSelector } from "react-redux";
import StatusSection from "components/common/detailView/detailViewRightSidebarSections/StatusSection";
import {
  setActiveIdeaId,
  setIdeaShowDetailViewDrawer,
} from "reduxStore/slices/ideaSlice";
import { updateIdeaData } from "reduxStore/operations/ideasAPI";
import Importance from "components/common/Importance";
import TagsSection from "components/common/detailView/detailViewRightSidebarSections/TagsSection";
import { fieldIconMapping } from "utils";
import {
  updateIdeaTags,
  updateIdeaAccounts,
} from "reduxStore/operations/ideasAPI";
import KanbanRoadmapsSection from "components/common/kanban/KanbanRoadmapsSection";
import KanbanFeaturesSection from "components/common/kanban/KanbanFeaturesSection";
import AccountsSection from "components/common/detailView/detailViewRightSidebarSections/AccountsSection";
import KanbanScoreSection from "../../../common/kanban/KanbanScoreSection";
import { Cross, CrossSmall } from "react-flaticons";
import { useLocation, useNavigate } from "react-router-dom";

const IdeaKanbanCard = ({
  idea,
  innerRef,
  draggableProps,
  dragHandleProps,
  statusArray,
  handleRemoveIdea,
}) => {
  const dispatch = useDispatch();
  const isRoadmapView = window.location.href.includes("roadmaps");
  const { selectedColumns, showDetailViewDrawer, groupByOption } = useSelector(
    (state) => state.ideas
  );
  const { products, tags } = useSelector((state) => state.company);
  const { accounts } = useSelector((state) => state.accounts);

  const handleKanbanItemClick = () => {
    dispatch(setActiveIdeaId(idea._id));
    dispatch(setIdeaShowDetailViewDrawer(!showDetailViewDrawer));
  };

  const handleImportanceOptionClick = (option) => {
    dispatch(updateIdeaData(idea._id, { importance: option }));
  };

  return (
    <div
      className=" relative group flex flex-col bg-white border border-gray-200 rounded-lg p-4 text-primary hover:cursor-pointer text-xs    "
      onClick={handleKanbanItemClick}
      ref={innerRef}
      {...(groupByOption !== "tags" &&
        groupByOption !== "accounts" && {
          ...draggableProps,
          ...dragHandleProps,
        })}
    >
      {isRoadmapView && (
        <button
          className=" group-hover:opacity-100 opacity-0  hover:bg-button-hover rounded p-0.5 cursor-pointer w-fit absolute  right-2 top-2  "
          onClick={(e) => handleRemoveIdea(e, idea)}
        >
          <CrossSmall size={14} />
        </button>
      )}
      <IconText
        icon={React.createElement(fieldIconMapping.idea)}
        text={idea?.title}
        truncateLength="60"
        align={"items-start"}
        iconColor="text-ideas-icon"
        textColor="text-title"
        hoverable={false}
        px=""
      />
      {selectedColumns.includes("tags") && (
        <div className="flex gap-x-2 items-center mt-5">
          <TagsSection
            item={idea}
            with_title={false}
            with_icon_before_tags={true}
            tags={tags ?? []}
            handleTagOptionClick={(option) => {
              dispatch(updateIdeaTags(option));
            }}
            dropdownPosition={"top-full left-0"}
          />
        </div>
      )}
      {selectedColumns.includes("accounts") && (
        <div className="flex gap-x-2 items-center">
          <AccountsSection
            item={idea}
            with_title={false}
            with_icon_before_accounts={true}
            accounts={accounts ?? []}
            handleAccountOptionClick={(option) => {
              dispatch(updateIdeaAccounts(option));
            }}
            dropdownPosition={"top-full left-0"}
          />
        </div>
      )}
      {selectedColumns.includes("score") && <KanbanScoreSection item={idea} />}
      {selectedColumns.includes("roadmap") && (
        <KanbanRoadmapsSection roadmaps={idea?.roadmaps} />
      )}
      {selectedColumns.includes("feature") && (
        <KanbanFeaturesSection features={idea?.features} />
      )}
      <hr className="w-full mt-1" />
      <div className="flex items-center justify-between mt-2.5">
        <div className="flex flex-1 gap-x-3 items-center">
          {selectedColumns.includes("status") && (
            <div className="relative">
              <StatusSection
                item={idea?.status ?? {}}
                statusArray={statusArray}
                handleStatusOptionClick={(option) => {
                  let isUpdated = "status";
                  let { icon, ...restOption } = option;
                  dispatch(
                    updateIdeaData(
                      idea._id,
                      { status: option._id },
                      idea,
                      isUpdated,
                      restOption
                    )
                  );
                }}
                position=""
                with_title={false}
                with_label={false}
                dropdownMenuWidth="w-36"
              />
            </div>
          )}
          {selectedColumns.includes("importance") && (
            <Importance
              value={idea.importance}
              handleOptionClick={handleImportanceOptionClick}
            />
          )}
          {selectedColumns.includes("product") && (
            <div className="relative">
              <ProductSection
                item={idea.product}
                products={products ?? []}
                handleProductOptionClick={(option) => {
                  dispatch(updateIdeaData(idea._id, { product: option._id }));
                }}
                position=""
                with_title={false}
              />
            </div>
          )}
        </div>
        {selectedColumns.includes("assignee") && (
          <ProfileIcon name={idea?.assignee?.name ?? ""} />
        )}
      </div>
    </div>
  );
};

export default IdeaKanbanCard;
