/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from "react";
import { fieldIconMapping } from "utils";
import IconText from "components/common/IconText";
import StatusSection from "components/common/detailView/detailViewRightSidebarSections/StatusSection";
import ProfileIcon from "components/common/ProfileIcon";
import { useDispatch, useSelector } from "react-redux";
import { generateStatusArray } from "utils/status";
import { CrossSmall } from "react-flaticons";
import { updateFeatureData } from "reduxStore/operations/featuresAPI";
import {
  setActiveFeatureId,
  setFeatureShowDetailViewDrawer,
  setDetailViewFeatures,
} from "reduxStore/slices/featureSlice";
import { useLocation } from "react-router-dom";
import { handleResetActiveIds } from "utils/handleResetActiveIds";
import { resetActiveIds } from "utils/resetActiveIds";

const ObjectiveDetailViewFeatureRowComponent = ({ item, removeFeatures }) => {
  const dispatch = useDispatch();
  const { statuses } = useSelector((state) => state.company);
  const { features } = useSelector((state) => state.features);
  const [featureStatuses, setFeatureStatuses] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    setFeatureStatuses(statuses.feature);
  }, [statuses]);

  const featureStatusArray = useMemo(
    () => generateStatusArray(featureStatuses),
    [featureStatuses]
  );

  const objectiveFeatures = useMemo(() => {
    return (features ?? []).filter((feature) =>
      (item?.features ?? []).includes(feature._id)
    );
  }, [features, item?.features]);

  // Truncate text function
  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
  };

  return (
    <>
      {(objectiveFeatures ?? []).map((feature) => (
        <div
          key={feature._id}
          className="flex items-center justify-between p-1 hover:bg-row-background rounded cursor-pointer group"
          onClick={() => {
            if (pathname.includes("/roadmaps")) {
              resetActiveIds({ dispatch });
            }
            handleResetActiveIds(dispatch, pathname);
            dispatch(setActiveFeatureId(feature._id));
            dispatch(setFeatureShowDetailViewDrawer(true));
            dispatch(setDetailViewFeatures(objectiveFeatures));
          }}
        >
          <IconText
            icon={React.createElement(fieldIconMapping.feature)}
            iconColor={"text-features-icon"}
            text={truncateText(feature.title, 40)}
            hoverable={false}
            px=""
            py=""
            gap_x="gap-x-2"
          />
          <div className="flex items-center gap-x-2">
            <StatusSection
              item={feature?.status ?? {}}
              statusArray={featureStatusArray}
              with_title={false}
              with_label={false}
              dropdownMenuPosition={"right-0"}
              handleStatusOptionClick={(option) => {
                let { icon, ...restOption } = option;
                dispatch(
                  updateFeatureData(
                    feature._id,
                    { status: option._id },
                    "status",
                    feature,
                    restOption
                  )
                );
              }}
            />
            <ProfileIcon name={feature?.assignee?.name ?? "N A"} />
            <div
              className="hover:bg-button-hover rounded-full p-0.5 opacity-0 group-hover:opacity-100 transition-opacity"
              onClick={(e) => {
                e.stopPropagation();
                removeFeatures([feature._id]);
              }}
            >
              <CrossSmall size={14} />
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default ObjectiveDetailViewFeatureRowComponent;
