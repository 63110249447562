import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Interrogation, Search, Settings, SignOutAlt } from "react-flaticons";
import DemoProfileImage from "assets/demo_profile_image.webp";
import IconText from "components/common/IconText";
import AddNewPopupOverlay from "components/common/AddNewPopupOverlay";
import DropdownItem from "components/common/DropdownItem";
import { Link, useNavigate } from "react-router-dom";
import { fieldIconMapping, truncateText } from "utils";
import { createFeature } from "reduxStore/operations/featuresAPI";
import { createObjective } from "reduxStore/operations/objectivesAPI";
import { createIdea } from "reduxStore/operations/ideasAPI";
import { logout } from "reduxStore/operations/authAPI";
import { setLoading } from "reduxStore/slices/authSlice";
import { createRoadmap } from "reduxStore/operations/roadmapsAPI";
import { setIdeaViewType } from "reduxStore/slices/ideaSlice";
import { setFeatureViewType } from "reduxStore/slices/featureSlice";
import { setObjectiveViewType } from "reduxStore/slices/objectiveSlice";
import PersonalView from "./PersonalView";
import { resetActiveIds } from "../../utils/resetActiveIds";
import SearchDropdown from "./sidebar_component/SearchDropdown";
import FormOverlay from "./sidebar_component/FormOverlay";
import { NameInitialsAvatar } from "react-name-initials-avatar";

const Sidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { personalViews } = useSelector((state) => state.personalViews);
  const { user } = useSelector((state) => state.user);
  const addDropdownMenuOptions = [
    {
      icon: fieldIconMapping.idea,
      iconColor: "#EDB900",
      label: "Idea",
      addOptions: ["status", "importance", "product", "accounts"],
      onAddClick: ({ title, status, product, importance, accounts, score }) => {
        dispatch(
          createIdea(title, status, product, importance, accounts, score)
        );
        setSelectedOption(null);
      },
    },
    {
      icon: fieldIconMapping.feature,
      iconColor: "#3380F4",
      label: "Feature",
      addOptions: ["product", "status", "assignee", "release"],
      onAddClick: ({ title, status, release, product, score }) => {
        dispatch(createFeature(title, status, release, product, score));
        setSelectedOption(null);
      },
    },
    {
      icon: fieldIconMapping.objective,
      iconColor: "#F43379",
      label: "Objective",
      addOptions: ["status", "timeFrame", "type", "assignee"],
      onAddClick: ({ title, timeFrame, type, status }) => {
        dispatch(createObjective(title, timeFrame, type, status));
        setSelectedOption(null);
      },
    },
    {
      icon: fieldIconMapping.roadmap,
      label: "Roadmap",
      addOptions: ["status", "forRoadmap", "products", "assignee"],
      onAddClick: ({
        title,
        forRoadmap,
        products,
        releases,
        timeFrames,
        manualTimeFrame,
        status,
        assignee,
      }) => {
        dispatch(
          createRoadmap(
            title,
            forRoadmap,
            products,
            releases,
            timeFrames,
            manualTimeFrame,
            status,
            assignee
          )
        );
        setSelectedOption(null);
      },
    },
  ];

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isImageDropdownOpen, setIsImageDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const addDropdownRef = useRef(null);
  const imageDropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const handleOptionClick = (option) => {
    if (option.handleClick) {
      option.handleClick();
    } else {
      setSelectedOption(option);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        addDropdownRef.current &&
        !addDropdownRef.current.contains(event.target)
      ) {
        setIsDropdownOpen(false);
      }
      if (
        imageDropdownRef.current &&
        !imageDropdownRef.current.contains(event.target)
      ) {
        setIsImageDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="flex flex-col min-w-[240px] max-w-[240px] bg-sidebar-background z-50">
      <div className="flex justify-between items-center px-4 bg-sidebar-background mt-5 sticky top-0">
        <div className="flex gap-x-1.5 items-center">
          <img src="/lane_icon.svg" width={"25px"} />
          <span className="text-lg font-bold text-primary mr-2">
            {truncateText(user?.company?.company_name ?? "", 8)}
          </span>
        </div>
        {/* <button
          className="relative p-1 bg-gray-200 rounded"
          onClick={toggleDropdown}
          ref={addDropdownRef}
        > */}
          {/* <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11 5H7V1C7 0.734784 6.89464 0.48043 6.70711 0.292893C6.51957 0.105357 6.26522 0 6 0C5.73478 0 5.48043 0.105357 5.29289 0.292893C5.10536 0.48043 5 0.734784 5 1V5H1C0.734784 5 0.48043 5.10536 0.292893 5.29289C0.105357 5.48043 0 5.73478 0 6C0 6.26522 0.105357 6.51957 0.292893 6.70711C0.48043 6.89464 0.734784 7 1 7H5V11C5 11.2652 5.10536 11.5196 5.29289 11.7071C5.48043 11.8946 5.73478 12 6 12C6.26522 12 6.51957 11.8946 6.70711 11.7071C6.89464 11.5196 7 11.2652 7 11V7H11C11.2652 7 11.5196 6.89464 11.7071 6.70711C11.8946 6.51957 12 6.26522 12 6C12 5.73478 11.8946 5.48043 11.7071 5.29289C11.5196 5.10536 11.2652 5 11 5Z"
              fill="#696969"
            />
          </svg> */}
          {/* {isDropdownOpen && (
            <div className="absolute w-40 bg-white text-primary rounded-lg py-2 px-1 mx-1 border-2 -left-1 top-full mt-0.5">
              <ul>
                {addDropdownMenuOptions.map((option, index) => {
                  return (
                    <li key={index} className="mb-0.5">
                      <DropdownItem
                        option={option}
                        handleOptionClick={() => {
                          handleOptionClick(option);
                        }}
                        hoverColor="hover:bg-button-hover"
                      />
                      {option.label === "Roadmap" && (
                          <hr className="my-2 mx-auto w-5/6" />
                        )}
                    </li>
                  )
                })}
              </ul>
            </div>
          )} */}
        {/* </button> */}
      </div>
      <div className="flex flex-col h-full justify-between grow-0 custom-scrollbar   overflow-auto pt-7 pb-4 ">
        <div className="flex flex-col gap-y-1 px-2    ">
          {/* Logo and Expand Button */}
          <SearchDropdown />
          <IconText
            icon={React.createElement(fieldIconMapping.insights)}
            text="Insights"
            py="py-1"
            isASidebarComponent={true}
          />
          <IconText
            icon={React.createElement(fieldIconMapping.roadmap)}
            text="Roadmaps"
            py="py-1"
            isASidebarComponent={true}
            onClick={() => {
              resetActiveIds({ dispatch });
              navigate("/roadmaps");
            }}
            addButton={true}
            option={addDropdownMenuOptions[3]}
            handleOptionClick={handleOptionClick}
          />
          <div className="mt-8"></div>
          <div className="text-[12px] text-primary mx-1.5">Records</div>
          <IconText
            icon={React.createElement(fieldIconMapping.objective)}
            text="Objectives"
            py="py-1"
            isASidebarComponent={true}
            onClick={() => {
              resetActiveIds({ dispatch });
              dispatch(setObjectiveViewType("classiclist"));
              navigate("/objectives");
            }}
            addButton={true}
            option={addDropdownMenuOptions[2]}
            handleOptionClick={handleOptionClick}
          />
          <IconText
            icon={React.createElement(fieldIconMapping.idea)}
            text="Ideas"
            py="py-1"
            isASidebarComponent={true}
            onClick={() => {
              resetActiveIds({ dispatch });
              dispatch(setIdeaViewType("modernlist"));
              navigate("/ideas");
            }}
            addButton={true}
            option={addDropdownMenuOptions[0]}
            handleOptionClick={handleOptionClick}
          />
          <IconText
            icon={React.createElement(fieldIconMapping.feature)}
            text="Features"
            py="py-1"
            isASidebarComponent={true}
            onClick={() => {
              resetActiveIds({ dispatch });
              dispatch(setFeatureViewType("classiclist"));
              navigate("/features");
            }}
            addButton={true}
            option={addDropdownMenuOptions[1]}
            handleOptionClick={handleOptionClick}
          />
          {/* My Space Section */}
          <div className="mt-8"></div>
          <div className="text-[12px] text-primary mx-1.5">Spaces</div>
          <div className="flex flex-col gap-y-1">
            {(personalViews ?? []).map((view) => (
              <PersonalView key={view._id} view={view} py="py-1" />
            ))}
          </div>
        </div>

        {/* Bottom Section */}
        <div className="flex flex-col gap-y-1 px-2 mt-20">
          {/* <FormOverlay /> */}
          <Link to={"/settings/profile"}>
            <IconText icon={<Settings />} text="Settings" py="py-1" />
          </Link>
          <div
            className={`relative flex items-center gap-x-2 p-1 cursor-pointer   pl-1.5 rounded ${
              isImageDropdownOpen
                ? "bg-button-active_10"
                : "hover:bg-button-hover"
            }`}
            onClick={() => {
              setIsImageDropdownOpen(!isImageDropdownOpen);
            }}
            ref={imageDropdownRef}
          >
            {/* <img
              src={DemoProfileImage}
              alt="Profile"
              className="w-5 h-5 rounded-full  "
            /> */}
            <NameInitialsAvatar
              name={user?.name || "N"}
              size="20px"
              textSize="10px"
              borderWidth="1px"
              bgColor="#0A7Aff1A"
            />
            <span className="text-primary text-sm">{user?.name || "Name"}</span>
            {isImageDropdownOpen && (
              <div className="absolute bottom-full left-0 p-1 bg-white shadow border rounded">
                <DropdownItem
                  option={{ label: "Sign Out", icon: SignOutAlt }}
                  iconColor="text-primary"
                  iconSize={14}
                  gap_x="gap-x-2"
                  py="py-1"
                  px="px-1"
                  handleOptionClick={() => {
                    dispatch(setLoading(true));
                    dispatch(logout(navigate));
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Popup Overlay */}
      {selectedOption && (
        <AddNewPopupOverlay
          option={selectedOption}
          onClose={() => setSelectedOption(null)}
        />
      )}
    </div>
  );
};

export default Sidebar;
