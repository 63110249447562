/* eslint-disable react/prop-types */
import React from "react";
import { SyncLoader } from "react-spinners";
import { tailspin } from "ldrs";

tailspin.register();

// Default values shown

const Loader = ({ color = "#0A7AFF", size = "40" }) => {
  return (
    <l-tailspin size={size} stroke="5" speed="0.9" color={color}></l-tailspin>
  );
};

export default Loader;
