/* eslint-disable react/prop-types */
import React from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";

const Progress = ({ value, color }) => {
  return (
    <div className="w-[30px] mx-4">
      <CircularProgressbar
        value={value ?? 0}
        text={`${value.toFixed(0)}` ?? "0"}
        styles={buildStyles({
          textSize: "42px",
          pathColor: color,
          textColor: "black",
        })}
      />
    </div>
  );
};

export default Progress;
