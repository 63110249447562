import { createSlice } from "@reduxjs/toolkit";

// Initial state for user data
const initialState = {
  user: null,
};

// Create a slice for user data
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
    },
  },
});

// Export actions to use in components
export const { setUser } = userSlice.actions;

// Export the reducer to be included in the store
export const userReducer = userSlice.reducer;
