import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activityLogs: {
    feature: [],
    idea: [],
    objective: [],
    roadmap: [],
  },
};

const activitLogsSlice = createSlice({
  name: "activityLogs",
  initialState,
  reducers: {
    setIdeaActivityLogs(state, action) {
      state.activityLogs.idea = action.payload;
    },
    setFeatureActivityLogs(state, action) {
      state.activityLogs.feature = action.payload;
    },
    setObjectiveActivityLogs(state, action) {
      state.activityLogs.objective = action.payload;
    },
    setRoadmapActivityLogs(state, action) {
      state.activityLogs.roadmap = action.payload;
    },
  },
});

export const {
  setIdeaActivityLogs,
  setFeatureActivityLogs,
  setObjectiveActivityLogs,
  setRoadmapActivityLogs,
} = activitLogsSlice.actions;

export const activityLogsReducer = activitLogsSlice.reducer;
