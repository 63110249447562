/* eslint-disable react/prop-types */
import React, { useMemo } from "react";
import { fieldIconMapping } from "utils";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveKeyResultId,
  setKeyResultShowDetailViewDrawer,
} from "reduxStore/slices/keyResultSlice";
import { setDetailKeyResults } from "reduxStore/slices/keyResultSlice";
import KanbanModuleSection from "./KanbanModuleSection";

const KanbanKeyResultsSection = ({ keyResults }) => {
  const dispatch = useDispatch();
  const { keyResults: allKeyResults } = useSelector(
    (state) => state.keyResults
  );

  const filteredKeyResults = useMemo(() => {
    return (allKeyResults ?? []).filter((keyResult) =>
      (keyResults ?? []).includes(keyResult._id)
    );
  }, [keyResults, allKeyResults]);

  const handleKeyResultClick = (keyResult) => {
    dispatch(setActiveKeyResultId(keyResult._id));
    dispatch(setKeyResultShowDetailViewDrawer(true));
    dispatch(setDetailKeyResults(filteredKeyResults ?? []));
  };

  return (
    <KanbanModuleSection
      items={filteredKeyResults}
      icon={fieldIconMapping.key_results}
      truncateLength={filteredKeyResults?.length > 1 ? 28 : 40}
      iconColor="text-key_results-icon"
      textColor="text-title_50"
      onItemClick={handleKeyResultClick}
    />
  );
};

export default KanbanKeyResultsSection;
