import { setUser } from "../slices/userSlice";
import { apiConnector } from "api/apiconnector";
import { userEndPoints } from "api/api";
import { toast } from "react-toastify";

const { GET_USER_DETAILS, UPDATE_USER_DETAILS } = userEndPoints;

export const fetchUserDetails = (userId) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector(
        `GET`,
        `${GET_USER_DETAILS}/${userId}`
      );

      if (response.status === 200) {
        dispatch(setUser(response.data.user));
      } else {
        console.error("Failed to fetch user details:", response.statusText);
        toast.error("Failed to fetch user details");
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
      toast.error("An error occurred while fetching user details");
    }
  };
};

export const updateUserProfile = (user) => async (dispatch) => {
  try {
    const response = await apiConnector("PUT", UPDATE_USER_DETAILS, user);

    if (response.status === 200) {
      dispatch(setUser(response.data.user));

      // toast.success("Profile updated successfully");
    } else {
      console.error("Failed to update profile:", response.statusText);
      toast.error("Failed to update profile");
    }
  } catch (error) {
    console.error("Error updating profile:", error);
    toast.error("An error occurred while updating the profile");
  }
};
