/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from "react";
import { MinusSmall, PlusSmall } from "react-flaticons";
import ObjectiveDetailViewFeatureRowComponent from "../detailView/detailViewRowComponents/ObjectiveDetailViewFeatureRowComponent";
import ObjectiveDetailViewIdeaRowComponent from "../detailView/detailViewRowComponents/ObjectiveDetailViewIdeaRowComponent";
import InitiativeDropdown from "components/common/detailView/dropdown/InitiativeDropdown";

const InitiativeBasedDetailSection = ({
  item,
  selectedIdeas,
  handleIdeaSelect,
  removeSelectedIdeas,
  selectedFeatures,
  handleFeatureSelect,
  removeSelectedFeatures,
  updateIdeas,
  updateFeatures,
  removeFeatures,
  removeIdeas,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const containerRef = useRef(null);

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="flex flex-col gap-y-2 text-primary">
      <div className="relative flex items-center gap-x-1.5" ref={containerRef}>
        <div>Opportunities</div>
        <div
          className={`rounded  hover:bg-button-hover cursor-pointer ${
            isDropdownOpen ? "bg-button-active_10" : "bg-topbar-button-hover"
          }`}
          onClick={() => {
            setIsDropdownOpen(!isDropdownOpen);
          }}
        >
          {isDropdownOpen ? <MinusSmall /> : <PlusSmall />}
        </div>
        {isDropdownOpen && (
          <InitiativeDropdown
            item={item}
            selectedIdeas={selectedIdeas}
            handleIdeaSelect={handleIdeaSelect}
            removeSelectedIdeas={removeSelectedIdeas}
            selectedFeatures={selectedFeatures}
            handleFeatureSelect={handleFeatureSelect}
            removeSelectedFeatures={removeSelectedFeatures}
            updateFeatures={updateFeatures}
            updateIdeas={updateIdeas}
          />
        )}
      </div>
      <hr />
      <div className="flex flex-col gap-y-1.5 pt-2">
        <ObjectiveDetailViewIdeaRowComponent
          item={item}
          removeIdeas={removeIdeas}
        />
        <ObjectiveDetailViewFeatureRowComponent
          item={item}
          removeFeatures={removeFeatures}
        />
      </div>
    </div>
  );
};

export default InitiativeBasedDetailSection;
