/* eslint-disable react/prop-types */
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useMemo, useState } from "react";
import { generateStatusArray } from "utils/status";
import Loader from "components/common/Loader";
import { DragDropContext } from "@hello-pangea/dnd";
import IdeaKanbanColumn from "./IdeaKanbanColumn";
import { fieldIconMapping } from "utils";
import { updateIdeaData } from "reduxStore/operations/ideasAPI";

const IdeaKanbanView = ({ groupedData, isRoadmapDetailColumn = false }) => {
  const dispatch = useDispatch();
  const { groupByOption, isViewsLoading } = useSelector((state) => state.ideas);
  const { statuses } = useSelector((state) => state.company);
  const [ideaStatuses, setIdeaStatuses] = useState([]);

  useEffect(() => {
    setIdeaStatuses(statuses.idea);
  }, [statuses]);

  const statusArray = useMemo(
    () => generateStatusArray(ideaStatuses),
    [ideaStatuses]
  );

  const [localGroupByOption, setLocalGroupByOption] = useState(null);

  useEffect(() => {
    setLocalGroupByOption(groupByOption);
  }, [groupByOption]);

  const handleOnDragEnd = (result) => {
    const { source, destination, draggableId } = result;
    if (!destination || source.droppableId === destination.droppableId) return;
    if (groupByOption === "default") {
      const updateToStatusId = destination.droppableId;
      dispatch(updateIdeaData(draggableId, { status: updateToStatusId }));
    }

    if (groupByOption === "product") {
      const updateToProductId = destination.droppableId;
      dispatch(updateIdeaData(draggableId, { product: updateToProductId }));
    }

    if (groupByOption === "importance") {
      const updateToImportance = destination.droppableId;
      dispatch(updateIdeaData(draggableId, { importance: updateToImportance }));
    }
  };

  return (
    <div
      className={`h-full overflow-x-auto ${
        isRoadmapDetailColumn && "bg-gray-50"
      }`}
    >
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <div className="flex gap-x-5 p-4">
          {isViewsLoading ||
          localGroupByOption === null ||
          localGroupByOption != groupByOption ? (
            <div className="flex items-center justify-center h-[600px] w-full">
              <Loader />
            </div>
          ) : (
            Object.entries(groupedData)?.map(([groupId, groupData]) => (
              <IdeaKanbanColumn
                key={groupId}
                groupByOption={groupByOption}
                group={{
                  _id: groupId,
                  label: groupData.label,
                  color: groupData.color,
                }}
                itemsForGroup={groupData.items}
                statusArray={statusArray}
                isRoadmapDetailColumn={isRoadmapDetailColumn}
                roadmapTypeIcon={React.createElement(
                  fieldIconMapping.timeFrames
                )}
              />
            ))
          )}
        </div>
      </DragDropContext>
    </div>
  );
};

export default IdeaKanbanView;
