/* eslint-disable react/prop-types */
import React, { useState } from "react";
import IconsHeader from "components/common/detailView/DetailViewRightSidebarIconsHeader";
import Loader from "components/common/Loader";
import IdeaDetailViewRightDetailSidebar from "./rightSidebarSections/detail/IdeaDetailViewRightDetailSidebar";
import IdeaDetailViewRightLogSidebar from "./rightSidebarSections/log/IdeaDetailViewRightLogSidebar";

const IdeaDetailViewRightSidebar = ({ item = null }) => {
  const [selectedIcon, setSelectedIcon] = useState("detail");

  return (
    <div className="flex flex-col px-2 mt-6 w-full text-primary h-full">
      {!item ? (
        <div className="flex items-center justify-center h-screen">
          <Loader />
        </div>
      ) : (
        <>
          <IconsHeader
            selectedIcon={selectedIcon}
            setSelectedIcon={setSelectedIcon}
          />
          <hr className="mt-2 mb-5" />
          {selectedIcon === "detail" && (
            <IdeaDetailViewRightDetailSidebar item={item} />
          )}
          {selectedIcon === "log" && (
            <IdeaDetailViewRightLogSidebar item={item} />
          )}
        </>
      )}
    </div>
  );
};

export default IdeaDetailViewRightSidebar;
