import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllIntegrations } from "../../../reduxStore/operations/integrationsAPI";
import SettingsTopbar from "../SettingsTopbar";
import { useNavigate } from "react-router-dom";
import { IoCheckmarkCircle } from "react-icons/io5";
import { ChartNetwork } from "react-flaticons";

const Integrations = () => {
  const { companyId } = useSelector((state) => state.auth);
  const integrations = useSelector((state) => state.integrations);
  const { jira } = integrations;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const jiraClickHandler = async () => {
    navigate("/settings/integrations/jira");
  };

  useEffect(() => {
    dispatch(getAllIntegrations(companyId));
  }, []);

  return (
    <>
      <SettingsTopbar title="Integration" icon={<ChartNetwork />} />
      <div className="p-6">
        <div
          onClick={jiraClickHandler}
          className="p-4 flex gap-4 border rounded-lg items-center w-fit cursor-pointer hover:bg-slate-50"
        >
          <div>
            <div className="flex gap-3 items-center">
              <img src="/jiraIcon.svg" alt="jira" width={30} height={30} />
              <h4 className="text-lg">Jira</h4>
            </div>
            <div className="text-xs text-gray-600 mt-2">
              Sync Jira Projects and Epics
            </div>
          </div>
          <div className="self-baseline">
            {jira && <IoCheckmarkCircle fill="#4bae4f" fontSize={20} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default Integrations;
