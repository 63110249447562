/* eslint-disable react/prop-types */
import React, { useState } from "react";
import IconsHeader from "components/common/detailView/DetailViewRightSidebarIconsHeader";
import Loader from "components/common/Loader";
import RoadmapDetailViewRightDetailSidebar from "./rightSidebarSections/detail/RoadmapDetailViewRightDetailSidebar";
import RoadmapDetailViewRightLogSidebar from "./rightSidebarSections/log/RoadmapDetailViewRightLogSidebar";

const RoadmapDetailViewRightSidebar = ({ item = null }) => {
  const [selectedIcon, setSelectedIcon] = useState("detail");
  return (
    <div className="flex flex-col px-2 mt-6 w-full text-primary">
      {!item ? (
        <div className="flex items-center justify-center h-screen">
          <Loader />
        </div>
      ) : (
        <>
          <IconsHeader
            selectedIcon={selectedIcon}
            setSelectedIcon={setSelectedIcon}
          />
          <hr className="mt-2 mb-5" />
          {selectedIcon === "detail" && (
            <RoadmapDetailViewRightDetailSidebar item={item} />
          )}
          {selectedIcon === "log" && (
            <RoadmapDetailViewRightLogSidebar item={item} />
          )}
        </>
      )}
    </div>
  );
};

export default RoadmapDetailViewRightSidebar;
