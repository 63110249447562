import React, { useEffect, useState, useRef } from "react";
import AccountPopupOverlay from "components/common/addNewPopupOverlays/AccountPopupOverlay";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewAccount,
  deleteExistingAccount,
  getAccounts,
  updateExistingAccount,
} from "reduxStore/operations/accountAPI";
import { Search } from "react-flaticons";
import DropdownItem from "components/common/DropdownItem";
import { Trash, MenuDotsVertical } from "react-flaticons";
import { TbEdit } from "react-icons/tb";

const Accounts = () => {
  const dispatch = useDispatch();
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [currentAccount, setCurrentAccount] = useState({
    name: "",
    email: "",
    value: "",
  });
  const [visibleButtonId, setVisibleButtonId] = useState(null);

  const { accounts } = useSelector((state) => state.accounts);

  const dropdownRef = useRef(null);

  const handleAddAccount = (account) => {
    dispatch(addNewAccount(account));
  };

  const handleEditAccount = (id, updatedAccount) => {
    dispatch(updateExistingAccount(id, updatedAccount));
  };

  const openEditForm = (account) => {
    setCurrentAccount(account);
    setIsEditOpen(true);
  };

  const toggleActionMenu = (id) => {
    setVisibleButtonId((prevId) => (prevId === id ? null : id));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setVisibleButtonId(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className=" bg-white  ">
      <div className="w-[60%] ">
        <table className="min-w-full table-auto  ">
          <thead>
            <tr className=" text-sm ">
              <th className="text-primary text-start pr-1 px-3 font-normal bg-white">
                <span className="flex items-center gap-x-1 ">Name</span>
              </th>
              <th className="text-primary text-start pr-1 px-3 font-normal bg-white">
                Email
              </th>
              <th className="text-primary text-start pr-1 px-3 font-normal bg-white">
                Value ($)
              </th>
              <th className="text-primary text-start pr-1 px-3 font-normal bg-white">
                {" "}
              </th>
            </tr>
          </thead>
          <tbody>
            {accounts.map((account, index) => (
              <tr
                key={index}
                className="group text-gray-700 text-[14px] rounded hover:bg-row-background relative"
              >
                <td className="truncate pr-1 px-3 py-2">{account.name}</td>
                <td className="truncate pr-1 px-3 py-2">{account.email}</td>
                <td className="truncate pr-1 px-3 py-2 ">{account.value}</td>
                <td className="truncate pr-1 px-3 py-2 opacity-0 group-hover:opacity-100 transition-opacity">
                  <div
                    className={`p-1 rounded-full w-fit hover:bg-button-hover cursor-pointer ${
                      visibleButtonId === index && "bg-button-active_10"
                    }`}
                    onClick={() => toggleActionMenu(index)}
                  >
                    <MenuDotsVertical size={12} />
                  </div>

                  {visibleButtonId === index && (
                    <div
                      ref={dropdownRef}
                      className="absolute visible right-0 text-sm p-1 w-24 bg-white z-50 rounded border shadow-md mt-0.5"
                    >
                      <DropdownItem
                        option={{ label: "Edit", icon: TbEdit }}
                        textSize="text-xs"
                        handleOptionClick={() => {
                          openEditForm(account);
                        }}
                        gap_x="gap-x-2"
                      />
                      <DropdownItem
                        option={{ label: "Delete", icon: <Trash /> }}
                        textSize="text-xs"
                        handleOptionClick={() => {
                          dispatch(deleteExistingAccount(account?._id));
                        }}
                        gap_x="gap-x-2"
                      />
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {isAddOpen && (
        <AccountPopupOverlay
          type="add"
          onClose={() => setIsAddOpen(false)}
          onSubmit={handleAddAccount}
        />
      )}

      {isEditOpen && (
        <AccountPopupOverlay
          type="edit"
          accountData={currentAccount}
          onClose={() => setIsEditOpen(false)}
          onSubmit={handleEditAccount}
        />
      )}
    </div>
  );
};

export default Accounts;
