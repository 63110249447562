import { apiConnector } from "api/apiconnector";
import { activityLogEndPoints } from "api/api";
import {
  setFeatureActivityLogs,
  setIdeaActivityLogs,
  setObjectiveActivityLogs,
  setRoadmapActivityLogs,
} from "../slices/activityLogSlice";

const {
  GET_FEATURE_ACTIVITY_LOGS,
  GET_IDEA_ACTIVITY_LOGS,
  GET_OBJECTIVE_ACTIVITY_LOGS,
  GET_ROADMAP_ACTIVITY_LOGS,
} = activityLogEndPoints;

export const getFeatureActivityLogs = (featureId) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector(
        "GET",
        GET_FEATURE_ACTIVITY_LOGS(featureId)
      );
      if (response.status === 200 && response.data) {
        dispatch(setFeatureActivityLogs(response.data.activityLog));
      }
    } catch (error) {
      console.error("Error getting feature logs:", error);
    }
  };
};

export const getIdeaActivityLogs = (ideaId) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector(
        "GET",
        GET_IDEA_ACTIVITY_LOGS(ideaId)
      );
      if (response.status === 200 && response.data) {
        dispatch(setIdeaActivityLogs(response.data.activityLog));
      }
    } catch (error) {
      console.error("Error getting idea logs:", error);
    }
  };
};

export const getObjectiveActivityLogs = (objectiveId) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector(
        "GET",
        GET_OBJECTIVE_ACTIVITY_LOGS(objectiveId)
      );
      if (response.status === 200 && response.data) {
        dispatch(setObjectiveActivityLogs(response.data.activityLog));
      }
    } catch (error) {
      console.error("Error getting objective logs:", error);
    }
  };
};
export const getRoadmapActivityLogs = (roadmapId) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector(
        "GET",
        GET_ROADMAP_ACTIVITY_LOGS(roadmapId)
      );
      if (response.status === 200 && response.data) {
        dispatch(setRoadmapActivityLogs(response.data.activityLog));
      }
    } catch (error) {
      console.error("Error getting roadmap logs:", error);
    }
  };
};
