import React from "react";

const InsightsTab = () => {
  return (
    <div className=" flex justify-center items-center h-[80%] w-full ">
      <p className="text-lg text-gray-500">No insights to show</p>
    </div>
  );
};

export default InsightsTab;
