import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import SettingsTopbar from "../SettingsTopbar";
import { Outlet } from "react-router-dom";
import { toSnakeCase } from "utils";
import { ChartConnected } from "react-flaticons";

const SettingsCustomizations = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const tabs = [
    { label: "Status" },
    { label: "Release" },
    { label: "Product" },
    { label: "Tags" },
    { label: "Time-frame" },
  ];

  const getInitialTab = () => {
    const currentPath = location.pathname.split("/").pop();
    const foundTab = tabs.find((tab) => toSnakeCase(tab.label) === currentPath);
    return foundTab ? foundTab : tabs[0];
  };

  const [activeTab, setActiveTab] = useState(getInitialTab());

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    if (activeTab.label === "Status") {
      navigate("");
    } else {
      navigate(`${toSnakeCase(activeTab.label)}`);
    }
  }, [activeTab, navigate]);

  return (
    <>
      <SettingsTopbar
        title="Customizations"
        tabs={tabs}
        activeTab={activeTab}
        handleTabClick={handleTabClick}
        icon={<ChartConnected />}
      />
      <div className="relative overflow-scroll bg-white shadow w-full h-full">
        <div className="p-6 w-full h-full overflow-auto">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default SettingsCustomizations;
