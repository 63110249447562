import { jwtDecode } from "jwt-decode";
import cookie from "cookie";

// Not used till now
export const getObjectFromCookie = (cookieName) => {
  const cookieValue = document.cookie
    .split("; ")
    .find((row) => row.startsWith(`${encodeURIComponent(cookieName)}=`));

  if (cookieValue) {
    // Decode the cookie value and parse the JSON string to get the object
    const jsonString = decodeURIComponent(cookieValue.split("=")[1]);
    return JSON.parse(jsonString);
  }

  return null; // Return null if the cookie is not found
};

// for Loading the cookies into state/slices
export const getCookie = (name) => {
  // console.log(document.cookie);
  // cookie.parse will convert string to object and return
  // particular document specific cookie
  const cookies = cookie.parse(document.cookie);
  // console.log(cookies);

  // see if cookie and that cookie with specific name is present or not
  const value = cookies && cookies[name];

  if (value === undefined) {
    // Handle the case where the cookie doesn't exist
    return undefined; // or any other appropriate value or behavior
  }

  const hasToParse = Boolean((value && value[0] === "{") || value[0] === "[");

  // converting to JS object as used in frontend to frontend only
  return hasToParse ? JSON.parse(value) : value;
};

export const setCookie = (name, value) => {
  const expirationDate = new Date();
  expirationDate.setTime(expirationDate.getTime() + 24 * 60 * 60 * 1000); // 24 hours in milliseconds

  const cookieValue =
    typeof value === "object" ? JSON.stringify(value) : String(value);

  // setting cookie in docuement.cookie
  document.cookie = `${name}=${cookieValue}; expires=${expirationDate.toUTCString()}; path=/`;
};

export const deleteCookie = (name) => {
  const expirationDate = new Date();
  expirationDate.setTime(expirationDate.getTime() - 24 * 60 * 60 * 1000); // Set expiration date to past time (24 hours ago)

  document.cookie = `${name}=; expires=${expirationDate.toUTCString()}; path=/`;
};

export const getEmailFromToken = (token) => {
  try {
    const decoded = jwtDecode(token);

    return decoded.email;
  } catch (error) {
    return null;
  }
};

export const getPhoneNumberFromToken = (token) => {
  try {
    const decoded = jwtDecode(token);

    return decoded.phoneNumber;
  } catch (error) {
    return null;
  }
};

export const getUserIdFromToken = (token) => {
  try {
    const decoded = jwtDecode(token);

    return decoded.userId;
  } catch (error) {
    return null;
  }
};

// Function to get company ID from JWT token
export const getCompanyIdFromToken = (token) => {
  try {
    const decoded = jwtDecode(token);
    return decoded.companyId; // Assuming companyId is stored in the token payload
  } catch (error) {
    console.error("Error decoding token:", error);
    return null;
  }
};
