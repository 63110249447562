/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { fieldIconMapping } from "utils";
import IconText from "components/common/IconText";

const ScoreSection = ({ item, updateScore }) => {
  const [score, setScore] = useState(item?.score);

  const [reach, setReach] = useState(score?.reach || 0);
  const [impact, setImpact] = useState(score?.impact || 0);
  const [confidence, setConfidence] = useState(score?.confidence || 0);
  const [effort, setEffort] = useState(score?.effort || 0);
  const [value, setValue] = useState(score?.value || 0);

  const [isEditing, setIsEditing] = useState({
    reach: false,
    impact: false,
    confidence: false,
    effort: false,
    value: false,
  });

  const handleClick = (field) => {
    setIsEditing((prev) => ({ ...prev, [field]: true }));
  };

  const handleBlur = (field) => {
    setIsEditing((prev) => ({ ...prev, [field]: false }));
    updateScore({
      reach,
      impact,
      confidence,
      effort,
      value,
    });
  };

  const handleUpdateField = (field, value) => {
    if (field === "reach") setReach(value);
    if (field === "impact") setImpact(value);
    if (field === "confidence") setConfidence(value);
    if (field === "effort") setEffort(value);
    if (field === "value") setValue(value);
  };

  useEffect(() => {
    setScore(item.score);
  }, [item]);

  useEffect(() => {
    setReach(score?.reach || 0);
    setConfidence(score?.confidence || 0);
    setEffort(score?.effort || 0);
    setImpact(score?.impact || 0);
    setValue(score?.value || 0);
  }, [score]);

  if (!score) {
    return null;
  }

  return (
    <div className="flex flex-col gap-y-3">
      <div className="flex gap-x-8 items-center text-title_50 mb-1">
        <IconText
          icon={React.createElement(fieldIconMapping.score)}
          text="Score"
          iconColor="#696969"
          iconSize={16}
          hoverable={false}
          gap_x="gap-x-2"
        />
        <div className="flex items-center gap-x-2">
          <div className="rounded bg-tags-active px-1.5 py-1 text-xs">
            {score.type}
          </div>
        </div>
      </div>
      <div className="flex items-center gap-x-28 ml-2 text-sm">
        <span className="text-title">Score</span>
        <span className="ml-1.5">
          {score.type === "Value/Effort" &&
            score?.value_effort_score?.toFixed(1)}
          {score.type === "RICE" && score?.rice_score?.toFixed(1)}
          {score.type === "Impact/Effort" &&
            score?.impact_effort_score?.toFixed(1)}
        </span>
      </div>
      {score.type === "RICE" && (
        <div className="flex flex-col gap-y-1 ml-2 text-sm">
          <div className="flex items-center gap-x-28">
            <span>Reach</span>
            <input
              type="number"
              value={reach}
              onClick={() => handleClick("reach")}
              onBlur={() => handleBlur("reach")}
              onChange={(e) =>
                handleUpdateField("reach", parseFloat(e.target.value))
              }
              readOnly={!isEditing.reach}
              className="ml-0.5 border rounded p-1 w-[100px]"
            />
          </div>
          <div className="flex items-center gap-x-28">
            <span>Impact</span>
            <input
              type="number"
              value={impact}
              onClick={() => handleClick("impact")}
              onBlur={() => handleBlur("impact")}
              onChange={(e) =>
                handleUpdateField("impact", parseFloat(e.target.value))
              }
              readOnly={!isEditing.impact}
              className="-ml-0.5 border rounded p-1 w-[100px]"
            />
          </div>
          <div className="flex items-center gap-x-20">
            <span>Confidence</span>
            <input
              type="number"
              value={confidence}
              onClick={() => handleClick("confidence")}
              onBlur={() => handleBlur("confidence")}
              onChange={(e) =>
                handleUpdateField("confidence", parseFloat(e.target.value))
              }
              readOnly={!isEditing.confidence}
              className="-ml-0.5 border rounded p-1 w-[100px]"
            />
          </div>
          <div className="flex items-center gap-x-28">
            <span>Effort</span>
            <input
              type="number"
              value={effort}
              onClick={() => handleClick("effort")}
              onBlur={() => handleBlur("effort")}
              onChange={(e) =>
                handleUpdateField("effort", parseFloat(e.target.value))
              }
              readOnly={!isEditing.effort}
              className="ml-[9px] border rounded p-1 w-[100px]"
            />
          </div>
        </div>
      )}
      {score.type === "Value/Effort" && (
        <div className="flex flex-col gap-y-1 ml-2 text-sm">
          <div className="flex items-center gap-x-32">
            <span>Value</span>
            <input
              type="number"
              value={value}
              onClick={() => handleClick("value")}
              onBlur={() => handleBlur("value")}
              onChange={(e) =>
                handleUpdateField("value", parseFloat(e.target.value))
              }
              readOnly={!isEditing.value}
              className="-ml-3 border rounded p-1 w-[100px]"
            />
          </div>
          <div className="flex items-center gap-x-28">
            <span>Effort</span>
            <input
              type="number"
              value={effort}
              onClick={() => handleClick("effort")}
              onBlur={() => handleBlur("effort")}
              onChange={(e) =>
                handleUpdateField("effort", parseFloat(e.target.value))
              }
              readOnly={!isEditing.effort}
              className="ml-1 border rounded p-1 w-[100px]"
            />
          </div>
        </div>
      )}
      {score.type === "Impact/Effort" && (
        <div className="flex flex-col gap-y-1 ml-2 text-sm">
          <div className="flex items-center gap-x-28">
            <span>Impact</span>
            <input
              type="number"
              value={impact}
              onClick={() => handleClick("impact")}
              onBlur={() => handleBlur("impact")}
              onChange={(e) =>
                handleUpdateField("impact", parseFloat(e.target.value))
              }
              readOnly={!isEditing.impact}
              className="-ml-0.5 border rounded p-1"
            />
          </div>
          <div className="flex items-center gap-x-28">
            <span>Effort</span>
            <input
              type="number"
              value={effort}
              onClick={() => handleClick("effort")}
              onBlur={() => handleBlur("effort")}
              onChange={(e) =>
                handleUpdateField("effort", parseFloat(e.target.value))
              }
              readOnly={!isEditing.effort}
              className="ml-[9px] border rounded p-1"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ScoreSection;
