import { toast } from "react-toastify";
import { featureEndPoints } from "api/api";
import { apiConnector } from "api/apiconnector";
import {
  appendFeature,
  removeFeature,
  removeFeatures,
  setActiveFeatureId,
  setFeatureRoadmaps,
  setFeatures,
  setFeatureShowDetailViewDrawer,
  setIsFeatureLoading,
  updateFeature,
  updateFeatures,
} from "../slices/featureSlice";
import { removeIdeaFeature, addIdeaFeature } from "../slices/ideaSlice";
import CustomToast from "components/common/CustomToast";
import React from "react";
import { getReleaseRoadmaps } from "./releaseAPI";

const {
  ADD_FEATURE,
  GET_ALL_FEATURES,
  UPDATE_FEATURE_NON_ENTITY,
  UPDATE_MULTIPLE_FEATURES,
  UPDATE_FEATURE_PRIORITIZATION,
  UPDATE_FEATURE_IDEAS,
  DELETE_FEATURE,
  ADD_FEATURE_TAGS,
  DELETE_FEATURES,
} = featureEndPoints;

export const createFeature = (title, status, release, product, score) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector("POST", ADD_FEATURE(), {
        title,
        status,
        release,
        product,
        score,
      });

      if (response.status === 201 && response.data) {
        dispatch(appendFeature(response.data.newFeature));
        toast(
          React.createElement(CustomToast, {
            linkAddress: "/features",
            title,
            module: "Feature",
            idModule: response.data.newFeature,
          })
        );
      } else {
        toast.error("Failed to add features");
      }
    } catch (error) {
      console.error("Error adding features:", error);
      toast.error("Error adding features");
    }
  };
};

export const getAllFeatures = (page = 1, limit = 100) => {
  return async (dispatch) => {
    try {
      dispatch(setIsFeatureLoading(true));
      const queryParams = new URLSearchParams({ page, limit }).toString();
      const response = await apiConnector(
        "GET",
        `${GET_ALL_FEATURES()}?${queryParams}`
      );

      if (response.status === 200 && response.data) {
        dispatch(setFeatures(response.data.features));
      } else {
        toast.error("Failed to fetch features");
      }
    } catch (error) {
      console.error("Error fetching features:", error);
      toast.error("Error fetching features");
    }
  };
};

export const updateFeatureData = (
  featureId,
  updatedData,
  isUpdated,
  feature,
  restOption
) => {
  return async (dispatch) => {

    console.log(featureId);


    let newFeature = {};
    if (feature && restOption) {
      // Creating a deep copy of the objective to avoid mutating the original object
      newFeature = JSON.parse(JSON.stringify(feature));
      switch (isUpdated) {
        case "status":
          newFeature.status = restOption;
          break;
        case "release":
          newFeature.release = restOption;
          break;
        case "product":
          newFeature.product = restOption;
          break;
        default:
          break;
      }
      dispatch(
        updateFeature({
          updatedFeature: newFeature,
          isUpdated: isUpdated,
        })
      );
    }
    try {
      const response = await apiConnector(
        "PATCH",
        UPDATE_FEATURE_NON_ENTITY(featureId),
        updatedData
      );

      if (response.status === 200 && response.data) {
        dispatch(
          updateFeature({
            updatedFeature: response.data.updatedFeature,
            isUpdated: isUpdated,
          })
        );
        if (isUpdated === "release") {
          if (updatedData.release === null) {
            dispatch(setFeatureRoadmaps({ releaseIds: [], roadmaps: [] }));
          } else {
            dispatch(getReleaseRoadmaps([updatedData.release]));
          }
        }
        if (isUpdated === "product") {
          dispatch(
            getReleaseRoadmaps([response?.data?.updatedFeature?.release?._id])
          );
        }
      }
    } catch (error) {
      toast.error(`Error updating feature: ${error.message}`);
      dispatch(
        updateFeature({
          updatedFeature: feature,
          isUpdated: isUpdated,
        })
      );
    }
  };
};

export const updateFeaturesData = (
  features,
  update,
  isUpdatedArray,
  releases
) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector("PUT", UPDATE_MULTIPLE_FEATURES(), {
        features,
        update,
      });
      if (response.status === 200) {
        dispatch(
          updateFeatures({
            updatedFeatures: response.data.features,
            isUpdatedArray: isUpdatedArray,
          })
        );
        if (isUpdatedArray?.includes("release")) {
          dispatch(getReleaseRoadmaps([update.release]));
        } else if (isUpdatedArray?.includes("product")) {
          dispatch(getReleaseRoadmaps(releases));
        }
      }
    } catch (error) {
      console.log(error);
      toast.error(`Error Updating Features: ${error.message}`);
    }
  };
};

export const updateFeatureScore = (
  featureId,
  type,
  value,
  effort,
  impact,
  reach,
  confidence
) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector(
        "PATCH",
        UPDATE_FEATURE_PRIORITIZATION(featureId),
        { type, value, effort, impact, reach, confidence }
      );
      if (response.status === 200) {
        dispatch(
          updateFeature({
            updatedFeature: response.data.feature,
            isUpdated: "score",
          })
        );
      }
    } catch (error) {
      console.log(error);
      toast.error(`Error Updating Score: ${error.message}`);
    }
  };
};

export const updateFeatureIdeas = (featureId, ideas) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector(
        "PATCH",
        UPDATE_FEATURE_IDEAS(featureId),
        ideas
      );

      if (response.status === 200 && response.data) {
        dispatch(
          updateFeature({
            updatedFeature: response.data.updatedFeature,
            isUpdated: "ideas",
          })
        );
        dispatch(addIdeaFeature({ featureId, ideas: ideas.ideas }));
      }
    } catch (error) {
      console.log(error);
      toast.error(`Error updating feature: ${error.message}`);
    }
  };
};

export const removeFeatureIdeas = (featureId, ideas) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector(
        "DELETE",
        UPDATE_FEATURE_IDEAS(featureId),
        ideas
      );

      if (response.status === 200 && response.data) {
        dispatch(
          updateFeature({
            updatedFeature: response.data.updatedFeature,
            isUpdated: "ideas",
          })
        );
        dispatch(removeIdeaFeature({ featureId, ideas: ideas.ideas }));
      }
    } catch (error) {
      toast.error(`Error updating feature: ${error.message}`);
    }
  };
};

export const updateFeatureTags =
  (option, fullTags, feature) => async (dispatch) => {
    let newFeature = {};
    if (feature) {
      // Creating a deep copy of the objective to avoid mutating the original object
      newFeature = JSON.parse(JSON.stringify(feature));
      newFeature.tags = fullTags;
    }
    dispatch(
      updateFeature({
        updatedFeature: newFeature,
        isUpdated: "tags",
      })
    );

    try {
      const response = await apiConnector(
        "PATCH",
        ADD_FEATURE_TAGS(option.itemId),
        {
          tags: option.tags,
        }
      );
      if (response.status === 200) {
        dispatch(
          updateFeature({
            updatedFeature: response.data.feature,
            isUpdated: "tags",
          })
        );
      } else {
        console.error("Failed to update tag:", response.statusText);
      }
    } catch (error) {
      console.error("Error adding tag:", error);
      toast.error("Error adding tag:");
      dispatch(
        updateFeature({
          updatedFeature: feature,
          isUpdated: "tags",
        })
      );
    }
  };

export const deleteFeature = (featureId, navigate) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector("DELETE", DELETE_FEATURE(featureId));

      if (response.status === 200 && response.data) {
        dispatch(setActiveFeatureId(null));
        dispatch(setFeatureShowDetailViewDrawer(false));
        dispatch(removeFeature(featureId));
        toast.success("Feature deleted successfully");
        navigate("/features");
      }
    } catch (error) {
      toast.error(`Error deleting feature: ${error.message}`);
    }
  };
};

export const deleteFeatures = (features) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector("DELETE", DELETE_FEATURES(), {
        features,
      });

      if (response.status === 200 && response.data) {
        dispatch(setActiveFeatureId(null));
        dispatch(setFeatureShowDetailViewDrawer(false));
        dispatch(removeFeatures({ featureIds: features }));
        toast.success("Feature deleted successfully");
      }
    } catch (error) {
      toast.error(`Error deleting feature: ${error.message}`);
    }
  };
};
