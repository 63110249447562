/* eslint-disable react/prop-types */
import React from "react";
import IconText from "components/common/IconText";
import { getStatusIcon } from "utils/status";
import { rgbColor } from "utils";
import { Minus } from "react-flaticons";

const StatusRowSection = ({
  status,
  dropdownState,
  toggleDropdown,
  statusArray,
}) => {
  return (
    <div
      className={`p-1 w-fit rounded hover:bg-gray-200 ${
        dropdownState.isStatusDropdownOpen && "bg-button-active_10"
      }`}
      onClick={(e) => {
        e.stopPropagation();
        toggleDropdown();
      }}
    >
      <div className="rounded w-fit bg-white">
        <IconText
          icon={
            status?.label ? getStatusIcon(status.label, statusArray) : Minus
          }
          text={status?.label ?? ""}
          textSize="text-xs"
          px="px-1"
          py="py-1"
          gap_x="gap-x-1 rounded"
          hoverable={false}
          iconTextBgColor={rgbColor(status?.color ?? "", 0.2)}
          iconColor={status?.color ?? ""}
          textColor="text-title_50"
          iconSize={14}
        />
      </div>
    </div>
  );
};

export default StatusRowSection;
