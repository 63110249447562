/* eslint-disable react/prop-types */
import React from "react";
import { Link } from "react-router-dom";
import IconText from "./IconText";
import { AngleSmallRight } from "react-flaticons";
import { truncateText } from "utils";

const Breadcrumb = ({ crumbs, iconSize = "12" }) => {
  return (
    <nav className="rounded-md  ">
      <ol className="list-reset flex">
        {crumbs.map((crumb, index) => (
          <li key={index} className="flex items-center">
            <Link to={crumb.path} className=" text-title_50 hover:underline">
              {crumb.icon ? (
                <IconText
                  icon={
                    React.isValidElement(crumb.icon)
                      ? crumb.icon
                      : React.createElement(crumb.icon)
                  }
                  text={truncateText(crumb?.name ?? "", 20)}
                  isASidebarComponent={false}
                  hoverable={false}
                  iconSize={iconSize}
                  isTextActive={true}
                  py=""
                />
              ) : (
                <div className="text-sm px-2">{crumb.name}</div>
              )}
            </Link>
            {index < crumbs.length - 1 && (
              <span className="mx-1">
                <AngleSmallRight size={14} />
              </span>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
