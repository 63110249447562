import {
  groupByFor,
  groupByHealth,
  groupByStatus,
  groupByTag,
} from "./grouping";

export const groupDataByRoadmap = (data = [], groupBy, statuses, tags) => {

  const groupByFunc = {
    status: () => groupByStatus(data, statuses),
    tags: () => groupByTag(data, tags),
    health: () => groupByHealth(data),
    for: () => groupByFor(data),
    default: () => ({ all: data }),
  };
  return (groupByFunc[groupBy] || groupByFunc.default)();
};
