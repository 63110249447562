import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  accounts: [],
};

const accountsSlice = createSlice({
  name: "accounts",
  initialState,
  reducers: {
    setAccounts: (state, action) => {
      state.accounts = action.payload;
    },

    addAccount: (state, action) => {
      state.accounts.push(action.payload);
    },

    editAccount: (state, action) => {
      const { accountId, updatedAccount } = action.payload;
      const accountIndex = state.accounts.findIndex(
        (account) => account._id === accountId
      );
      if (accountIndex !== -1) {
        state.accounts[accountIndex] = updatedAccount;
      }
    },

    deleteAccount: (state, action) => {
      state.accounts = state.accounts.filter(
        (account) => account._id !== action.payload
      );
    },
  },
});

export const { setAccounts, addAccount, editAccount, deleteAccount } =
  accountsSlice.actions;

export default accountsSlice.reducer;
