/* eslint-disable react/prop-types */
import { NameInitialsAvatar } from "react-name-initials-avatar";

const ProfileIcon = ({ name }) => {
  return (
    <NameInitialsAvatar
      name={name}
      size="25px"
      textSize="10px"
      borderWidth="1px"
      bgColor="#0A7Aff1A"
    />
  );
};

export default ProfileIcon;
