import { toSnakeCase } from ".";
import { objectiveHealths } from "./objectiveUtils";

export const groupByStatus = (data, statuses) => {
  const statusOrder = ["parked", "to_do", "in_progress", "done"];

  const sortedStatuses = statuses.slice().sort((a, b) => {
    const typeComparison =
      statusOrder.indexOf(a.type) - statusOrder.indexOf(b.type);

    if (typeComparison === 0) {
      return new Date(a.createdAt) - new Date(b.createdAt);
    }

    return typeComparison;
  });

  return sortedStatuses.reduce((acc, status) => {
    acc[status._id] = {
      label: status.label,
      color: status.color,
      items: data
        .filter((item) => item.status._id === status._id)
        .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)),
    };

    return acc;
  }, {});
};

export const groupByRelease = (data, releases = []) => {
  return releases.reduce(
    (acc, release) => {
      if (release?._id) {
        acc[release._id] = {
          label: release.label,
          items: data.filter((item) => item.release?._id === release._id),
        };
      }
      return acc;
    },
    data.some((item) => !item.release)
      ? {
          "no-release": {
            _id: null,
            label: "No Release",
            items: data.filter((item) => !item.release),
          },
        }
      : {}
  );
};

export const groupByTimeFrame = (data, timeFrames = []) => {
  return timeFrames?.reduce(
    (acc, timeFrame) => {
      if (timeFrame?._id) {
        acc[timeFrame._id] = {
          label: timeFrame.label,
          color: timeFrame.color,
          items: data.filter((item) => item.timeFrame?._id === timeFrame._id),
        };
      }
      return acc;
    },
    data.some((item) => !item.timeFrame)
      ? {
          "no-timeFrame": {
            _id: null,
            label: "No Time-frame",
            items: data.filter((item) => !item.timeFrame),
          },
        }
      : {}
  );
};

export const groupByProduct = (data, products = []) => {
  return products.reduce(
    (acc, product) => {
      if (product?._id) {
        acc[product._id] = {
          label: product.label,
          color: product.color,
          items: data.filter((item) => item.product?._id === product._id),
        };
      }
      return acc;
    },
    data.some((item) => !item.product)
      ? {
          "no-product": {
            _id: null,
            label: "No Product",
            items: data.filter((item) => !item.product),
          },
        }
      : {}
  );
};

export const groupByTag = (data, tags) => {
  return (tags ?? []).reduce(
    (acc, tag) => {
      acc[tag._id] = {
        label: tag.label,
        items: data.filter((item) =>
          (item?.tags ?? []).some((itemTag) => itemTag._id === tag._id)
        ),
      };
      return acc;
    },
    data.some((item) => (item?.tags ?? []).length === 0)
      ? {
          "no-tags": {
            label: "No Tags",
            items: data.filter((item) => (item?.tags ?? []).length === 0),
          },
        }
      : {}
  );
};

export const groupByAccount = (data, accounts) => {
  return (accounts ?? []).reduce(
    (acc, account) => {
      acc[account._id] = {
        label: account.name,
        items: data.filter((item) =>
          (item?.accounts ?? []).some(
            (itemAccount) => itemAccount._id === account._id
          )
        ),
      };
      return acc;
    },
    data.some((item) => (item?.accounts ?? []).length === 0)
      ? {
          "no-accounts": {
            label: "No Accounts",
            items: data.filter((item) => (item?.accounts ?? []).length === 0),
          },
        }
      : {}
  );
};

export const groupByObjective = (data, objectives) => {
  return (objectives || []).reduce(
    (acc, objective) => {
      acc[objective._id] = {
        label: objective.title,
        items: data.filter((item) =>
          (item?.objectives ?? []).some((obj) => obj === objective._id)
        ),
      };

      return acc;
    },
    data.some((item) => (item?.objectives ?? []).length === 0)
      ? {
          "no-objectives": {
            label: "No Objectives",
            items: data.filter((item) => (item?.objectives ?? []).length === 0),
          },
        }
      : {}
  );
};

export const groupByImportance = (data) => {
  return [1, 2, 3, 4, 5].reduce((acc, importance) => {
    acc[importance] = {
      label: importance.toString(),
      items: data.filter((item) => item.importance === importance),
    };
    return acc;
  }, {});
};

export const groupByHealth = (data) => {
  return objectiveHealths().reduce((acc, health) => {
    acc[health._id] = {
      label: health.label,
      color: health.color,
      items: data.filter((item) => item.health === toSnakeCase(health.label)),
    };
    return acc;
  }, {});
};

export const groupByFor = (data) => {
  return ["Strategy", "Release", "Ideas"].reduce((acc, val) => {
    acc[val] = {
      label: val,
      items: data.filter((item) => item.for === val),
    };
    return acc;
  }, {});
};
