/* eslint-disable react/prop-types */
import { useSelector } from "react-redux";
import { useDataProcessing } from "hooks/useDataProcessing";
import { useEffect, useMemo, useState } from "react";
import Loader from "components/common/Loader";
import RoadmapListView from "./views/list/RoadmapListView";
import { handleGroupSelect } from "utils/listUtils";
import MasterRoadmapSelectionPopup from "../common/masterSelectionPopup/MasterRoadmapSelectionPopup";

const RoadmapComponent = ({ searchTerm = "" }) => {
  const { roadmaps, groupByOption, selectedColumns, viewType } = useSelector(
    (state) => state.roadmaps
  );


  const filteredRoadmaps = useMemo(() => {
    return (roadmaps ?? []).filter((roadmap) =>
      roadmap.title.toLowerCase().includes(searchTerm.trim().toLowerCase())
    );
  }, [roadmaps, searchTerm]);

  const { groupedData, loading } = useDataProcessing(
    filteredRoadmaps,
    "roadmap"
  );

  const [collapsedGroups, setCollapsedGroups] = useState({});
  const [selectedRoadmaps, setSelectedRoadmaps] = useState(new Set());

  const toggleGroupCollapse = (group) => {
    setCollapsedGroups((prev) => ({
      ...prev,
      [group]: !prev[group],
    }));
  };

  const handleRoadmapSelect = (roadmap) => {
    setSelectedRoadmaps((prevSelected) => {
      const newSelected = new Set(prevSelected);
      newSelected.has(roadmap._id)
        ? newSelected.delete(roadmap._id)
        : newSelected.add(roadmap._id);
      return newSelected;
    });
  };

  const handleLocalGroupSelect = (group) =>
    handleGroupSelect(group, setSelectedRoadmaps, groupedData);

  return (
    <div className="relative overflow-scroll bg-white shadow w-full h-full">
      {loading ? (
        <div className="flex items-center justify-center h-full">
          <Loader />
        </div>
      ) : (
        <>
          {viewType === "classiclist" && (
            <RoadmapListView
              groupedData={groupedData}
              selectedColumns={selectedColumns}
              collapsedGroups={collapsedGroups}
              toggleGroupCollapse={toggleGroupCollapse}
              selectedRoadmaps={selectedRoadmaps}
              handleRoadmapSelect={handleRoadmapSelect}
              handleGroupSelect={handleLocalGroupSelect}
              groupByOption={groupByOption}
            />
          )}
          {viewType === "list" && (
            <RoadmapListView
              groupedData={groupedData}
              selectedColumns={selectedColumns}
              collapsedGroups={collapsedGroups}
              toggleGroupCollapse={toggleGroupCollapse}
              selectedRoadmaps={selectedRoadmaps}
              handleRoadmapSelect={handleRoadmapSelect}
              handleGroupSelect={handleLocalGroupSelect}
              groupByOption={groupByOption}
            />
          )}
        </>
      )}
      {selectedRoadmaps.size > 0 && (
        <MasterRoadmapSelectionPopup
          selectedRoadmaps={selectedRoadmaps}
          resetSelectedRoadmaps={() => {
            setSelectedRoadmaps(new Set());
          }}
        />
      )}
    </div>
  );
};

export default RoadmapComponent;
