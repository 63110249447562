import React, { useEffect, useState } from "react";
import SettingsTopbar from "../SettingsTopbar";
import Loader from "../../common/Loader";
import {
  createIntegration,
  deleteIntegration,
  getIntegration,
  updateIntegration,
} from "../../../reduxStore/operations/integrationsAPI";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const IntegrationDetails = () => {
  const [email, setEmail] = useState();
  const [apiToken, setApiToken] = useState();
  const [domain, setDomain] = useState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate("/");
  const integrations = useSelector((state) => state.integrations);
  const { jira } = integrations;
  const { companyId } = useSelector((state) => state.auth);

  const submitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    const payload = {
      name: "jira",
      email,
      domain,
      apiToken,
      companyId,
    };
    if (jira) {
      dispatch(updateIntegration(payload, navigate));
    } else {
      dispatch(createIntegration(payload, navigate));
    }
    setLoading(false);
  };

  const jiraDisconnectHandler = () => {
    dispatch(deleteIntegration("jira", companyId));
    navigate("/settings/integrations");
  };

  useEffect(() => {
    dispatch(getIntegration("jira", companyId)).then((res) => {
      setEmail(res.email);
      setDomain(res.domain);
    });
  }, []);

  return (
    <>
      <SettingsTopbar
        title="Jira"
        // crumb={{ name: "Jira", path: "/settings/integrations/jira" }}
      />
      <div className="flex flex-col m-10 w-1/3">
        <form onSubmit={submitHandler}>
          <div className="mb-4">
            <label className="block text-gray-700">Email</label>
            <input
              type="email"
              className="w-full p-2 border border-gray-300 rounded mt-1"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Domain</label>
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded mt-1"
              value={domain}
              onChange={(e) => setDomain(e.target.value)}
              placeholder="E.g. https://test.atlassian.net"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">API Token</label>
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded mt-1"
              value={apiToken}
              onChange={(e) => setApiToken(e.target.value)}
              placeholder="API Token"
              required
            />
          </div>
          <div className="flex gap-2 mt-8">
            <button
              type="submit"
              disabled={loading}
              className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600"
            >
              {loading ? <Loader size="20" color="white" /> : "Submit"}
            </button>
            {jira && (
              <button
                onClick={jiraDisconnectHandler}
                className="p-2 border text-red-500 rounded hover:bg-red-50"
              >
                Disconnect
              </button>
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default IntegrationDetails;
