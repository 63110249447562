import { toast } from "react-toastify";
import { accountEndPoints } from "api/api";
import { apiConnector } from "api/apiconnector";
import {
  setAccounts,
  editAccount,
  deleteAccount,
  addAccount,
} from "../slices/accountSlice";

const { ADD_ACCOUNT, GET_ACCOUNTS, UPDATE_ACCOUNT, DELETE_ACCOUNT } =
  accountEndPoints;

// Action to fetch all accounts
export const getAccounts = () => async (dispatch) => {
  try {
    const { data } = await apiConnector("GET", GET_ACCOUNTS);
    dispatch(setAccounts(data.accounts));
  } catch (error) {
    console.error(error);
    toast.error(error.response?.data?.message ?? "Failed to fetch accounts.");
  }
};

// Action to add a new account
export const addNewAccount = (newAccount) => async (dispatch) => {
  try {
    const { data } = await apiConnector("POST", ADD_ACCOUNT, newAccount);
    dispatch(addAccount(data.account));
    // toast.success("Account added successfully!");
  } catch (error) {
    console.error(error);
    toast.error(error.response?.data?.message ?? "Failed to add account.");
  }
};

// Action to update an existing account
export const updateExistingAccount =
  (accountId, updatedAccount) => async (dispatch) => {
    try {
      const { data } = await apiConnector(
        "PUT",
        UPDATE_ACCOUNT(accountId),
        updatedAccount
      );
      dispatch(editAccount({ accountId, updatedAccount: data.account }));
      // toast.success("Account updated successfully!");
    } catch (error) {
      console.error(error);
      toast.error(error.response?.data?.message ?? "Failed to update account.");
    }
  };

// Action to delete an account
export const deleteExistingAccount = (accountId) => async (dispatch) => {
  try {
    await apiConnector("DELETE", DELETE_ACCOUNT(accountId));
    dispatch(deleteAccount(accountId));
    // toast.success("Account deleted successfully!");
  } catch (error) {
    console.error(error);
    toast.error(error.response?.data?.message ?? "Failed to delete account.");
  }
};
