export const incrementLabel = (label) => {
  // Regular expression to match the non-numeric part and the numeric part of the label
  const regex = /(\D+)(\d+)/;
  const match = label.match(regex);

  if (match) {
    const [_, nonNumericPart, numericPart] = match;
    const incrementedNumericPart = parseInt(numericPart, 10) + 1;
    return `${nonNumericPart}${incrementedNumericPart}`;
  }

  // If no numeric part is found, return the label as is
  return label;
};
