/* eslint-disable react/prop-types */
import IconText from "../IconText";
import React from "react";
import { rgbColor } from "utils";
import Importance from "../Importance";

const SimpleCheckboxItem = ({
  option,
  handleOptionClick,
  selected,
  checkboxTextSize = "text-xs",
  iconSize = "14",
  isImportanceType = false,
}) => {
  return (
    <div
      className="flex items-center cursor-pointer hover:bg-topbar-button-hover w-full pl-1.5 rounded"
      onClick={(e) => {
        e.stopPropagation();
        handleOptionClick(option);
      }}
    >
      <input
        type="checkbox"
        className={`accent-checkbox-checked cursor-pointer`}
        id={option.label}
        checked={selected}
        aria-label={option.label}
        onChange={() => handleOptionClick(option)}
        onClick={(e) => {
          e.stopPropagation();
        }}
      />
      <div className={`${checkboxTextSize} text-primary ml-2 rounded p-1`}>
        {isImportanceType ? (
          <Importance value={option.label} readOnly={true} />
        ) : option.icon ? (
          <div
            className={
              (option?.withBackgroundColor ?? true) &&
              option.iconColor &&
              "bg-white rounded"
            }
          >
            <IconText
              icon={
                React.isValidElement(option.icon)
                  ? option.icon
                  : React.createElement(option.icon)
              }
              text={option.label}
              truncateLength={40}
              iconTextBgColor={
                (option?.withBackgroundColor ?? true) && option.iconColor
                  ? rgbColor(option.iconColor, 0.2)
                  : ""
              }
              iconColor={option.iconColor}
              textSize={checkboxTextSize}
              gap_x="gap-x-1.5 rounded"
              py="py-1"
              px="px-1"
              iconSize={iconSize}
              hoverable={false}
              textColor="text-title_50"
            />
          </div>
        ) : (
          <div
            className={
              (option?.withBackgroundColor ?? true) &&
              option.iconColor &&
              "bg-white rounded"
            }
          >
            <div
              className="rounded p-1 text-title_50"
              style={{
                backgroundColor:
                  (option?.withBackgroundColor ?? true) && option.iconColor
                    ? rgbColor(option.iconColor, 0.2)
                    : "",
              }}
            >
              {option.label}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SimpleCheckboxItem;
