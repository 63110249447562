import {
  RiProgress5Line,
  RiProgress8Fill,
  RiProgress8Line,
} from "react-icons/ri";
import { fieldIconMapping, rgbColor } from ".";
import { TbCircleDashed } from "react-icons/tb";

export const generateStatusArray = (statusGroups) => [
  ...(statusGroups?.filter((item) => item.type === "parked") ?? []).map(
    (item) => ({
      ...item,
      icon: <TbCircleDashed className="text-primary" />,
    })
  ),
  ...(statusGroups?.filter((item) => item.type === "to_do") ?? []).map(
    (item) => ({
      ...item,
      icon: <RiProgress8Fill className="text-primary" />,
    })
  ),
  ...(statusGroups?.filter((item) => item.type === "in_progress") ?? []).map(
    (item) => ({
      ...item,
      icon: <RiProgress5Line className="text-primary" />,
    })
  ),
  ...(statusGroups?.filter((item) => item.type === "done") ?? []).map(
    (item) => ({
      ...item,
      icon: <RiProgress8Line className="text-primary" />,
    })
  ),
];
export const getStatusColor = (status, statusArray, opacity = 1) => {
  const statusObj = statusArray
    ? (statusArray ?? []).find((item) => item?.label === status)
    : null;
  return statusObj
    ? statusObj.color?.length > 7
      ? statusObj.color
      : rgbColor(statusObj.color, opacity)
    : `rgba(0, 0, 0, ${opacity})`;
};

export const getStatusIcon = (status, statusArray, defaultIcon) => {
  const statusObj = (statusArray ?? []).find((item) => item?.label === status);
  return statusObj?.icon ?? defaultIcon;
};
