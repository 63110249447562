import { Outlet } from "react-router-dom";
import SettingsSidebar from "../components/settings/SettingsSidebar";

const Settings = () => {
  return (
    <div className="flex font-inter w-screen h-screen tracking-wide">
      <SettingsSidebar />
      <div className="flex flex-col min-w-[80%] w-[100%]">
        <Outlet />
      </div>
    </div>
  );
};

export default Settings;
