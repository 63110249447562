/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { generateStatusArray } from "utils/status";
import ProductSection from "../detailView/detailViewRightSidebarSections/ProductSection";
import StatusSection from "../detailView/detailViewRightSidebarSections/StatusSection";
import ImportanceSection from "../detailView/detailViewRightSidebarSections/ImportanceSection";
import { fieldIconMapping } from "utils";

const AddIdeaPopupOverlay = ({ option, name, setIsWarn }) => {
  const [status, setStatus] = useState(null);
  const [product, setProduct] = useState(null);
  const [importance, setImportance] = useState(2);
  const { statuses, products } = useSelector((state) => state.company);
  const { scoreType } = useSelector((state) => state.company);
  const [ideaStatuses, setIdeaStatuses] = useState([]);

  const handleStatusOptionClick = (option) => {
    setStatus(option);
  };
  const handleProductOptionClick = (option) => {
    setProduct(option);
  };
  const handleImportanceOptionClick = (option) => {
    setImportance(option);
  };

  const handleAddIdea = () => {
    if (name === "") {
      setIsWarn(true);
      setTimeout(() => {
        setIsWarn(false);
      }, 3000);
      return;
    }
    option.onAddClick({
      title: name,
      status: status._id ?? null,
      product: product?._id ?? null,
      importance: importance,
      accounts: [],
      score: {
        type:
          scoreType === "value_effort_score"
            ? "Value/Effort"
            : scoreType === "rice_score"
            ? "RICE"
            : scoreType === "impact_effort_score"
            ? "Impact/Effort"
            : "",
        value: 0.0,
        effort: 0.0,
        reach: 0.0,
        impact: 0.0,
        value_effort_score: 0.0,
        impact_effort_score: 0.0,
        rice_score: 0.0,
      },
    });
  };

  useEffect(() => {
    setIdeaStatuses(statuses.idea);
  }, [statuses]);

  const statusArray = useMemo(
    () => generateStatusArray(ideaStatuses),
    [ideaStatuses]
  );

  useEffect(() => {
    if (products !== null) {
      setProduct(products?.[0] ?? "");
    }
  }, [products]);

  useEffect(() => {
    setStatus(statusArray[0]);
  }, [statusArray]);

  return (
    <div className="flex flex-col gap-y-2 -ml-1">
      <div className="flex items-center gap-x-2">
        {option.addOptions.includes("status") && (
          <StatusSection
            item={status ?? {}}
            statusArray={statusArray}
            handleStatusOptionClick={handleStatusOptionClick}
            position=""
            with_title={false}
          />
        )}
        {option.addOptions.includes("importance") && (
          <div className="flex gap-x-1 rounded border p-1.5">
            <ImportanceSection
              value={importance}
              with_icon={false}
              handleImportanceOptionClick={handleImportanceOptionClick}
            />
          </div>
        )}
        {option.addOptions.includes("product") && (
          <ProductSection
            item={{
              ...product,
              icon: React.createElement(fieldIconMapping.product),
            }}
            products={products}
            handleProductOptionClick={handleProductOptionClick}
            position=""
            with_title={false}
          />
        )}
      </div>
      <hr />
      <button
        className="p-2 bg-add-popup-button rounded text-white w-fit text-sm ml-auto"
        onClick={handleAddIdea}
      >
        Add {option.label}
      </button>
    </div>
  );
};

export default AddIdeaPopupOverlay;
