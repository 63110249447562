/* eslint-disable react/prop-types */
import { useSelector } from "react-redux";
import { useMemo, useState } from "react";
import ObjectiveListView from "components/objectives/views/list/ObjectiveListView";
import { useDataProcessing } from "hooks/useDataProcessing";
import Loader from "components/common/Loader";
import ObjectiveRoadmapsKanbanView from "./ObjectiveRoadmapsKanbanView";
import { handleGroupSelect } from "utils/listUtils";

function ObjectivesRoadmapsComponent({
  searchTerm = "",
  roadmap = null,
  filterOptions,
  filteredTimeFrames,
}) {
  const { objectives, groupByOption, selectedColumns, viewType } = useSelector(
    (state) => state.objectives
  );

  const filteredObjectives = useMemo(() => {
    return (objectives ?? [])
      .filter((item) =>
        item.title.toLowerCase().includes(searchTerm.trim().toLowerCase())
      )
      .filter((item) =>
        (filteredTimeFrames ?? []).some(
          (timeFrame) => timeFrame._id === item?.timeFrame?._id ?? ""
        )
      );
  }, [filteredTimeFrames, objectives, searchTerm]);

  const { groupedData, loading } = useDataProcessing(
    filteredObjectives,
    "objective",
    roadmap
  );

  const [collapsedGroups, setCollapsedGroups] = useState({});
  const [selectedObjectives, setSelectedObjectives] = useState(new Set());

  const toggleGroupCollapse = (group) => {
    setCollapsedGroups((prev) => ({
      ...prev,
      [group]: !prev[group],
    }));
  };

  const handleObjectiveSelect = (objective) => {
    setSelectedObjectives((prevSelected) => {
      const newSelected = new Set(prevSelected);
      newSelected.has(objective._id)
        ? newSelected.delete(objective._id)
        : newSelected.add(objective._id);
      return newSelected;
    });
  };

  const handleLocalGroupSelect = (group) =>
    handleGroupSelect(group, setSelectedObjectives, groupedData);

  return (
    <div className="relative overflow-scroll bg-white shadow w-full h-full">
      {loading ? (
        <div className="flex items-center justify-center h-full">
          <Loader />
        </div>
      ) : (
        <>
          {viewType === "classiclist" && (
            <ObjectiveListView
              groupedData={groupedData}
              selectedColumns={selectedColumns}
              collapsedGroups={collapsedGroups}
              toggleGroupCollapse={toggleGroupCollapse}
              selectedObjectives={selectedObjectives}
              handleObjectiveSelect={handleObjectiveSelect}
              handleGroupSelect={handleLocalGroupSelect}
              groupByOption={groupByOption}
            />
          )}
          {viewType === "kanban" && (
            <ObjectiveRoadmapsKanbanView
              groupedData={groupedData}
              roadmap={roadmap}
              isRoadmapDetailColumn={true}
              filterOptions={filterOptions}
            />
          )}
        </>
      )}
    </div>
  );
}

export default ObjectivesRoadmapsComponent;
