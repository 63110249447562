import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/common/Loader";
import IconWrapper from "components/common/IconWrapper";
import ColorDropdown from "./ColorDropdown";
import { fieldIconMapping } from "utils";
import CalendarSection from "./CalendarSection";
import {
  editReleaseInCompany,
  addReleaseToCompany,
  deleteReleaseOfCompany,
} from "reduxStore/operations/releaseAPI";
import EditInputForm from "./EditInputForm";
import AddInputForm from "./AddInputForm";
import { incrementLabel } from "utils/settingsUtils";

const ReleaseField = () => {
  const addRef = useRef(null);
  const editRefs = useRef({});
  const dispatch = useDispatch();

  const { releases } = useSelector((state) => state.company);

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [activeStartDate, setActiveStartDate] = useState("");
  const [activeEndDate, setActiveEndDate] = useState("");

  // Add Related States
  const [isAddInputOpen, setIsAddInputOpen] = useState(false);
  const [addNewReleaseLabel, setAddNewReleaseLabel] = useState("");

  // Edit Related States
  const [inputLabelClicked, setInputLabelClicked] = useState(false);
  const [activeEditReleaseId, setActiveEditReleaseId] = useState(null);
  const [editLabel, setEditLabel] = useState("");
  const [originalEditLabel, setOriginalEditLabel] = useState("");
  const [originalStartDate, setOriginalStartDate] = useState("");
  const [originalEndDate, setOriginalEndDate] = useState("");

  const resetEditOptions = () => {
    setIsCalendarOpen(false);
    setEditLabel("");
    setOriginalStartDate("");
    setOriginalEndDate("");
    setActiveStartDate("");
    setActiveEndDate("");
    setActiveEditReleaseId(null);
  };

  const resetAddOptions = () => {
    setIsCalendarOpen(false);
    setAddNewReleaseLabel(false);
    setIsAddInputOpen(false);
    setActiveStartDate("");
    setActiveEndDate("");
  };

  const editButtonClick = (releaseValue, startDate, endDate, releaseId) => {
    setOriginalEditLabel(releaseValue);
    setEditLabel(releaseValue);
    setActiveEditReleaseId(releaseId);
    setOriginalStartDate(startDate);
    setOriginalEndDate(endDate);
  };

  const addButtonClick = (lastRelease) => {
    setIsAddInputOpen(true);
    setAddNewReleaseLabel(incrementLabel(lastRelease?.label ?? "Cycle 0"));
    setOriginalStartDate(lastRelease?.endDate ?? Date.now());
    setOriginalEndDate(lastRelease?.endDate ?? Date.now());
  };

  const handleEditRelease = () => {
    if (editLabel !== originalEditLabel || activeStartDate || activeEndDate) {
      dispatch(
        editReleaseInCompany(
          activeEditReleaseId,
          editLabel !== "" ? editLabel : originalEditLabel,
          activeStartDate || originalStartDate,
          activeEndDate || originalEndDate
        )
      );
    }
    resetEditOptions();
  };

  const handleAddRelease = () => {
    if (addNewReleaseLabel !== "" || activeStartDate || activeEndDate) {
      dispatch(
        addReleaseToCompany(
          addNewReleaseLabel,
          activeStartDate || originalStartDate,
          activeEndDate || originalEndDate
        )
      );
    }
    resetAddOptions();
  };

  const handleDeleteRelease = () => {
    dispatch(deleteReleaseOfCompany(activeEditReleaseId));
    resetEditOptions();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        activeEditReleaseId &&
        editRefs.current[activeEditReleaseId] &&
        !editRefs.current[activeEditReleaseId].contains(event.target)
      ) {
        handleEditRelease();
        resetAddOptions();
        setInputLabelClicked(false);
      } else if (
        isAddInputOpen &&
        addRef.current &&
        !addRef.current.contains(event.target)
      ) {
        handleAddRelease();
        resetEditOptions();
        setInputLabelClicked(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [
    activeEditReleaseId,
    activeStartDate,
    activeEndDate,
    editLabel,
    addNewReleaseLabel,
    isAddInputOpen,
  ]);

  if (!releases) {
    return (
      <div className="flex items-center justify-center h-[600px]">
        <Loader />
      </div>
    );
  }

  return (
    <div className="text-sm text-title_10 cursor-default">
      <div className="mb-5 font-semibold">Releases</div>
      <div className="flex flex-col gap-y-2">
        {releases.length > 0 ? (
          releases.map((release) => (
            <div
              key={release._id}
              className="flex items-center text-primary text-sm gap-x-3 w-fit"
              ref={(el) => (editRefs.current[release._id] = el)}
              onClick={() =>
                editButtonClick(
                  release.label,
                  release.startDate,
                  release.endDate,
                  release._id
                )
              }
            >
              <ColorDropdown
                icon={React.createElement(fieldIconMapping["release"])}
                iconColor="#696969"
                openDropdownCondition={false}
                clickable={false}
              />
              {activeEditReleaseId === release._id ? (
                <EditInputForm
                  inputLabel={editLabel}
                  setInputLabel={setEditLabel}
                  inputFocusCondition={inputLabelClicked}
                  onEditInputEnter={handleEditRelease}
                  onInputButtonClick={setIsCalendarOpen}
                  startDate={activeStartDate || originalStartDate}
                  endDate={activeEndDate || originalEndDate}
                  isCalendarActiveCondition={isCalendarOpen}
                  setIsCalendarOpen={setIsCalendarOpen}
                  setStartDate={setActiveStartDate}
                  setEndDate={setActiveEndDate}
                  withCalendar={true}
                  handleRemoveOptionClick={() => handleDeleteRelease()}
                />
              ) : (
                <>
                  <div
                    className="p-2 border rounded w-48 cursor-text"
                    onClick={() => {
                      setInputLabelClicked(true);
                    }}
                  >
                    {release.label}
                  </div>
                  <CalendarSection
                    startDate={release.startDate}
                    endDate={release.endDate}
                    setIsCalendarOpen={setIsCalendarOpen}
                    isCalendarActiveCondition={
                      isCalendarOpen && activeEditReleaseId === release._id
                    }
                  />
                </>
              )}
            </div>
          ))
        ) : (
          <div className="flex items-center gap-x-3">
            <div>
              <IconWrapper
                icon={React.createElement(fieldIconMapping.release)}
                size="14"
              />
            </div>
            <div className="p-2 border rounded w-48 cursor-not-allowed bg-row-background">
              No Release
            </div>
          </div>
        )}
        {isAddInputOpen ? (
          <div className="flex items-center gap-x-3 w-fit" ref={addRef}>
            <AddInputForm
              icon={React.createElement(fieldIconMapping["release"])}
              iconClickable={false}
              inputLabel={addNewReleaseLabel}
              setInputLabel={(value) => setAddNewReleaseLabel(value)}
              withCalendar={true}
              startDate={activeStartDate || originalStartDate}
              endDate={activeEndDate || originalEndDate}
              setStartDate={setActiveStartDate}
              setEndDate={setActiveEndDate}
              setIsCalendarOpen={setIsCalendarOpen}
              isCalendarActiveCondition={isCalendarOpen}
              onInputEnter={() => handleAddRelease()}
              handleCloseOptionClick={resetAddOptions}
            />
          </div>
        ) : (
          <div
            className="text-primary cursor-pointer hover:bg-button-hover p-1 w-fit rounded"
            onClick={() => {
              addButtonClick(releases[releases.length - 1]);
            }}
          >
            + Add
          </div>
        )}
      </div>
    </div>
  );
};

export default ReleaseField;
