/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CrossSmall, Compress, Expand } from "react-flaticons";
import Breadcrumb from "../Breadcrumb";
import { useDispatch } from "react-redux";
import { setActiveKeyResultId } from "../../../reduxStore/slices/keyResultSlice";

const DetailViewHeader = ({
  type,
  item,
  showBreadcrumb = true,
  showDetailViewDrawer,
  activeItem,
  setShowDetailViewDrawer,
  setActiveItemId,
  shouldNavigate = false,
  Icon,
}) => {
  const dispatch = useDispatch();
  const crumbs = [
    { icon: Icon, name: type + "s", path: `/${type.toLowerCase()}s` },
    {
      name: item[`${type.toLowerCase()}_name`],
      path: `/${type.toLowerCase()}s/${item._id}`,
    },
  ];

  const navigate = useNavigate();

  // const toggleExpandedView = () => {
  //   if (!showBreadcrumb) {
  //     dispatch(setShowDetailViewDrawer(false));
  //     dispatch(setActiveItemId(null));
  //     navigate(`/${type.toLowerCase()}s/${item._id}`);
  //   } else {
  //     dispatch(setActiveItemId(item._id));
  //     dispatch(setShowDetailViewDrawer(true));
  //     navigate(`/${type.toLowerCase()}s`);
  //   }
  // };

  const toggleDrawer = () => {
    dispatch(setShowDetailViewDrawer(false));
    dispatch(setActiveItemId(null));
  };

  return (
    <div className="flex items-center justify-between">
      {showBreadcrumb ? (
        <Breadcrumb crumbs={crumbs} />
      ) : (
        <div
          className="hover:bg-button-hover rounded p-1 cursor-pointer"
          onClick={toggleDrawer}
        >
          <CrossSmall size={16} />
        </div>
      )}
      {/* <div
        className="hover:bg-button-hover rounded p-2 cursor-pointer"
        onClick={toggleExpandedView}
      >
        {showBreadcrumb ? <Compress size={12} /> : <Expand size={12} />}
      </div> */}
    </div>
  );
};

export default DetailViewHeader;
