/* eslint-disable react/prop-types */
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import FeatureListView from "./views/list/FeatureListView";
import FeatureKanbanView from "./views/kanban/FeatureKanbanView";
import { useDataProcessing } from "hooks/useDataProcessing";
import Loader from "components/common/Loader";

import { handleGroupSelect } from "utils/listUtils";

import {
  setDetailViewFeatures,
  setFeatureViewType,
} from "reduxStore/slices/featureSlice";
import MasterFeatureSelectionPopup from "../common/masterSelectionPopup/MasterFeatureSelectionPopup";
import { useLocation } from "react-router-dom";

function FeaturesComponent({ roadmap = null, searchTerm = "" }) {
  const dispatch = useDispatch();
  const { objectives } = useSelector((state) => state.objectives);
  const { features, groupByOption, selectedColumns, viewType } = useSelector(
    (state) => state.features
  );
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const urlViewType = searchParams.get("viewType");
  const filteredFeatures = useMemo(() => {
    return (features ?? []).filter((feature) =>
      feature.title.toLowerCase().includes(searchTerm.trim().toLowerCase())
    );
  }, [features, searchTerm]);

  const { groupedData, loading } = useDataProcessing(
    filteredFeatures,
    "feature",
    roadmap,
    objectives
  );

  const [collapsedGroups, setCollapsedGroups] = useState({});
  const [selectedFeatures, setSelectedFeatures] = useState(new Set());

  useEffect(() => {
    const allData = Object.values(groupedData).flatMap((group) => group.items);
    dispatch(setDetailViewFeatures(allData));
  }, [groupedData, features]);

  const toggleGroupCollapse = (group) => {
    setCollapsedGroups((prev) => ({
      ...prev,
      [group]: !prev[group],
    }));
  };

  const handleFeatureSelect = (feature) => {
    setSelectedFeatures((prevSelected) => {
      const newSelected = new Set(prevSelected);
      newSelected.has(feature._id)
        ? newSelected.delete(feature._id)
        : newSelected.add(feature._id);
      return newSelected;
    });
  };

  const resetSelectedFeatures = () => {
    setSelectedFeatures(new Set());
  };
  useEffect(() => {
    if (viewType === "kanban") {
      resetSelectedFeatures();
    }
  }, [viewType]);

  // useEffect(() => {
  //   if (urlViewType) {
  //     dispatch(
  //       setFeatureViewType(urlViewType === "kanban" ? "kanban" : "classiclist")
  //     );
  //   }
  // }, [dispatch, urlViewType]);

  useEffect(() => {
    if (urlViewType === viewType) {
      dispatch(
        setFeatureViewType(urlViewType === "kanban" ? "kanban" : "classiclist")
      );
    }
  }, [dispatch, urlViewType, viewType]);

  const handleLocalGroupSelect = (group) =>
    handleGroupSelect(group, setSelectedFeatures, groupedData);

  return (
    <div className="relative overflow-scroll bg-white shadow w-full h-full">
      {loading ? (
        <div className="flex items-center justify-center h-full">
          <Loader />
        </div>
      ) : (
        <>
          {viewType === "classiclist" && (
            <FeatureListView
              groupedData={groupedData}
              selectedColumns={selectedColumns}
              collapsedGroups={collapsedGroups}
              toggleGroupCollapse={toggleGroupCollapse}
              selectedFeatures={selectedFeatures}
              handleFeatureSelect={handleFeatureSelect}
              handleGroupSelect={handleLocalGroupSelect}
              groupByOption={groupByOption}
            />
          )}
          {viewType === "kanban" && (
            <FeatureKanbanView groupedData={groupedData} />
          )}
        </>
      )}
      {selectedFeatures.size > 0 && viewType !== "kanban" && (
        <MasterFeatureSelectionPopup
          selectedFeatures={selectedFeatures}
          resetSelectedFeatures={resetSelectedFeatures}
        />
      )}
    </div>
  );
}

export default FeaturesComponent;
