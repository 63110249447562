/* eslint-disable react/prop-types */
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fieldIconMapping } from "utils";
import {
  setActiveFeatureId,
  setFeatureShowDetailViewDrawer,
} from "reduxStore/slices/featureSlice";
import KanbanModuleSection from "./KanbanModuleSection";
import { setDetailViewFeatures } from "reduxStore/slices/featureSlice";

const KanbanFeaturesSection = ({ features }) => {
  const dispatch = useDispatch();
  const { features: allFeatures } = useSelector((state) => state.features);

  const filteredFeatures = useMemo(() => {
    return (allFeatures ?? []).filter((feature) =>
      (features ?? []).includes(feature._id)
    );
  }, [features, allFeatures]);

  const handleFeatureClick = (feature) => {
    dispatch(setActiveFeatureId(feature._id));
    dispatch(setDetailViewFeatures(filteredFeatures));
    dispatch(setFeatureShowDetailViewDrawer(true));
  };

  return (
    <KanbanModuleSection
      items={filteredFeatures}
      icon={fieldIconMapping.feature}
      truncateLength={filteredFeatures?.length > 1 ? 28 : 40}
      iconColor="text-features-icon"
      textColor="text-title_50"
      onItemClick={handleFeatureClick}
    />
  );
};

export default KanbanFeaturesSection;
