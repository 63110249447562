/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from "react";
import { generateStatusArray } from "utils/status";
import Loader from "components/common/Loader";
import IdeasModernListCard from "./IdeasModernListCard";
import { useSelector } from "react-redux";
import ListViewGroupHeader from "components/common/classicList/ListViewGroupHeader";
import { isObjectEmpty } from "utils";

const IdeasModernListView = ({
  groupedData,
  collapsedGroups,
  toggleGroupCollapse,
  selectedIdeas,
  handleIdeaSelect,
  handleGroupSelect,
  selectedColumns,
  groupByOption,
}) => {
  const { statuses } = useSelector((state) => state.company);
  const { ideas, isLoading, isViewsLoading, filters } = useSelector(
    (state) => state.ideas
  );

  const [ideaStatuses, setIdeaStatuses] = useState([]);
  useEffect(() => {
    setIdeaStatuses(statuses.idea);
  }, [statuses]);

  const statusArray = useMemo(
    () => generateStatusArray(ideaStatuses),
    [ideaStatuses]
  );
  const [localGroupByOption, setLocalGroupByOption] = useState(null);

  useEffect(() => {
    setLocalGroupByOption(groupByOption);
  }, [groupByOption]);

  const hasData =
    groupedData &&
    Object.keys(groupedData).length > 0 &&
    Object.values(groupedData).some((groupData) => groupData.items.length > 0);

  const loading =
    ((localGroupByOption === null || localGroupByOption !== groupByOption) &&
      isObjectEmpty(filters)) ||
    isLoading ||
    isViewsLoading;

  return (
    <div className="w-full h-full overflow-x-auto">
      {loading ? (
        <div className="flex items-center justify-center h-full">
          <Loader />
        </div>
      ) : (
        <>
          <div className="flex pt-5 text-primary sticky z-30 bg-white top-0 text-sm">
            <div className="w-5/6 grow-0 pl-6">Idea</div>
            {selectedColumns.includes("status") && (
              <div className="w-1/6 grow-0">Status</div>
            )}
          </div>
          <div>
            {ideas && !hasData && localGroupByOption === "default" ? (
              <div className="absolute flex items-center justify-center h-[87%] w-full">
                <p className="text-lg text-gray-500">No ideas to show</p>
              </div>
            ) : (
              Object.entries(groupedData).map(
                ([groupId, groupData], groupIndex) => (
                  <React.Fragment key={groupIndex}>
                    {localGroupByOption !== "default" && (
                      <ListViewGroupHeader
                        group={{
                          _id: groupId,
                          color: groupData.color,
                          label: groupData.label,
                        }}
                        groupByOption={localGroupByOption}
                        items={groupData.items}
                        selectedItems={selectedIdeas}
                        toggleGroupCollapse={toggleGroupCollapse}
                        handleGroupSelect={handleGroupSelect}
                        collapsedGroups={collapsedGroups}
                        statusArray={statusArray}
                      />
                    )}
                    <div className="flex flex-col  pt-[10px] ">
                      {/* <div className="flex flex-col gap-y-2.5"> */}
                      {!collapsedGroups[groupId] &&
                        groupData.items?.map((idea, index) => (
                          <div key={index}>
                            <IdeasModernListCard
                              idea={idea}
                              onSelect={handleIdeaSelect}
                              isSelected={selectedIdeas.has(idea._id)}
                              statusArray={statusArray}
                            />
                            <div className=" h-[1px] my-[10px] bg-[#6969692c] w-[90%]  mr-auto ml-auto  "></div>
                          </div>
                        ))}
                    </div>
                  </React.Fragment>
                )
              )
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default IdeasModernListView;
