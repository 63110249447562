/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  RiProgress5Line,
  RiProgress8Fill,
  RiProgress8Line,
} from "react-icons/ri";
import { Minus } from "react-flaticons";
import { TbCircleDashed } from "react-icons/tb";

import Sidebar from "components/core/Sidebar";
import Topbar from "components/core/topbar/Topbar";

import Loader from "components/common/Loader";
import IconWrapper from "components/common/IconWrapper";

import {
  capitalizeFirstLetter,
  transformFiltersToObject,
  fieldIconMapping,
  truncateText,
} from "utils";
import { objectiveTopbarButtonFunctions } from "utils/topbarButtonFunctions";
import { objectiveHealths } from "utils/objectiveUtils";
import { particularRoadmapTopbarTabFunctions } from "utils/topbarTabFunctions";

import ObjectivesRoadmapsComponent from "./ObjectivesRoadmapsComponent";

import {
  setObjectiveActiveProperties,
  setObjectiveViewType,
} from "reduxStore/slices/objectiveSlice";
import {
  setActiveRoadmapId,
  setRoadmapShowDetailViewDrawer,
} from "reduxStore/slices/roadmapsSlice";

import { fetchAllViews } from "reduxStore/operations/viewsAPI";
import AllDetailView from "components/common/AllDetailView";

const RoadmapStrategyDetailViewComponent = ({
  roadmap,
  showBreadcrumb = true,
}) => {
  const dispatch = useDispatch();
  const { statuses, timeFrames, tags } = useSelector((state) => state.company);
  const { viewType, isLoading, isViewsLoading } = useSelector(
    (state) => state.objectives
  );

  const { roadmapIdViews } = useSelector((state) => state.roadmaps);

  const [objectiveStatuses, setObjectiveStatuses] = useState([]);
  const [searchbarTerm, setSearchbarTerm] = useState("");
  const [currentViewType, setCurrentViewType] = useState(null);
  const [tabs, setTabs] = useState(null);

  const crumbs = [
    {
      icon: (
        <IconWrapper
          icon={React.createElement(fieldIconMapping.roadmap)}
          iconColor="text-roadmaps-icon"
        />
      ),
      name: "Roadmaps",
      path: `/roadmaps`,
    },
    {
      name: truncateText(roadmap.title, 20),
      path: `/roadmaps/${roadmap._id}`,
    },
  ];

  useEffect(() => {
    setObjectiveStatuses(statuses.objective);
  }, [statuses]);

  useEffect(() => {
    dispatch(fetchAllViews("roadmapId", null, roadmap._id));
  }, [dispatch, roadmap]);

  useEffect(() => {
    if (roadmapIdViews) {
      const transformedLocalViews = roadmapIdViews.map((view) => ({
        ...view,
        filters: transformFiltersToObject(view.filters),
      }));
      setTabs([...transformedLocalViews]);
    }
  }, [roadmapIdViews]);

  const filteredTimeFrames = useMemo(() => {
    if (roadmap?.manualTimeFrame?.startDate) {
      return (timeFrames ?? []).filter(
        (timeFrame) =>
          new Date(timeFrame.endDate) <
            new Date(roadmap.manualTimeFrame.endDate) &&
          new Date(timeFrame.endDate) >
            new Date(roadmap.manualTimeFrame.startDate)
      );
    } else {
      return roadmap?.timeFrames || timeFrames;
    }
  }, [roadmap?.manualTimeFrame, timeFrames, roadmap?.timeFrames]);

  useEffect(() => {
    if (viewType !== "kanban") {
      dispatch(setObjectiveViewType("kanban"));
      setCurrentViewType("kanban");
    }
  }, [dispatch, viewType]);

  const topbarFunctions = objectiveTopbarButtonFunctions(dispatch);
  const topbarTabFunctions = particularRoadmapTopbarTabFunctions(dispatch);

  const setActiveProperties = (
    sortOption,
    selectedColumns,
    groupByOption,
    filters
  ) => {
    dispatch(
      setObjectiveActiveProperties({
        sortOption,
        selectedColumns,
        groupByOption,
        filters,
      })
    );
  };

  const statusParked =
    (objectiveStatuses ?? []).filter((item) => item.type === "parked") ?? [];
  const statusToDo =
    (objectiveStatuses ?? []).filter((item) => item.type === "to_do") ?? [];
  const statusInProgress =
    (objectiveStatuses ?? []).filter((item) => item.type === "in_progress") ??
    [];
  const statusDone =
    (objectiveStatuses ?? []).filter((item) => item.type === "done") ?? [];

  const groupByDropdownOptions = [
    { label: "Default", icon: Minus },
    ...(viewType !== "kanban"
      ? [{ label: "Time frame", icon: fieldIconMapping["time_frame"] }]
      : []),
    { label: "Status", icon: fieldIconMapping["status"] },
    { label: "Product", icon: fieldIconMapping["product"] },
  ];

  const getFilteredObjectives = () => {
    const predefinedFields = [
      "progress",
      "health",
      "status",
      "time_frame",
      "tags",
      "assignee",
      "key_result",
      "feature",
      // "roadmap",
    ];

    const filteredOptions = predefinedFields.map((field) => ({
      label: capitalizeFirstLetter(field),
      icon: fieldIconMapping[field] || Minus,
    }));
    return [...filteredOptions];
  };

  // Determine whether to show dropdown options based on objectives
  const showButtonDropdownOptions = getFilteredObjectives();

  const getFilterOptions = (timeFrames) => [
    {
      label: "Status",
      icon: fieldIconMapping.status,
      subOptions: [
        ...statusParked.map((item) => ({
          ...item,
          iconColor: item.color,
          icon: TbCircleDashed,
        })),
        ...statusToDo.map((item) => ({
          ...item,
          iconColor: item.color,
          icon: RiProgress8Fill,
        })),
        ...statusInProgress.map((item) => ({
          ...item,
          iconColor: item.color,
          icon: RiProgress5Line,
        })),
        ...statusDone.map((item) => ({
          ...item,
          iconColor: item.color,
          icon: RiProgress8Line,
        })),
      ],
    },
    {
      label: "Progress",
      icon: fieldIconMapping.progress,
      subOptions: [
        { label: "< 20" },
        { label: ">= 20 and < 50" },
        { label: ">= 50 and < 80" },
        { label: ">= 80 and < 100" },
        { label: "100" },
      ],
    },
    {
      label: "Health",
      icon: fieldIconMapping.health,
      subOptions: objectiveHealths().map((option) => ({
        ...option,
        iconColor: option.color,
        withBackgroundColor: false,
      })),
    },
    {
      label: "Time Frame",
      icon: fieldIconMapping.time_frame,
      subOptions: (timeFrames
        ? timeFrames.filter(
            (timeFrame) =>
              !filteredTimeFrames?.some(
                (filteredTimeFrame) => filteredTimeFrame._id === timeFrame._id
              )
          )
        : filteredTimeFrames ?? []
      ).map((timeFrame) => ({
        ...timeFrame,
        icon: fieldIconMapping.timeFrames,
        iconColor: timeFrame.color,
      })),
    },
    { label: "Assignee", icon: fieldIconMapping.assignee },
    {
      label: "Tags",
      icon: fieldIconMapping.tags,
      subOptions: tags ?? [],
    },
  ];

  const filterOptions = getFilterOptions();
  const allFilterOptions = getFilterOptions(timeFrames);

  // const sortOptions = [
  //   "Default",
  //   "Status",
  //   "Time Frame",
  //   "Health",
  //   "Assignee",
  // ].map((label) => ({ label }));

  const sortOptions = [
    { label: "Created", icon: Minus },
    { label: "Status", icon: fieldIconMapping.status },
    { label: "Time Frame", icon: fieldIconMapping.time_frame },
    { label: "Health", icon: fieldIconMapping.health },
    { label: "Assignee", icon: fieldIconMapping.assignee },
  ];

  return (
    <div className="flex font-inter w-screen h-screen tracking-wide cursor-default">
      <Sidebar />
      {(isLoading && isViewsLoading && currentViewType !== null) ||
      tabs === null ? (
        <div className="absolute inset-0 flex items-center justify-center">
          <Loader />
        </div>
      ) : (
        <div className="flex flex-col min-w-[80%] w-[100%]">
          <Topbar
            title="Objectives"
            tabs={tabs}
            groupByDropdownOptions={groupByDropdownOptions}
            showButtonDropdownOptions={showButtonDropdownOptions}
            filterOptions={filterOptions}
            sortOptions={sortOptions}
            topbarFunctions={topbarFunctions}
            topbarTabFunctions={topbarTabFunctions}
            setActiveProperties={setActiveProperties}
            with_different_view_option={false}
            activeState="objectives"
            showBreadcrumb={showBreadcrumb}
            breadCrumbs={crumbs}
            handleRightSidebarClick={() => {
              dispatch(setActiveRoadmapId(roadmap._id));
              dispatch(setRoadmapShowDetailViewDrawer(true));
            }}
            withShareOption={true}
            withTopSidebarOption={true}
            with_group_by_option={false}
            searchTerm={searchbarTerm}
            onSearchChange={(value) => setSearchbarTerm(value)}
            roadmap={roadmap}
          />
          <ObjectivesRoadmapsComponent
            searchTerm={searchbarTerm}
            roadmap={roadmap}
            filterOptions={allFilterOptions}
            filteredTimeFrames={filteredTimeFrames}
          />
        </div>
      )}
      <AllDetailView />
    </div>
  );
};

export default RoadmapStrategyDetailViewComponent;
