/* eslint-disable react/prop-types */
import React from "react";
import ColorDropdown from "./ColorDropdown";
import { Check, CrossSmall } from "react-flaticons";
import CalendarSection from "./CalendarSection";

const AddInputForm = ({
  icon,
  iconColor,
  iconClickable = true,
  hex,
  setHex,
  onIconClick,
  openIconDropdownCondition,
  inputLabel,
  setInputLabel,
  onInputEnter,
  handleCloseOptionClick,
  withCalendar = false,
  startDate,
  endDate,
  setIsCalendarOpen,
  isCalendarActiveCondition,
  setStartDate,
  setEndDate,
}) => {
  return (
    <>
      <ColorDropdown
        icon={icon}
        iconColor={iconColor}
        clickable={iconClickable}
        hex={hex}
        setHex={setHex}
        onClick={onIconClick}
        openDropdownCondition={openIconDropdownCondition}
      />
      <input
        type="text"
        value={inputLabel}
        onChange={(e) => setInputLabel(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onInputEnter();
          }
        }}
        className="p-2 border rounded w-48"
        placeholder="Add New Status..."
        autoFocus
      />

      {withCalendar && (
        <CalendarSection
          startDate={startDate ?? null}
          endDate={endDate ?? null}
          setIsCalendarOpen={setIsCalendarOpen}
          isCalendarActiveCondition={isCalendarActiveCondition}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
      )}

      <div
        className="p-2 hover:bg-button-hover  rounded-lg hover:cursor-pointer   "
        onClick={() => onInputEnter()}
      >
        <Check
          size={12}
          className="transition-all duration-200 ease-in-out   " // Change to gray on hover
        />
      </div>
      <div
        className="p-2 hover:bg-button-hover  rounded-lg hover:cursor-pointer   "
        onClick={() => handleCloseOptionClick()}
      >
        <CrossSmall
          size={14}
          className="transition-all duration-200 ease-in-out   " // Change to gray on hover
        />
      </div>
    </>
  );
};

export default AddInputForm;
