/* eslint-disable react/prop-types */
import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import IconText from "components/common/IconText";
import Importance from "components/common/Importance";
import StatusRowSection from "components/common/classicList/StatusRowSection";
import ProductRowSection from "components/common/classicList/ProductRowSection";
import TagsRowSection from "components/common/classicList/TagsRowSection";
import AccountsRowSection from "components/common/classicList/AccountsRowSection";
import AssigneeRowSection from "components/common/classicList/AssigneeRowSection";

import DropdownMenu from "components/common/detailView/dropdown/DropdownMenu";
import TagsSearchDropdown from "components/common/detailView/dropdown/TagsSearchDropdown";

import { toSnakeCase, fieldIconMapping } from "utils";
import { generateStatusArray } from "utils/status";

import {
  setActiveIdeaId,
  setIdeaShowDetailViewDrawer,
} from "reduxStore/slices/ideaSlice";

import {
  updateIdeaData,
  updateIdeaTags,
  updateIdeaAccounts,
} from "reduxStore/operations/ideasAPI";

import useRowOutsideClick from "hooks/useRowOutsideClick";
import AccountsSearchDropdown from "components/common/detailView/dropdown/AccountsSearchDropdown";
import ModuleRowSection from "../../../../common/classicList/ModuleRowSection";

// Main Component
const IdeasRowComponent = ({
  idea,
  onSelect,
  isSelected,
  selectedColumns,
  pl = "pl-0",
  py = "py-2",
  textSize = "text-sm",
  withCheckboxOption = true,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { products, tags, statuses } = useSelector((state) => state.company);
  const { accounts } = useSelector((state) => state.accounts);
  const { roadmaps } = useSelector((state) => state.roadmaps);
  const { features } = useSelector((state) => state.features);
  const { showDetailViewDrawer } = useSelector((state) => state.ideas);

  const ideaRoadmaps = useMemo(() => {
    return (roadmaps ?? []).filter((roadmap) =>
      (idea?.roadmaps ?? []).includes(roadmap._id)
    );
  }, [idea?.roadmaps, roadmaps]);

  const ideaFeatures = useMemo(() => {
    return (features ?? []).filter((feature) =>
      (idea?.features ?? []).includes(feature._id)
    );
  }, [idea?.features, features]);

  const [isHovered, setIsHovered] = useState(false);
  const [ideaStatuses, setIdeaStatuses] = useState([]);
  const [dropdownState, setDropdownState] = useState({
    isStatusDropdownOpen: false,
    isProductDropdownOpen: false,
    isTagDropdownOpen: false,
    isAccountDropdownOpen: false,
  });
  const dropdownRef = useRef(null);

  const [selectedTags, setSelectedTags] = useState(
    new Set(idea?.tags?.map((tag) => tag._id))
  );
  const [selectTags, setSelectTags] = useState(idea?.tags ?? []);
  const [initialSelectedTags, setInitialSelectedTags] = useState(
    new Set((idea?.tags ?? []).map((tag) => tag._id))
  );

  useEffect(() => {
    setSelectTags(idea?.tags ?? []);
    setSelectedTags(new Set(idea?.tags?.map((tag) => tag._id)));
    setInitialSelectedTags(new Set((idea?.tags ?? []).map((tag) => tag._id)));
  }, [idea]);

  const [selectedAccounts, setSelectedAccounts] = useState(
    new Set(idea?.accounts?.map((account) => account._id))
  );
  const [selectAccounts, setSelectAccounts] = useState(idea?.accounts ?? []);
  const [initialSelectedAccounts, setInitialSelectedAccounts] = useState(
    new Set((idea?.accounts ?? []).map((account) => account._id))
  );

  useEffect(() => {
    setSelectAccounts(idea?.accounts ?? []);
    setSelectedAccounts(new Set(idea?.accounts?.map((account) => account._id)));
    setInitialSelectedAccounts(
      new Set((idea?.accounts ?? []).map((account) => account._id))
    );
  }, [idea]);

  useEffect(() => {
    setIdeaStatuses(statuses.idea);
  }, [statuses]);

  const statusArray = useMemo(
    () => generateStatusArray(ideaStatuses),
    [ideaStatuses]
  );

  const closeAllDropdowns = () => {
    setDropdownState({
      isStatusDropdownOpen: false,
      isProductDropdownOpen: false,
      isTagDropdownOpen: false,
      isAccountDropdownOpen: false,
    });
  };

  const handleRowClick = () => {
    dispatch(setActiveIdeaId(idea._id));
    dispatch(setIdeaShowDetailViewDrawer(!showDetailViewDrawer));
  };

  const handleStatusOptionClick = (option) => {
    dispatch(updateIdeaData(idea._id, { status: option._id }));
    closeAllDropdowns();
  };

  const handleProductOptionClick = (option) => {
    dispatch(updateIdeaData(idea._id, { product: option._id }));
    closeAllDropdowns();
  };

  const handleTagDropdownOptionClick = (option) => {
    const tagId = option._id;
    const updatedTags = new Set(selectedTags);

    if (updatedTags.has(tagId)) {
      updatedTags.delete(tagId);
      setSelectTags((prevTags) => prevTags.filter((tag) => tag._id !== tagId));
    } else {
      updatedTags.add(tagId);
      setSelectTags((prevTags) => [...prevTags, option]);
    }

    setSelectedTags(updatedTags);
  };

  const handleAccountDropdownOptionClick = (option) => {
    const accountId = option._id;
    const updatedAccounts = new Set(selectedAccounts);

    if (updatedAccounts.has(accountId)) {
      updatedAccounts.delete(accountId);
      setSelectAccounts((prevAccounts) =>
        prevAccounts.filter((account) => account._id !== accountId)
      );
    } else {
      updatedAccounts.add(accountId);
      setSelectAccounts((prevAccounts) => [...prevAccounts, option]);
    }

    setSelectedAccounts(updatedAccounts);
  };

  const handleTagsOptionClick = (option) => {
    dispatch(updateIdeaTags(option));
    closeAllDropdowns();
  };

  const handleAccountsOptionClick = (option) => {
    dispatch(updateIdeaAccounts(option));
    closeAllDropdowns();
  };

  const handleImportanceOptionClick = (option) => {
    dispatch(updateIdeaData(idea._id, { importance: option }));
  };

  const toggleDropdown = useCallback((type) => {
    setDropdownState((prev) => {
      const newState = Object.keys(prev).reduce((acc, key) => {
        acc[key] = key === type ? !prev[key] : false;
        return acc;
      }, {});
      return newState;
    });
  }, []);

  useRowOutsideClick({
    item: idea,
    selectedTags,
    initialSelectedTags,
    tagDropdownState: dropdownState.isTagDropdownOpen,
    dropdownRef,
    selectedAccounts,
    accountsDropdownState: dropdownState.isAccountDropdownOpen,
    initialSelectedAccounts,
    handleAccountsOptionClick,
    handleTagsOptionClick,
    closeAllDropdowns,
  });

  return (
    <tr
      className={`group text-xs text-title_50 cursor-pointer relative ${
        isSelected ? "bg-button-active_10" : "hover:bg-row-background"
      }`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleRowClick}
      ref={dropdownRef}
    >
      <td
        className={`py-1 ${pl} relative sticky top-0 left-0 z-20   group-hover:rounded-l-lg ${
          isSelected
            ? "bg-button-active_10"
            : "bg-white group-hover:bg-row-background"
        }`}
      >
        <div className="flex">
          {withCheckboxOption && (
            <div
              className={`flex p-1 items-center ${
                !isSelected ? (isHovered ? "visible" : "invisible") : "visible"
              }`}
            >
              <input
                type="checkbox"
                checked={isSelected}
                onChange={() => {
                  onSelect(idea);
                }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className="cursor-pointer"
              />
            </div>
          )}
          <IconText
            icon={React.createElement(fieldIconMapping.idea)}
            iconSize={14}
            text={idea?.title ?? ""}
            truncateLength={pl !== "pl-0" ? 70 : 86}
            textColor="text-title"
            iconColor="text-ideas-icon"
            textSize={textSize}
            isASidebarComponent={false}
            hoverable={false}
            px=""
            py={py}
          />
        </div>
      </td>
      {(selectedColumns ?? []).map((key, index) => {
        const snakeCaseKey = toSnakeCase(key);

        return (
          <td
            key={index}
            className={`${
              key === "tags" ||
              key === "accounts" ||
              key === "roadmap" ||
              key === "feature"
                ? "min-w-44 max-w-66"
                : "max-w-44"
            } truncate pr-1 px-3`}
          >
            {(() => {
              switch (snakeCaseKey) {
                case "status":
                  return (
                    <StatusRowSection
                      status={idea?.[snakeCaseKey] ?? {}}
                      dropdownState={dropdownState}
                      toggleDropdown={() => {
                        toggleDropdown("isStatusDropdownOpen");
                      }}
                      statusArray={statusArray}
                    />
                  );
                case "score":
                  switch (idea?.score?.type) {
                    case "Value/Effort":
                      return (
                        idea?.score?.value_effort_score?.toFixed(1) ?? "0.0"
                      );
                    case "RICE":
                      return idea?.score?.rice_score?.toFixed(1) ?? "0.0";
                    case "Impact/Effort":
                      return (
                        idea?.score?.impact_effort_score?.toFixed(1) ?? "0.0"
                      );
                    default:
                      return "";
                  }
                case "product":
                  return (
                    <ProductRowSection
                      item={idea[snakeCaseKey]}
                      dropdownState={dropdownState}
                      toggleDropdown={() =>
                        toggleDropdown("isProductDropdownOpen")
                      }
                    />
                  );
                case "importance":
                  return (
                    <span className="p-1 rounded">
                      <Importance
                        value={idea?.[snakeCaseKey] ?? 0}
                        handleOptionClick={handleImportanceOptionClick}
                      />
                    </span>
                  );
                case "tags":
                  return (
                    <TagsRowSection
                      tags={selectTags ?? []}
                      dropdownState={dropdownState}
                      toggleDropdown={() => toggleDropdown("isTagDropdownOpen")}
                    />
                  );

                case "accounts":
                  return (
                    <AccountsRowSection
                      accounts={selectAccounts ?? []}
                      dropdownState={dropdownState}
                      toggleDropdown={() =>
                        toggleDropdown("isAccountDropdownOpen")
                      }
                    />
                  );
                case "roadmap":
                  return (
                    <ModuleRowSection
                      moduleItems={ideaRoadmaps ?? []}
                      icon={React.createElement(fieldIconMapping.roadmap)}
                      iconColor="text-roadmaps-icon"
                    />
                  );
                case "feature":
                  return (
                    <ModuleRowSection
                      moduleItems={ideaFeatures ?? []}
                      icon={React.createElement(fieldIconMapping.feature)}
                      iconColor="text-features-icon"
                    />
                  );
                case "assignee":
                  return (
                    <AssigneeRowSection
                      name={idea?.[snakeCaseKey]?.name ?? ""}
                    />
                  );
                default:
                  return idea?.[snakeCaseKey] ?? "";
              }
            })()}
            {snakeCaseKey === "status" && (
              <DropdownMenu
                options={statusArray ? statusArray : []}
                isOpen={dropdownState.isStatusDropdownOpen}
                position="top-full -mt-3 w-32"
                handleOptionClick={handleStatusOptionClick}
              />
            )}
            {snakeCaseKey === "product" && (
              <DropdownMenu
                options={products ?? []}
                isOpen={dropdownState.isProductDropdownOpen}
                position="top-full -mt-3 w-32"
                handleOptionClick={handleProductOptionClick}
                withAddOption={true}
                handleAddOptionClick={() =>
                  navigate("/settings/customizations/product")
                }
              />
            )}
            {snakeCaseKey === "tags" && (
              <div className="absolute w-48 z-20 bg-white -ml-8">
                <TagsSearchDropdown
                  searchPlaceholder={"Search Tags..."}
                  items={tags ?? []}
                  selectedItems={selectedTags}
                  isDropdownOpen={dropdownState.isTagDropdownOpen}
                  onItemSelect={handleTagDropdownOptionClick}
                />
              </div>
            )}
            {snakeCaseKey === "accounts" && (
              <div className="absolute w-48 z-20 bg-white -ml-8">
                <AccountsSearchDropdown
                  searchPlaceholder={"Search Accounts..."}
                  items={accounts ?? []}
                  selectedItems={selectedAccounts}
                  isDropdownOpen={dropdownState.isAccountDropdownOpen}
                  onItemSelect={handleAccountDropdownOptionClick}
                />
              </div>
            )}
          </td>
        );
      })}
    </tr>
  );
};

export default IdeasRowComponent;
