/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Loader from "components/common/Loader";
import { generateStatusArray } from "utils/status";
import { capitalizeFirstLetter, colors, toSnakeCase } from "utils";
import IconText from "components/common/IconText";
import {
  RiProgress5Line,
  RiProgress8Fill,
  RiProgress8Line,
} from "react-icons/ri";
import { getStatusIcon } from "utils/status";
import ColorDropdown from "../ColorDropdown";
import AddInputForm from "../AddInputForm";
import EditInputForm from "../EditInputForm";
import { TbCircleDashed } from "react-icons/tb";
import CustomizationsLabel from "../../../common/CustomizationsLabel";
import StatusLabel from "../../../common/StatusLabel";

const ActiveModuleStatusField = ({
  module,
  handleAddStatus,
  handleEditStatus,
  handleDeleteStatus,
}) => {
  const { statuses } = useSelector((state) => state.company);
  //const company = useSelector((state) => state.company);
  //const [statuses, useSelector((state) => state.company);] = useState(null);
  //const statusArray = useMemo(() => generateStatusArray(statuses), [statuses]);
  const moduleIcons = {
    Parked: <TbCircleDashed className="text-primary" />,
    "To do": <RiProgress8Fill className="text-primary" />,
    "In progress": <RiProgress5Line className="text-primary" />,
    Done: <RiProgress8Line className="text-primary" />,
  };
  const editRefs = useRef([[]]);
  const addRefs = useRef([]);

  const [hex, setHex] = useState(colors[0]);
  const [isColorDropdownOpen, setIsColorDropdownOpen] = useState(false);
  const [activeDomain, setActiveDomain] = useState("");
  const [isAddInputOpen, setIsAddInputOpen] = useState(false);
  const [activeEditId, setActiveEditId] = useState(null);
  const [editLabel, setEditLabel] = useState("");
  const [orignalEditLabel, setOrignalEditLabel] = useState("");
  const [orignalEditStatusColor, setOrignalEditStatusColor] = useState("");
  const [addNewStatusLabel, setAddNewStatusLabel] = useState("");
  const [replaceId, setReplaceId] = useState({ id: "", module: "" });

  const resetEditOptions = () => {
    setIsColorDropdownOpen(false);
    setActiveDomain("");
    setActiveEditId(null);
    setEditLabel("");
    setOrignalEditLabel("");
    setOrignalEditStatusColor("");
  };
  const resetAddOptions = () => {
    setActiveDomain("");
    setAddNewStatusLabel("");
    setIsAddInputOpen(false);
  };

  const addButtonClick = (statusDomain) => {
    setActiveDomain(statusDomain);
    setAddNewStatusLabel("");
    setIsAddInputOpen(true);
    setHex(colors[0]);
  };

  const editButtonClick = (
    id,
    statusValue,
    statusColor,
    shouldOpenColorDropdown,
    statusDomain
  ) => {
    if (activeEditId === null) {
      setHex(statusColor);
    } else {
      setHex(hex);
    }
    setIsColorDropdownOpen(shouldOpenColorDropdown);
    setActiveDomain(statusDomain);
    setActiveEditId(id);
    if (editLabel !== "") {
      setEditLabel(editLabel);
    } else {
      setEditLabel(statusValue);
    }
    setOrignalEditLabel(statusValue);
    setOrignalEditStatusColor(statusColor);
  };

  const addNewStatusEnter = () => {
    if (addNewStatusLabel.trim() !== "") {
      handleAddStatus({
        label: addNewStatusLabel.trim(),
        color: hex,
        module: module.replace(" ", "_").toLowerCase(),
        type: activeDomain,
      });
    }
    resetAddOptions();
  };

  const editStatusEnter = () => {
    if (editLabel.trim() === "") {
      return;
    } else if (
      orignalEditLabel === editLabel.trim() &&
      hex === orignalEditStatusColor
    ) {
      resetEditOptions();
    } else {
      handleEditStatus(activeEditId, {
        label: editLabel.trim(),
        color: hex,
      });
      resetEditOptions();
    }
  };

  // const removeStatusClick = () => {
  //   handleDeleteStatus(activeEditId, replaceId);
  //   resetEditOptions();
  // };

  const removeStatusClick = (statusDomain, id) => {
    console.log(statusDomain);
    // Filter statuses based on the activeDomain
    const filteredStatuses = (statuses[toSnakeCase(module)] ?? []).filter(
      (item) => item.type === activeDomain || statusDomain
    );

    // Get the first status (index 0) from the filtered list
    const firstStatus =
      filteredStatuses.length > 0 ? filteredStatuses[0] : null;

    if (firstStatus) {
      // Set the replaceId to the first status before calling the delete function
      const replaceId = {
        id: firstStatus._id,
        module: firstStatus.module,
      };
      console.log(activeEditId, id, replaceId);
      // Call handleDeleteStatus with activeEditId and replaceId
      handleDeleteStatus(activeEditId || id, replaceId);
    }

    // Reset the edit options after handling the delete
    resetEditOptions();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isAddInputOpen &&
        activeDomain !== "" &&
        addRefs.current[activeDomain] &&
        !addRefs.current[activeDomain].contains(event.target)
      ) {
        addNewStatusEnter();
        resetEditOptions();
      } else if (
        activeEditId !== null &&
        activeDomain !== "" &&
        editRefs.current[activeDomain][activeEditId] &&
        !editRefs.current[activeDomain][activeEditId].contains(event.target)
      ) {
        editStatusEnter();
        resetAddOptions();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isAddInputOpen, activeDomain, activeEditId, editLabel, hex]);

  const statusModules = ["parked", "to_do", "in_progress", "done"];
  return (
    <div className="text-sm text-title_10">
      {!statuses ? (
        <div className="flex items-center justify-center h-[500px]">
          <Loader />
        </div>
      ) : (
        <div>
          {statusModules.map((statusDomain, statusIndex) => (
            <div className="mb-6" key={statusIndex}>
              <span className="bg-default rounded">
                <IconText
                  icon={moduleIcons[capitalizeFirstLetter(statusDomain)]}
                  text={capitalizeFirstLetter(statusDomain)}
                  hoverable={false}
                  px=""
                  textColor="text-title_10"
                />
                <hr className="w-1/6 mb-2" />
              </span>
              <div className="flex flex-col gap-y-2">
                {(statuses[toSnakeCase(module)] ?? [])
                  .filter((item) => item.type === statusDomain)
                  .map((status, index) => {
                    // if (
                    //   statusDomain === "parked" &&
                    //   replaceId.module !== toSnakeCase(module)
                    // ) {
                    //   setReplaceId({
                    //     id: status._id,
                    //     module: toSnakeCase(module),
                    //   });
                    // }
                    return (
                      <div
                        key={index}
                        className={`flex items-center text-primary text-sm gap-x-3 w-fit`}
                        ref={(el) => {
                          if (!editRefs.current[statusDomain]) {
                            editRefs.current[statusDomain] = [];
                          }
                          editRefs.current[statusDomain][status._id] = el;
                        }}
                      >
                        <ColorDropdown
                          icon={
                            moduleIcons[capitalizeFirstLetter(statusDomain)]
                          }
                          iconColor={
                            activeEditId === status._id ? hex : status.color
                          }
                          clickable={statusDomain !== "parked"}
                          hex={hex}
                          setHex={setHex}
                          onClick={() => {
                            statusDomain !== "parked" && !isColorDropdownOpen
                              ? editButtonClick(
                                  status._id,
                                  status.label,
                                  status.color,
                                  true,
                                  statusDomain
                                )
                              : setIsColorDropdownOpen(false);
                          }}
                          openDropdownCondition={
                            isColorDropdownOpen && activeEditId === status._id
                          }
                        />
                        {activeEditId === status._id &&
                        activeDomain === statusDomain ? (
                          <>
                            <EditInputForm
                              inputLabel={editLabel}
                              setInputLabel={(value) => setEditLabel(value)}
                              onEditInputEnter={editStatusEnter}
                              onInputButtonClick={setIsColorDropdownOpen}
                              inputFocusCondition={!isColorDropdownOpen}
                              handleRemoveOptionClick={() => {
                                removeStatusClick();
                              }}
                              showRemovable={index > 0}
                            />
                          </>
                        ) : (
                          // <div
                          //   className={`p-2 border rounded w-48 ${
                          //     statusDomain === "parked"
                          //       ? "bg-row-background cursor-not-allowed"
                          //       : "cursor-text"
                          //   }`}
                          //   onClick={() => {
                          //     if (statusDomain !== "parked") {
                          //       editButtonClick(
                          //         statusDomain,
                          //         status._id,
                          //         status.label,
                          //         status.color,
                          //         false
                          //       );
                          //     }
                          //   }}
                          // >
                          //   {status.label}
                          // </div>
                          <StatusLabel
                            label={status.label}
                            onClick={editButtonClick}
                            statusDomain={statusDomain}
                            handleDeleteItem={removeStatusClick}
                            id={status._id}
                            color={status.color}
                            editBoolean={false}
                            showDelete={index > 0}
                          />
                        )}
                      </div>
                    );
                  })}

                {statusDomain !== "parked" && (
                  <>
                    {activeDomain === statusDomain && isAddInputOpen ? (
                      <div
                        className="flex items-center gap-x-3 w-fit"
                        ref={(el) => (addRefs.current[statusDomain] = el)}
                      >
                        <AddInputForm
                          icon={
                            moduleIcons[capitalizeFirstLetter(statusDomain)] ||
                            null
                          }
                          iconColor={hex}
                          hex={hex}
                          setHex={setHex}
                          onIconClick={() =>
                            setIsColorDropdownOpen(!isColorDropdownOpen)
                          }
                          openIconDropdownCondition={isColorDropdownOpen}
                          inputLabel={addNewStatusLabel}
                          setInputLabel={(value) => setAddNewStatusLabel(value)}
                          onInputEnter={addNewStatusEnter}
                          handleCloseOptionClick={resetAddOptions}
                        />
                      </div>
                    ) : (
                      <div
                        className="text-primary cursor-pointer hover:bg-button-hover p-1 w-fit rounded"
                        onClick={() => {
                          addButtonClick(statusDomain);
                        }}
                      >
                        + Add
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ActiveModuleStatusField;
