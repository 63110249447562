/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from "react";
import DemoProfileImage from "assets/demo_profile_image.webp";
import { useDispatch, useSelector } from "react-redux";
// import { getKeyResultStatuses } from "reduxStore/operations/statusAPI";
import { generateStatusArray } from "utils/status";
import StatusSection from "../detailView/detailViewRightSidebarSections/StatusSection";

const AddKeyResultPopupOverlay = ({ option, name }) => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState(null);
  const { statuses } = useSelector((state) => state.company);
  const { companyId } = useSelector((state) => state.auth);

  const handleStatusOptionClick = (option) => {
    setStatus(option);
  };

  //   useEffect(() => {
  //     dispatch(getKeyResultStatuses(companyId));
  //   }, [dispatch, companyId]);

  const [keyResultStatuses, setKeyResultStatuses] = useState([]);
  useEffect(() => {
    setKeyResultStatuses(statuses.key_result);
  }, [statuses]);

  const statusArray = useMemo(
    () => generateStatusArray(keyResultStatuses),
    [keyResultStatuses]
  );

  useEffect(() => {
    setStatus(statusArray[0]);
  }, [statusArray]);

  return (
    <div className="flex flex-col gap-y-2 -mx-1">
      {/* <div className="flex items-center gap-x-2">
        {option.addOptions.includes("status") && (
          <StatusSection
            item={status ?? {}}
            statusArray={statusArray}
            handleStatusOptionClick={(option) =>
              handleStatusOptionClick(option)
            }
            position=""
            with_title={false}
          />
        )}
        {option.addOptions.includes("assignee") && (
          <div className="mr-3 p-1 flex items-center">
            <img
              src={DemoProfileImage}
              alt="Profile"
              className="w-6 h-6 rounded-full"
            />
          </div>
        )}
      </div> */}
      <hr />
      <button
        className="p-2 bg-add-popup-button rounded text-white w-fit text-sm ml-auto"
        onClick={() =>
          option.onAddClick({
            title: name,
            status: status?._id ?? null,
          })
        }
      >
        Add {option.label}
      </button>
    </div>
  );
};

export default AddKeyResultPopupOverlay;
