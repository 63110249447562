import { setScoreScoreType } from "../slices/companySlice";
import { apiConnector } from "api/apiconnector"; // Ensure you have an apiConnector function to handle API requests
import { companyEndPoints } from "api/api"; // Import your API endpoints
import { getCompanyTags } from "./tagsAPI";
import { getCompanyReleases } from "./releaseAPI";
import { getCompanyProducts } from "./productAPI";
import { getCompanyTimeFrames } from "./timeFramesAPI";
import { getStatuses } from "./statusAPI";
import { getAccounts } from "./accountAPI";

const { GET_COMPANY_DETAILS } = companyEndPoints;

export const fetchCompanyData = (companyId) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector(
        "GET",
        GET_COMPANY_DETAILS(companyId)
      );
      if (response.status === 200) {
        dispatch(setScoreScoreType(response.data.company.scoreType));
        dispatch(getCompanyReleases());
        dispatch(getCompanyProducts());
        dispatch(getCompanyTags());
        dispatch(getCompanyTimeFrames());
        dispatch(getStatuses());
        dispatch(getAccounts());
      } else {
        console.error("Failed to fetch company data:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching company data:", error);
    }
  };
};
