/* eslint-disable react/prop-types */
import React from "react";
import HTMLReactParser from "html-react-parser";
import { TbEdit } from "react-icons/tb";
import { isEmptyDescription } from "utils/descriptionUtils";

const DescriptionDetailViewTab = ({ item, handleEditorOpen }) => {
  const descriptionContent =
    item?.description && !isEmptyDescription(item.description)
      ? item.description
      : "No Description";

  // Modify the descriptionContent to add text-lg to h2 elements
  const modifiedContent = descriptionContent.replace(/<h2>/g, '<h2 class="text-xl font-bold ">');

  return (
    <div className="relative flex min-h-[260px] justify-between mb-5">
      <div className="break-words w-full">
        <div
          className="rounded p-1 hover:bg-row-background cursor-text"
          onClick={handleEditorOpen}
        >
          {HTMLReactParser(modifiedContent)}
        </div>
      </div>
      {/* <div
        className="rounded h-fit hover:bg-button-hover p-1 text-primary right-0 top-0 cursor-pointer"
        onClick={handleEditorOpen}
      >
        <TbEdit size={20} />
      </div> */}
    </div>
  );
};

export default DescriptionDetailViewTab;
