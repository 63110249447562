import { resetActiveIds } from "./resetActiveIds";

export const handleResetActiveIds = (dispatch, pathname) => {
  const resetFlags = {
    resetFeature: true,
    resetIdea: true,
    resetKeyResult: true,
    resetObjective: true,
    resetRoadmap: true,
  };

  switch (pathname) {
    case "/features":
      resetFlags.resetFeature = false;
      break;
    case "/ideas":
      resetFlags.resetIdea = false;
      resetFlags.resetFeature = false;
      break;
    case "/roadmaps":
      resetFlags.resetRoadmap = false;
      break;
    case "/objectives":
      resetFlags.resetObjective = false;
      resetFlags.resetKeyResult = false;
      resetFlags.resetFeature = false;
      break;
    default:
      // Handle other paths or do nothing if no reset is required
      return;
  }

  resetActiveIds({ dispatch, ...resetFlags });
};
