/* eslint-disable react/prop-types */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { CrossSmall } from "react-flaticons";
import DropdownMenu from "components/common/detailView/dropdown/DropdownMenu";
import { useDispatch, useSelector } from "react-redux";
import { generateStatusArray } from "utils/status";
import { fieldIconMapping } from "utils";
import {
  updateObjectivesData,
  deleteObjectives,
} from "reduxStore/operations/objectivesAPI";
import StatusButton from "./buttons/StatusButton";
import HealthButton from "./buttons/HealthButton";
import DeleteButton from "./buttons/DeleteButton";
import Loader from "../Loader";

const MasterObjectiveSelectionPopup = ({
  selectedObjectives,
  resetSelectedObjectives,
}) => {
  const dispatch = useDispatch();
  const { statuses, timeFrames } = useSelector((state) => state.company);
  const dropdownRef = useRef(null);

  const [objectiveStatuses, setObjectiveStatuses] = useState([]);
  const [updatedStatus, setUpdatedStatus] = useState(null);
  const [updatedTimeFrame, setUpdatedTimeFrame] = useState(null);
  const [updatedHealth, setUpdatedHealth] = useState(null);
  const [dropdownState, setDropdownState] = useState({
    isStatusDropdownOpen: false,
    isHealthDropdownOpen: false,
    isTimeFrameDropdownOpen: false,
  });
  const [loading, setLoading] = useState(false);
  const [objectivesToDelete, setObjectivesToDelete] = useState(false);

  useEffect(() => {
    setObjectiveStatuses(statuses.objective);
  }, [statuses]);

  const statusArray = useMemo(
    () => generateStatusArray(objectiveStatuses),
    [objectiveStatuses]
  );

  const closeAllDropdowns = () => {
    setDropdownState({
      isStatusDropdownOpen: false,
      isHealthDropdownOpen: false,
      isTimeFrameDropdownOpen: false,
    });
  };

  useEffect(() => {
    if (selectedObjectives.size === 0) {
      closeAllDropdowns();
    }
  }, [selectedObjectives.size]);

  const toggleDropdown = useCallback((type) => {
    setDropdownState((prev) => {
      const newState = Object.keys(prev).reduce((acc, key) => {
        acc[key] = key === type ? !prev[key] : false;
        return acc;
      }, {});
      return newState;
    });
  }, []);

  const handleOptionClick = (type, option) => {
    switch (type) {
      case "status":
        setUpdatedStatus(option);
        break;
      case "timeFrame":
        setUpdatedTimeFrame(option);
        break;
      case "health":
        setUpdatedHealth(option);
        break;
      default:
        break;
    }
    closeAllDropdowns();
  };

  const removeSelection = (type) => {
    switch (type) {
      case "status":
        setUpdatedStatus(null);
        break;
      case "timeFrame":
        setUpdatedTimeFrame(null);
        break;
      case "health":
        setUpdatedHealth(null);
        break;
      default:
        break;
    }
  };

  const handleDelete = () => {
    setObjectivesToDelete(!objectivesToDelete);
  };

  useEffect(() => {
    // Handler for clicks outside of the dropdown
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeAllDropdowns();
      }
    };

    // Add event listener for clicks outside
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const applyChanges = async () => {
    setLoading(true);
    try {
      if (objectivesToDelete) {
        await dispatch(deleteObjectives(Array.from(selectedObjectives)));
        resetSelectedObjectives();
        setObjectivesToDelete(false);
      } else {
        const updateData = {
          ...(updatedStatus && { status: updatedStatus._id }),
          ...(updatedHealth && { health: updatedHealth._id }),
          ...(updatedTimeFrame && { timeFrame: updatedTimeFrame._id }),
        };
        await dispatch(
          updateObjectivesData(
            Array.from(selectedObjectives),
            updateData,
            Object.keys(updateData)
          )
        );
      }
      resetSelectedObjectives();
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="absolute bottom-5 left-1/2 transform -translate-x-1/2 bg-white z-50 p-5 rounded-lg shadow-lg border w-[45%]">
      <div className="flex flex-col gap-y-4 text-xs">
        <span>
          {objectivesToDelete
            ? `Clicking "Apply" will delete the selected ${
                selectedObjectives.size > 1 ? selectedObjectives.size : ""
              } objective${
                selectedObjectives.size > 1 ? "s" : ""
              }. Are you sure?`
            : `${selectedObjectives.size} selected objectives`}
        </span>

        <div className="flex items-center justify-between" ref={dropdownRef}>
          <DeleteButton
            handleDelete={handleDelete}
            itemsToDelete={objectivesToDelete}
          />

          <div className="flex items-center gap-x-2.5">
            {/* Status */}
            <StatusButton
              statusArray={statusArray}
              updatedStatus={updatedStatus}
              dropdownState={dropdownState}
              removeSelection={removeSelection}
              toggleDropdown={toggleDropdown}
              handleOptionClick={handleOptionClick}
            />

            {/* Time Frame */}
            <button
              className={`relative flex items-center gap-x-1 border border-gray-300 ${
                dropdownState.isTimeFrameDropdownOpen
                  ? "bg-button-active_10"
                  : "hover:bg-row-background"
              }  p-1.5 rounded-md`}
              onClick={() => toggleDropdown("isTimeFrameDropdownOpen")}
            >
              {updatedTimeFrame?.label || "Change Time-frame"}
              {updatedTimeFrame && (
                <div
                  className="cursor-pointer hover:bg-button-hover p-0.5 rounded-full"
                  onClick={(e) => {
                    e.stopPropagation();
                    removeSelection("timeFrame");
                  }}
                >
                  <CrossSmall size={12} />
                </div>
              )}
              <DropdownMenu
                options={(timeFrames ?? []).map((timeFrame) => ({
                  ...timeFrame,
                  icon: fieldIconMapping.timeFrames,
                }))}
                isOpen={dropdownState.isTimeFrameDropdownOpen}
                position="bottom-full w-32 right-0"
                iconSize={12}
                handleOptionClick={(option) =>
                  handleOptionClick("timeFrame", option)
                }
              />
            </button>

            {/* Health */}
            <HealthButton
              updatedHealth={updatedHealth}
              handleOptionClick={handleOptionClick}
              dropdownState={dropdownState}
              toggleDropdown={toggleDropdown}
              removeSelection={removeSelection}
            />
          </div>
        </div>

        <div className="ml-auto flex gap-x-4 items-center">
          <button
            className="border-dashed border-2 border-gray-300 hover:bg-row-background p-1.5 rounded-md"
            onClick={resetSelectedObjectives}
          >
            Deselect all
          </button>

          {loading ? (
            <div className="w-fit ml-auto py-1.5 px-4 bg-button-active rounded text-white cursor-not-allowed flex items-center gap-x-2">
              <Loader size="14" color="white" />
              <span>Applying...</span>
            </div>
          ) : (
            <button
              className="w-fit ml-auto p-1.5 bg-add-popup-button hover:bg-button-active rounded text-white"
              onClick={applyChanges}
            >
              Apply
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default MasterObjectiveSelectionPopup;
