/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from "react";
import IconText from "components/common/IconText";
import { fieldIconMapping, rgbColor } from "utils";
import SimpleCheckboxItem from "components/common/checkboxItem/SimpleCheckboxItem";

const RoadmapProductsSection = ({
  item,
  products,
  handleProductOptionClick,
  with_title = true,
  position = "gap-x-6",
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef();
  const [selectedProducts, setSelectedProducts] = useState(
    new Set(item?.products.map((item) => item._id))
  );
  const initalProducts = new Set(item?.products.map((item) => item._id));

  const onProductClick = (option) => {
    setSelectedProducts((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(option)) {
        newSelected.delete(option);
      } else {
        newSelected.add(option);
      }
      return newSelected;
    });
  };

  const toggleDropdown = (e) => {
    e.stopPropagation();
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
        if (selectedProducts.size === 0) {

        }
        if (
          !areSetsEqual(initalProducts, selectedProducts) &&
          selectedProducts.size !== 0
        ) {
          handleProductOptionClick({
            products: Array.from(selectedProducts),
            itemId: item?._id,
          });
        } else {
          setSelectedProducts(initalProducts);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectedProducts, handleProductOptionClick]);

  const areSetsEqual = (setA, setB) => {
    if (setA.size !== setB.size) return false;
    for (let item of setA) if (!setB.has(item)) return false;
    return true;
  };

  return (
    <div className={`flex ${position} items-start text-title_50`}>
      {with_title && (
        <IconText
          icon={React.createElement(fieldIconMapping.product)}
          text="Products"
          iconColor="#696969"
          iconSize={16}
          hoverable={false}
          gap_x="gap-x-2"
        />
      )}
      {products && (
        <div className="relative cursor-pointer" ref={dropdownRef}>
          <div
            className={`relative flex flex-wrap  rounded cursor-pointer text-xs ${
              isDropdownOpen ? "bg-button-active_10" : "hover:bg-button-hover"
            }`}
            onClick={toggleDropdown}
          >
            {item?.products?.map((product) => (
              <div key={product._id} className="bg-white rounded m-1">
                <div
                  className="rounded p-1 bg-topbar-button-hover text-title_50"
                  style={{ backgroundColor: rgbColor(product?.color, 0.2) }}
                >
                  {product?.label}
                </div>
              </div>
            ))}
          </div>
          {isDropdownOpen && (
            <div className="absolute top-full left-0 bg-white rounded z-10 shadow border mt-0.5 p-2">
              {products
                .filter((product) => selectedProducts.has(product._id))
                .map((product) => (
                  <div key={product._id}>
                    <SimpleCheckboxItem
                      option={{ ...product, iconColor: product.color }}
                      handleOptionClick={() => onProductClick(product._id)}
                      iconSize="12"
                      selected={selectedProducts.has(product._id)}
                    />
                  </div>
                ))}
              {products
                .filter((product) => !selectedProducts.has(product._id))
                .map((product) => (
                  <div key={product._id}>
                    <SimpleCheckboxItem
                      option={{ ...product, iconColor: product.color }}
                      handleOptionClick={() => onProductClick(product._id)}
                      iconSize="12"
                      selected={selectedProducts.has(product._id)}
                    />
                  </div>
                ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default RoadmapProductsSection;
