/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { AngleSmallDown, AngleSmallUp, Minus } from "react-flaticons";
import { getStatusIcon } from "utils/status";
import { rgbColor, fieldIconMapping } from "utils";
import IconText from "components/common/IconText";
import Importance from "components/common/Importance";

const ListViewGroupHeader = ({
  group,
  groupByOption,
  items,
  selectedItems,
  toggleGroupCollapse,
  handleGroupSelect,
  collapsedGroups,
  statusArray,
}) => {
  const [isHovered, setIsHovered] = useState(null);
  if (group.label === undefined) return;
  return (
    <div
      className="relative sticky group    flex justify-center items-center gap-2  left-0 cursor-pointer pt-2.5  min-w-16 w-full   pr-3"
      // className="relative sticky left-0 cursor-pointer pt-2.5 w-full pr-3"
      onClick={() => toggleGroupCollapse(group._id)}
      onMouseEnter={() => items.length > 0 && setIsHovered(group._id)}
      onMouseLeave={() => items.length > 0 && setIsHovered(null)}
    >
      <input
        type="checkbox"
        onClick={(e) => e.stopPropagation()}
        onChange={() => handleGroupSelect(group._id)}
        checked={
          items.length > 0
            ? items.every((item) => selectedItems.has(item._id))
            : false
        }
        className="cursor-pointer z-50  opacity-0 group-hover:opacity-100 transition-all duration-150   "
      />
      <div
        className="flex items-center justify-between bg-[#ECECEC] rounded-lg pr-[9px] py-1 mr-auto    text-sm"
        style={{
          backgroundColor:
            groupByOption !== "health" && rgbColor(group.color, 0.2),
        }}
      >
        <div className="flex   p-1 items-center gap-x-1">
          <div
            className={`flex items-center ${
              items.length > 0 &&
              items.every((item) => selectedItems.has(item._id))
                ? ""
                : isHovered === group._id
                ? "visible"
                : "invisible"
            }`}
          >
            {/* <input
              type="checkbox"
              onClick={(e) => e.stopPropagation()}
              onChange={() => handleGroupSelect(group._id)}
              checked={
                items.length > 0
                  ? items.every((item) => selectedItems.has(item._id))
                  : false
              }
              className="cursor-pointer z-50"
            /> */}
          </div>
          {groupByOption !== "importance" ? (
            <IconText
              icon={
                groupByOption === "status" || groupByOption === "default" ? (
                  getStatusIcon(group.label, statusArray)
                ) : groupByOption === "release" ? (
                  React.createElement(fieldIconMapping.release)
                ) : groupByOption === "time_frame" ||
                  groupByOption === "health" ? (
                  React.createElement(fieldIconMapping.timeFrames)
                ) : groupByOption === "objective" ? (
                  React.createElement(fieldIconMapping.objective)
                ) : groupByOption === "for" ? (
                  React.createElement(fieldIconMapping.for)
                ) : (
                  <Minus color="transparent" className="-ml-4" />
                )
              }
              text={group.label}
              truncateLength={80}
              iconColor={
                group.color
                  ? rgbColor(group.color)
                  : groupByOption === "objective"
                  ? "text-objectives-icon"
                  : "text-title_50"
              }
              textColor="text-title_50"
              hoverable={false}
              gap_x="gap-x-1.5"
              px=""
              py=""
            />
          ) : (
            <div>
              <Importance value={group.label ?? 0} readOnly={true} />
            </div>
          )}
        </div>
        {collapsedGroups[group._id] ? (
          <AngleSmallDown size={12} />
        ) : (
          <AngleSmallUp size={12} />
        )}
      </div>
    </div>
  );
};

export default ListViewGroupHeader;
