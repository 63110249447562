import { toSnakeCase } from ".";

export const sortGroupedData = (
  groupedData = {},
  sortOption = {},
  groupBy,
  scoreType = "value_effort_score",
  statuses = {}
) => {
  const sortedGroupedData =
    groupedData &&
    Object.keys(groupedData).reduce((acc, groupKey) => {
      acc[groupKey] = applySorting(
        groupedData[groupKey],
        sortOption,
        statuses,
        scoreType
      );
      return acc;
    }, {});

  const predefinedOrder =
    statuses &&
    statuses
      .slice()
      .sort((a, b) => {
        const typeOrder = ["parked", "to_do", "in_progress", "done"];

        const typeComparison =
          typeOrder.indexOf(a.type) - typeOrder.indexOf(b.type);

        if (typeComparison === 0) {
          return new Date(a.createdAt) - new Date(b.createdAt);
        }

        return typeComparison;
      })
      .map((status) => status?.label);

  if (toSnakeCase(sortOption.field) === toSnakeCase(groupBy)) {
    const sortByOrder = (a, b) => {
      const indexA = predefinedOrder.indexOf(a);
      const indexB = predefinedOrder.indexOf(b);

      if (indexA === -1 && indexB === -1) {
        return sortOption.order === "asc"
          ? a.localeCompare(b)
          : b.localeCompare(a);
      }
      if (indexA === -1) return sortOption.order === "asc" ? 1 : -1;
      if (indexB === -1) return sortOption.order === "asc" ? -1 : 1;
      return sortOption.order === "asc" ? indexA - indexB : indexB - indexA;
    };

    const sortedGroupKeys = Object.keys(sortedGroupedData).sort(sortByOrder);

    return sortedGroupKeys.reduce((acc, key) => {
      acc[key] = sortedGroupedData[key];
      return acc;
    }, {});
  }

  return sortedGroupedData;
};

const applySorting = (data = [], sortOption = {}, statuses = {}, scoreType) => {
  const realData = data.items;
  let sortedData = [];

  if (sortOption.field === "status") {
    const sortedStatuses = statuses
      .slice()
      .sort((a, b) => {
        const typeOrder = ["parked", "to_do", "in_progress", "done"];

        const typeComparison =
          typeOrder.indexOf(a.type) - typeOrder.indexOf(b.type);

        if (typeComparison === 0) {
          return new Date(a.createdAt) - new Date(b.createdAt);
        }

        return typeComparison;
      })
      .map((status) => status?.label);

    sortedData = sortedStatuses.flatMap((status) =>
      realData.filter(
        (item) => toSnakeCase(item?.status?.label) === toSnakeCase(status)
      )
    );

    sortedData = sortOption.order === "asc" ? sortedData : sortedData.reverse();
  } else if (sortOption.field === "score") {
    sortedData = [...realData].sort((a, b) => {
      const { field, order } = sortOption;
      const aScore = a[field][scoreType];
      const bScore = b[field][scoreType];
      return (aScore > bScore ? 1 : -1) * (order === "asc" ? 1 : -1);
    });
  } else if (sortOption.field === "release" || sortOption.field === "product") {
    sortedData = [...realData].sort((a, b) => {
      const { field, order } = sortOption;

      if (a[field] === null && b[field] !== null) return 1;
      if (a[field] !== null && b[field] === null) return -1;
      if (a[field] === null && b[field] === null) return 0;

      const extractParts = (str) => {
        const match = str.match(/^([^\d]*)(\d*)$/);
        const textPart = match ? match[1].trim() : str;
        const numberPart = match && match[2] ? parseInt(match[2], 10) : null;
        return { textPart, numberPart };
      };


      const aParts = extractParts(a[field]?.label ?? "");
      const bParts = extractParts(b[field]?.label ?? "");

      if (aParts.textPart !== bParts.textPart) {
        return (
          (aParts.textPart > bParts.textPart ? 1 : -1) *
          (order === "asc" ? 1 : -1)
        );
      }

      if (aParts.numberPart !== null && bParts.numberPart !== null) {
        return (
          (aParts.numberPart > bParts.numberPart ? 1 : -1) *
          (order === "asc" ? 1 : -1)
        );
      }

      if (aParts.numberPart !== null && bParts.numberPart === null) return -1;
      if (aParts.numberPart === null && bParts.numberPart !== null) return 1;

      return 0;
    });
  } else if (sortOption.field === "time_frame") {
    sortedData = [...realData].sort((a, b) => {
      const fieldA = a.timeFrame?.startDate
        ? new Date(a.timeFrame.startDate)
        : a.timeFrame?.label;
      const fieldB = b.timeFrame?.startDate
        ? new Date(b.timeFrame.startDate)
        : b.timeFrame?.label;

      if (fieldA > fieldB) return sortOption.order === "asc" ? 1 : -1;
      if (fieldA < fieldB) return sortOption.order === "asc" ? -1 : 1;
      return 0;
    });
  } else if (
    sortOption.field === "importance" ||
    sortOption.field === "health" ||
    sortOption.field === "progress"
  ) {
    sortedData = [...realData].sort((a, b) => {
      const { field, order } = sortOption;

      if (a[field] === null && b[field] !== null) return 1;
      if (a[field] !== null && b[field] === null) return -1;
      if (a[field] === null && b[field] === null) return 0;

      return (a[field] > b[field] ? 1 : -1) * (order === "asc" ? 1 : -1);
    });
  } else if (sortOption.field !== "default") {
    sortedData = [...realData].sort((a, b) => {
      const { field, order } = sortOption;

      if (a[field] === null && b[field] !== null) return 1;
      if (a[field] !== null && b[field] === null) return -1;
      if (a[field] === null && b[field] === null) return 0;

      return (a[field] > b[field] ? 1 : -1) * (order === "asc" ? 1 : -1);
    });
  } else {
    sortedData = realData;
    // console.log(sortedData);
  }

  return {
    ...data,
    items: sortedData,
  };
};
