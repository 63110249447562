/* eslint-disable react/prop-types */
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDataProcessing } from "hooks/useDataProcessing";
import IdeaKanbanView from "./views/kanban/IdeaKanbanView";
import IdeasClassicListView from "./views/list/classic/IdeasClassicListView";
import IdeasModernListView from "./views/list/modern/IdeasModernListView";
import Loader from "components/common/Loader";
import {
  setDetailViewIdeas,
  setIdeaViewType,
} from "reduxStore/slices/ideaSlice";
import { handleGroupSelect } from "utils/listUtils";
import MasterIdeaSelectionPopup from "../common/masterSelectionPopup/MasterIdeaSelectionPopup";
import { useLocation } from "react-router-dom";

const IdeasComponent = ({ searchTerm = "" }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const urlViewType = searchParams.get("viewType");

  const { objectives } = useSelector((state) => state.objectives);
  const { ideas, groupByOption, selectedColumns, viewType } = useSelector(
    (state) => state.ideas
  );

  const filteredIdeas = useMemo(() => {
    return (ideas ?? []).filter((idea) =>
      idea.title.toLowerCase().includes(searchTerm.trim().toLowerCase())
    );
  }, [ideas, searchTerm]);

  const { groupedData, loading } = useDataProcessing(
    filteredIdeas,
    "idea",
    null,
    objectives
  );

  const [collapsedGroups, setCollapsedGroups] = useState({});
  const [selectedIdeas, setSelectedIdeas] = useState(new Set());

  useEffect(() => {
    const allData = Object.values(groupedData).flatMap((group) => group.items);
    dispatch(setDetailViewIdeas(allData));
  }, [groupedData]);

  const toggleGroupCollapse = (group) => {
    setCollapsedGroups((prev) => ({
      ...prev,
      [group]: !prev[group],
    }));
  };

  const handleIdeaSelect = (idea) => {
    setSelectedIdeas((prevSelected) => {
      const newSelected = new Set(prevSelected);
      newSelected.has(idea._id)
        ? newSelected.delete(idea._id)
        : newSelected.add(idea._id);
      return newSelected;
    });
  };

  const resetSelectedIdeas = () => {
    setSelectedIdeas(new Set());
  };

  // useEffect(() => {
  //   if (urlViewType === viewType) {
  //     dispatch(
  //       setIdeaViewType(urlViewType === "kanban" ? "kanban" : "modernlist")
  //     );
  //   }
  // }, [dispatch, urlViewType, viewType]);

  useEffect(() => {
    if (!urlViewType) {
      dispatch(setIdeaViewType("modernlist"));
    }
    if (urlViewType === viewType) {
      var newViewType;

      switch (urlViewType) {
        case "kanban":
          newViewType = "kanban";
          break;
        case "classiclist":
          newViewType = "classiclist";
          break;
        case "list":
          newViewType = "modernlist";
          break;
        case "modernlist":
        default:
          newViewType = "modernlist";
          break;
      }

      dispatch(setIdeaViewType(newViewType));
    }
  }, [dispatch, urlViewType, viewType]);

  useEffect(() => {
    if (viewType === "kanban") {
      resetSelectedIdeas();
    }
  }, [viewType]);

  const handleLocalGroupSelect = (group) =>
    handleGroupSelect(group, setSelectedIdeas, groupedData);

  return (
    <div className="relative overflow-scroll bg-white shadow w-full h-full ">
      {loading ? (
        <div className="flex items-center justify-center h-full">
          <Loader />
        </div>
      ) : (
        <>
          {viewType === "classiclist" && (
            <IdeasClassicListView
              groupedData={groupedData}
              collapsedGroups={collapsedGroups}
              toggleGroupCollapse={toggleGroupCollapse}
              selectedIdeas={selectedIdeas}
              handleIdeaSelect={handleIdeaSelect}
              handleGroupSelect={handleLocalGroupSelect}
              groupByOption={groupByOption}
              selectedColumns={selectedColumns}
            />
          )}
          {viewType === "modernlist" && (
            <IdeasModernListView
              groupedData={groupedData}
              collapsedGroups={collapsedGroups}
              toggleGroupCollapse={toggleGroupCollapse}
              selectedIdeas={selectedIdeas}
              handleIdeaSelect={handleIdeaSelect}
              handleGroupSelect={handleLocalGroupSelect}
              groupByOption={groupByOption}
              selectedColumns={selectedColumns}
            />
          )}
          {viewType === "kanban" && (
            <IdeaKanbanView groupedData={groupedData} />
          )}
        </>
      )}
      {selectedIdeas.size > 0 && viewType !== "kanban" && (
        <MasterIdeaSelectionPopup
          selectedIdeas={selectedIdeas}
          resetSelectedIdeas={resetSelectedIdeas}
        />
      )}
    </div>
  );
};

export default IdeasComponent;
