/* eslint-disable react/prop-types */
import React, { useState } from "react";
import QuillEditor from "components/common/QuillEditor";
import IdeaDetailViewTopbar from "./IdeaDetailViewTopbar";
import { Bulb } from "react-flaticons";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveIdeaId,
  setIdeaShowDetailViewDrawer,
} from "reduxStore/slices/ideaSlice";
import {
  updateIdeaData,
  updateIdeaFeatures,
} from "reduxStore/operations/ideasAPI";
import DOMPurify from "dompurify";
import { convert } from "html-to-text";
import DetailViewHeader from "components/common/detailView/DetailViewHeader";
import DescriptionDetailViewTab from "components/common/detailView/DescriptionDetailViewTab";
import LinkFeaturesDetailViewTab from "components/common/detailView/LinkFeaturesDetailViewTab";
import { isEmptyDescription } from "utils/descriptionUtils";
import { removeIdeaFeatures } from "../../../reduxStore/operations/ideasAPI";

const IdeaDetailViewComponent = ({
  item,
  showBreadcrumb = false,
  onNext = () => {},
  onPrev = () => {},
}) => {
  const dispatch = useDispatch();
  const { activeIdeaId, showDetailViewDrawer } = useSelector(
    (state) => state.ideas
  );
  const [activeTab, setActiveTab] = useState("Overview");
  const [isQuillEditorActive, setIsQuillEditorActive] = useState(
    // isEmptyDescription(item?.description ?? "")
    false
  );
  const handleEditorClose = () => {
    setIsQuillEditorActive(false);
  };
  const handleEditorOpen = () => {
    setIsQuillEditorActive(true);
  };
  const handleTabClick = (option) => {
    setActiveTab(option);
  };

  const [selectedFeatures, setSelectedFeatures] = useState(new Set());

  const handleFeatureSelect = (feature) => {
    setSelectedFeatures((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(feature._id)) {
        newSelected.delete(feature._id);
      } else {
        newSelected.add(feature._id);
      }
      return newSelected;
    });
  };

  const removeSelectedFeatures = () => {
    setSelectedFeatures(new Set());
  };

  const handleFormSubmit = (content) => {
    // Sanitize the HTML content
    const sanitizedContent = DOMPurify.sanitize(content);

    // Convert HTML to plain text and trim
    const plainTextContent = convert(sanitizedContent, {
      wordwrap: 130,
    }).trim();

    const plainTextDescription = convert(DOMPurify.sanitize(item.description), {
      wordwrap: 130,
    }).trim();

    // Compare the plain text versions
    if (plainTextDescription !== plainTextContent) {
      dispatch(updateIdeaData(item._id, { description: sanitizedContent }));
    }

    setIsQuillEditorActive(false);
  };

  return (
    <>
      {!item ? (
        <div className="w-full h-full m-auto">Loading....</div>
      ) : (
        <div className="flex flex-col h-full pb-5">
          <div className="flex-none pt-4 px-4 pb-7 text-primary sticky top-0 bg-white z-40">
            <DetailViewHeader
              type="Idea"
              item={item}
              showBreadcrumb={showBreadcrumb}
              showDetailViewDrawer={showDetailViewDrawer}
              activeItem={activeIdeaId}
              setShowDetailViewDrawer={setIdeaShowDetailViewDrawer}
              setActiveItemId={setActiveIdeaId}
              Icon={Bulb}
            />
          </div>

          <div className="flex-none px-6">
            <IdeaDetailViewTopbar
              idea={item}
              onNext={onNext}
              onPrev={onPrev}
              onTabClick={handleTabClick}
              activeTab={activeTab}
              updateIdeaData={(title) =>
                dispatch(updateIdeaData(item._id, { title: title }))
              }
            />
          </div>
          <div className="overflow-auto custom-scrollbar h-full w-full px-6 pt-3 text-primary overflow-auto">
            {activeTab === "Overview" ? (
              isQuillEditorActive ? (
                <QuillEditor
                  initialContent={item.description}
                  onClose={handleEditorClose}
                  handleFormSubmit={handleFormSubmit}
                />
              ) : (
                <DescriptionDetailViewTab
                  item={item}
                  handleEditorOpen={handleEditorOpen}
                />
              )
            ) : (
              activeTab === "Features" && (
                <LinkFeaturesDetailViewTab
                  currentItem={item}
                  selectedItems={selectedFeatures}
                  handleItemSelect={handleFeatureSelect}
                  updateLinkedItems={(features) =>
                    dispatch(
                      updateIdeaFeatures(item._id, { features: features })
                    )
                  }
                  removeLinkedItems={(features) =>
                    dispatch(
                      removeIdeaFeatures(item._id, { features: features })
                    )
                  }
                  clearSelectedItems={removeSelectedFeatures}
                />
              )
            )}

            {/* <div className="flex flex-col gap-y-5 py-5 text-sm px-6">
            <div>
            <div>PRD</div>
            <div className="text-xs underline italic text-button-active">
            {item.prd}
            </div>
            </div>
            <div>
            <div>Design</div>
            <div className="text-xs underline italic text-button-active">
            {item.design}
            </div>
            </div>
            </div> */}
          </div>
        </div>
      )}
    </>
  );
};

export default IdeaDetailViewComponent;
