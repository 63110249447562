/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from "react";
import IconText from "components/common/IconText";
import { fieldIconMapping } from "utils";
import DropdownMenu from "../dropdown/DropdownMenu";
import { useNavigate } from "react-router-dom";
import IconWrapper from "../../IconWrapper";

const ReleaseSection = ({
  itemLabel,
  releases,
  handleReleaseOptionClick,
  position = "gap-x-6",
  dropdownMenuPosition = "top-full left-0",
  with_title = true,
  with_icon = false,
}) => {
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef();

  const handleLocalReleaseOptionClick = (option) => {
    handleReleaseOptionClick(option);
    setIsDropdownOpen(false);
  };

  const toggleDropdown = (e) => {
    e.stopPropagation();
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={`flex items-center ${position} text-title_50`}>
      {with_title && (
        <IconText
          icon={React.createElement(fieldIconMapping.release)}
          text="Release"
          iconColor="#696969"
          iconSize={16}
          hoverable={false}
          gap_x="gap-x-2"
        />
      )}
      <div className="relative" ref={dropdownRef}>
        <div
          className={`${
            itemLabel ? "px-1.5 py-1" : "px-7 py-3.5"
          } text-xs rounded cursor-pointer  ${
            isDropdownOpen ? "bg-button-active_10" : "hover:bg-button-hover"
          }`}
          onClick={toggleDropdown}
        >
          <div className="flex items-center gap-x-2">
            {with_icon && (
              <IconWrapper
                icon={React.createElement(fieldIconMapping.release)}
                size={14}
              />
            )}
            {itemLabel ?? ""}
          </div>
          {isDropdownOpen && (
            <DropdownMenu
              options={[{ _id: null, label: "No Release" }]
                .concat(releases ?? [])
                .map((option) => ({
                  _id: option._id,
                  label: option.label,
                  icon: fieldIconMapping["release"],
                }))}
              isOpen={isDropdownOpen}
              position={`${dropdownMenuPosition} w-[7.4rem] mt-0.5`}
              handleOptionClick={handleLocalReleaseOptionClick}
              withAddOption={true}
              handleAddOptionClick={() =>
                navigate("/settings/customizations/release")
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ReleaseSection;
