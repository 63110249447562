/* eslint-disable react/prop-types */
import React, { useMemo } from "react";
import KeyResultRow from "./KeyResultRow";
import { useSelector } from "react-redux";

const KeyResultBasedRows = ({ objective, selectedColumns }) => {
  const { keyResults } = useSelector((state) => state.keyResults);
  const objectiveKeyResults = useMemo(() => {
    return (keyResults ?? []).filter((keyResult) =>
      (objective?.keyResults ?? []).includes(keyResult._id)
    );
  }, [objective?.keyResults, keyResults]);
  return (
    <>
      {(objectiveKeyResults ?? []).map((keyResult) => (
        <React.Fragment key={keyResult}>
          <KeyResultRow
            objective={objective}
            objectiveKeyResults={objectiveKeyResults}
            keyResult={keyResult}
            selectedColumns={selectedColumns}
          />
        </React.Fragment>
      ))}
    </>
  );
};

export default KeyResultBasedRows;
