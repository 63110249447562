/* eslint-disable react/prop-types */
import { useEffect, useState, useCallback } from "react";
import { Dialog, DialogPanel } from "@headlessui/react";
import { useDispatch } from "react-redux";
import { setActiveKeyResultId } from "../../../reduxStore/slices/keyResultSlice";

const DetailViewDrawer = ({
  showDetailViewDrawer,
  activeItemId,
  items,
  setShowDetailViewDrawerAction,
  setActiveItemIdAction,
  DetailViewComponent,
  RightSidebarComponent,
  shouldNavigate = true,
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(showDetailViewDrawer);
  const [activeItem, setActiveItem] = useState(null);
  useEffect(() => {
    if (items !== null && activeItemId !== null) {
      const currentItem = (items ?? []).find(
        (item) => item._id === activeItemId
      );
      setActiveItem(currentItem || items?.[0]);
    }
  }, [items, activeItemId]);

  useEffect(() => {
    if (activeItem === null || activeItem === undefined) {
      dispatch(setShowDetailViewDrawerAction(false));
    }
  }, [activeItem]);

  useEffect(() => {
    setOpen(showDetailViewDrawer);
  }, [showDetailViewDrawer]);

  useEffect(() => {
    if (!open) {
      dispatch(setShowDetailViewDrawerAction(false));
    }
  }, [dispatch, open]);

  const handleNextItem = useCallback(() => {
    if (activeItemId !== null) {
      const currentIndex = items.findIndex((item) => item._id === activeItemId);
      const nextIndex = (currentIndex + 1) % items.length;
      dispatch(setActiveItemIdAction(items[nextIndex]._id));
    }
  }, [dispatch, activeItemId, items]);

  const handlePrevItem = useCallback(() => {
    if (activeItemId !== null) {
      const currentIndex = items.findIndex((item) => item._id === activeItemId);
      const prevIndex = (currentIndex - 1 + items.length) % items.length;
      dispatch(setActiveItemIdAction(items[prevIndex]._id));
    }
  }, [dispatch, activeItemId, items]);

  return (
    <Dialog open={open} onClose={setOpen} className="relative z-50">
      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
            <DialogPanel
              transition
              className="pointer-events-auto relative w-screen max-w-4xl transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
            >
              <div className="flex bg-white shadow-2xl h-full tracking-wide font-inter">
                {activeItem !== null && (
                  <>
                    <div className="flex flex-col border-r-2 w-2/3">
                      <DetailViewComponent
                        item={activeItem}
                        onNext={handleNextItem}
                        onPrev={handlePrevItem}
                        shouldNavigate={shouldNavigate}
                      />
                    </div>
                    <div className="w-[350px]">
                      <RightSidebarComponent item={activeItem} />
                    </div>
                  </>
                )}
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default DetailViewDrawer;
