/* eslint-disable react/prop-types */
import React from "react";
import { rgbColor } from "utils";
import IconText from "../../IconText";
import { CrossSmall } from "react-flaticons";
import DropdownMenu from "components/common/detailView/dropdown/DropdownMenu";

const StatusButton = ({
  statusArray,
  updatedStatus,
  dropdownState,
  removeSelection,
  toggleDropdown,
  handleOptionClick,
}) => {
  return (
    <button
      className={`relative flex items-center gap-x-1 border border-gray-300 ${
        dropdownState.isStatusDropdownOpen
          ? "bg-button-active_10"
          : "hover:bg-row-background"
      }  p-1.5 rounded-md`}
      onClick={() => toggleDropdown("isStatusDropdownOpen")}
    >
      {updatedStatus ? (
        <IconText
          icon={updatedStatus.icon}
          text={updatedStatus.label}
          textBgColor={rgbColor(updatedStatus.color, 0.2)}
          textSize="text-xs"
          textColor="text-title"
          px=""
          py=""
          gap_x="gap-x-1"
          iconColor={updatedStatus.color}
          hoverable={false}
        />
      ) : (
        "Change Status"
      )}
      {updatedStatus && (
        <div
          className="cursor-pointer hover:bg-button-hover p-0.5 rounded-full"
          onClick={(e) => {
            e.stopPropagation();
            removeSelection("status");
          }}
        >
          <CrossSmall size={12} />
        </div>
      )}
      <DropdownMenu
        options={statusArray || []}
        isOpen={dropdownState.isStatusDropdownOpen}
        position="bottom-full w-32 right-0"
        handleOptionClick={(option) => handleOptionClick("status", option)}
      />
    </button>
  );
};

export default StatusButton;
