/* eslint-disable react/prop-types */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { CrossSmall } from "react-flaticons";
import DropdownMenu from "components/common/detailView/dropdown/DropdownMenu";
import { fieldIconMapping } from "utils";
import { useDispatch, useSelector } from "react-redux";
import { generateStatusArray } from "utils/status";
import {
  updateFeaturesData,
  deleteFeatures,
} from "reduxStore/operations/featuresAPI";
import StatusButton from "./buttons/StatusButton";
import ProductButton from "./buttons/ProductButton";
import DeleteButton from "./buttons/DeleteButton";
import Loader from "../Loader";
import { bulkPushJiraIssues } from "../../../reduxStore/operations/jiraAPI";
import { Button, Select } from "antd";
import { getAllFeatures } from "../../../reduxStore/operations/featuresAPI";

const MasterFeatureSelectionPopup = ({
  selectedFeatures,
  resetSelectedFeatures,
}) => {
  const dispatch = useDispatch();
  const { releases, products, statuses } = useSelector(
    (state) => state.company
  );
  const { companyId } = useSelector((state) => state.auth);
  const { jira, jiraProjects } = useSelector((state) => state.integrations);

  const dropdownRef = useRef(null);

  const [featureStatuses, setFeatureStatuses] = useState([]);
  const [updatedStatus, setUpdatedStatus] = useState(null);
  const [updatedProduct, setUpdatedProduct] = useState(null);
  const [updatedRelease, setUpdatedRelease] = useState(null);
  const [dropdownState, setDropdownState] = useState({
    isStatusDropdownOpen: false,
    isReleaseDropdownOpen: false,
    isProductDropdownOpen: false,
  });
  const [loading, setLoading] = useState(false);
  const [isPushToJiraLoading, setIsPushToJiraLoading] = useState(false);
  const [selectedProject, setSelectedProject] = useState();
  const [featuresToDelete, setFeaturesToDelete] = useState(false);

  useEffect(() => {
    setFeatureStatuses(statuses.feature);
  }, [statuses]);

  const statusArray = useMemo(
    () => generateStatusArray(featureStatuses),
    [featureStatuses]
  );

  const closeAllDropdowns = () => {
    setDropdownState({
      isStatusDropdownOpen: false,
      isReleaseDropdownOpen: false,
      isProductDropdownOpen: false,
    });
  };

  useEffect(() => {
    if (selectedFeatures.size === 0) {
      closeAllDropdowns();
    }
  }, [selectedFeatures.size]);

  const toggleDropdown = useCallback((type) => {
    setDropdownState((prev) => {
      const newState = Object.keys(prev).reduce((acc, key) => {
        acc[key] = key === type ? !prev[key] : false;
        return acc;
      }, {});
      return newState;
    });
  }, []);

  const handleOptionClick = (type, option) => {
    switch (type) {
      case "status":
        setUpdatedStatus(option); // Option will contain both _id and label
        break;
      case "product":
        setUpdatedProduct(option);
        break;
      case "release":
        setUpdatedRelease(option);
        break;
      default:
        break;
    }
    closeAllDropdowns();
  };

  const removeSelection = (type) => {
    switch (type) {
      case "status":
        setUpdatedStatus(null);
        break;
      case "product":
        setUpdatedProduct(null);
        break;
      case "release":
        setUpdatedRelease(null);
        break;
      default:
        break;
    }
  };

  const handleDelete = () => {
    setFeaturesToDelete(!featuresToDelete);
  };

  useEffect(() => {
    // Handler for clicks outside of the dropdown
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeAllDropdowns();
      }
    };

    // Add event listener for clicks outside
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const applyChanges = async () => {
    setLoading(true);

    try {
      if (featuresToDelete) {
        await dispatch(deleteFeatures(Array.from(selectedFeatures)));
        resetSelectedFeatures();
        setFeaturesToDelete(false);
      } else {
        const updateData = {
          ...(updatedStatus && { status: updatedStatus._id }),
          ...(updatedProduct && { product: updatedProduct._id }),
          ...(updatedRelease && { release: updatedRelease._id }),
        };

        await dispatch(
          updateFeaturesData(
            Array.from(selectedFeatures),
            updateData,
            Object.keys(updateData),
            (releases ?? []).map((release) => release._id)
          )
        );
        resetSelectedFeatures();
      }
    } finally {
      setLoading(false);
    }
  };

  const bulkPushToJiraHandler = async () => {
    setIsPushToJiraLoading(true);
    const responseData = await dispatch(
      bulkPushJiraIssues(
        companyId,
        Array.from(selectedFeatures),
        selectedProject
      )
    );
    setIsPushToJiraLoading(false);
    resetSelectedFeatures();
    if (responseData.createdKeys?.length !== 0) {
      await dispatch(getAllFeatures());
    }
  };

  return (
    <div className="absolute bottom-5 left-1/2 transform -translate-x-1/2 bg-white z-50 p-5 rounded-lg shadow-lg border w-[45%]">
      <div className="flex flex-col gap-y-4 text-xs">
        <span>
          {featuresToDelete
            ? `Clicking "Apply" will delete the selected ${
                selectedFeatures.size > 1 ? selectedFeatures.size : ""
              } feature${selectedFeatures.size > 1 ? "s" : ""}. Are you sure?`
            : `${selectedFeatures.size} selected features`}
        </span>

        <div className="flex items-center justify-between" ref={dropdownRef}>
          <DeleteButton
            handleDelete={handleDelete}
            itemsToDelete={featuresToDelete}
          />

          <div className="flex items-center gap-x-2.5">
            {/* Status */}
            <StatusButton
              statusArray={statusArray}
              updatedStatus={updatedStatus}
              dropdownState={dropdownState}
              removeSelection={removeSelection}
              toggleDropdown={toggleDropdown}
              handleOptionClick={handleOptionClick}
            />

            {/* Product */}
            <ProductButton
              products={products}
              updatedProduct={updatedProduct}
              dropdownState={dropdownState}
              removeSelection={removeSelection}
              toggleDropdown={toggleDropdown}
              handleOptionClick={handleOptionClick}
            />

            {/* Release */}
            <button
              className={`relative flex items-center gap-x-1 border border-gray-300 ${
                dropdownState.isReleaseDropdownOpen
                  ? "bg-button-active_10"
                  : "hover:bg-row-background"
              }  p-1.5 rounded-md`}
              onClick={() => toggleDropdown("isReleaseDropdownOpen")}
            >
              {updatedRelease?.label || "Change release"}
              {updatedRelease && (
                <div
                  className="cursor-pointer hover:bg-button-hover p-0.5 rounded-full"
                  onClick={(e) => {
                    e.stopPropagation();
                    removeSelection("release");
                  }}
                >
                  <CrossSmall size={12} />
                </div>
              )}
              <DropdownMenu
                options={[{ _id: null, label: "No Release" }]
                  .concat(releases ?? [])
                  .map((option) => ({
                    ...option,
                    icon: fieldIconMapping["release"],
                  }))}
                isOpen={dropdownState.isReleaseDropdownOpen}
                position="bottom-full w-32 right-0"
                handleOptionClick={(option) =>
                  handleOptionClick("release", option)
                }
              />
            </button>
          </div>
        </div>

        <div className="flex gap-x-4 items-center justify-between">
          <div className="flex items-center gap-2">
            {/* hiding this for now */}
            {false && jira && (
              <>
                <Select
                  value={selectedProject}
                  placeholder="Select project"
                  options={jiraProjects}
                  onSelect={(value) => setSelectedProject(value)}
                  className="flex-grow"
                  size="small"
                />
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={bulkPushToJiraHandler}
                  loading={isPushToJiraLoading}
                  disabled={isPushToJiraLoading || !selectedProject}
                >
                  Push to Jira
                </Button>
              </>
            )}
          </div>
          <div className="flex items-center gap-2">
            <button
              className="border-dashed border-2 border-gray-300 hover:bg-row-background p-1.5 rounded-md"
              onClick={resetSelectedFeatures}
            >
              Deselect all
            </button>

            {loading ? (
              <div className="w-fit ml-auto py-1.5 px-4 bg-button-active rounded text-white cursor-not-allowed flex items-center gap-x-2">
                <Loader size="14" color="white" />
                <span>Applying...</span>
              </div>
            ) : (
              <button
                className="w-fit ml-auto p-1.5 bg-add-popup-button hover:bg-button-active rounded text-white"
                onClick={applyChanges}
              >
                Apply
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MasterFeatureSelectionPopup;
