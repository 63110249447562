/* eslint-disable react/prop-types */
import React, { useRef, useEffect, useState } from "react";
import { CrossSmall } from "react-flaticons";
import AddFeaturePopupOverlay from "./addNewPopupOverlays/AddFeaturePopupOverlay";
import AddIdeaPopupOverlay from "./addNewPopupOverlays/AddIdeaPopupOverlay";
import AddObjectivePopupOverlay from "./addNewPopupOverlays/AddObjectivePopupOverlay";
import AddRoadmapPopupOverlay from "./addNewPopupOverlays/addRoadmap/AddRoadmapPopupOverlay";
import IconText from "./IconText";

const AddNewPopupOverlay = ({ option, onClose }) => {
  const [name, setName] = useState("");
  const [isNameEditable, setIsNameEditable] = useState(true);
  const [isWarn, setIsWarn] = useState(false);
  const popupRef = useRef(null);

  // Function to handle click outside of the popup
  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    // Set up the event listener for clicks outside
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="fixed inset-0 bg-add-popup-background bg-opacity-50 flex justify-center items-center pb-64 text-xs">
      <div
        ref={popupRef}
        className="border border-gray-300 bg-white p-5 rounded-md relative min-w-[600px] w-auto max-w-3xl"
      >
        <div className="flex flex-col">
          <div className="flex items-center justify-between mb-2">
            <IconText
              icon={React.createElement(option.icon)}
              iconColor={option?.iconColor ?? ""}
              text={option?.label ?? ""}
              textColor="text-title_50"
              hoverable={false}
              gap_x="gap-x-2"
              px=""
              py=""
            />
            <button
              onClick={onClose}
              className="self-end rounded p-0.5 hover:bg-button-hover"
            >
              <CrossSmall color="#374957" />
            </button>
          </div>
          {isNameEditable ? (
            <input
              type="text"
              value={name}
              className={`w-full text-[18px] py-2 px-2 mb-3.5  rounded-md focus:outline-none ${
                isWarn ? " shadow-[0_0_5px_rgba(255,0,0,0.5)] " : "  "
              }  `}
              onChange={(e) => setName(e.target.value)}
              placeholder={
                isWarn
                  ? `Enter ${option.label.toLowerCase()} name`
                  : `${option.label} name`
              }
              required
              autoFocus
            />
          ) : (
            <div className="text-lg py-2 mb-3.5">{name}</div>
          )}
          {option.label === "Feature" && (
            <AddFeaturePopupOverlay
              option={option}
              name={name}
              setIsWarn={setIsWarn}
            />
          )}
          {option.label === "Idea" && (
            <AddIdeaPopupOverlay
              option={option}
              name={name}
              setIsWarn={setIsWarn}
            />
          )}
          {option.label === "Objective" && (
            <AddObjectivePopupOverlay
              option={option}
              name={name}
              setIsWarn={setIsWarn}
            />
          )}
          {option.label === "Roadmap" && (
            <AddRoadmapPopupOverlay
              option={option}
              name={name}
              setIsNameEditable={setIsNameEditable}
              setIsWarn={setIsWarn}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AddNewPopupOverlay;
