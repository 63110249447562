/* eslint-disable react/prop-types */
import React from "react";
import ProfileIcon from "components/common/ProfileIcon";

const AssigneeRowSection = ({ name }) => {
  return (
    <div className="flex items-center gap-x-2">
      <ProfileIcon name={name ?? ""} />
      <span>{name ?? ""}</span>
    </div>
  );
};

export default AssigneeRowSection;
