import {
  addView,
  deleteView,
  editView,
  fetchAllViews,
  updateParticularRoadmapView,
} from "../reduxStore/operations/viewsAPI";

export const featureTopbarTabFunctions = (dispatch, navigate) => ({
  fetchAllViews: (viewTypeName) =>
    dispatch(fetchAllViews("feature", viewTypeName)),
  deleteView: (viewId, viewType) =>
    dispatch(deleteView(viewId, "feature", viewType, navigate)),
  editView: (
    viewId,
    viewType,
    label,
    sortOption,
    filtersData,
    selectedColumns,
    groupByOption
  ) =>
    dispatch(
      editView(
        viewId,
        "feature",
        viewType,
        label,
        sortOption,
        filtersData,
        selectedColumns,
        groupByOption
      )
    ),
  addView: (
    viewType,
    label,
    sortOption,
    filtersData,
    selectedColumns,
    groupByOption
  ) =>
    dispatch(
      addView(
        label,
        "feature",
        viewType,
        sortOption,
        filtersData,
        selectedColumns,
        groupByOption,
        navigate
      )
    ),
});

export const ideaTopbarTabFunctions = (dispatch, navigate) => ({
  fetchAllViews: (viewTypeName) =>
    dispatch(fetchAllViews("idea", viewTypeName)),
  deleteView: (viewId, viewType) =>
    dispatch(deleteView(viewId, "idea", viewType, navigate)),
  editView: (
    viewId,
    viewType,
    label,
    sortOption,
    filtersData,
    selectedColumns,
    groupByOption
  ) =>
    dispatch(
      editView(
        viewId,
        "idea",
        viewType,
        label,
        sortOption,
        filtersData,
        selectedColumns,
        groupByOption
      )
    ),
  addView: (
    viewType,
    label,
    sortOption,
    filtersData,
    selectedColumns,
    groupByOption
  ) =>
    dispatch(
      addView(
        label,
        "idea",
        viewType,
        sortOption,
        filtersData,
        selectedColumns,
        groupByOption,
        navigate
      )
    ),
});

export const objectiveTopbarTabFunctions = (dispatch, navigate) => ({
  fetchAllViews: (viewTypeName) =>
    dispatch(fetchAllViews("objective", viewTypeName)),
  deleteView: (viewId, viewType) =>
    dispatch(deleteView(viewId, "objective", viewType, navigate)),
  editView: (
    viewId,
    viewType,
    label,
    sortOption,
    filtersData,
    selectedColumns,
    groupByOption
  ) =>
    dispatch(
      editView(
        viewId,
        "objective",
        viewType,
        label,
        sortOption,
        filtersData,
        selectedColumns,
        groupByOption
      )
    ),
  addView: (
    viewType,
    label,
    sortOption,
    filtersData,
    selectedColumns,
    groupByOption
  ) =>
    dispatch(
      addView(
        label,
        "objective",
        viewType,
        sortOption,
        filtersData,
        selectedColumns,
        groupByOption,
        navigate
      )
    ),
});

export const roadmapTopbarTabFunctions = (dispatch, navigate) => ({
  fetchAllViews: (viewTypeName) =>
    dispatch(fetchAllViews("roadmap", viewTypeName)),
  editView: (
    viewId,
    viewType,
    label,
    sortOption,
    filtersData,
    selectedColumns,
    groupByOption
  ) =>
    dispatch(
      editView(
        viewId,
        "roadmap",
        viewType,
        label,
        sortOption,
        filtersData,
        selectedColumns,
        groupByOption
      )
    ),

  deleteView: (viewId, viewType) =>
    dispatch(deleteView(viewId, "roadmap", viewType, navigate)),
  addView: (
    viewType,
    label,
    sortOption,
    filtersData,
    selectedColumns,
    groupByOption
  ) =>
    dispatch(
      addView(
        label,
        "roadmap",
        viewType,
        sortOption,
        filtersData,
        selectedColumns,
        groupByOption,
        navigate
      )
    ),
});

export const particularRoadmapTopbarTabFunctions = (dispatch, navigate) => ({
  fetchAllViews: (viewTypeName, roadmapId) =>
    dispatch(fetchAllViews("roadmapId", viewTypeName, roadmapId)),
  editView: (
    viewId,
    viewType,
    label,
    sortOption,
    filtersData,
    selectedColumns,
    groupByOption,
    roadmapId
  ) =>
    dispatch(
      updateParticularRoadmapView(
        viewId,
        {
          module: "roadmapId",
          viewType,
          label,
          sortOption,
          filters: filtersData,
          selectedColumns,
          groupByOption,
        },
        roadmapId
      )
    ),

  deleteView: (viewId, viewType) =>
    dispatch(deleteView(viewId, "roadmapId", viewType, navigate)),
});
