/* eslint-disable react/prop-types */
import React from "react";
import { rgbColor, capitalizeFirstLetter, fieldIconMapping } from "utils";
import IconText from "components/common/IconText";

const TimeFrameRowSection = ({ timeFrame, dropdownState, toggleDropdown }) => {
  return (
    <div
      className={`p-1 w-fit rounded hover:bg-button-hover ${
        dropdownState.isTimeFrameDropdownOpen && "bg-button-active_10"
      }`}
      onClick={(e) => {
        e.stopPropagation();
        toggleDropdown("isTimeFrameDropdownOpen");
      }}
    >
      <div className="rounded w-fit bg-white">
        <IconText
          icon={React.createElement(fieldIconMapping.timeFrames)}
          text={capitalizeFirstLetter(timeFrame?.label ?? "No Time-frame")}
          iconTextBgColor={rgbColor(timeFrame?.color, 0.2)}
          iconColor={timeFrame?.color}
          textColor={"text-title_50"}
          px="px-1"
          py="py-1"
          gap_x="gap-x-1"
          textSize="text-xs"
          iconSize="12"
        />
      </div>
    </div>
  );
};

export default TimeFrameRowSection;
