/* eslint-disable react/prop-types */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { generateStatusArray } from "utils/status";
import { updateIdeasData, deleteIdeas } from "reduxStore/operations/ideasAPI";
import StatusButton from "./buttons/StatusButton";
import ProductButton from "./buttons/ProductButton";
import DeleteButton from "./buttons/DeleteButton";
import ImportanceButton from "./buttons/ImportanceButton";
import Loader from "../Loader";

const MasterIdeaSelectionPopup = ({ selectedIdeas, resetSelectedIdeas }) => {
  const dispatch = useDispatch();
  const { products, statuses } = useSelector((state) => state.company);
  const dropdownRef = useRef(null);

  const [ideaStatuses, setIdeaStatuses] = useState([]);
  const [updatedStatus, setUpdatedStatus] = useState(null);
  const [updatedProduct, setUpdatedProduct] = useState(null);
  const [updatedImportance, setUpdatedImportance] = useState(null);
  const [dropdownState, setDropdownState] = useState({
    isStatusDropdownOpen: false,
    isProductDropdownOpen: false,
    isImportanceDropdownOpen: false,
  });
  const [loading, setLoading] = useState(false);
  const [ideasToDelete, setIdeasToDelete] = useState(false); // State to track if deletion is needed

  useEffect(() => {
    setIdeaStatuses(statuses.idea);
  }, [statuses]);

  const statusArray = useMemo(
    () => generateStatusArray(ideaStatuses),
    [ideaStatuses]
  );

  const closeAllDropdowns = () => {
    setDropdownState({
      isStatusDropdownOpen: false,
      isProductDropdownOpen: false,
      isImportanceDropdownOpen: false,
    });
  };

  useEffect(() => {
    if (selectedIdeas.size === 0) {
      closeAllDropdowns();
    }
  }, [selectedIdeas.size]);

  const toggleDropdown = useCallback((type) => {
    setDropdownState((prev) => {
      const newState = Object.keys(prev).reduce((acc, key) => {
        acc[key] = key === type ? !prev[key] : false;
        return acc;
      }, {});
      return newState;
    });
  }, []);

  const handleOptionClick = (type, option) => {
    closeAllDropdowns();
    switch (type) {
      case "status":
        setUpdatedStatus(option);
        break;
      case "product":
        setUpdatedProduct(option);
        break;
      case "importance":
        setUpdatedImportance(option);
        break;
      default:
        break;
    }
  };

  const removeSelection = (type) => {
    closeAllDropdowns();
    switch (type) {
      case "status":
        setUpdatedStatus(null);
        break;
      case "product":
        setUpdatedProduct(null);
        break;
      case "importance":
        setUpdatedImportance(null);
        break;
      default:
        break;
    }
  };

  const handleDelete = () => {
    setIdeasToDelete(!ideasToDelete);
  };

  useEffect(() => {
    // Handler for clicks outside of the dropdown
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeAllDropdowns();
      }
    };

    // Add event listener for clicks outside
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const applyChanges = async () => {
    setLoading(true);
    try {
      if (ideasToDelete) {
        await dispatch(deleteIdeas(Array.from(selectedIdeas)));
        resetSelectedIdeas();
        setIdeasToDelete(false);
      } else {
        const updateData = {
          ...(updatedStatus && { status: updatedStatus._id }),
          ...(updatedProduct && { product: updatedProduct._id }),
          ...(updatedImportance && { importance: updatedImportance }),
        };
        await dispatch(
          updateIdeasData(
            Array.from(selectedIdeas),
            updateData,
            Object.keys(updateData)
          )
        );
        resetSelectedIdeas();
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="absolute bottom-5 left-1/2 transform -translate-x-1/2 bg-white z-50 p-5 rounded-lg shadow-lg border w-[45%]">
      <div className="flex flex-col gap-y-4 text-xs">
        <span>
          {ideasToDelete
            ? `Clicking "Apply" will delete the selected ${
                selectedIdeas.size > 1 ? selectedIdeas.size : ""
              } idea${selectedIdeas.size > 1 ? "s" : ""}. Are you sure?`
            : `${selectedIdeas.size} selected ideas`}
        </span>

        <div className="flex items-center justify-between" ref={dropdownRef}>
          <DeleteButton
            handleDelete={handleDelete}
            itemsToDelete={ideasToDelete}
          />

          <div className="flex items-center gap-x-2.5">
            {/* Status */}
            <StatusButton
              statusArray={statusArray}
              updatedStatus={updatedStatus}
              dropdownState={dropdownState}
              removeSelection={removeSelection}
              toggleDropdown={toggleDropdown}
              handleOptionClick={handleOptionClick}
            />

            {/* Product */}
            <ProductButton
              products={products}
              updatedProduct={updatedProduct}
              dropdownState={dropdownState}
              removeSelection={removeSelection}
              toggleDropdown={toggleDropdown}
              handleOptionClick={handleOptionClick}
            />

            {/* Importance */}
            <ImportanceButton
              updatedImportance={updatedImportance}
              dropdownState={dropdownState}
              removeSelection={removeSelection}
              toggleDropdown={toggleDropdown}
              handleOptionClick={handleOptionClick}
            />
          </div>
        </div>

        <div className="ml-auto flex gap-x-4 items-center">
          <button
            className="border-dashed border-2 border-gray-300 hover:bg-row-background p-1.5 rounded-md"
            onClick={resetSelectedIdeas}
          >
            Deselect all
          </button>

          {loading ? (
            <div className="w-fit ml-auto py-1.5 px-4 bg-button-active rounded text-white cursor-not-allowed flex items-center gap-x-2">
              <Loader size="14" color="white" />
              <span>Applying...</span>
            </div>
          ) : (
            <button
              className="w-fit ml-auto p-1.5 bg-add-popup-button hover:bg-button-active rounded text-white"
              onClick={applyChanges}
            >
              Apply
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default MasterIdeaSelectionPopup;
