/* eslint-disable react/prop-types */
import React, { useRef, useState } from "react";
import Searchbar from "../../Searchbar";
import SimpleCheckboxItem from "../../checkboxItem/SimpleCheckboxItem";

const TagsSearchDropdown = ({
  searchPlaceholder,
  items,
  selectedItems,
  onItemSelect,
  iconComponent,
  isDropdownOpen,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const searchbarRef = useRef(null);

  const filteredItems = items?.filter((item) =>
    item?.label?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div
      className="relative"
      ref={searchbarRef}
      onClick={(e) => e.stopPropagation()}
    >
      {isDropdownOpen && (
        <>
          <span>
            <Searchbar
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder={searchPlaceholder}
            />
          </span>
          <div className="absolute w-full bg-white shadow-md rounded px-2 z-50">
            <div className="max-h-40 overflow-y-auto custom-scrollbar my-1.5">
              {filteredItems
                ?.filter((item) => selectedItems.has(item._id))
                ?.map((item) => (
                  <div key={item._id} className="my-0.5">
                    <SimpleCheckboxItem
                      option={{ label: item?.label, icon: iconComponent }}
                      selected={true}
                      checkboxTextSize="text-xs"
                      handleOptionClick={() => onItemSelect(item)}
                    />
                  </div>
                ))}
              {filteredItems
                ?.filter((item) => !selectedItems.has(item._id))
                ?.map((item) => (
                  <div key={item._id} className="my-0.5">
                    <SimpleCheckboxItem
                      option={{ label: item?.label, icon: iconComponent }}
                      selected={false}
                      checkboxTextSize="text-xs"
                      handleOptionClick={() => onItemSelect(item)}
                    />
                  </div>
                ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default TagsSearchDropdown;
