import { Button, Select, Tag } from "antd";
import React, { useState } from "react";
import { LuExternalLink } from "react-icons/lu";
import { useDispatch, useSelector } from "react-redux";
import {
  getJiraIssue,
  pushJiraIssue,
} from "../../../../reduxStore/operations/jiraAPI";
import { getAllFeatures } from "../../../../reduxStore/operations/featuresAPI";
import { toast } from "react-toastify";
import { SyncOutlined } from "@ant-design/icons";

const JiraSection = ({ item }) => {
  const dispatch = useDispatch();
  const { companyId } = useSelector((state) => state.auth);
  const integrations = useSelector((state) => state.integrations);
  const { jira } = integrations;
  const [isLoading, setIsLoading] = useState(false);
  const [selectedProject, setSelectedProject] = useState();
  const { jiraProjects } = useSelector((state) => state.integrations);

  const pushClickHandler = async () => {
    setIsLoading(true);
    await dispatch(
      pushJiraIssue(
        companyId,
        item._id,
        item.title,
        item.description,
        selectedProject
      )
    );
    await dispatch(getAllFeatures());
    setIsLoading(false);
  };

  const refreshClickHandler = async () => {
    setIsLoading(true);
    const res = await dispatch(
      getJiraIssue(companyId, item._id, item.jira?.key)
    );
    if (res) {
      await dispatch(getAllFeatures());
      toast.success("Jira details refreshed successfully");
    }
    setIsLoading(false);
  };

  return (
    <div className="px-2">
      <div className="flex items-center gap-2 mb-2">
        <div className="flex items-center gap-2">
          <img src="/jiraIcon.svg" alt="jira" width={14} height={14} />
          <div>Jira</div>
        </div>
        {item.jira?.key && jira && (
          <div className="p-1 rounded hover:bg-gray-200 h-fit flex cursor-pointer">
            <SyncOutlined
              spin={isLoading}
              onClick={refreshClickHandler}
              disabled={isLoading}
              style={{ fontSize: "12px" }}
            />
          </div>
        )}
      </div>

      {!item.jira?.key && (
        <div className="flex items-center gap-2 mt-2 w-100">
          <Select
            value={selectedProject}
            placeholder="Select project"
            options={jiraProjects}
            onSelect={(value) => setSelectedProject(value)}
            className="flex-grow"
          />
          <Button
            onClick={pushClickHandler}
            loading={isLoading}
            disabled={!selectedProject || isLoading}
          >
            Push
          </Button>
        </div>
      )}
      {item.jira?.key && (
        <div className="mt-2 text-sm">
          <div className="flex items-center gap-4">
            <div className="w-14">ID: </div>

            <div className="flex items-center gap-2">
              <div className="text-sm">{item.jira?.key}</div>
              <a
                href={item.jira?.link}
                target="_blank"
                className="cursor-pointer flex items-center gap-2 p-1 hover:bg-slate-200 rounded"
              >
                <LuExternalLink />
              </a>
            </div>
          </div>
          {jira && (
            <div className="flex items-center gap-4 mt-2">
              <div className="w-14">Status: </div>
              {item.jira?.status}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default JiraSection;
