/* eslint-disable react/prop-types */
import React from "react";
import IconText from "components/common/IconText";
import { CrossSmall } from "react-flaticons";
import HTMLReactParser from "html-react-parser/lib/index";
import { capitalizeFirstLetter } from "utils";

const LinkedItemList = ({
  linkedItemIds,
  allItems,
  itemIconComponent,
  linkedItemsKey,
  handleRemoveLinkedItem,
  onLinkedItemClick,
}) => (
  <div className="my-2">
    {linkedItemIds.length > 0 ? (
      linkedItemIds.map((itemId) => {
        const linkedItem = allItems.find((f) => f._id === itemId);
        return linkedItem ? (
          <div
            key={linkedItem._id}
            className="flex items-center justify-between hover:bg-row-background pr-2 rounded-lg group mb-4"
          >
            <div
              className="flex flex-col text-xs flex-1 cursor-pointer"
              onClick={() => {
                onLinkedItemClick(linkedItem._id);
              }}
            >
              <IconText
                icon={itemIconComponent}
                text={linkedItem.title}
                hoverable={false}
                textColor={"text-title_50 "}
              />
              {/* <span className="ml-[2.15rem] pb-2">
                {HTMLReactParser(linkedItem?.description ?? "")}
              </span> */}
            </div>
            <div className="opacity-0 group-hover:opacity-100 transition-opacity duration-300">
              <IconText
                icon={<CrossSmall />}
                text="Unlink"
                onClick={(e) => {
                  e.stopPropagation();
                  handleRemoveLinkedItem(linkedItem);
                }}
                gap_x="gap-x-1"
                textSize="text-xs"
                iconSize="12"
                px="px-1.5"
                py="py-1.5"
              />
            </div>
          </div>
        ) : null;
      })
    ) : (
      <p>No linked {linkedItemsKey}</p>
    )}
  </div>
);

export default LinkedItemList;
