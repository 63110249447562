/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import QuillEditor from "components/common/QuillEditor";
import KeyResultDetailViewTopbar from "./KeyResultDetailViewTopbar";
import { fieldIconMapping } from "utils";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveKeyResultId,
  setKeyResultShowDetailViewDrawer,
} from "reduxStore/slices/keyResultSlice";
import { updateKeyResultData } from "reduxStore/operations/keyResultsAPI";
import DOMPurify from "dompurify";
import DetailViewHeader from "components/common/detailView/DetailViewHeader";
import DescriptionDetailViewTab from "components/common/detailView/DescriptionDetailViewTab";
import { isEmptyDescription } from "utils/descriptionUtils";
import InitiativeBasedDetailSection from "../../objectives/typeBasedSections/InitiativeBasedDetailSection";
import {
  removeKeyResultFeatures,
  removeKeyResultIdeas,
  updateKeyResultFeatures,
  updateKeyResultIdeas,
} from "reduxStore/operations/keyResultsAPI";

const KeyResultDetailViewComponent = ({
  item,
  showBreadcrumb = false,
  onNext = () => {},
  onPrev = () => {},
}) => {
  const dispatch = useDispatch();
  const { activeKeyResultId, showDetailViewDrawer } = useSelector(
    (state) => state.keyResults
  );

  const [isQuillEditorActive, setIsQuillEditorActive] = useState(
    // isEmptyDescription(item?.description ?? "")
    false
  );
  const handleEditorClose = () => {
    setIsQuillEditorActive(false);
  };

  const handleEditorOpen = () => {
    setIsQuillEditorActive(true);
  };

  const [selectedIdeas, setSelectedIdeas] = useState(new Set());

  const handleIdeaSelect = (idea) => {
    setSelectedIdeas((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(idea._id)) {
        newSelected.delete(idea._id);
      } else {
        newSelected.add(idea._id);
      }
      return newSelected;
    });
  };

  const removeSelectedIdeas = () => {
    setSelectedIdeas(new Set());
  };

  const [selectedFeatures, setSelectedFeatures] = useState(new Set());

  const handleFeatureSelect = (feature) => {
    setSelectedFeatures((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(feature._id)) {
        newSelected.delete(feature._id);
      } else {
        newSelected.add(feature._id);
      }
      return newSelected;
    });
  };

  const removeSelectedFeatures = () => {
    setSelectedFeatures(new Set());
  };

  const handleFormSubmit = (content) => {
    // Sanitize the HTML content
    const sanitizedContent = DOMPurify.sanitize(content);
    dispatch(updateKeyResultData(item._id, { description: sanitizedContent }));
    setIsQuillEditorActive(false);
  };

  return (
    <>
      {!item ? (
        <div className="w-full h-full m-auto">Loading....</div>
      ) : (
        <div className="flex flex-col h-full pb-5">
          <div className="flex-none pt-4 px-4 pb-7 text-primary sticky top-0 bg-white z-40">
            <DetailViewHeader
              type="KeyResult"
              item={item}
              showBreadcrumb={showBreadcrumb}
              showDetailViewDrawer={showDetailViewDrawer}
              activeItem={activeKeyResultId}
              setShowDetailViewDrawer={setKeyResultShowDetailViewDrawer}
              setActiveItemId={setActiveKeyResultId}
              Icon={fieldIconMapping.keyResults}
            />
          </div>
          <div className="flex-none px-6">
            <KeyResultDetailViewTopbar
              keyResult={item}
              onNext={onNext}
              onPrev={onPrev}
              updateKeyResultData={(title) =>
                dispatch(updateKeyResultData(item._id, { title: title }))
              }
            />
          </div>
          <div className="h-full w-full px-6 pt-3 text-primary overflow-auto custom-scrollbar">
            {isQuillEditorActive ? (
              <QuillEditor
                initialContent={item.description}
                onClose={handleEditorClose}
                handleFormSubmit={handleFormSubmit}
              />
            ) : (
              <DescriptionDetailViewTab
                item={item}
                handleEditorOpen={handleEditorOpen}
              />
            )}
            <InitiativeBasedDetailSection
              item={item}
              selectedIdeas={selectedIdeas}
              handleIdeaSelect={handleIdeaSelect}
              removeSelectedIdeas={removeSelectedIdeas}
              selectedFeatures={selectedFeatures}
              handleFeatureSelect={handleFeatureSelect}
              removeSelectedFeatures={removeSelectedFeatures}
              updateFeatures={(features) => {
                dispatch(updateKeyResultFeatures(item._id, features));
              }}
              updateIdeas={(ideas) => {
                dispatch(updateKeyResultIdeas(item._id, ideas));
              }}
              removeFeatures={(features) => {
                dispatch(removeKeyResultFeatures(item._id, features));
              }}
              removeIdeas={(ideas) => {
                dispatch(removeKeyResultIdeas(item._id, ideas));
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default KeyResultDetailViewComponent;
