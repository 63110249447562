/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import IconText from "components/common/IconText";
import { fieldIconMapping } from "utils";
import {
  AngleSmallDown,
  AngleSmallUp,
  Copy,
  MenuDotsVertical,
  Trash,
} from "react-flaticons";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import DropdDownItem from "components/common/DropdownItem";
import { deleteObjective } from "reduxStore/operations/objectivesAPI";

const ObjectiveDetailViewTopbar = ({
  objective,
  onNext,
  onPrev,
  updateObjectiveData,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [opentMenuDotsDropdown, setOpenMenuDotsDropdown] = useState(false);
  const [isObjectiveNameEditable, setIsObjectiveNameEditable] = useState(false);
  const [title, setTitle] = useState(objective.title);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const saveTitle = () => {
    setIsObjectiveNameEditable(false);
    if (title !== objective.title && title.trim() !== "") {
      updateObjectiveData(title);
    } else {
      setTitle(objective.title);
    }
  };

  useEffect(() => {
    setTitle(objective.title);
  }, [objective.title]);

  const dropdownRef = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenMenuDotsDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="flex items-center gap-x-2">
        <IconText
          icon={React.createElement(fieldIconMapping.objective)}
          text="Objective"
          iconColor={"text-objectives-icon"}
          isASidebarComponent={false}
          iconSize={12}
          textSize="text-xs"
          hoverable={false}
          px=""
        />

        <div className="flex">
          <button className="bg-[#ECECEC] p-0.5" onClick={onPrev}>
            <AngleSmallUp size={14} color="#696969" />
          </button>
          <button className="bg-[#ECECEC] p-0.5" onClick={onNext}>
            <AngleSmallDown size={14} color="#696969" />
          </button>
        </div>
      </div>
      <div className="flex gap-x-5 items-center text-primary">
        <div className="w-full rounded">
          {isObjectiveNameEditable ? (
            <input
              type="text"
              value={title}
              onChange={handleTitleChange}
              onBlur={saveTitle}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  saveTitle();
                }
              }}
              className="rounded text-xl text-title_50 font-medium w-full focus:outline-none focus:border-b"
              autoFocus
            />
          ) : (
            <div
              className="border-b border-white text-xl text-title_50 font-medium cursor-text"
              onClick={() => setIsObjectiveNameEditable(true)}
            >
              {title}
            </div>
          )}
        </div>
        <div className="flex gap-x-2 items-center">
          <Copy size={12} />
          <div
            className={`relative p-1 rounded-full hover:bg-topbar-button-hover cursor-pointer ${
              opentMenuDotsDropdown && "bg-button-active_10"
            }`}
            onClick={() => {
              setOpenMenuDotsDropdown(!opentMenuDotsDropdown);
            }}
            ref={dropdownRef}
          >
            <MenuDotsVertical size={12} />
            {opentMenuDotsDropdown && (
              <div className="absolute right-0 text-sm p-1 w-24 bg-white z-50 rounded border shadow-md mt-0.5">
                <DropdDownItem
                  option={{ label: "Delete", icon: <Trash /> }}
                  textSize="text-xs"
                  handleOptionClick={() => {
                    dispatch(deleteObjective(objective._id, navigate));
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <hr className="my-2.5" />
    </>
  );
};

export default ObjectiveDetailViewTopbar;
