import { createSlice } from "@reduxjs/toolkit";
import { isObject, transformFiltersToObject } from "utils";

const initialState = {
  roadmaps: [],
  selectedColumns: ["status", "health", "tags"],
  filters: {},
  sortOption: { order: "asc", field: "created" },
  listViews: [],
  roadmapIdViews: [],
  groupByOption: "default",
  viewType: "classiclist",
  activeRoadmapId: null,
  showDetailViewDrawer: false,
  modernListExists: false,
  isLoading: false,
  isViewsLoading: false,
};

const priorityMap = {
  health: 1,
  status: 2,
  columns: 3,
  tags: 4,
  assignee: 5,
};

const roadmapsSlice = createSlice({
  name: "roadmaps",
  initialState,
  reducers: {
    setRoadmaps(state, action) {
      state.roadmaps = action.payload;
      state.isLoading = false;
    },
    updateRoadmap(state, action) {
      const { updatedRoadmap, isUpdated } = action.payload;
      const index = state.roadmaps.findIndex(
        (roadmap) => roadmap._id === updatedRoadmap._id
      );

      if (index !== -1) {
        if (isUpdated === "tags") {
          state.roadmaps[index].tags = updatedRoadmap.tags;
        } else if (isUpdated === "releases") {
          state.roadmaps[index].releases = updatedRoadmap.releases;
        } else if (isUpdated === "timeFrames") {
          state.roadmaps[index].timeFrames = updatedRoadmap.timeFrames;
        } else if (isUpdated === "products") {
          state.roadmaps[index].products = updatedRoadmap.products;
        } else if (isUpdated === "ideas") {
          state.roadmaps[index].ideas = updatedRoadmap.ideas;
        } else if (isUpdated === "title") {
          state.roadmaps[index].title = updatedRoadmap.title;
        } else {
          state.roadmaps[index] = updatedRoadmap;
        }
      }
    },
    appendRoadmap(state, action) {
      const newRoadmap = action.payload;
      // state.roadmaps.unshift(newRoadmap);
      state.roadmaps.push(newRoadmap);
    },
    removeRoadmap(state, action) {
      const deletedRoadmapId = action.payload;
      const index = state.roadmaps.findIndex(
        (roadmap) => roadmap._id === deletedRoadmapId
      );
      if (index !== -1) {
        state.roadmaps.splice(index, 1);
      }
    },
    updateRoadmaps(state, action) {
      const { updatedRoadmaps, isUpdatedArray } = action.payload;

      updatedRoadmaps.forEach((updatedRoadmap) => {
        const index = state.roadmaps.findIndex(
          (roadmap) => roadmap._id === updatedRoadmap._id
        );

        if (index !== -1) {
          if (isUpdatedArray.length > 0) {
            isUpdatedArray.forEach((updateKey) => {
              state.roadmaps[index][updateKey] = updatedRoadmap[updateKey];
            });
          } else {
            state.roadmaps[index] = updatedRoadmap;
          }
        }
      });
    },
    removeRoadmaps(state, action) {
      const { roadmapIds } = action.payload;
      roadmapIds.forEach((roadmapId) => {
        const index = state.roadmaps.findIndex(
          (roadmap) => roadmap._id === roadmapId
        );
        if (index !== -1) {
          state.roadmaps.splice(index, 1);
        }
      });
    },
    toggleRoadmapColumn(state, action) {
      const column = action.payload;

      if (state.selectedColumns.includes(column)) {
        state.selectedColumns = state.selectedColumns.filter(
          (col) => col !== column
        );
      } else {
        const columnPriority = priorityMap[column];
        let insertIndex = state.selectedColumns.length;

        for (let i = 0; i < state.selectedColumns.length; i++) {
          const currentPriority = priorityMap[state.selectedColumns[i]];
          if (columnPriority < currentPriority) {
            insertIndex = i;
            break;
          }
        }

        state.selectedColumns.splice(insertIndex, 0, column);
      }
    },
    setRoadmapFilters(state, action) {
      state.filters = action.payload;
    },
    updateRoadmapFilter(state, action) {
      const { key, value } = action.payload;

      if (!state.filters[key]) {
        state.filters[key] = [];
      }

      const existingIndex = state.filters[key].findIndex(
        (v) => JSON.stringify(v) === JSON.stringify(value)
      );

      if (existingIndex !== -1) {
        state.filters[key] = state.filters[key].filter(
          (v) => JSON.stringify(v) !== JSON.stringify(value)
        );
      } else {
        state.filters[key].push(value);
      }

      if (state.filters[key].length === 0) {
        delete state.filters[key];
      }
    },
    clearRoadmapFilters(state) {
      state.filters = {};
    },
    setRoadmapSortOption(state, action) {
      state.sortOption = action.payload;
    },
    setRoadmapActiveProperties(state, action) {
      const { filters, groupByOption, sortOption, selectedColumns } =
        action.payload;

      const finalFilters = isObject(filters)
        ? filters
        : transformFiltersToObject(filters);

      state.filters = finalFilters || state.filters;
      state.groupByOption = groupByOption || state.groupByOption;
      state.sortOption = sortOption || state.sortOption;
      state.selectedColumns = selectedColumns || state.selectedColumns;
    },
    setRoadmapViews(state, action) {
      const { views, viewTypeName } = action.payload;
      if (
        viewTypeName === "classiclist" ||
        viewTypeName === "modernlist" ||
        viewTypeName === "list"
      ) {
        state.listViews = views;
      }
      state.isViewsLoading = false;
    },
    setRoadmapIdViews(state, action) {
      state.roadmapIdViews = action.payload;
    },
    appendRoadmapView(state, action) {
      const { newlyAddedView, viewTypeName } = action.payload;
      if (
        viewTypeName === "list" ||
        viewTypeName === "modernlist" ||
        viewTypeName === "classiclist"
      ) {
        state.listViews.push(newlyAddedView);
      }
    },
    removeRoadmapView(state, action) {
      const { deletedViewId, viewTypeName } = action.payload;
      let index = -1;
      if (
        viewTypeName === "list" ||
        viewTypeName === "modernlist" ||
        viewTypeName === "classiclist"
      ) {
        index = state.listViews.findIndex((view) => view._id === deletedViewId);
        if (index !== -1) {
          state.listViews.splice(index, 1);
        }
      }
    },
    updateRoadmapView(state, action) {
      const { updatedView, viewTypeName } = action.payload;
      let index = -1;
      if (
        viewTypeName === "list" ||
        viewTypeName === "modernlist" ||
        viewTypeName === "classiclist"
      ) {
        index = state.listViews.findIndex(
          (view) => view._id === updatedView._id
        );
        if (index !== -1) {
          state.listViews[index] = updatedView;
        }
      } else {
        index = state.roadmapIdViews.findIndex(
          (view) => view._id === updatedView._id
        );
        if (index !== -1) {
          state.roadmapIdViews[index] = updatedView;
        }
      }
    },
    setRoadmapGroupByOption(state, action) {
      state.groupByOption = action.payload;
    },
    setRoadmapViewType(state, action) {
      state.viewType = action.payload;
      state.isViewsLoading = false;
    },
    setActiveRoadmapId(state, action) {
      state.activeRoadmapId = action.payload;
    },
    setRoadmapShowDetailViewDrawer(state, action) {
      state.showDetailViewDrawer = action.payload;
    },
    setIsRoadmapLoading(state, action) {
      state.isLoading = action.payload;
    },
    setIsRoadmapViewLoading(state, action) {
      state.isViewsLoading = action.payload;
    },
    updateRoadmapStatus(state, action) {
      const updatedStatus = action.payload;

      state.roadmaps?.forEach((roadmap) => {
        if (roadmap?.status?._id === updatedStatus._id) {
          roadmap.status = updatedStatus;
        }
      });
    },
    replaceRoadmapStatus(state, action) {
      const { statusId, replaceStatus } = action.payload;

      state.roadmaps?.forEach((roadmap) => {
        if (roadmap?.status?._id === statusId) {
          roadmap.status = replaceStatus;
        }
      });
    },
  },
});

export const {
  setRoadmaps,
  updateRoadmap,
  appendRoadmap,
  removeRoadmap,
  updateRoadmaps,
  removeRoadmaps,
  toggleRoadmapColumn,
  setRoadmapFilters,
  updateRoadmapFilter,
  clearRoadmapFilters,
  setRoadmapSortOption,
  setRoadmapActiveProperties,
  setRoadmapViews,
  setRoadmapIdViews,
  updateRoadmapView,
  removeRoadmapView,
  appendRoadmapView,
  setRoadmapGroupByOption,
  setRoadmapViewType,
  setActiveRoadmapId,
  setRoadmapShowDetailViewDrawer,
  setIsRoadmapLoading,
  setIsRoadmapViewLoading,
  updateRoadmapStatus,
  replaceRoadmapStatus,
} = roadmapsSlice.actions;

export const roadmapsReducer = roadmapsSlice.reducer;
