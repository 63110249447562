/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from "react";
import IconText from "components/common/IconText";
import { fieldIconMapping, rgbColor } from "utils";
import DropdownMenu from "../dropdown/DropdownMenu";
import { useNavigate } from "react-router-dom";
import IconWrapper from "../../IconWrapper";
import SimpleCheckboxItem from "components/common/checkboxItem/SimpleCheckboxItem";

const ProductSection = ({
  item,
  products,
  handleProductOptionClick,
  with_title = true,
  position = "gap-x-6",
  with_multi_select = false,
  selectedProducts = new Set(),
}) => {
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef();

  const handleLocalProductOptionClick = (option) => {
    handleProductOptionClick(option);
    setIsDropdownOpen(false);
  };

  const toggleDropdown = (e) => {
    e.stopPropagation();
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={`flex ${position} items-center text-title_50`}>
      {with_title && (
        <IconText
          icon={React.createElement(fieldIconMapping.product)}
          text="Product"
          iconColor="#696969"
          iconSize={16}
          hoverable={false}
          gap_x="gap-x-2"
        />
      )}
      {products && (
        <div className="relative cursor-pointer" ref={dropdownRef}>
          <div
            className={`p-1 w-fit rounded hover:bg-button-hover ${
              isDropdownOpen && "bg-button-active_10"
            }`}
            onClick={toggleDropdown}
          >
            <div className={item?.color ? "rounded w-fit bg-white" : ""}>
              <div
                className={`flex gap-x-2 items-center p-1 rounded text-xs`}
                style={{
                  backgroundColor: item?.color ? rgbColor(item.color, 0.2) : "",
                }}
              >
                {item?.icon && (
                  <IconWrapper
                    icon={item.icon}
                    size={14}
                    iconColor={rgbColor(item.color)}
                  />
                )}
                {item?.label ?? "No Product"}{" "}
                {with_multi_select &&
                  selectedProducts.size > 1 &&
                  selectedProducts.size !== products.length && (
                    <div className="rounded-full p-0.5 bg-button-active_10 text-center">
                      {selectedProducts.size}
                    </div>
                  )}
              </div>
            </div>
          </div>
          {isDropdownOpen &&
            (!with_multi_select ? (
              <DropdownMenu
                options={products ?? []}
                isOpen={isDropdownOpen}
                position="top-full w-28"
                iconSize={12}
                handleOptionClick={handleLocalProductOptionClick}
                withAddOption={true}
                handleAddOptionClick={() =>
                  navigate("/settings/customizations/product")
                }
              />
            ) : (
              <div className="absolute top-full left-0 bg-white rounded z-10 shadow border mt-0.5 p-2">
                {products
                  .filter((product) => selectedProducts.has(product._id))
                  .map((product) => (
                    <div key={product._id}>
                      <SimpleCheckboxItem
                        option={{ ...product, iconColor: product.color }}
                        handleOptionClick={() =>
                          handleProductOptionClick(product._id)
                        }
                        iconSize="12"
                        selected={selectedProducts.has(product._id)}
                      />
                    </div>
                  ))}
                {products
                  .filter((product) => !selectedProducts.has(product._id))
                  .map((product) => (
                    <div key={product._id}>
                      <SimpleCheckboxItem
                        option={{ ...product, iconColor: product.color }}
                        handleOptionClick={() =>
                          handleProductOptionClick(product._id)
                        }
                        iconSize="12"
                        selected={selectedProducts.has(product._id)}
                      />
                    </div>
                  ))}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default ProductSection;
