import React, { useEffect, useState } from "react";
import Sidebar from "components/core/Sidebar";
import IdeaDetailViewComponent from "components/ideas/detailView/IdeaDetailViewComponent";
import { useParams, useNavigate } from "react-router-dom";
import IdeaDetailViewRightSidebar from "components/ideas/detailView/IdeaDetailViewRightSidebar";
import Loader from "components/common/Loader";
import { useDispatch, useSelector } from "react-redux";
import { getAllIdeas } from "reduxStore/operations/ideasAPI";
import { isObjectEmpty } from "utils";

const IdeasDetailView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { ideaId } = useParams();

  const { ideas } = useSelector((state) => state.ideas);
  const [allIdeas, setAllIdeas] = useState(null);
  const [idea, setIdea] = useState(null);
  const [ideaIndex, setIdeaIndex] = useState(null);

  useEffect(() => {
    if (isObjectEmpty(ideas)) {
      dispatch(getAllIdeas());
    } else {
      setAllIdeas(ideas);
    }
  }, [ideas]);

  useEffect(() => {
    if (allIdeas !== null) {
      const tempIdea = allIdeas.find((item) => item._id === ideaId);
      const tempIndex = allIdeas.findIndex((item) => item._id === ideaId);
      setIdea(tempIdea);
      setIdeaIndex(tempIndex);
    }
  }, [allIdeas, ideaId]);

  const handleNextIdea = () => {
    if (ideaIndex !== null) {
      const nextIdea = allIdeas[(ideaIndex + 1) % allIdeas.length];
      navigate(`/ideas/${nextIdea._id}`);
    }
  };

  const handlePrevIdea = () => {
    if (ideaIndex !== null) {
      const prevIndex = (ideaIndex - 1 + allIdeas.length) % allIdeas.length;
      const prevIdea = allIdeas[prevIndex];
      navigate(`/ideas/${prevIdea._id}`);
    }
  };

  return (
    <div className="flex font-inter w-screen h-screen tracking-wide">
      <Sidebar />
      {!idea ? (
        <div className="absolute inset-0 flex items-center justify-center">
          <Loader />
        </div>
      ) : (
        <>
          <div className="flex flex-col border-r-2 w-2/3">
            <IdeaDetailViewComponent
              item={idea}
              showBreadcrumb={true}
              onNext={handleNextIdea}
              onPrev={handlePrevIdea}
            />
          </div>
          <div className="flex w-[320px]">
            <IdeaDetailViewRightSidebar item={idea} />
          </div>
        </>
      )}
    </div>
  );
};

export default IdeasDetailView;
