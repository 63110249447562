import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/common/Loader";
import IconWrapper from "components/common/IconWrapper";
import EditInputForm from "./EditInputForm";
import ColorDropdown from "./ColorDropdown";
import { fieldIconMapping } from "utils";
import AddInputForm from "./AddInputForm";
import {
  addTagToCompany,
  deleteTagOfCompany,
  editTagInCompany,
} from "reduxStore/operations/tagsAPI";
import CustomizationsLabel from "../../common/CustomizationsLabel";

const TagsField = () => {
  const addRef = useRef(null);
  const editRefs = useRef({});

  const dispatch = useDispatch();

  const { tags } = useSelector((state) => state.company);

  const [activeEditTagId, setActiveEditTagId] = useState(null);
  const [editLabel, setEditLabel] = useState("");
  const [orignalEditTagLabel, setOrignalEditTagLabel] = useState("");
  const [isAddInputOpen, setIsAddInputOpen] = useState(false);
  const [addNewTagLabel, setAddNewTagLabel] = useState("");

  const resetEditOptions = () => {
    setActiveEditTagId(null);
    setEditLabel("");
    setOrignalEditTagLabel("");
  };

  const resetAddOptions = () => {
    setAddNewTagLabel("");
    setIsAddInputOpen(false);
  };

  const addButtonClick = () => {
    setAddNewTagLabel("");
    setIsAddInputOpen(true);
  };

  const editButtonClick = (id,label) => {
    setActiveEditTagId(id);
    setEditLabel(label);
    setOrignalEditTagLabel(label);
  };

  const handleAddTag = () => {
    if (addNewTagLabel.trim() !== "") {
      dispatch(addTagToCompany(addNewTagLabel.trim()));
    }
    resetAddOptions();
  };

  const handleEditTag = () => {
    if (editLabel.trim() === "") {
      return;
    } else if (
      orignalEditTagLabel.trim().toLowerCase() ===
      editLabel.trim().toLowerCase()
    ) {
      resetEditOptions();
    } else {
      dispatch(editTagInCompany(activeEditTagId, editLabel.trim()));
      resetEditOptions();
    }
  };

  const handleDeleteTag = (id) => {
    dispatch(deleteTagOfCompany(id||activeEditTagId));
    resetEditOptions();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isAddInputOpen &&
        addRef.current &&
        !addRef.current.contains(event.target)
      ) {
        handleAddTag();
        resetEditOptions();
      } else if (
        activeEditTagId &&
        editRefs.current[activeEditTagId] &&
        !editRefs.current[activeEditTagId].contains(event.target)
      ) {
        handleEditTag();
        resetAddOptions();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isAddInputOpen, addNewTagLabel, activeEditTagId, editLabel]);

  return (
    <div className="text-sm text-title_10 cursor-default">
      {!tags ? (
        <div className="flex items-center justify-center h-[600px]">
          <Loader />
        </div>
      ) : (
        <div>
          <div className="mb-5 font-semibold">Tags</div>
          <div className="flex flex-col gap-y-2">
            {tags.length > 0 ? (
              tags.map((tag) => (
                <div
                  key={tag._id}
                  className={`flex items-center text-primary text-sm gap-x-3 w-fit`}
                  ref={(el) => (editRefs.current[tag._id] = el)}
                >
                  <ColorDropdown
                    icon={React.createElement(fieldIconMapping.tags)}
                    iconColor={"#696969"}
                    openDropdownCondition={false}
                    clickable={false}
                  />
                  {activeEditTagId === tag._id ? (
                    <EditInputForm
                      inputLabel={editLabel}
                      setInputLabel={(value) => setEditLabel(value)}
                      onEditInputEnter={handleEditTag}
                      inputFocusCondition={true}
                      onInputButtonClick={null}
                      handleRemoveOptionClick={handleDeleteTag}
                    />
                  ) : (
                    // <div
                    //   className={`p-2 border rounded w-48 cursor-text`}
                    //   onClick={() => editButtonClick(tag)}
                    // >
                    //   {tag.label}
                     
                    // </div>
                     <CustomizationsLabel
                     label={tag.label}
                     onClick={editButtonClick}
                     handleDeleteItem={handleDeleteTag}
                     id={ tag._id}
                     showDelete={true}
                   />
                  )}
                </div>
              ))
            ) : (
              <div className="flex items-center gap-x-3">
                <div>
                  <IconWrapper
                    icon={React.createElement(fieldIconMapping.tags)}
                    size="14"
                  />
                </div>
                <div className="p-2 border rounded w-48 cursor-not-allowed bg-row-background">
                  No Tags
                </div>
              </div>
            )}
            {isAddInputOpen ? (
              <div className="flex items-center gap-x-3 w-fit" ref={addRef}>
                <AddInputForm
                  icon={React.createElement(fieldIconMapping.tags)}
                  iconColor={"#696969"}
                  iconClickable={false}
                  onIconClick={null}
                  openIconDropdownCondition={false}
                  inputLabel={addNewTagLabel}
                  setInputLabel={setAddNewTagLabel}
                  onInputEnter={handleAddTag}
                  handleCloseOptionClick={resetAddOptions}
                />
              </div>
            ) : (
              <div
                className="text-primary cursor-pointer hover:bg-button-hover p-1 w-fit rounded"
                onClick={addButtonClick}
              >
                + Add
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default TagsField;
