/* eslint-disable react/prop-types */
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useMemo, useState } from "react";
import { generateStatusArray } from "utils/status";
import Loader from "components/common/Loader";
import { DragDropContext } from "@hello-pangea/dnd";
import IdeaKanbanColumn from "components/ideas/views/kanban/IdeaKanbanColumn";
import { fieldIconMapping } from "utils";
import { updateIdeaData } from "reduxStore/operations/ideasAPI";
import { applyFilters } from "utils/filtering";
import useFilterLogic from "hooks/useFilterLogic";
import AddItemSidebarDialog from "components/common/roadmapKanbanDialog/AddItemSidebarDialog";
import AddItemSidebarContent from "components/common/roadmapKanbanDialog/AddItemSidebarContent";
import { updateRoadmapIdeas } from "reduxStore/operations/roadmapsAPI";
import { removeRoadmapIdeas } from "../../../../reduxStore/operations/roadmapsAPI";

const IdeaRoadmapsKanbanView = ({
  groupedData,
  roadmap,
  isRoadmapDetailColumn = false,
  filterOptions,
}) => {
  const dispatch = useDispatch();
  const { ideas } = useSelector((state) => state.ideas);
  const [selectedIdeas, setSelectedIdeas] = useState(new Set());
  const { groupByOption } = useSelector((state) => state.ideas);
  const { statuses } = useSelector((state) => state.company);
  const allFilterOptions = [...filterOptions];
  const [activeAddDropdown, setActiveAddDropdown] = useState(null);
  const [ideaStatuses, setIdeaStatuses] = useState([]);
  const [localGroupByOption, setLocalGroupByOption] = useState(null);
  const [filters, setFilters] = useState({});

  const updateIdeaFilter = ({ key, value }) => {
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters };

      if (!newFilters[key]) {
        newFilters[key] = [value];
      } else if (newFilters[key].includes(value)) {
        newFilters[key] = newFilters[key].filter((v) => v !== value);
      } else {
        newFilters[key] = [...newFilters[key], value];
      }

      if (newFilters[key].length === 0) {
        delete newFilters[key];
      }

      return newFilters;
    });
  };

  const topbarFunctions = {
    updateFilter: (filter) => updateIdeaFilter(filter),
    clearFilters: () => setFilters({}),
  };

  const {
    selectedFilterOption,
    subFilterDropdownOptions,
    setSelectedFilterOption,
    setSubFilterDropdownOptions,
    handleFilterOptionClick,
    handleSubFilterOptionClick,
    handleResetFilterClick,
    handleRemoveFilter,
  } = useFilterLogic(topbarFunctions);

  const resetFilterRelatedOptions = () => {
    setSelectedFilterOption(null);
    setSubFilterDropdownOptions(null);
  };

  useEffect(() => {
    setIdeaStatuses(statuses.idea);
  }, [statuses]);

  const statusArray = useMemo(
    () => generateStatusArray(ideaStatuses),
    [ideaStatuses]
  );

  useEffect(() => {
    setLocalGroupByOption(groupByOption);
  }, [groupByOption]);

  const allIdeas = (ideas ?? []).filter(
    (idea) =>
      !roadmap?.ideas?.some(
        (roadmapIdea) =>
          // roadmapIdea?.timeFrame?.label === activeAddDropdownLabel &&
          roadmapIdea?.idea === idea._id
      )
  );

  const filteredIdeas = applyFilters(allIdeas, filters);

  const handleIdeaSelect = (idea) => {
    setSelectedIdeas((prevSelected) => {
      const newSelected = new Set(prevSelected);
      newSelected.has(idea._id)
        ? newSelected.delete(idea._id)
        : newSelected.add(idea._id);
      return newSelected;
    });
  };

  const removeSelectedIdeas = () => {
    setSelectedIdeas(new Set());
  };

  const handleRemoveIdea = (e, idea) => {
    e.stopPropagation();
  
    // Find the timeFrameId associated with the given idea
    const timeFrameId = roadmap.ideas.find(
      (timeFrame) => timeFrame.idea === idea._id
    )?.timeFrame;
  
    // If a timeFrameId is found, dispatch the action to remove the idea
    if (timeFrameId) {
      // Pass the timeFrameId and idea._id as an array (even if just one idea is removed)
      dispatch(removeRoadmapIdeas(roadmap._id, timeFrameId, [idea._id]));
    }
  };
  

  const updateIdeas = (selectedIdeas, toTimeFrameId) => {
    const updateToTimeFrameId = toTimeFrameId ?? activeAddDropdown._id;
    dispatch(
      updateRoadmapIdeas(
        roadmap._id,
        updateToTimeFrameId,
        Array.from(selectedIdeas)
      )
    );
    removeSelectedIdeas();
  };

  const handleOnDragEnd = (result) => {
    const { source, destination, draggableId } = result;
    if (!destination || source.droppableId === destination.droppableId) return;
    if (groupByOption === "default" && roadmap !== null) {
      const updateToTimeFrameId = destination.droppableId;
      updateIdeas([draggableId], updateToTimeFrameId);
    }

    if (groupByOption === "product") {
      const updateToProductId = destination.droppableId;
      dispatch(updateIdeaData(draggableId, { product: updateToProductId }));
    }
  };

  return (
    <div
      className={`h-full overflow-x-auto ${
        isRoadmapDetailColumn && "bg-gray-50"
      }`}
    >
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <div className="flex gap-x-5 p-4">
          {localGroupByOption === null ||
          localGroupByOption != groupByOption ? (
            <div className="flex items-center justify-center h-[600px] w-full">
              <Loader />
            </div>
          ) : (
            Object.entries(groupedData)?.map(([groupId, groupData]) => (
              <IdeaKanbanColumn
                key={groupId}
                groupByOption={groupByOption}
                group={{
                  _id: groupId,
                  label: groupData.label,
                  color: groupData.color,
                }}
                itemsForGroup={groupData.items}
                statusArray={statusArray}
                isRoadmapDetailColumn={isRoadmapDetailColumn}
                roadmapTypeIcon={React.createElement(
                  fieldIconMapping.timeFrames
                )}
                handleRemoveIdea={handleRemoveIdea}
                AddItemsSidebar={
                  <AddItemSidebarDialog
                    isOpen={activeAddDropdown?._id === groupId}
                    setIsOpen={setActiveAddDropdown}
                    sidebarComponent={
                      <AddItemSidebarContent
                        items={filteredIdeas}
                        filterOptions={allFilterOptions}
                        filters={filters}
                        selectedFilterOption={selectedFilterOption}
                        subFilterDropdownOptions={subFilterDropdownOptions}
                        setSelectedFilterOption={setSelectedFilterOption}
                        setSubFilterDropdownOptions={
                          setSubFilterDropdownOptions
                        }
                        statusArray={statusArray}
                        handleFilterOptionClick={handleFilterOptionClick}
                        handleSubFilterOptionClick={handleSubFilterOptionClick}
                        handleResetFilterClick={handleResetFilterClick}
                        handleRemoveFilter={handleRemoveFilter}
                        handleItemSelect={handleIdeaSelect}
                        selectedItems={selectedIdeas}
                        removeSelectedItems={removeSelectedIdeas}
                        updateItems={(ideas) => updateIdeas(ideas)}
                        activeAddDropdownLabel={activeAddDropdown?.label}
                        resetFilterRelatedOptions={resetFilterRelatedOptions}
                        iconColor="text-ideas-icon"
                        icon={fieldIconMapping.idea}
                        placeholder="Search Ideas..."
                        type="idea"
                      />
                    }
                    handleResetFilters={handleResetFilterClick}
                  />
                }
                activeAddDropdown={activeAddDropdown}
                setActiveAddDropdown={setActiveAddDropdown}
              />
            ))
          )}
        </div>
      </DragDropContext>
    </div>
  );
};

export default IdeaRoadmapsKanbanView;
