/* eslint-disable react/prop-types */
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useMemo, useState } from "react";
import { generateStatusArray } from "utils/status";
import { fieldIconMapping } from "utils";
import Loader from "components/common/Loader";
import { DragDropContext } from "@hello-pangea/dnd";
import ObjectiveKanbanColumn from "components/objectives/views/kanban/ObjectiveKanbanColumn";
import { updateObjectiveData } from "reduxStore/operations/objectivesAPI";
import AddItemSidebarDialog from "components/common/roadmapKanbanDialog/AddItemSidebarDialog";
import AddItemSidebarContent from "components/common/roadmapKanbanDialog/AddItemSidebarContent";
import { applyFilters } from "utils/filtering";
import useFilterLogic from "hooks/useFilterLogic";

const ObjectiveRoadmapsKanbanView = ({
  groupedData,
  roadmap,
  isRoadmapDetailColumn = false,
  filterOptions,
}) => {
  const dispatch = useDispatch();
  const { pathname } = window.location;
  const { objectives } = useSelector((state) => state.objectives);
  const [selectedObjectives, setSelectedObjectives] = useState(new Set());
  const { groupByOption } = useSelector((state) => state.objectives);
  const { statuses } = useSelector((state) => state.company);
  const allFilterOptions = [...filterOptions];
  const [activeAddDropdown, setActiveAddDropdown] = useState("");
  const [objectiveStatuses, setObjectiveStatuses] = useState([]);
  const [localGroupByOption, setLocalGroupByOption] = useState(null);
  const [filters, setFilters] = useState({});

  const updateObjectiveFilter = ({ key, value }) => {
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters };

      if (!newFilters[key]) {
        newFilters[key] = [value];
      } else if (newFilters[key].includes(value)) {
        newFilters[key] = newFilters[key].filter((v) => v !== value);
      } else {
        newFilters[key] = [...newFilters[key], value];
      }

      if (newFilters[key].length === 0) {
        delete newFilters[key];
      }

      return newFilters;
    });
  };

  const topbarFunctions = {
    updateFilter: (filter) => updateObjectiveFilter(filter),
    clearFilters: () => setFilters({}),
  };

  const {
    selectedFilterOption,
    subFilterDropdownOptions,
    setSelectedFilterOption,
    setSubFilterDropdownOptions,
    handleFilterOptionClick,
    handleSubFilterOptionClick,
    handleResetFilterClick,
    handleRemoveFilter,
  } = useFilterLogic(topbarFunctions);

  const resetFilterRelatedOptions = () => {
    setSelectedFilterOption(null);
    setSubFilterDropdownOptions(null);
  };

  useEffect(() => {
    setObjectiveStatuses(statuses.objective);
  }, [statuses]);

  const statusArray = useMemo(
    () => generateStatusArray(objectiveStatuses),
    [objectiveStatuses]
  );

  useEffect(() => {
    setLocalGroupByOption(groupByOption);
  }, [groupByOption]);

  const allObjectives = (objectives ?? []).filter(
    (objective) =>
      // (objective?.timeFrame?.label ?? "") !== activeAddDropdown &&
      !roadmap?.timeFrames?.some(
        (timeFrame) => timeFrame._id === objective?.timeFrame?._id
      )
  );
  const filteredObjectives = applyFilters(allObjectives, filters);

  const handleObjectiveSelect = (objective) => {
    setSelectedObjectives((prevSelected) => {
      const newSelected = new Set(prevSelected);
      newSelected.has(objective._id)
        ? newSelected.delete(objective._id)
        : newSelected.add(objective._id);
      return newSelected;
    });
  };

  const removeSelectedObjectives = () => {
    setSelectedObjectives(new Set());
  };

  const updateObjectives = (selectedObjectives) => {
    //   var updateToTimeFrameId;
    //   if (pathname.includes("roadmaps")) {
    //     updateToTimeFrameId = (item) => item.label === activeAddDropdown._id;
    //   } else {
    //   const  updateToTimeFrameId = activeAddDropdown._id;
    // }
    const updateToTimeFrameId = activeAddDropdown._id;
    (selectedObjectives ?? []).map((objective) => {
      console.log(objective);

      dispatch(
        updateObjectiveData(
          objective,
          { timeFrame: updateToTimeFrameId },
          "timeFrame"
        )
      );
    });
    removeSelectedObjectives();
  };

  const handleOnDragEnd = (result) => {
    const { source, destination, draggableId } = result;
    if (!destination || source.droppableId === destination.droppableId) return;

    if (!roadmap && groupByOption === "default") {
      const updateToStatusId = destination.droppableId;
      dispatch(
        updateObjectiveData(draggableId, { status: updateToStatusId }, "status")
      );
    }

    if (
      (roadmap && groupByOption === "default") ||
      groupByOption === "time_frame"
    ) {
      const updateToTimeFrameId = destination.droppableId;
      dispatch(
        updateObjectiveData(
          draggableId,
          { timeFrame: updateToTimeFrameId },
          "timeFrame"
        )
      );
      // dispatch(updateObjectiveData(draggableId, AllDetailView));
    }
  };

  return (
    <div
      className={`h-full overflow-x-auto ${roadmap !== null && "bg-gray-50"}`}
    >
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <div className="flex gap-x-5 p-4">
          {localGroupByOption === null ||
          localGroupByOption !== groupByOption ? (
            <div className="flex items-center justify-center h-[600px] w-full">
              <Loader />
            </div>
          ) : (
            Object.entries(groupedData)?.map(([groupId, groupData]) => (
              <ObjectiveKanbanColumn
                key={groupId}
                groupByOption={groupByOption}
                group={{
                  _id: groupId,
                  label: groupData.label,
                  color: groupData.color,
                }}
                itemsForGroup={groupData.items}
                statusArray={statusArray}
                isRoadmapDetailColumn={isRoadmapDetailColumn}
                roadmapTypeIcon={React.createElement(
                  fieldIconMapping.timeFrames
                )}
                AddItemsSidebar={
                  <AddItemSidebarDialog
                    isOpen={activeAddDropdown?._id === groupId}
                    setIsOpen={setActiveAddDropdown}
                    sidebarComponent={
                      <AddItemSidebarContent
                        items={filteredObjectives}
                        filterOptions={allFilterOptions}
                        filters={filters}
                        selectedFilterOption={selectedFilterOption}
                        subFilterDropdownOptions={subFilterDropdownOptions}
                        setSelectedFilterOption={setSelectedFilterOption}
                        setSubFilterDropdownOptions={
                          setSubFilterDropdownOptions
                        }
                        handleFilterOptionClick={handleFilterOptionClick}
                        handleSubFilterOptionClick={handleSubFilterOptionClick}
                        handleResetFilterClick={handleResetFilterClick}
                        handleRemoveFilter={handleRemoveFilter}
                        handleItemSelect={handleObjectiveSelect}
                        selectedItems={selectedObjectives}
                        removeSelectedItems={removeSelectedObjectives}
                        updateItems={(objectives) =>
                          updateObjectives(objectives)
                        }
                        activeAddDropdownLabel={activeAddDropdown?.label}
                        resetFilterRelatedOptions={resetFilterRelatedOptions}
                        iconColor="text-objectives-icon"
                        icon={fieldIconMapping.objective}
                        placeholder="Search Objectives..."
                        statusArray={statusArray}
                      />
                    }
                    handleResetFilters={handleResetFilterClick}
                  />
                }
                activeAddDropdown={activeAddDropdown}
                setActiveAddDropdown={setActiveAddDropdown}
              />
            ))
          )}
        </div>
      </DragDropContext>
    </div>
  );
};

export default ObjectiveRoadmapsKanbanView;
