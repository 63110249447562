/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import Searchbar from "../Searchbar";
import IconText from "../IconText";
import { BsFilter } from "react-icons/bs";
import FilterOptionsDropdown from "../../core/topbar/dropdowns/FilterOptionsDropdown";
import CheckboxDropdownItem from "../CheckboxDropdownItem";
import IconWrapper from "../IconWrapper";
import { getStatusIcon } from "utils/status";
import { rgbColor } from "utils";

const AddItemSidebarContent = ({
  items,
  filterOptions,
  filters,
  selectedFilterOption,
  subFilterDropdownOptions,
  handleFilterOptionClick,
  handleSubFilterOptionClick,
  handleResetFilterClick,
  handleRemoveFilter,
  handleItemSelect,
  selectedItems,
  removeSelectedItems,
  updateItems,
  activeAddDropdownLabel,
  resetFilterRelatedOptions,
  iconColor,
  icon,
  placeholder,
  type,
  statusArray,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const dropdownRef = useRef(null);
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  const filteredItems = items.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.trim().toLowerCase())
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowFilterDropdown(false);
        resetFilterRelatedOptions();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="flex flex-col p-6 w-full h-[96%]">
      <div className="flex items-center gap-x-2 w-full">
        <Searchbar
          value={searchTerm}
          onChange={(event) => setSearchTerm(event.target.value)}
          placeholder={placeholder}
        />
        <div className="relative">
          <div
            className={showFilterDropdown && "bg-button-active_10 rounded"}
            ref={dropdownRef}
          >
            <IconText
              icon={<BsFilter />}
              text={"Filter"}
              iconSize={18}
              gap_x="gap-x-1.5"
              textColor={showFilterDropdown ? "text-title_10" : "text-primary"}
              iconColor={showFilterDropdown ? "text-title_10" : "text-primary"}
              hoverColor="hover:bg-topbar-button-hover"
              onClick={() => {
                setShowFilterDropdown(!showFilterDropdown);
                resetFilterRelatedOptions();
              }}
            />
            {showFilterDropdown && (
              <FilterOptionsDropdown
                filterOptions={filterOptions}
                selectedFilterOption={selectedFilterOption}
                subFilterDropdownOptions={subFilterDropdownOptions}
                handleFilterOptionClick={handleFilterOptionClick}
                handleSubFilterOptionClick={handleSubFilterOptionClick}
                filters={filters}
                handleResetFilters={handleResetFilterClick}
                handleRemoveFilter={handleRemoveFilter}
                position="right-0 top-full -mr-1"
              />
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-y-2 mt-4 overflow-auto custom-scrollbar w-full h-full">
        {filteredItems
          .filter((item) => selectedItems.has(item._id))
          .map((item) => (
            <div
              key={item._id}
              className="flex items-center justify-between p-1 hover:bg-topbar-button-hover rounded cursor-pointer"
              onClick={() => handleItemSelect(item)}
            >
              <div className="w-5/6">
                <CheckboxDropdownItem
                  option={{
                    label: item.title,
                    icon: icon,
                    iconColor: iconColor,
                    withBackgroundColor: false,
                  }}
                  selected={true}
                  handleOptionClick={() => handleItemSelect(item)}
                />
              </div>
              <div className="pr-4 text-primary text-xs">
                {type === "feature" ? (
                  item?.release?.label ?? ""
                ) : (
                  <div className="bg-white rounded">
                    <div
                      className="p-0.5 rounded"
                      style={{
                        backgroundColor: rgbColor(
                          item?.status?.color ?? "",
                          0.2
                        ),
                      }}
                    >
                      <IconWrapper
                        icon={getStatusIcon(
                          item?.status?.label ?? "",
                          statusArray
                        )}
                        iconColor={item?.status?.color ?? ""}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        {filteredItems
          .filter((item) => !selectedItems.has(item._id))
          .map((item) => (
            <div
              key={item._id}
              className="flex items-center justify-between p-1 hover:bg-topbar-button-hover rounded cursor-pointer"
              onClick={() => handleItemSelect(item)}
            >
              <div className="w-5/6">
                <CheckboxDropdownItem
                  option={{
                    label: item.title,
                    icon: icon,
                    iconColor: iconColor,
                    withBackgroundColor: false,
                  }}
                  selected={false}
                  handleOptionClick={() => handleItemSelect(item)}
                />
              </div>
              <div className="pr-4 text-primary text-xs">
                {type === "feature" ? (
                  item?.release?.label ?? ""
                ) : (
                  <div className="bg-white rounded">
                    <div
                      className="p-0.5 rounded"
                      style={{
                        backgroundColor: rgbColor(
                          item?.status?.color ?? "",
                          0.2
                        ),
                      }}
                    >
                      <IconWrapper
                        icon={getStatusIcon(
                          item?.status?.label ?? "",
                          statusArray
                        )}
                        iconColor={item?.status?.color ?? ""}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
      </div>
      <div className="flex items-center gap-x-4 w-fit mr-auto mt-4">
        <div
          className="p-1.5 bg-button-active text-white rounded text-sm cursor-pointer shadow hover:bg-[#0047AB]"
          onClick={() => updateItems(Array.from(selectedItems))}
        >
          Add to {activeAddDropdownLabel}
        </div>
        <div
          className="p-1.5 rounded bg-white border shadow text-title text-sm cursor-pointer hover:bg-button-hover"
          onClick={removeSelectedItems}
        >
          Cancel
        </div>
      </div>
    </div>
  );
};

export default AddItemSidebarContent;

