import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  jira: false,
  jiraProjects: [],
};

const integrationsSlice = createSlice({
  name: "integrations",
  initialState,
  reducers: {
    updateIntegrationStatus(state, action) {
      state[action.payload.name] = action.payload.enabled;
    },
    updateJiraProjects(state, action) {
      state.jiraProjects = action.payload;
    },
  },
});

export const { updateIntegrationStatus, updateJiraProjects } =
  integrationsSlice.actions;
export const integrationsReducer = integrationsSlice.reducer;
