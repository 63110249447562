import {
  setEmail,
  setToken,
  setUserId,
  setCompanyId,
  setLoading,
} from "../slices/authSlice";
import { setFeatures, setFeatureViews } from "../slices/featureSlice";
import { setIdeas, setIdeaViews } from "../slices/ideaSlice";
import { setObjectives, setObjectiveViews } from "../slices/objectiveSlice";
import { setUser } from "../slices/userSlice";
import { toast } from "react-toastify";
import { setCookie, deleteCookie } from "utils/cookieUtils";
import { apiConnector } from "api/apiconnector";
import { authEndPoints } from "api/api";
import { setRoadmaps, setRoadmapViews } from "../slices/roadmapsSlice";
import { clearStatuses, setStatuses } from "../slices/companySlice";
const {
  SIGNIN,
  SIGNUP,
  LOGOUT,
  REQUEST_PASSWORD_RESET,
  VERIFY_OTP,
  RESET_PASSWORD,
  COMPANY_REGISTER,
} = authEndPoints;

// export const signUp = (name, email, password, companyName, navigate) => {
//   return async (dispatch) => {
//     try {
//       const response = await apiConnector(`POST`, SIGNUP, {
//         name,
//         email,
//         password,
//         companyName,
//       });

//       if (response.status === 201) {
//         setCookie("jwt", response.data.token);
//         localStorage.setItem("jwt", response.data.token);
//         localStorage.setItem("user", JSON.stringify(response.data.user));
//         dispatch(setLoading(false));
//         dispatch(setToken(response.data.token));
//         dispatch(setEmail(response.data.user.email));
//         dispatch(setUserId(response.data.user._id));
//         dispatch(setCompanyId(response.data.user.company));
//         navigate("/");
//       }
//     } catch (error) {
//       dispatch(setLoading(false));
//       if (
//         error.response &&
//         error.response.data &&
//         error.response.data.message
//       ) {
//         // Server returned a specific error message
//         toast.error(error.response.data.message);
//       } else {
//         // Fallback for unknown errors
//         toast.error("An error occurred. Please try again.");
//       }
//     }
//   };
// };

export const signUp = (email, password, setStep) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector(`POST`, SIGNUP, {
        email,
        password,
      });

      //  setCookie("jwt", response.data.token);
      //  localStorage.setItem("jwt", response.data.token);
      //  localStorage.setItem("user", JSON.stringify(response.data.user));
      //   dispatch(setLoading(false));
      //  // dispatch(setToken(response.data.token));
      //   dispatch(setEmail(response.data.user.email));
      //   dispatch(setUserId(response.data.user._id));
      //   dispatch(setCompanyId(response.data.user.company));
      setStep(2);
    } catch (error) {
      dispatch(setLoading(false));
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        // Server returned a specific error message
        toast.error(error.response.data.message);
      } else {
        // Fallback for unknown errors
        toast.error("An error occurred. Please try again.");
      }
    }
  };
};

export const companyRegister = (name, companyName, token, navigate) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector(`POST`, COMPANY_REGISTER, {
        name,
        companyName,
        token,
      });
      
      setCookie("jwt", response.data.token);
      localStorage.setItem("jwt", response.data.token);
      localStorage.setItem("user", JSON.stringify(response.data.user));
      dispatch(setLoading(false));
      dispatch(setToken(response.data.token));
      dispatch(setEmail(response.data.user.email));
      dispatch(setUserId(response.data.user._id));
      dispatch(setCompanyId(response.data.user.company));
      navigate("/");
    } catch (error) {
      dispatch(setLoading(false));
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        // Server returned a specific error message
        toast.error(error.response.data.message);
      } else {
        // Fallback for unknown errors
        toast.error("An error occurred. Please try again.");
      }
    }
  };
};

export const signin = (email, password, navigate) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector(`POST`, SIGNIN, {
        email,
        password,
      });

      if (response.status === 200) {
        setCookie("jwt", response.data.token);
        localStorage.setItem("jwt", response.data.token);
        localStorage.setItem("user", JSON.stringify(response.data.user));
        dispatch(setLoading(false));
        dispatch(setToken(response.data.token));
        dispatch(setEmail(response.data.user.email));
        dispatch(setUserId(response.data.user._id));
        dispatch(setCompanyId(response.data.user.company));
        navigate("/");
      }
    } catch (error) {
      dispatch(setLoading(false));
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        // Server returned a specific error message
        toast.error(error.response.data.message);
      } else {
        // Fallback for unknown errors
        toast.error("An error occurred. Please try again.");
      }
    }
  };
};

export const logout = (navigate) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector(`POST`, LOGOUT);

      if (response.status === 200) {
        deleteCookie("jwt");
        localStorage.removeItem("jwt");
        localStorage.removeItem("user");
        dispatch(setFeatures([]));
        dispatch(setIdeas([]));
        dispatch(setObjectives([]));
        dispatch(setRoadmaps([]));
        dispatch(clearStatuses());
        dispatch(setFeatureViews({ views: [], viewTypeName: "list" }));
        dispatch(setFeatureViews({ views: [], viewTypeName: "kanban" }));
        dispatch(setObjectiveViews({ views: [], viewTypeName: "list" }));
        dispatch(setObjectiveViews({ views: [], viewTypeName: "kanban" }));
        dispatch(setIdeaViews({ views: [], viewTypeName: "list" }));
        dispatch(setIdeaViews({ views: [], viewTypeName: "kanban" }));
        dispatch(setRoadmapViews({ views: [], viewTypeName: "list" }));
        dispatch(setToken(null));
        dispatch(setUser(null));
        dispatch(setEmail(null));
        dispatch(setUserId(null));
        dispatch(setCompanyId(null));
        dispatch(setLoading(false));
        navigate("/signin");
      }
    } catch (error) {
      dispatch(setLoading(false));
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        // Server returned a specific error message
        toast.error(error.response.data.message);
      } else {
        // Fallback for unknown errors
        toast.error("An error occurred. Please try again.");
      }
    }
  };
};

// 1. Request Password Reset API
export const requestPasswordReset = (email, setStep) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await apiConnector("POST", REQUEST_PASSWORD_RESET, {
        email,
      });
      toast.success(response.data.message);
      setStep(2);
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "An error occurred. Please try again."
      );
    } finally {
      dispatch(setLoading(false));
    }
  };
};

// 2. Verify OTP API
export const verifyOtp = (email, otp, setStep) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await apiConnector("POST", VERIFY_OTP, { email, otp });
      toast.success(response.data.message);
      setStep(3);
    } catch (error) {
      toast.error(
        error?.response?.data?.message ??
          "Invalid or expired OTP. Please try again."
      );
    } finally {
      dispatch(setLoading(false));
    }
  };
};

// 3. Reset Password API
export const resetPassword = (email, newPassword, setStep, navigate) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await apiConnector("POST", RESET_PASSWORD, {
        email,
        newPassword,
      });
      toast.success(response.data.message);
      setStep(1);
      navigate("/signin");
    } catch (error) {
      toast.error(
        error?.response?.data?.message ??
          "An error occurred while resetting the password."
      );
    } finally {
      dispatch(setLoading(false));
    }
  };
};
