import { useEffect } from "react";

const areSetsEqual = (setA, setB) => {
  if (setA.size !== setB.size) return false;
  for (let item of setA) if (!setB.has(item)) return false;
  return true;
};

const useRowOutsideClick = ({
  item,
  selectedTags,
  initialSelectedTags,
  tagDropdownState,
  dropdownRef,
  handleTagsOptionClick,
  selectedAccounts = [],
  initialSelectedAccounts = new Set(),
  accountsDropdownState = false,
  handleAccountsOptionClick = () => {},
  selectedColumns = [],
  initialSelectedColumns = new Set(),
  columnsDropdownState = false,
  handleColumnsOptionClick = () => {},
  closeAllDropdowns,
  getSelectedTagObjects
}) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        if (
          tagDropdownState &&
          !areSetsEqual(initialSelectedTags, selectedTags)
        ) {
          handleTagsOptionClick({
            tags: Array.from(selectedTags) ?? [],
            itemId: item?._id,
            fullTags: getSelectedTagObjects(),
          });
        }
        if (
          columnsDropdownState &&
          !areSetsEqual(initialSelectedColumns, selectedColumns)
        ) {
          handleColumnsOptionClick({
            items: Array.from(selectedColumns) ?? [],
            itemId: item?._id,
          });
        }
        if (
          accountsDropdownState &&
          !areSetsEqual(initialSelectedAccounts, selectedAccounts)
        ) {
          handleAccountsOptionClick({
            accounts: Array.from(selectedAccounts) ?? [],
            itemId: item?._id,
          });
        }
        closeAllDropdowns();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [
    selectedTags,
    tagDropdownState,
    initialSelectedTags,
    selectedColumns,
    columnsDropdownState,
    initialSelectedColumns,
    selectedAccounts,
    accountsDropdownState,
    initialSelectedAccounts,
  ]);
};

export default useRowOutsideClick;
