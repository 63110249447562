/* eslint-disable react/prop-types */
import React from "react";
import { truncateText, rgbColor } from "utils";

const ColumnsRowSection = ({
  roadmap,
  columns,
  dropdownState,
  toggleDropdown,
}) => {
  return (
    <div
      className={`gap-2 -ml-0.5 my-0.5 ${
        columns?.length > 0
          ? "flex flex-wrap items-center p-1"
          : " pl-2 pr-14 py-2.5"
      } rounded ${
        dropdownState.isColumnsDropdownOpen
          ? "bg-button-active_10"
          : "hover:bg-button-hover"
      } cursor-pointer rounded`}
      onClick={(e) => {
        e.stopPropagation();
        toggleDropdown();
      }}
    >
      {(columns ?? []).slice(0, 2).map((column, idx) => (
        <div
          key={idx}
          className={
            roadmap.for === "Release"
              ? "rounded bg-tags-active p-1 text-xs"
              : "bg-white rounded"
          }
          title={
            columns.length > 1 && column?.label?.length > 8 && column.label
          }
        >
          <div
            className={roadmap.for !== "Release" ? "rounded p-1 text-xs" : ""}
            style={{ backgroundColor: rgbColor(column?.color ?? "", 0.2) }}
          >
            {columns.length > 1
              ? truncateText(column?.label ?? "", 8)
              : column.label}
          </div>
        </div>
      )) ?? ""}
      {columns?.length > 2 && (
        <div className="text-xs">+{columns.length - 2}</div>
      )}
    </div>
  );
};

export default ColumnsRowSection;
