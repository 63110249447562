/* eslint-disable react/prop-types */
import CheckboxDropdownItem from "components/common/CheckboxDropdownItem";
import { toSnakeCase } from "utils";

const ShowOptionsDropdown = ({
  showButtonDropdownOptions,
  handleShowOptionClick,
  selectedColumns,
  viewType,
}) => {
  return (
    <ul>
      {(showButtonDropdownOptions ?? []).map((option, index) => (
        <li key={index} className="mb-0.5">
          <div className="flex justify-between items-center p-2">
            {option.showAs !== "tab" ? (
              <CheckboxDropdownItem
                option={option}
                handleOptionClick={() => handleShowOptionClick(option)}
                selected={
                  !option.optionType
                    ? selectedColumns.includes(toSnakeCase(option.label))
                    : viewType === "modernlist"
                    ? true
                    : false
                }
                typeOfCheckbox="switch"
                checkboxTextSize="text-sm"
              />
            ) : (
              option.showAs === "tab" && (
                <div className="flex items-center justify-center w-full -my-1.5">
                  {option.subOptions.map((subOption, index) => (
                    <div
                      key={index}
                      className={`flex-1 border text-center cursor-pointer hover:bg-topbar-button-hover p-1 ${
                        index === 0 && "rounded-l"
                      } ${index === option.subOptions.length - 1 && "rounded-r"}
                      ${
                        viewType === "classiclist"
                          ? subOption.label === "Classic"
                            ? "bg-button-active_10"
                            : "shadow"
                          : ""
                      }
                      ${
                        viewType === "modernlist"
                          ? subOption.label === "Modern"
                            ? "bg-button-active_10"
                            : "shadow"
                          : ""
                      }`}
                      onClick={() => handleShowOptionClick(option, subOption)}
                    >
                      {subOption.label}
                    </div>
                  ))}
                </div>
              )
            )}
          </div>
        </li>
      ))}
    </ul>
  );
};

export default ShowOptionsDropdown;
