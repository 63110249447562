/* eslint-disable react/prop-types */
import React from "react";
import { CirclePicker } from "react-color";
import { colors } from "utils";

const ColorPicker = ({ hex, setHex }) => {
  return (
    <CirclePicker
      color={hex}
      colors={colors}
      onChange={(color) => {
        setHex(color.hex);
      }}
      circleSize={12}
      width={80}
      circleSpacing={8}
    />
  );
};

export default ColorPicker;
