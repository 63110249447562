/* eslint-disable react/prop-types */
import React from "react";
import { truncateText } from "utils";

const TabItem = ({ tab, index, activeTab, handleTabClick }) => {
  return (
    <span
      className={`text-sm cursor-pointer py-2 ${
        (
          index === 0
            ? activeTab?.label === tab?.label
            : activeTab?._id === tab?._id
        )
          ? "border-b-2 border-topbar-tab-active text-title_50 font-[500]"
          : ""
      }`}
      onClick={() => handleTabClick(tab, index)}
      title={tab?.label?.length > 14 && tab?.label}
    >
      {truncateText(tab?.label ?? "", 14)}
    </span>
  );
};

export default TabItem;
