import {
  AngleSmallLeft,
  ChartConnected,
  ChartNetwork,
  CreditCard,
  Following,
  User,
} from "react-flaticons";
import IconText from "../common/IconText";
import { Link } from "react-router-dom";

const SettingsSidebar = () => {
  return (
    <div className="flex flex-col justify-between min-w-[240px] max-w-[240px] bg-sidebar-background z-30">
      <div>
        {/* Logo and Expand Button */}
        <div className="flex justify-between items-center cursor-default px-4 mt-5 mb-7  ">
          <div className="flex gap-x-1.5 items-center ">
            <Link className="cursor-pointer" to="/features">
              <AngleSmallLeft size={22} className=" text-gray-500 " />
            </Link>
            <span className="text-lg font-bold text-primary">Settings</span>
          </div>
        </div>
        <div className="flex flex-col gap-y-1.5  px-2">
          <Link to={"profile"}>
            <IconText
              icon={<User />}
              text="Profile"
              py="py-1"
              isASidebarComponent={true}
            />
          </Link>
          <Link to={"customizations"}>
            <IconText
              icon={<ChartConnected />}
              text="Customizations"
              py="py-1"
              isASidebarComponent={true}
            />
          </Link>
          <Link to={"accounts"}>
            <IconText
              icon={<Following />}
              text="Accounts"
              py="py-1"
              isASidebarComponent={true}
            />
          </Link>
          <Link to={"integrations"}>
            <IconText
              icon={<ChartNetwork />}
              text="Integrations"
              py="py-1"
              isASidebarComponent={true}
            />
          </Link>
          <Link to={"billing"}>
            <IconText
              icon={<CreditCard />}
              text="Billing"
              py="py-1"
              isASidebarComponent={true}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SettingsSidebar;
