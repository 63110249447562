/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from "react";
import IconText from "components/common/IconText";
import { fieldIconMapping, rgbColor } from "utils";
import DropdownMenu from "../dropdown/DropdownMenu";
import { useNavigate } from "react-router-dom";
import IconWrapper from "../../IconWrapper";
import { Calendar } from "react-flaticons";

const TimeFrameSection = ({
  item,
  timeFrames,
  handleTimeFrameOptionClick,
  with_title = true,
  position = "gap-x-6",
  for_add = false,
}) => {
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef();

  const handleLocalTimeFrameOptionClick = (option) => {
    handleTimeFrameOptionClick(option);
    setIsDropdownOpen(false);
  };

  const toggleDropdown = (e) => {
    e.stopPropagation();
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div className={`flex ${position} items-center text-title_50`}>
      {with_title && (
        <IconText
          icon={<Calendar />}
          text="Time-frame"
          iconColor="#696969"
          iconSize={16}
          hoverable={false}
          gap_x="gap-x-2"
        />
      )}
      {timeFrames !== null && (
        <div className="relative cursor-pointer" ref={dropdownRef}>
          <div
            className={`${
              item?.label ? "p-1 w-fit" : "ml-1 px-8 py-2"
            } rounded hover:bg-gray-200 ${
              isDropdownOpen && "bg-button-active_10"
            }`}
            onClick={toggleDropdown}
          >
            <div className={item?.color ? "rounded w-fit bg-white" : ""}>
              <div
                className="flex gap-x-2 items-center p-1 rounded text-xs"
                style={{
                  backgroundColor: item?.color ? rgbColor(item.color, 0.2) : "",
                }}
              >
                {item?.icon && (
                  <IconWrapper
                    iconColor={rgbColor(item.color)}
                    icon={
                      React.createElement(fieldIconMapping.timeFrames)
                      // for_add ? (
                      //   React.createElement(fieldIconMapping.timeFrames)
                      // ) : (
                      //   <Calendar />
                      // )
                    }
                    size={14}
                  />
                )}
                <div>{item?.label ?? ""} </div>
              </div>
            </div>
          </div>
          {isDropdownOpen && (
            <DropdownMenu
              options={(timeFrames ?? []).map((timeFrame) => ({
                _id: timeFrame._id,
                label: timeFrame.label,
                icon: fieldIconMapping.timeFrames,
                color: timeFrame.color,
              }))}
              isOpen={isDropdownOpen}
              position="top-full w-32"
              iconSize={12}
              handleOptionClick={handleLocalTimeFrameOptionClick}
              withAddOption={true}
              handleAddOptionClick={() =>
                navigate("/settings/customizations/time-Frame")
              }
            />
          )}
        </div>
      )}
    </div>
  );
};

export default TimeFrameSection;
