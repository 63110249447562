/* eslint-disable react/prop-types */
import React from "react";
import { Document, Comments, TimeFast } from "react-flaticons";

const DetailViewRightSidebarIconsHeader = ({
  selectedIcon,
  setSelectedIcon,
}) => {
  return (
    <div className="flex items-center justify-between px-3 cursor-pointer">
      <div
        className={`mx-2 py-1.5 rounded w-1/3 ${
          selectedIcon === "detail"
            ? "bg-button-active_10"
            : "hover:bg-button-hover"
        }`}
        onClick={() => {
          setSelectedIcon("detail");
        }}
      >
        <Document
          className={`mx-auto  ${
            selectedIcon === "detail"
              ? "bg-button-active_10"
              : "hover:bg-button-hover"
          } `}
          size={16}
        />
      </div>
      <div
        className={`mx-2 py-0.5 rounded w-1/3`}
        // onClick={() => {
        //   setSelectedIcon("feedback");
        // }}
      >
        <div className="flex items-center justify-center gap-x-1">
          <Comments size={16} />
          <div className="text-[10px] rounded-full bg-button-active_10 p-1">
            Soon
          </div>
        </div>
      </div>
      <div
        className={`mx-2 py-1.5 rounded w-1/3 ${
          selectedIcon === "log"
            ? "bg-button-active_10"
            : "hover:bg-button-hover"
        }`}
        onClick={() => {
          setSelectedIcon("log");
        }}
      >
        <TimeFast className="mx-auto" size={16} />
      </div>
    </div>
  );
};

export default DetailViewRightSidebarIconsHeader;
