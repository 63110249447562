/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import IconText from "components/common/IconText";
import { CrossSmall, MinusSmall, PlusSmall } from "react-flaticons";
import { useDispatch } from "react-redux";
import { fieldIconMapping } from "utils";
import AddKeyResultPopupOverlay from "components/common/addNewPopupOverlays/AddKeyResultPopupOverlay";
import { createKeyResult } from "reduxStore/operations/keyResultsAPI";
import ObjectiveDetailViewKeyResultRowComponent from "../detailView/detailViewRowComponents/ObjectiveDetailViewKeyResultRowComponent";

const KeyResultsBasedDetailSection = ({ objective }) => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");

  const option = {
    label: "Key result",
    addOptions: ["status", "assignee"],
    onAddClick: ({ title, status }) => {
      dispatch(createKeyResult(title, status, objective._id));
      setName("");
      setIsKeyResultToBeAdded(false);
    },
  };
  const [isKeyResultToBeAdded, setIsKeyResultToBeAdded] = useState(false);
  const containerRef = useRef(null);

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setIsKeyResultToBeAdded(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="flex flex-col gap-y-2">
      <div className="relative flex items-center gap-x-1.5" ref={containerRef}>
        <div>Key Results</div>
        <div
          className={`rounded  hover:bg-button-hover cursor-pointer ${
            isKeyResultToBeAdded
              ? "bg-button-active_10"
              : "bg-topbar-button-hover"
          }`}
          onClick={() => {
            setIsKeyResultToBeAdded(!isKeyResultToBeAdded);
          }}
        >
          {isKeyResultToBeAdded ? <MinusSmall /> : <PlusSmall />}
        </div>
        {isKeyResultToBeAdded && (
          <div className="border border-gray-300 bg-white p-5 rounded-md absolute top-full w-full z-10 mt-1">
            {/* Content */}
            <div className="flex flex-col">
              {/* Close button */}
              <div className="flex items-center justify-between mb-2">
                <IconText
                  icon={React.createElement(fieldIconMapping.key_results)}
                  iconColor={option?.iconColor ?? ""}
                  text={option?.label ?? ""}
                  hoverable={false}
                  gap_x="gap-x-2"
                  px=""
                  py=""
                />
                <button
                  onClick={() => setIsKeyResultToBeAdded(false)}
                  className="self-end rounded p-0.5 hover:bg-button-hover"
                >
                  <CrossSmall color="#374957" />
                </button>
              </div>
              {/* Input field */}
              <input
                type="text"
                value={name}
                className="w-full text-lg py-2 mb-3.5 rounded-md focus:outline-none"
                onChange={(e) => setName(e.target.value)}
                placeholder={option.label + " name"}
                autoFocus
              />
              <AddKeyResultPopupOverlay option={option} name={name} />
            </div>
          </div>
        )}
      </div>
      <hr />
      <div className="pt-2">
        <ObjectiveDetailViewKeyResultRowComponent objective={objective} />
      </div>
    </div>
  );
};

export default KeyResultsBasedDetailSection;
