/* eslint-disable react/prop-types */
import React, { useState } from "react";
import LinkIdeasDetailViewTab from "components/common/detailView/LinkIdeasDetailViewTab";
import LinkFeaturesDetailViewTab from "components/common/detailView/LinkFeaturesDetailViewTab";

const InitiativeDropdown = ({
  item,
  selectedIdeas,
  handleIdeaSelect,
  removeSelectedIdeas,
  selectedFeatures,
  handleFeatureSelect,
  removeSelectedFeatures,
  updateIdeas,
  updateFeatures,
}) => {
  const [isIdeaToBeAdded, setIsIdeaToBeAdded] = useState(false);
  const [isFeatureToBeAdded, setIsFeatureToBeAdded] = useState(true);
  return (
    <div className="absolute bg-white rounded shadow border p-2 w-full top-full mt-1 z-20">
      {/* {isIdeaToBeAdded && (
        <LinkIdeasDetailViewTab
          currentItem={item}
          selectedItems={selectedIdeas}
          handleItemSelect={handleIdeaSelect}
          clearSelectedItems={removeSelectedIdeas}
          with_linked_item_list={false}
          updateLinkedItems={(ideas) => updateIdeas(ideas)}
          with_different_tabs={[
            {
              label: "Features",
              active: isFeatureToBeAdded,
              onClick: () => {
                setIsIdeaToBeAdded(false);
                setIsFeatureToBeAdded(true);
              },
            },
            {
              label: "Ideas",
              active: isIdeaToBeAdded,
              onClick: () => {
                setIsIdeaToBeAdded(true);
                setIsFeatureToBeAdded(false);
              },
            },
          ]}
        />
      )} */}
      {isFeatureToBeAdded && (
        <LinkFeaturesDetailViewTab
          currentItem={item}
          selectedItems={selectedFeatures}
          handleItemSelect={handleFeatureSelect}
          clearSelectedItems={removeSelectedFeatures}
          with_linked_item_list={false}
          updateLinkedItems={(features) => updateFeatures(features)}
          // with_different_tabs={[
          //   {
          //     label: "Features",
          //     active: isFeatureToBeAdded,
          //     onClick: () => {
          //       setIsIdeaToBeAdded(false);
          //       setIsFeatureToBeAdded(true);
          //     },
          //   },
          //   {
          //     label: "Ideas",
          //     active: isIdeaToBeAdded,
          //     onClick: () => {
          //       setIsIdeaToBeAdded(true);
          //       setIsFeatureToBeAdded(false);
          //     },
          //   },
          // ]}
        />
      )}
    </div>
  );
};

export default InitiativeDropdown;
