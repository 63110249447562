import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/common/Loader";
import IconWrapper from "components/common/IconWrapper";
import ColorDropdown from "./ColorDropdown";
import { fieldIconMapping, colors } from "utils";
import CalendarSection from "./CalendarSection";
import {
  editTimeFrameInCompany,
  addTimeFrameToCompany,
  deleteTimeFrameOfCompany,
} from "reduxStore/operations/timeFramesAPI";
import EditInputForm from "./EditInputForm";
import AddInputForm from "./AddInputForm";
import { CrossSmall, Delete, Trash } from "react-flaticons";
import CustomizationsLabel from "../../common/CustomizationsLabel";

const TimeFramesField = () => {
  const addRef = useRef(null);
  const editRefs = useRef({});
  const dispatch = useDispatch();

  const { timeFrames } = useSelector((state) => state.company);

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [activeStartDate, setActiveStartDate] = useState("");
  const [activeEndDate, setActiveEndDate] = useState("");
  const [hex, setHex] = useState(colors[0]);
  const [isColorDropdownOpen, setIsColorDropdownOpen] = useState(false);
  const [orignalEditTimeFrameColor, setOrignalEditTimeFrameColor] =
    useState("");

  // Add Related States
  const [isAddInputOpen, setIsAddInputOpen] = useState(false);
  const [addNewTimeFrameLabel, setAddNewTimeFrameLabel] = useState("");

  // Edit Related States
  const [inputLabelClicked, setInputLabelClicked] = useState(false);
  const [activeEditTimeFrameId, setActiveEditTimeFrameId] = useState(null);
  const [editLabel, setEditLabel] = useState("");
  const [originalEditLabel, setOriginalEditLabel] = useState("");
  const [originalStartDate, setOriginalStartDate] = useState("");
  const [originalEndDate, setOriginalEndDate] = useState("");

  const resetEditOptions = () => {
    setEditLabel("");
    setOriginalEditLabel("");
    setIsColorDropdownOpen(false);
    setIsCalendarOpen(false);
    setOriginalStartDate("");
    setOriginalEndDate("");
    setActiveStartDate("");
    setActiveEndDate("");
    setActiveEditTimeFrameId(null);
    setOrignalEditTimeFrameColor("");
  };

  const resetAddOptions = () => {
    setAddNewTimeFrameLabel("");
    setIsCalendarOpen(false);
    setIsAddInputOpen(false);
    setActiveStartDate("");
    setActiveEndDate("");
  };

  const editButtonClick = (
    timeFrameValue,
    startDate,
    endDate,
    timeFrameId,
    timeFrameColor,
    shouldOpenColorDropdown = false
  ) => {
    if (activeEditTimeFrameId === null) {
      setHex(timeFrameColor);
    } else {
      setHex(hex);
    }
    setIsColorDropdownOpen(shouldOpenColorDropdown);
    setOriginalEditLabel(timeFrameValue);
    if (activeEditTimeFrameId === null) {
      setEditLabel(timeFrameValue);
    } else {
      setEditLabel(editLabel);
    }
    setActiveEditTimeFrameId(timeFrameId);
    setOriginalStartDate(startDate);
    setOriginalEndDate(endDate);
    setOrignalEditTimeFrameColor(timeFrameColor);
  };

  const addButtonClick = () => {
    setIsAddInputOpen(true);
    setAddNewTimeFrameLabel("");
    setOriginalStartDate(null);
    setOriginalEndDate(null);
    setHex(colors[0]);
  };

  const handleEditTimeFrame = () => {
    if (
      editLabel !== originalEditLabel ||
      activeStartDate ||
      activeEndDate ||
      hex !== orignalEditTimeFrameColor
    ) {
      dispatch(
        editTimeFrameInCompany(
          activeEditTimeFrameId,
          editLabel !== "" ? editLabel : originalEditLabel,
          hex,
          activeStartDate || originalStartDate,
          activeEndDate || originalEndDate
        )
      );
    }
    resetEditOptions();
  };

  const handleAddTimeFrame = () => {
    if (addNewTimeFrameLabel !== "" || activeStartDate || activeEndDate) {
      dispatch(
        addTimeFrameToCompany(
          addNewTimeFrameLabel,
          hex,
          activeStartDate || originalStartDate,
          activeEndDate || originalEndDate
        )
      );
    }
    resetAddOptions();
  };

  const handleDeleteTimeFrame = (TimeFrameId) => {
    dispatch(deleteTimeFrameOfCompany(TimeFrameId || activeEditTimeFrameId));
    resetEditOptions();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        activeEditTimeFrameId &&
        editRefs.current[activeEditTimeFrameId] &&
        !editRefs.current[activeEditTimeFrameId].contains(event.target)
      ) {
        handleEditTimeFrame();
        resetAddOptions();
        setInputLabelClicked(false);
      } else if (
        isAddInputOpen &&
        addRef.current &&
        !addRef.current.contains(event.target)
      ) {
        handleAddTimeFrame();
        resetEditOptions();
        setInputLabelClicked(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [
    activeEditTimeFrameId,
    activeStartDate,
    activeEndDate,
    editLabel,
    addNewTimeFrameLabel,
    isAddInputOpen,
    hex,
  ]);

  if (!timeFrames) {
    return (
      <div className="flex items-center justify-center h-[600px]">
        <Loader />
      </div>
    );
  }

  return (
    <div className="text-sm text-title_10 cursor-default  ">
      <div className="mb-5 font-semibold">Time-frames</div>
      <div className="flex flex-col gap-y-2">
        {timeFrames.length > 0 ? (
          timeFrames.map((timeFrame, index) => {
            return (
              <div
                key={timeFrame._id}
                className="flex items-center text-primary text-sm gap-x-2 w-fit group "
                ref={(el) => (editRefs.current[timeFrame._id] = el)}
                onClick={() =>
                  editButtonClick(
                    timeFrame.label,
                    timeFrame.startDate,
                    timeFrame.endDate,
                    timeFrame._id,
                    timeFrame.color
                  )
                }
              >
                <ColorDropdown
                  icon={React.createElement(fieldIconMapping.timeFrames)}
                  iconColor={
                    activeEditTimeFrameId === timeFrame._id
                      ? hex
                      : timeFrame.color
                  }
                  openDropdownCondition={
                    isColorDropdownOpen &&
                    activeEditTimeFrameId === timeFrame._id
                  }
                  hex={hex}
                  setHex={setHex}
                  onClick={(e) => {
                    e.stopPropagation(),
                      !isColorDropdownOpen
                        ? editButtonClick(
                            timeFrame.label,
                            timeFrame.startDate,
                            timeFrame.endDate,
                            timeFrame._id,
                            timeFrame.color,
                            true
                          )
                        : setIsColorDropdownOpen(false);
                  }}
                  clickable={true}
                />
                {activeEditTimeFrameId === timeFrame._id ? (
                  <EditInputForm
                    inputLabel={editLabel}
                    setInputLabel={setEditLabel}
                    inputFocusCondition={inputLabelClicked}
                    onEditInputEnter={handleEditTimeFrame}
                    onInputButtonClick={setIsCalendarOpen}
                    startDate={activeStartDate || originalStartDate}
                    endDate={activeEndDate || originalEndDate}
                    isCalendarActiveCondition={false}
                    setIsCalendarOpen={setIsCalendarOpen}
                    setStartDate={setActiveStartDate}
                    setEndDate={setActiveEndDate}
                    withCalendar={false}
                    handleRemoveOptionClick={() => handleDeleteTimeFrame()}
                    showRemovable={index > 2}
                  />
                ) : (
                  <>
                    {/* <div
                      className="p-2 border rounded w-48 cursor-text"
                      onClick={() => {
                        setInputLabelClicked(true);
                      }}
                    >
                      {timeFrame.label}
                    </div> */}
                    <CustomizationsLabel
                      label={timeFrame.label}
                      onClick={setInputLabelClicked}
                      handleDeleteItem={handleDeleteTimeFrame}
                      id={timeFrame._id}
                      showDelete={index>2}
                    />
                    {/* {index > 2 && (
                    <CalendarSection
                      startDate={timeFrame?.startDate ?? null}
                      endDate={timeFrame?.endDate ?? null}
                      setIsCalendarOpen={setIsCalendarOpen}
                      isCalendarActiveCondition={
                        isCalendarOpen &&
                        activeEditTimeFrameId === timeFrame._id
                      }
                    />
                  )} */}
                  </>
                )}
              </div>
            );
          })
        ) : (
          <div className="flex items-center gap-x-3">
            <div>
              <IconWrapper
                icon={React.createElement(fieldIconMapping.timeFrames)}
                size="14"
                iconColor={colors[0]}
              />
            </div>
            <div className="p-2 border rounded w-48 cursor-not-allowed bg-row-background">
              No Time-frame
            </div>
          </div>
        )}
        {isAddInputOpen ? (
          <div className="flex items-center gap-x-2 w-fit" ref={addRef}>
            <AddInputForm
              hex={hex}
              iconColor={hex}
              setHex={setHex}
              icon={React.createElement(fieldIconMapping.timeFrames)}
              iconClickable={true}
              openIconDropdownCondition={isColorDropdownOpen}
              onIconClick={() => setIsColorDropdownOpen(!isColorDropdownOpen)}
              inputLabel={addNewTimeFrameLabel}
              setInputLabel={(value) => setAddNewTimeFrameLabel(value)}
              withCalendar={false}
              startDate={activeStartDate || originalStartDate}
              endDate={activeEndDate || originalEndDate}
              setStartDate={setActiveStartDate}
              setEndDate={setActiveEndDate}
              setIsCalendarOpen={setIsCalendarOpen}
              isCalendarActiveCondition={isCalendarOpen}
              onInputEnter={() => handleAddTimeFrame()}
              handleCloseOptionClick={resetAddOptions}
            />
          </div>
        ) : (
          <div
            className="text-primary cursor-pointer hover:bg-button-hover p-1 w-fit rounded"
            onClick={() => {
              addButtonClick();
            }}
          >
            + Add
          </div>
        )}
      </div>
    </div>
  );
};

export default TimeFramesField;
