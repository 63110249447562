import React from "react";
import { getAllFeatures } from "reduxStore/operations/featuresAPI";
import LinkDetailViewTab from "./linkDetailViewTab/LinkDetailViewTab";
import IconWrapper from "../IconWrapper";
import { fieldIconMapping } from "utils";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveFeatureId,
  setFeatureShowDetailViewDrawer,
} from "reduxStore/slices/featureSlice";
import { setDetailViewFeatures } from "reduxStore/slices/featureSlice";
import { useLocation } from "react-router-dom";
import { handleResetActiveIds } from "../../../utils/handleResetActiveIds";
import { resetActiveIds } from "../../../utils/resetActiveIds";

const LinkFeaturesDetailViewTab = (props) => {
  const dispatch = useDispatch();
  const { features } = useSelector((state) => state.features);
  const { pathname } = useLocation();

  return (
    <LinkDetailViewTab
      {...props}
      fetchAllItems={getAllFeatures}
      itemNameField="title"
      itemIconComponent={
        <IconWrapper
          icon={React.createElement(fieldIconMapping.feature)}
          iconColor="text-features-icon"
        />
      }
      linkedItemsKey="features"
      searchPlaceholder="Search features..."
      onLinkedItemClick={(featureId) => {
        if (pathname === "/features") {
          resetActiveIds({
            dispatch,
            resetFeature: false,
          });
        }
        if (pathname.includes("/roadmaps")) {
          resetActiveIds({ dispatch });
        }
        handleResetActiveIds(dispatch, pathname);
        dispatch(setDetailViewFeatures(features));
        dispatch(setActiveFeatureId(featureId));
        dispatch(setFeatureShowDetailViewDrawer(true));
      }}
    />
  );
};

export default LinkFeaturesDetailViewTab;
