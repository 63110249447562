/* eslint-disable react/prop-types */
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useMemo, useState } from "react";
import { generateStatusArray } from "utils/status";
import { fieldIconMapping } from "utils";
import Loader from "components/common/Loader";
import { DragDropContext } from "@hello-pangea/dnd";
import ObjectiveKanbanColumn from "./ObjectiveKanbanColumn";
import { updateObjectiveData } from "reduxStore/operations/objectivesAPI";

const ObjectiveKanbanView = ({
  groupedData,
  roadmap,
  isRoadmapDetailColumn = false,
}) => {
  const dispatch = useDispatch();
  const { groupByOption, isViewsLoading } = useSelector(
    (state) => state.objectives
  );
  const { statuses } = useSelector((state) => state.company);
  const [objectiveStatuses, setObjectiveStatuses] = useState([]);

  useEffect(() => {
    setObjectiveStatuses(statuses.objective);
  }, [statuses]);

  const statusArray = useMemo(
    () => generateStatusArray(objectiveStatuses),
    [objectiveStatuses]
  );

  const [localGroupByOption, setLocalGroupByOption] = useState(null);

  useEffect(() => {
    setLocalGroupByOption(groupByOption);
  }, [groupByOption]);

  const handleOnDragEnd = (result) => {
    const { source, destination, draggableId } = result;
    if (!destination || source.droppableId === destination.droppableId) return;

    if (!roadmap && groupByOption === "default") {
      const updateToStatusId = destination.droppableId;
      dispatch(
        updateObjectiveData(draggableId, { status: updateToStatusId }, "status")
      );
    }

    if (
      (roadmap && groupByOption === "default") ||
      groupByOption === "time_frame"
    ) {
      const updateToTimeFrameId = destination.droppableId;
      dispatch(
        updateObjectiveData(
          draggableId,
          { timeFrame: updateToTimeFrameId },
          "timeFrame"
        )
      );
    }
  };

  return (
    <div
      className={`h-full overflow-x-auto ${roadmap !== null && "bg-gray-50"}`}
    >
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <div className="flex gap-x-5 p-4">
          {isViewsLoading ||
          localGroupByOption === null ||
          localGroupByOption !== groupByOption ? (
            <div className="flex items-center justify-center h-[600px] w-full">
              <Loader />
            </div>
          ) : (
            Object.entries(groupedData)?.map(([groupId, groupData]) => (
              <ObjectiveKanbanColumn
                key={groupId}
                groupByOption={groupByOption}
                group={{
                  _id: groupId,
                  label: groupData.label,
                  color: groupData.color,
                }}
                itemsForGroup={groupData.items}
                statusArray={statusArray}
                isRoadmapDetailColumn={isRoadmapDetailColumn}
                roadmapTypeIcon={React.createElement(
                  fieldIconMapping.timeFrames
                )}
              />
            ))
          )}
        </div>
      </DragDropContext>
    </div>
  );
};

export default ObjectiveKanbanView;
