/* eslint-disable react/prop-types */
import React from "react";
import IconWrapper from "components/common/IconWrapper";
import ColorPicker from "components/common/ColorPicker";

const ColorDropdown = ({
  icon,
  iconColor,
  clickable,
  hex,
  setHex,
  onClick,
  openDropdownCondition,
}) => {

  return (
    <div
      className={` cursor-pointer relative ${
        clickable &&
        (openDropdownCondition
          ? " bg-button-active_10 rounded p-1 -ml-1"
          : " hover:bg-button-hover rounded p-1 -ml-1")
      }`}
      onClick={onClick ? onClick : null}
    >
      <IconWrapper icon={icon} size={14} iconColor={iconColor} />
      {openDropdownCondition && (
        <div className="absolute p-3 left-0 rounded-md border bg-white top-6 z-40">
          <ColorPicker hex={hex} setHex={setHex} />
        </div>
      )}
    </div>
  );
};

export default ColorDropdown;
