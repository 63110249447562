import { combineReducers } from "@reduxjs/toolkit";
import { featuresReducer } from "../slices/featureSlice";
import { authReducer } from "../slices/authSlice";
import { companyReducer } from "../slices/companySlice";
import { userReducer } from "../slices/userSlice";
import { ideasReducer } from "../slices/ideaSlice";
import { objectivesReducer } from "../slices/objectiveSlice";
import { keyResultReducer } from "../slices/keyResultSlice";
import { roadmapsReducer } from "../slices/roadmapsSlice";
import accountsReducer from "../slices/accountSlice";
import { activityLogsReducer } from "../slices/activityLogSlice";
import { personalViewReducer } from "../slices/personalViewSlice";
import { integrationsReducer } from "../slices/integrationsSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  features: featuresReducer,
  company: companyReducer,
  ideas: ideasReducer,
  objectives: objectivesReducer,
  keyResults: keyResultReducer,
  roadmaps: roadmapsReducer,
  accounts: accountsReducer,
  activityLogs: activityLogsReducer,
  personalViews: personalViewReducer,
  integrations: integrationsReducer,
});

export default rootReducer;
