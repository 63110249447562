import React, { useEffect, useState } from "react";
import Sidebar from "components/core/Sidebar";
import FeatureDetailViewComponent from "components/features/detailView/FeatureDetailViewComponent";
import { useParams, useNavigate } from "react-router-dom";
import FeatureDetailViewRightSidebar from "components/features/detailView/FeatureDetailViewRightSidebar";
import Loader from "components/common/Loader";
import { useDispatch, useSelector } from "react-redux";
import { getAllFeatures } from "reduxStore/operations/featuresAPI";
import { isObjectEmpty } from "utils";

const FeaturesDetailView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { featureId } = useParams();

  const { features } = useSelector((state) => state.features);
  const [allFeatures, setAllFeatures] = useState(null);
  const [feature, setFeature] = useState(null);
  const [featureIndex, setFeatureIndex] = useState(null);

  useEffect(() => {
    if (isObjectEmpty(features)) {
      dispatch(getAllFeatures());
    } else {
      setAllFeatures(features);
    }
  }, [features]);

  useEffect(() => {
    if (allFeatures !== null) {
      const tempFeature = allFeatures.find((item) => item._id === featureId);
      const tempIndex = allFeatures.findIndex((item) => item._id === featureId);
      setFeature(tempFeature);
      setFeatureIndex(tempIndex);
    }
  }, [allFeatures, featureId]);

  const handleNextFeature = () => {
    if (featureIndex !== null) {
      const nextFeature = allFeatures[(featureIndex + 1) % allFeatures.length];
      navigate(`/features/${nextFeature._id}`);
    }
  };

  const handlePrevFeature = () => {
    if (featureIndex !== null) {
      const prevIndex =
        (featureIndex - 1 + allFeatures.length) % allFeatures.length;
      const prevFeature = allFeatures[prevIndex];
      navigate(`/features/${prevFeature._id}`);
    }
  };

  return (
    <div className="flex font-inter w-screen h-screen tracking-wide cursor-default">
      <Sidebar />
      {!feature ? (
        <div className="absolute inset-0 flex items-center justify-center">
          <Loader />
        </div>
      ) : (
        <>
          <div className="flex flex-col border-r-2 w-2/3">
            <FeatureDetailViewComponent
              item={feature}
              showBreadcrumb={true}
              onNext={handleNextFeature}
              onPrev={handlePrevFeature}
            />
          </div>
          <div className="flex w-[320px]">
            <FeatureDetailViewRightSidebar item={feature} />
          </div>
        </>
      )}
    </div>
  );
};

export default FeaturesDetailView;
