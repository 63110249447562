/* eslint-disable react/prop-types */
import React from "react";
import { truncateText } from "utils";

const AccountsRowSection = ({ accounts, dropdownState, toggleDropdown }) => {
  return (
    <div
      className={`gap-2 -ml-0.5 my-0.5 ${
        accounts?.length > 0
          ? "flex flex-wrap items-center p-1"
          : " pl-2 pr-14 py-2.5"
      } rounded ${
        dropdownState.isTagDropdownOpen
          ? "bg-button-active_10"
          : "hover:bg-button-hover"
      } cursor-pointer rounded`}
      onClick={(e) => {
        e.stopPropagation();
        toggleDropdown();
      }}
    >
      {(accounts ?? []).slice(0, 2).map((tag, idx) => (
        <div
          key={idx}
          className="rounded bg-tags-active p-1 text-xs"
          title={accounts.length > 1 && tag?.name?.length > 5 && tag.name}
        >
          {accounts.length > 1 ? truncateText(tag?.name ?? "", 5) : tag.name}
        </div>
      )) ?? ""}
      {accounts?.length > 2 && (
        <div className="text-xs">+{accounts.length - 2}</div>
      )}
    </div>
  );
};

export default AccountsRowSection;
