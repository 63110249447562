/* eslint-disable react/prop-types */
import React, { useState, useRef, useEffect } from "react";
import { LuKanban } from "react-icons/lu";
import IconWrapper from "components/common/IconWrapper";
import {
  LayerPlus,
  Layers,
  MenuBurger,
  SettingsSliders,
} from "react-flaticons";
import { useSelector } from "react-redux";
import FilterOptionsDropdown from "../dropdowns/FilterOptionsDropdown";
import DisplayOptionsDropdown from "../dropdowns/DisplayOptionsDropdown";
import GroupByDropdown from "../dropdowns/GroupByDropdown";
import { capitalizeFirstLetter, toSnakeCase } from "utils";
import { BsFilter } from "react-icons/bs";
import useFilterLogic from "hooks/useFilterLogic";
import { useLocation, useNavigate } from "react-router-dom";
import { ConfigProvider, Segmented } from "antd";
import { AppstoreOutlined, BarsOutlined } from "@ant-design/icons";
import ViewToggle from "../../../common/ViewToggle";
import SortOptionsDropdown from "../dropdowns/SortOptionsDropdown";

const rightButtons = [
  { icon: BsFilter, label: "Filter" },
  { icon: SettingsSliders, label: "Display" },
];

const TopbarOptionButtons = ({
  groupByDropdownOptions,
  showButtonDropdownOptions,
  filterOptions,
  sortOptions,
  topbarFunctions,
  with_different_view_option = true,
  with_group_by_option = true,
  activeState,
  isPersonal = false,
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const params = new URLSearchParams(window.location.search);
  const isViewAll = params.get("view") === "all" || params.get("view") === null;
  let listViewType = params.get("viewType");
  if (pathname === "/ideas") {
    listViewType = listViewType ? listViewType : "modernlist";
  } else {
    listViewType = listViewType ? listViewType : "classiclist";
  }
  useEffect(() => {
    let updatedViewType = listViewType ? listViewType : "modernlist"; // Start with the current listViewType

    // If the pathname is "/ideas"
    // if (pathname === "/ideas") {
    //   if (listViewType === "Kanban") {
    //     updatedViewType = "Kanban"; // Keep it Kanban if it is already Kanban
    //   } else {
    //     updatedViewType = listViewType; // Otherwise, set to modernlist
    //   }
    // }
    topbarFunctions.setViewsLoading();
    topbarFunctions.setViewType(updatedViewType);
  }, [listViewType, pathname]);

  const {
    selectedColumns,
    filters,
    groupByOption,
    sortOption,
    viewType,
    modernListExists,
  } = useSelector((state) => state[activeState]);

  const {
    selectedFilterOption,
    subFilterDropdownOptions,
    setSelectedFilterOption,
    setSubFilterDropdownOptions,
    handleFilterOptionClick,
    handleSubFilterOptionClick,
    handleResetFilterClick,
    handleRemoveFilter,
  } = useFilterLogic(topbarFunctions);

  const [
    displayGroupByButtonDropdownOptions,
    setDisplayGroupByButtonDropdownOptions,
  ] = useState(false);
  const [
    displayDisplayButtonDropdownOptions,
    setDisplayDisplayButtonDropdownOptions,
  ] = useState(false);
  const [
    displaySortButtonDropdownOptions,
    setDisplaySortButtonDropdownOptions,
  ] = useState(false);
  const [
    displayFilterButtonDropdownOptions,
    setDisplayFilterButtonDropdownOptions,
  ] = useState(false);

  const [selectedSortOption, setSelectedSortOption] = useState(
    sortOptions?.[0] ?? " "
  );
  const [sortOrder, setSortOrder] = useState("asc");
  const [activeView, setActiveView] = useState(listViewType || viewType);
  const [activeGroupByOption, setActiveGroupByOption] = useState(
    groupByDropdownOptions?.[0] ?? ""
  );

  const dropdownRef = useRef(null);

  const toggleGroupByButtonDropdown = () => {
    setDisplayGroupByButtonDropdownOptions((prev) => !prev);
    resetFilterRelatedOptions();
    setDisplayFilterButtonDropdownOptions(false);
    setDisplaySortButtonDropdownOptions(false);
    setDisplayDisplayButtonDropdownOptions(false);
  };

  const toggleShowButtonDropdown = () => {
    setDisplayDisplayButtonDropdownOptions((prev) => !prev);
    resetFilterRelatedOptions();
    setDisplayGroupByButtonDropdownOptions(false);
    setDisplayFilterButtonDropdownOptions(false);
    setDisplaySortButtonDropdownOptions(false);
  };

  const toggleFilterButtonDropdown = () => {
    setDisplayFilterButtonDropdownOptions((prev) => !prev);
    resetFilterRelatedOptions();
    setDisplayGroupByButtonDropdownOptions(false);
    setDisplayDisplayButtonDropdownOptions(false);
    setDisplaySortButtonDropdownOptions(false);
  };

  const toggleSortButtonDropdown = () => {
    setDisplaySortButtonDropdownOptions((prev) => !prev);
    resetFilterRelatedOptions();
    setDisplayGroupByButtonDropdownOptions(false);
    setDisplayFilterButtonDropdownOptions(false);
  };

  const toggleViewClick = (viewName) => {
    setDisplayFilterButtonDropdownOptions(false);
    resetFilterRelatedOptions();
    setDisplayGroupByButtonDropdownOptions(false);
    setDisplayDisplayButtonDropdownOptions(false);
    setDisplaySortButtonDropdownOptions(false);
    if (viewType !== viewName) {
      topbarFunctions.setViewsLoading();
      topbarFunctions.setViewType(viewName);
      if (activeState !== "objectives" && activeState !== "roadmaps") {
        navigate(`?viewType=${viewName}&view=all`);
      } else {
        navigate(`?view=all`);
      }
    }
  };

  const resetFilterRelatedOptions = () => {
    setSelectedFilterOption(null);
    setSubFilterDropdownOptions(null);
  };

  const handleShowOptionClick = (option, subOption) => {
    if (option.optionType === "viewType") {
      if (
        (viewType === "modernlist" && subOption.label === "Modern") ||
        (viewType === "classiclist" && subOption.label === "Classic")
      ) {
        return;
      }
      topbarFunctions.setViewType(
        subOption.label === "Modern" ? "modernlist" : "classiclist"
      );
      params.set(
        "viewType",
        subOption.label === "Modern" ? "modernlist" : "classiclist"
      );
      navigate(`?${params.toString()}`);
    } else {
      topbarFunctions.toggleColumn(toSnakeCase(option.label));
    }
  };

  const handleGroupByOptionClick = (option) => {
    setActiveGroupByOption(option);
    topbarFunctions.setGroupByOption(toSnakeCase(option.label));
    toggleGroupByButtonDropdown();
  };

  const ASCENDING = "asc";
  const DESCENDING = "desc";

  const handleSortOptionClick = (option) => {
    const isSameOptionSelected =
      capitalizeFirstLetter(selectedSortOption.label) ===
        capitalizeFirstLetter(option.label) && option.label !== "Default";

    const newOrder =
      isSameOptionSelected && sortOrder === ASCENDING ? DESCENDING : ASCENDING;

    setSortOrder(newOrder);
    setSelectedSortOption(option);
    topbarFunctions.setSortOption({
      order: newOrder,
      field: toSnakeCase(option.label),
    });
  };

  useEffect(() => {
    const capitalizedField = capitalizeFirstLetter(sortOption.field);
    setSelectedSortOption({ label: capitalizedField });
    setSortOrder(sortOption.order);
  }, [sortOption]);

  useEffect(() => {
    setActiveView(viewType);
  }, [viewType]);

  useEffect(() => {
    setActiveGroupByOption({ label: capitalizeFirstLetter(groupByOption) });
  }, [groupByOption]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDisplayDisplayButtonDropdownOptions(false);
        setDisplayFilterButtonDropdownOptions(false);
        setDisplaySortButtonDropdownOptions(false);
        setDisplayGroupByButtonDropdownOptions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getOptionValue = () => {
    return pathname === "/ideas" ? "modernlist" : "classiclist";
  };

  return (
    <div className="flex items-center ml-auto  " ref={dropdownRef}>
      {/* {with_different_view_option && isViewAll && (
        <div className="flex items-center ">
          <div
            className={`cursor-pointer ${
              activeView === "classiclist" || activeView === "modernlist"
                ? "bg-topbar-button-active"
                : "hover:bg-topbar-button-hover"
            } p-1 rounded`}
            onClick={() => {
              toggleViewClick(modernListExists ? "modernlist" : "classiclist");
            }}
          >
            <IconWrapper icon={<MenuBurger />} size={14} />
          </div>
          <div className="mx-1.5  border h-[20px]"></div>
          <div
            className={`cursor-pointer ${
              activeView === "kanban"
                ? "bg-topbar-button-active"
                : "hover:bg-topbar-button-hover"
            } p-0.5 rounded`}
            onClick={() => {
              toggleViewClick("kanban");
            }}
          >
            <IconWrapper icon={<LuKanban />} size={18} />
          </div>
        </div>
      )} */}
      {/* <div className="mx-3 ml-auto "></div> */}

      {/* <ViewToggle toggleViewClick={toggleViewClick} /> */}
      {with_different_view_option && isViewAll && (
        <div className=" absolute left-5  ">
          <ConfigProvider
            theme={{
              components: {
                Segmented: {
                  itemColor: "rgba(0, 0, 0, 0.65)", // Color for unselected items
                  itemHoverBg: "rgba(0, 0, 0, 0.06)", // Background color on hover
                  itemHoverColor: "rgba(0, 0, 0, 0.88)", // Text color on hover
                  itemSelectedBg: "rgba(0, 0, 0, 0.10)", // Grey background for selected item
                  itemSelectedColor: "rgba(0, 0, 0, 0.88)", // Text color for selected item
                  trackBg: "#ffffff", // White background for the entire control
                  trackPadding: 2,
                  colorText: "rgba(0, 0, 0, 0.88)", // Default text color
                  colorTextDisabled: "rgba(0, 0, 0, 0.25)", // Disabled text color
                  borderRadius: 6,
                  borderRadiusLG: 8,
                  borderRadiusSM: 4,
                  borderRadiusXS: 2,
                  boxShadowTertiary:
                    "0 1px 2px 0 rgba(0, 0, 0, 0.03), 0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02)",
                  controlHeight: 32,
                  controlHeightLG: 40,
                  controlHeightSM: 24,
                  controlPaddingHorizontal: 12,
                  controlPaddingHorizontalSM: 8,
                  fontFamily:
                    "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
                  fontSize: 14,
                  fontSizeLG: 16,
                  lineHeight: 1.5714285714285714,
                  lineWidth: 1,
                  marginSM: 12,
                  motionDurationMid: "0.2s",
                  motionDurationSlow: "0.3s",
                  motionEaseInOut: "cubic-bezier(0.645, 0.045, 0.355, 0.1)",
                  paddingXXS: 4,
                },
              },
            }}
          >
            <Segmented
              defaultValue={listViewType}
              onChange={(value) => {
                let newViewType = value;
                // Check if the current pathname is "/ideas" and value is "classiclist"
                if (pathname === "/ideas" && value === "classiclist") {
                  newViewType = "modernlist"; // Change to modernlist if conditions are met
                }

                toggleViewClick(newViewType);
              }}
              options={[
                {
                  label: "List",
                  value: getOptionValue(),
                  icon: <BarsOutlined />,
                },
                {
                  label: "Board",
                  value: "Kanban",
                  icon: <LuKanban className=" inline " />,

                  // icon: <AppstoreOutlined />,
                },
              ]}
            />
          </ConfigProvider>
        </div>
      )}
      {with_group_by_option && (
        <div
          className={`relative text-sm mr-2 text-nowrap  cursor-pointer rounded p-1.5 hover:bg-topbar-button-hover ${
            displayGroupByButtonDropdownOptions
              ? "bg-button-active_10 text-title "
              : ""
          }`}
          onClick={() => {
            toggleGroupByButtonDropdown();
          }}
        >
          <h2 className=" flex items-center gap-2 ">
            <IconWrapper icon={React.createElement(Layers)} size={14} />
            Group
          </h2>
          {/* <span
            className={` bg-topbar-button-active text-xs    text-title_50 rounded p-1 ml-1`}
          >
            {activeGroupByOption.label}
          </span> */}
        </div>
      )}
      {displayGroupByButtonDropdownOptions && (
        <GroupByDropdown
          groupByDropdownOptions={groupByDropdownOptions}
          activeOption={activeGroupByOption.label}
          // groupByDropdownOptions={(groupByDropdownOptions ?? []).filter(
          //   (option) => option.label !== activeGroupByOption.label
          // )}
          handleGroupByOptionClick={handleGroupByOptionClick}
        />
      )}
      {rightButtons && (
        <li className="flex gap-x-8 items-center mr-2 ">
          <SortOptionsDropdown
            sortOptions={sortOptions}
            selectedSortOption={selectedSortOption}
            displaySortButtonDropdownOptions={displaySortButtonDropdownOptions}
            toggleSortButtonDropdown={toggleSortButtonDropdown}
            handleSortOptionClick={handleSortOptionClick}
            sortOrder={sortOrder}
          />
        </li>
      )}

      <div className="flex items-center gap-x-4">
        {rightButtons.map((button, index) => (
          <div
            key={index}
            className={`flex items-center gap-x-2 cursor-pointer p-1.5 hover:bg-topbar-button-hover rounded ${
              button.label === "Display"
                ? displayDisplayButtonDropdownOptions
                  ? "bg-button-active_10 text-title_50"
                  : ""
                : displayFilterButtonDropdownOptions
                ? "bg-button-active_10 text-title_50"
                : ""
            }`}
            onClick={
              button.label === "Display"
                ? toggleShowButtonDropdown
                : toggleFilterButtonDropdown
            }
          >
            <IconWrapper
              icon={React.createElement(button.icon)}
              size={button.label === "Filter" ? 18 : 14}
            />
            <span>{button.label}</span>
          </div>
        ))}
      </div>

      {displayDisplayButtonDropdownOptions && (
        <DisplayOptionsDropdown
          displayDisplayButtonDropdownOptions={
            displayDisplayButtonDropdownOptions
          }
          sortOptions={sortOptions}
          selectedSortOption={selectedSortOption}
          displaySortButtonDropdownOptions={displaySortButtonDropdownOptions}
          toggleSortButtonDropdown={toggleSortButtonDropdown}
          handleSortOptionClick={handleSortOptionClick}
          showButtonDropdownOptions={showButtonDropdownOptions}
          handleShowOptionClick={handleShowOptionClick}
          selectedColumns={selectedColumns}
          sortOrder={sortOrder}
          viewType={viewType}
        />
      )}
      {displayFilterButtonDropdownOptions && (
        <FilterOptionsDropdown
          filterOptions={filterOptions}
          selectedFilterOption={selectedFilterOption}
          subFilterDropdownOptions={subFilterDropdownOptions}
          handleFilterOptionClick={handleFilterOptionClick}
          handleSubFilterOptionClick={handleSubFilterOptionClick}
          filters={filters}
          handleResetFilters={handleResetFilterClick}
          handleRemoveFilter={handleRemoveFilter}
        />
      )}
    </div>
  );
};

export default TopbarOptionButtons;
