import sanitizeHtml from "sanitize-html";

export const isEmptyDescription = (description) => {
  const sanitizedDescription = sanitizeHtml(description, {
    allowedTags: [],
    allowedAttributes: {},
  }).trim();

  return !sanitizedDescription;
};
