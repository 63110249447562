/* eslint-disable react/prop-types */
import React, { useMemo } from "react";
import { fieldIconMapping } from "utils";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveObjectiveId,
  setObjectiveShowDetailViewDrawer,
} from "reduxStore/slices/objectiveSlice";
import KanbanModuleSection from "./KanbanModuleSection";

const KanbanObjectivesSection = ({ objectives }) => {
  const dispatch = useDispatch();
  const { objectives: allObjectives } = useSelector(
    (state) => state.objectives
  );

  const filteredObjectives = useMemo(() => {
    return (allObjectives ?? []).filter((objective) =>
      (objectives ?? []).includes(objective._id)
    );
  }, [objectives, allObjectives]);

  const handleObjectiveClick = (objective) => {
    dispatch(setActiveObjectiveId(objective._id));
    dispatch(setObjectiveShowDetailViewDrawer(true));
  };

  return (
    <KanbanModuleSection
      items={filteredObjectives}
      icon={fieldIconMapping.objective}
      truncateLength={filteredObjectives?.length > 1 ? 28 : 40}
      iconColor="text-objectives-icon"
      textColor="text-title_50"
      onItemClick={handleObjectiveClick}
    />
  );
};

export default KanbanObjectivesSection;
