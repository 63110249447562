import { createSlice } from "@reduxjs/toolkit";

// Initial state for company data
const initialState = {
  featureStatuses: null,
  ideaStatuses: null,
  releases: null,
  products: null,
  tags: null,
  scoreType: null,
  timeFrames: null,
  statuses: {
    feature: [],
    idea: [],
    objective: [],
    roadmap: [],
    key_result: [],
  },
};

// Create a slice for company data
const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setCompanyFeatureStatuses(state, action) {
      state.featureStatuses = action.payload;
    },
    setCompanyIdeaStatuses(state, action) {
      state.ideaStatuses = action.payload;
    },
    updateIdeaStatus(state, action) {
      const { updatedStatus, domain } = action.payload;

      const index = state.ideaStatuses[domain]?.findIndex(
        (status) => status._id === updatedStatus._id
      );

      if (index !== -1 && state.ideaStatuses[domain]) {
        state.ideaStatuses[domain][index] = updatedStatus;
      } else {
        console.error("Domain or status not found");
      }
    },
    setReleases(state, action) {
      state.releases = action.payload;
    },
    appendRelease(state, action) {
      const addedRelease = action.payload;
      if (!state.releases) {
        state.releases = [];
      }
      state.releases.push(addedRelease);
    },
    updateRelease(state, action) {
      const updatedRelease = action.payload;
      const index = state.releases.findIndex(
        (release) => release._id === updatedRelease._id
      );
      if (index !== -1) {
        state.releases[index] = updatedRelease;
      }
    },
    removeRelease(state, action) {
      const deletedReleaseId = action.payload;
      const index = state.releases.findIndex(
        (release) => release._id === deletedReleaseId
      );

      if (index !== -1) {
        state.releases.splice(index, 1);
      }
    },
    setTags(state, action) {
      state.tags = action.payload;
    },
    appendTag(state, action) {
      const addedTag = action.payload;
      if (!state.tags) {
        state.tags = [];
      }
      state.tags.push(addedTag);
    },
    updateTag(state, action) {
      const updatedTag = action.payload;
      const index = state.tags.findIndex((tag) => tag._id === updatedTag._id);
      if (index !== -1) {
        state.tags[index] = updatedTag;
      }
    },
    removeTag(state, action) {
      const deletedTagId = action.payload;
      const index = state.tags.findIndex((tag) => tag._id === deletedTagId);

      if (index !== -1) {
        state.tags.splice(index, 1);
      }
    },
    setProducts(state, action) {
      state.products = action.payload;
    },
    appendProduct(state, action) {
      const addedProduct = action.payload;
      if (!state.products) {
        state.products = [];
      }
      state.products.push(addedProduct);
    },
    updateProduct(state, action) {
      const updatedProduct = action.payload;
      const index = state.products?.findIndex(
        (product) => product._id === updatedProduct._id
      );

      if (index !== -1 && state.products) {
        state.products[index] = updatedProduct;
      }
    },
    removeProduct(state, action) {
      const deletedProductId = action.payload;
      const index = state.products.findIndex(
        (product) => product._id === deletedProductId
      );

      if (index !== -1) {
        state.products.splice(index, 1);
      }
    },
    setTimeFrames(state, action) {
      state.timeFrames = action.payload;
    },
    appendTimeFrame(state, action) {
      const addedTimeFrame = action.payload;
      if (!state.timeFrames) {
        state.timeFrames = [];
      }
      state.timeFrames.push(addedTimeFrame);
    },
    updateTimeFrame(state, action) {
      const updatedTimeFrame = action.payload;
      const index = state.timeFrames?.findIndex(
        (timeFrame) => timeFrame._id === updatedTimeFrame._id
      );

      if (index !== -1 && state.timeFrames) {
        state.timeFrames[index] = updatedTimeFrame;
      }
    },
    removeTimeFrame(state, action) {
      const deletedTimeFrameId = action.payload;
      const index = state.timeFrames.findIndex(
        (timeFrame) => timeFrame._id === deletedTimeFrameId
      );

      if (index !== -1) {
        state.timeFrames.splice(index, 1);
      }
    },
    setScoreScoreType(state, action) {
      state.scoreType = action.payload;
    },

    /// add ankur verma

    setStatuses(state, action) {
      const { statuses } = action.payload;
      const existingStatusMap = {
        feature: new Set(state.statuses.feature.map((status) => status._id)),
        idea: new Set(state.statuses.idea.map((status) => status._id)),
        objective: new Set(
          state.statuses.objective.map((status) => status._id)
        ),
        roadmap: new Set(state.statuses.roadmap.map((status) => status._id)),
        key_result: new Set(
          state.statuses.key_result.map((status) => status._id)
        ),
      };

      statuses.forEach((status) => {
        const { module, _id } = status;

        if (state.statuses[module]) {
          if (!existingStatusMap[module].has(_id)) {
            state.statuses[module].push(status);
            existingStatusMap[module].add(_id);
          } else {
            const index = state.statuses[module].findIndex(
              (s) => s._id === _id
            );
            if (index !== -1) {
              state.statuses[module][index] = status;
            }
          }
        } else {
          console.error(`Invalid module: ${module}`);
        }
      });
    },

    appendStatus(state, action) {
      const { status } = action.payload;
      const { module } = status;
      if (state.statuses[module]) {
        state.statuses[module].push(status);
      } else {
        console.error("Invalid module");
      }
    },
    updateStatus(state, action) {
      const { status } = action.payload;
      const { module } = status;
      if (state.statuses[module]) {
        const index = state.statuses[module].findIndex(
          (s) => s._id === status._id
        );
        if (index !== -1) {
          state.statuses[module][index] = status;
        } else {
          console.error("Status not found");
        }
      } else {
        console.error("Invalid module");
      }
    },
    removeStatus(state, action) {
      const { module, statusId } = action.payload;
      if (state.statuses[module]) {
        state.statuses[module] = state.statuses[module].filter(
          (status) => status._id !== statusId
        );
      } else {
        console.error("Invalid module");
      }
    },
    clearStatuses(state) {
      // Reset the statuses for all modules
      state.statuses = {
        feature: [],
        idea: [],
        objective: [],
        roadmap: [],
        key_result: [],
      };
    },

    /// end ankur module
  },
});

// Export actions to use in components
export const {
  setCompanyFeatureStatuses,
  setCompanyIdeaStatuses,
  updateIdeaStatus,
  appendStatus,
  setReleases,
  appendRelease,
  updateRelease,
  removeRelease,
  setProducts,
  appendProduct,
  updateProduct,
  removeProduct,
  removeTag,
  setTags,
  appendTag,
  updateTag,
  setTimeFrames,
  appendTimeFrame,
  updateTimeFrame,
  removeTimeFrame,
  setScoreScoreType,
  setStatuses,
  updateStatus,
  removeStatus,
  clearStatuses,
} = companySlice.actions;

// Export the reducer to be included in the store
export const companyReducer = companySlice.reducer;
