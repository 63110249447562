import {
  groupByObjective,
  groupByProduct,
  groupByRelease,
  groupByStatus,
  groupByTag,
} from "./grouping";

export const groupDataByFeature = (
  data = [],
  groupBy,
  statuses = {},
  releases,
  products,
  objectives,
  tags
) => {

  const groupByFunc = {
    status: () => groupByStatus(data, statuses),
    release: () => groupByRelease(data, releases),
    product: () => groupByProduct(data, products),
    tags: () => groupByTag(data, tags),
    objective: () => groupByObjective(data, objectives),
    default: () => ({ all: data }),
  };
  return (groupByFunc[groupBy] || groupByFunc.default)();
};
