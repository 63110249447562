/* eslint-disable react/prop-types */
import React from "react";
import { FaSearch } from "react-icons/fa";

const Searchbar = ({
  value,
  onChange,
  placeholder = "Search... ",
  pad = "p-2",
}) => {
  return (
    <div className="relative w-full">
      <FaSearch className="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-300" />
      <input
        type="text"
        className={`w-full ${pad} pl-9 border border-gray-300 rounded text-sm focus:outline-none focus:ring-2 focus:ring-blue-200 text-title`}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        required
      />
    </div>
  );
};

export default Searchbar;
