/* eslint-disable react/prop-types */
import React from "react";
import IconText from "components/common/IconText";

const ModuleRowSection = ({ moduleItems = [], icon, iconColor }) => {
  return (
    <div className={`flex items-center gap-x-2 rounded`}>
      {moduleItems.length > 0 && (
        <IconText
          icon={icon}
          text={moduleItems?.[0]?.title ?? ""}
          iconColor={iconColor}
          truncateLength={20}
          textSize="text-xs"
          textColor="text-title_50"
          px="px-1"
          py="py-1"
          gap_x="gap-x-1.5"
          hoverable={false}
        />
      )}
      {moduleItems?.length > 1 && (
        <div className="text-xs">+{moduleItems.length - 1}</div>
      )}
    </div>
  );
};

export default ModuleRowSection;
