/* eslint-disable react/prop-types */
import SortOptionsDropdown from "./SortOptionsDropdown";
import ShowOptionsDropdown from "./ShowOptionsDropdown";

const DisplayOptionsDropdown = ({
  sortOptions,
  selectedSortOption,
  displaySortButtonDropdownOptions,
  toggleSortButtonDropdown,
  handleSortOptionClick,
  showButtonDropdownOptions,
  handleShowOptionClick,
  selectedColumns,
  sortOrder,
  viewType,
}) => {
  return (
    <div
      className={`absolute w-60 bg-white rounded-lg py-2 px-1 mx-1 border-2 shadow-lg right-5 top-full`}
      onClick={
        displaySortButtonDropdownOptions ? toggleSortButtonDropdown : null
      }
    >
      <ul>
        {/* <li className="flex gap-x-8 items-center p-2">
          <span>Sort by</span>
          <SortOptionsDropdown
            sortOptions={sortOptions}
            selectedSortOption={selectedSortOption}
            displaySortButtonDropdownOptions={displaySortButtonDropdownOptions}
            toggleSortButtonDropdown={toggleSortButtonDropdown}
            handleSortOptionClick={handleSortOptionClick}
            sortOrder={sortOrder}
          />
        </li>
        <hr className="my-2 mx-auto w-5/6" /> */}

        <ShowOptionsDropdown
          showButtonDropdownOptions={showButtonDropdownOptions}
          handleShowOptionClick={handleShowOptionClick}
          selectedColumns={selectedColumns}
          viewType={viewType}
        />
      </ul>
    </div>
  );
};

export default DisplayOptionsDropdown;
