/* eslint-disable react/prop-types */
import React, { useState } from "react";
import ReactQuill from "react-quill";
import { CrossSmall } from "react-flaticons";
import "react-quill/dist/quill.snow.css";

const QuillEditor = ({ initialContent = "", onClose, handleFormSubmit }) => {
  const [editorContent, setEditorContent] = useState(initialContent);

  const handleEditorChange = (content) => {
    setEditorContent(content);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (handleFormSubmit) {
      handleFormSubmit(editorContent);
    }
    // Optionally, you can close the editor after submitting
    onClose();
  };

  const modules = {
    toolbar: [
      // [{ header: "1" }, { header: "2" }],
      [{ header: "2" },"bold", "italic", "underline", "strike"],
      // ["bold", "italic", "underline", "strike"],
      // ["blockquote"],
      // [{ list: "ordered" }, { list: "bullet" }],
      // [{ color: [] }, { background: [] }],
      // ["link", "image"],
    ],
  };

  return (
    <div className="flex flex-col h-[420px] relative">
      {/* <div
        className="absolute top-2 right-2 cursor-pointer hover:bg-topbar-button-hover rounded p-1"
        onClick={onClose}
      >
        <CrossSmall size={16} />
      </div> */}
      <ReactQuill
        value={editorContent}
        onChange={handleEditorChange}
        theme="snow"
        modules={modules}
        placeholder="Start writing..."
        className="h-3/4 rounded-lg outline-none"
        style={{ border: "none" }}
      />
      <div className="flex gap-2 pt-3 ">
        <button
          onClick={handleSubmit}
          className="px-3 py-2 text-xs bg-blue-500 text-white rounded hover:bg-blue-700 transition duration-300"
        >
          Save
        </button>
        <button
          onClick={onClose}
          className="px-3 py-2 text-xs   hover:bg-button-hover  rounded "
        >
          Discard
        </button>
      </div>
      {/* <button
        className="mt-12 ml-auto px-3 py-1 text-xs bg-blue-500 text-white rounded hover:bg-blue-700 transition duration-300"
        onClick={handleSubmit}
      >
        Save
      </button> */}
    </div>
  );
};

export default QuillEditor;
