import React, { useEffect, useState, useRef } from "react";
import { AngleSmallDown, AngleSmallUp } from "react-flaticons";
import DropdownItem from "components/common/DropdownItem";
import ActiveModuleStatusField from "./ActiveModuleStatusField";
import { useDispatch } from "react-redux";
import { getStatuses } from "reduxStore/operations/statusAPI";
import {
  addStatuses,
  deleteStatuses,
  updateStatuses,
} from "reduxStore/operations/statusAPI";

const StatusField = () => {
  const dispatch = useDispatch();
  const moduleOptions = [
    { label: "Feature" },
    { label: "Idea" },
    { label: "Objective" },
    { label: "Roadmap" },
    // { label: "Key result" },
  ];
  const [activeModuleOption, setActiveModuleOption] = useState(
    moduleOptions[0]
  );
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleModuleSelect = (option) => {
    setActiveModuleOption(option);
    setIsDropdownOpen(false);
  };

  const handleAddStatus = (newStatus) => {
    dispatch(addStatuses(newStatus));
  };

  const handleEditStatus = (statusId, updatedValue) => {
    dispatch(updateStatuses(statusId, updatedValue));
  };

  const handleDeleteStatus = (statusId, replaceId) => {
    dispatch(deleteStatuses(statusId, replaceId.id, replaceId.module));
  };

  useEffect(() => {
    dispatch(getStatuses());
  }, [dispatch]);

  useEffect(() => {
    // Handler for clicks outside of the dropdown
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    // Add event listener for clicks outside
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="flex items-center gap-x-5 text-sm cursor-default">
        <span className="text-title font-semibold">Object</span>
        <span
          className="flex relative cursor-pointer justify-between items-center p-2 border rounded w-32"
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          ref={dropdownRef}
        >
          {activeModuleOption.label.replace("_", " ")}
          {isDropdownOpen ? (
            <AngleSmallUp color="#696969" size={14} />
          ) : (
            <AngleSmallDown color="#696969" size={14} />
          )}
          {isDropdownOpen && (
            <ul className="absolute top-full left-0 border rounded shadow bg-white z-10 w-full">
              {moduleOptions.map((option, index) => (
                <DropdownItem
                  key={index}
                  option={option}
                  handleOptionClick={handleModuleSelect}
                />
              ))}
            </ul>
          )}
        </span>
      </div>
      <div className="mt-10 cursor-default">
        <ActiveModuleStatusField
          module={activeModuleOption.label.toLowerCase()}
          handleAddStatus={handleAddStatus}
          handleEditStatus={handleEditStatus}
          handleDeleteStatus={handleDeleteStatus}
        />
      </div>
    </>
  );
};

export default StatusField;
