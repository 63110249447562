export const initializePendo = (userInfo) => {
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://cdn.pendo.io/agent/static/${import.meta.env.VITE_PENDO_KEY}/pendo.js`;
    document.head.appendChild(script);
  
    script.onload = () => {
      if (window.pendo) {
        window.pendo.initialize(userInfo);
      }
    };
};

  export const trackEvent = (eventName, metadata) => {
    if (window.pendo) {
      window.pendo.track(eventName, metadata);
    }
  };
  