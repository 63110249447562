/* eslint-disable react/prop-types */
import React from "react";
import IconText from "components/common/IconText";
// import DemoProfileImage from "assets/demo_profile_image.webp";
import { fieldIconMapping } from "utils";
import ProfileIcon from "../../ProfileIcon";

const AssigneeSection = ({
  itemLabel,
  position = "gap-x-4",
}) => {
  return (
    <div className={`flex ${position} items-center text-title_50`}>
      <IconText
        icon={React.createElement(fieldIconMapping["assignee"])}
        text={"Assignee"}
        iconColor="#696969"
        iconSize={16}
        hoverable={false}
        gap_x="gap-x-2"
      />
      <div className="flex items-center gap-x-1 text-sm">
        {/* <img
          src={DemoProfileImage}
          alt="Profile"
          className="w-6 h-6 rounded-full"
        /> */}
        <ProfileIcon name={itemLabel ?? "N A"} />
        {/* <span className="text-primary">Chris</span> */}
      </div>
    </div>
  );
};

export default AssigneeSection;
