import {
  setActiveFeatureId,
  setFeatureShowDetailViewDrawer,
} from "reduxStore/slices/featureSlice";
import {
  setActiveKeyResultId,
  setDetailKeyResults,
  setKeyResultShowDetailViewDrawer,
} from "reduxStore/slices/keyResultSlice";
import {
  setActiveObjectiveId,
  setObjectiveShowDetailViewDrawer,
} from "reduxStore/slices/objectiveSlice";
import {
  setActiveIdeaId,
  setIdeaShowDetailViewDrawer,
} from "reduxStore/slices/ideaSlice";
import {
  setActiveRoadmapId,
  setRoadmapShowDetailViewDrawer,
} from "reduxStore/slices/roadmapsSlice";

export const resetActiveIds = ({
  dispatch,
  resetFeature = true,
  resetIdea = true,
  resetKeyResult = true,
  resetObjective = true,
  resetRoadmap = true,
}) => {
  if (resetIdea) {
    dispatch(setActiveIdeaId(null));
    dispatch(setIdeaShowDetailViewDrawer(false));
  }

  if (resetRoadmap) {
    dispatch(setActiveRoadmapId(null));
    dispatch(setRoadmapShowDetailViewDrawer(false));
  }

  if (resetObjective) {
    dispatch(setActiveObjectiveId(null));
    dispatch(setObjectiveShowDetailViewDrawer(false));
  }

  if (resetFeature) {
    dispatch(setActiveFeatureId(null));
    dispatch(setFeatureShowDetailViewDrawer(false));
  }

  if (resetKeyResult) {
    dispatch(setDetailKeyResults(null));
    dispatch(setActiveKeyResultId(null));
    dispatch(setKeyResultShowDetailViewDrawer(false));
  }
};
