/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generateStatusArray } from "utils/status";
import StatusSection from "components/common/detailView/detailViewRightSidebarSections/StatusSection";
import ProductSection from "components/common/detailView/detailViewRightSidebarSections/ProductSection";
import TagsSection from "components/common/detailView/detailViewRightSidebarSections/TagsSection";
import AssigneeSection from "components/common/detailView/detailViewRightSidebarSections/AssigneeSection";
import ScoreSection from "components/common/detailView/detailViewRightSidebarSections/ScoreSection";
import AccountsSection from "components/common/detailView/detailViewRightSidebarSections/AccountsSection";
import ImportanceSection from "components/common/detailView/detailViewRightSidebarSections/ImportanceSection";
import RoadmapsSection from "components/common/detailView/detailViewRightSidebarSections/RoadmapsSection";
import { updateIdeaData } from "reduxStore/operations/ideasAPI";
import {
  updateIdeaTags,
  updateIdeaAccounts,
  updateIdeaScore,
} from "reduxStore/operations/ideasAPI";

const IdeaDetailViewRightDetailSidebar = ({ item }) => {
  const dispatch = useDispatch();
  const { statuses, products, tags } = useSelector((state) => state.company);
  const [statusArray, setStatusArray] = useState(null);
  const [allProducts, setAllProducts] = useState(null);
  const { accounts } = useSelector((state) => state.accounts);
  const [allIdeaStatuses, setAllIdeaStatuses] = useState(null);

  useEffect(() => {
    setAllIdeaStatuses(statuses.idea);
  }, [statuses]);

  useEffect(() => {
    if (products !== null) {
      setAllProducts(products);
    }
  }, [products]);

  useEffect(() => {
    if (allIdeaStatuses !== null) {
      setStatusArray(generateStatusArray(allIdeaStatuses));
    }
  }, [allIdeaStatuses]);

  return (
    <div className="flex flex-col gap-y-2 h-full overflow-auto custom-scrollbar">
      <StatusSection
        item={item?.status ?? {}}
        statusArray={statusArray}
        handleStatusOptionClick={(option) => {
          let isUpdated = "status";
          let { icon, ...restOption } = option;
          dispatch(
            updateIdeaData(
              item._id,
              { status: option._id },
              item,
              isUpdated,
              restOption
            )
          );
        }}
        position="gap-x-10"
        dropdownMenuWidth="w-36"
      />
      <ImportanceSection
        value={item?.importance ?? 0}
        handleImportanceOptionClick={(option) => {
          let isUpdated = "importance";
          dispatch(
            updateIdeaData(
              item._id,
              { importance: option },
              item,
              isUpdated,
              option
            )
          );
        }}
      />
      <AccountsSection
        item={item}
        accounts={accounts}
        position="gap-x-6"
        handleAccountOptionClick={(option) => {
          dispatch(updateIdeaAccounts(option, item));
        }}
      />
      <ProductSection
        item={item.product}
        products={allProducts}
        handleProductOptionClick={(option) => {
          let isUpdated = "product";
          let { icon, ...restOption } = option;
          dispatch(
            updateIdeaData(
              item._id,
              { product: option._id },
              item,
              isUpdated,
              restOption
            )
          );
        }}
        position="gap-x-8"
      />
      <TagsSection
        item={item}
        position="gap-x-14"
        tags={tags ?? []}
        handleTagOptionClick={(option) => {
          dispatch(updateIdeaTags(option, item));
        }}
      />
      <AssigneeSection
        itemLabel={item?.assignee?.name ?? ""}
        position="gap-x-6"
      />
      <hr className="my-4" />
      <ScoreSection
        item={item}
        updateScore={(option) => {
          dispatch(
            updateIdeaScore(
              item._id,
              item.score.type,
              option.value,
              option.effort,
              option.impact,
              option.reach,
              option.confidence
            )
          );
        }}
      />
      <hr className="my-4" />
      <div className="ml-1.5 text-sm mb-1">Roadmaps</div>
      {item?.roadmaps?.length > 0 && (
        <RoadmapsSection item={item} roadmaps={item?.roadmaps} />
      )}
    </div>
  );
};

export default IdeaDetailViewRightDetailSidebar;
