/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from "react";
import { capitalizeFirstLetter, isObjectEmpty } from "utils";
import { generateStatusArray } from "utils/status";
import Loader from "components/common/Loader";
import ObjectiveRowComponent from "./ObjectiveRowComponent";
import { useSelector } from "react-redux";
import ListViewGroupHeader from "components/common/classicList/ListViewGroupHeader";
import InitiativeBasedRows from "../../typeBasedSections/InitiativeBasedRows";
import KeyResultBasedRows from "../../typeBasedSections/KeyResultBasedRows";

const ObjectiveListView = ({
  groupedData,
  selectedColumns,
  collapsedGroups,
  toggleGroupCollapse,
  selectedObjectives,
  handleObjectiveSelect,
  handleGroupSelect,
  groupByOption,
}) => {
  const { statuses } = useSelector((state) => state.company);
  const { objectives, isLoading, isViewsLoading, filters } = useSelector(
    (state) => state.objectives
  );
  const [objectiveStatuses, setObjectiveStatuses] = useState([]);

  useEffect(() => {
    setObjectiveStatuses(statuses.objective);
  }, [statuses]);

  const statusArray = useMemo(
    () => generateStatusArray(objectiveStatuses),
    [objectiveStatuses]
  );

  const [localGroupByOption, setLocalGroupByOption] = useState(null);
  const [showInitiatives, setShowInitiatives] = useState([]);
  const [showKeyResults, setShowKeyResults] = useState([]);

  useEffect(() => {
    setLocalGroupByOption(groupByOption);
  }, [groupByOption]);

  const hasData =
    groupedData &&
    Object.keys(groupedData).length > 0 &&
    Object.values(groupedData).some((groupData) => groupData.items.length > 0);

  const loading =
    ((localGroupByOption === null || localGroupByOption !== groupByOption) &&
      isObjectEmpty(filters)) ||
    isLoading ||
    isViewsLoading;

  return (
    <div className="w-full h-full overflow-x-auto">
      {loading ? (
        <div className="flex items-center justify-center h-full">
          <Loader />
        </div>
      ) : (
        <table className="table-auto bg-white">
          <thead className="sticky z-30 bg-white top-0 text-sm">
            <tr>
              <th className="min-w-[44rem] sticky top-0 left-0 text-primary text-start pt-5 pl-6 font-normal bg-white">
                Objective
              </th>
              {selectedColumns?.map((header, index) => (
                <th
                  key={index}
                  className="text-start text-primary font-normal min-w-32 pt-5 px-3"
                >
                  {capitalizeFirstLetter(header)}
                </th>
              ))}
            </tr>
          </thead>
          {objectives && !hasData && localGroupByOption === "default" ? (
            <div className="absolute flex items-center justify-center h-[87%] w-full">
              <p className="text-lg text-gray-500">No objective to show.</p>
            </div>
          ) : (
            Object.entries(groupedData).map(
              ([groupId, groupData], groupIndex) => (
                <React.Fragment key={groupIndex}>
                  {localGroupByOption !== "default" && (
                    <ListViewGroupHeader
                      group={{
                        _id: groupId,
                        color: groupData.color,
                        label: groupData.label,
                      }}
                      groupByOption={localGroupByOption}
                      items={groupData.items}
                      selectedItems={selectedObjectives}
                      toggleGroupCollapse={toggleGroupCollapse}
                      handleGroupSelect={handleGroupSelect}
                      collapsedGroups={collapsedGroups}
                      statusArray={statusArray}
                    />
                  )}
                  <tbody>
                    {!collapsedGroups[groupId] &&
                      groupData.items?.map((objective, index) => (
                        <React.Fragment key={index}>
                          <ObjectiveRowComponent
                            objective={objective}
                            onSelect={handleObjectiveSelect}
                            isSelected={selectedObjectives.has(objective._id)}
                            setShowInitiatives={setShowInitiatives}
                            showInitiatives={showInitiatives}
                            setShowKeyResults={setShowKeyResults}
                            showKeyResults={showKeyResults}
                          />
                          {showInitiatives.includes(objective._id) &&
                            objective?.type === "Initiative_based" && (
                              <InitiativeBasedRows
                                objective={objective}
                                selectedColumns={selectedColumns}
                              />
                            )}

                          {showKeyResults.includes(objective._id) &&
                            objective?.type === "Key_result_based" && (
                              <KeyResultBasedRows
                                objective={objective}
                                selectedColumns={selectedColumns}
                              />
                            )}
                        </React.Fragment>
                      ))}
                  </tbody>
                </React.Fragment>
              )
            )
          )}
        </table>
      )}
    </div>
  );
};

export default ObjectiveListView;
