/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import IconText from "components/common/IconText";
import IconWrapper from "components/common/IconWrapper";
import { fieldIconMapping } from "utils";
import TagsSearchDropdown from "../dropdown/TagsSearchDropdown";

const TagsSection = ({
  item,
  position = "gap-x-12",
  with_title = true,
  with_icon_before_tags = false,
  py = "py-3.5",
  tags = [],
  handleTagOptionClick,
  dropdownPosition = "top-full right-0",
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedTags, setSelectedTags] = useState(
    new Set((item?.tags ?? []).map((tag) => tag._id))
  );
  const [selectTags, setSelectTags] = useState(item?.tags);

  useEffect(() => {
    if (item && item.tags) {
      const initialTags = new Set((item?.tags ?? []).map((tag) => tag._id));
      setSelectedTags(initialTags);
      setSelectTags(item?.tags);
    }
  }, [item]);

  const initialSelectedTags = new Set((item?.tags ?? []).map((tag) => tag._id));
  const dropdownRef = useRef(null);

  const getSelectedTagObjects = () => {
    return Array.from(selectedTags).map((id) =>
      selectTags.find((tag) => tag._id === id)
    );
  };

  const toggleDropdown = (e) => {
    e.stopPropagation();
    if (!areSetsEqual(initialSelectedTags, selectedTags)) {
      handleTagOptionClick({
        tags: Array.from(selectedTags),
        itemId: item?._id,
        fullTags: getSelectedTagObjects(),
      });
    }
    setIsDropdownOpen((prev) => !prev);
  };

  const onItemSelect = (option) => {
    const tagId = option._id;
    const updatedTags = new Set(selectedTags);

    if (updatedTags.has(tagId)) {
      updatedTags.delete(tagId);
      setSelectTags((prevTags) => prevTags.filter((tag) => tag._id !== tagId));
    } else {
      updatedTags.add(tagId);
      setSelectTags((prevTags) => [...prevTags, option]);
    }

    setSelectedTags(updatedTags);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);

        // Trigger API call only if the selected tags have changed
        if (!areSetsEqual(initialSelectedTags, selectedTags)) {
          handleTagOptionClick({
            tags: Array.from(selectedTags),
            itemId: item?._id,
            fullTags: getSelectedTagObjects(),
          });
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectedTags, handleTagOptionClick, item?._id]);

  // Helper function to check if two sets are equal
  const areSetsEqual = (setA, setB) => {
    if (setA.size !== setB.size) return false;
    for (let item of setA) if (!setB.has(item)) return false;
    return true;
  };

  return (
    <div
      className={`flex items-start ${position} text-title_50`}
      onClick={(e) => e.stopPropagation()}
    >
      {with_title && (
        <IconText
          icon={React.createElement(fieldIconMapping.tags)}
          text="Tags"
          iconColor="#696969"
          iconSize={16}
          hoverable={false}
          gap_x="gap-x-2"
        />
      )}
      <div className={`flex flex-col w-full`}>
        <div
          className={`relative gap-2 -ml-0.5 my-0.5 ${
            item?.tags?.length > 0
              ? "flex flex-wrap items-center p-1"
              : `pl-2 pr-14 ${py}`
          } ${
            isDropdownOpen ? "bg-button-active_10" : "hover:bg-gray-200"
          } cursor-pointer rounded`}
          onClick={toggleDropdown}
          ref={dropdownRef}
        >
          {with_icon_before_tags && (
            <IconWrapper
              icon={React.createElement(fieldIconMapping.tags)}
              iconColor={"#A9A9A9"}
              size={14}
            />
          )}

          {/* Render selected tags */}
          {(selectTags ?? []).map((tag, index) => (
            <div
              key={index}
              className="rounded bg-tags-active px-1.5 py-1 text-xs"
            >
              {tag?.label ?? ""}
            </div>
          ))}
          {isDropdownOpen && (
            <div
              className={`absolute flex flex-col min-w-48 ${dropdownPosition} mt-0.5 z-50 gap-y-4`}
            >
              <TagsSearchDropdown
                searchPlaceholder="Search tags..."
                items={tags}
                isDropdownOpen={isDropdownOpen}
                selectedItems={selectedTags}
                onItemSelect={onItemSelect}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TagsSection;
