/* eslint-disable react/prop-types */
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useMemo, useState } from "react";
import { generateStatusArray } from "utils/status";
import { fieldIconMapping } from "utils";
import Loader from "components/common/Loader";
import { DragDropContext } from "@hello-pangea/dnd";
import FeatureKanbanColumn from "components/features/views/kanban/FeatureKanbanColumn";
import { updateFeatureData } from "reduxStore/operations/featuresAPI";
import AddItemSidebarDialog from "components/common/roadmapKanbanDialog/AddItemSidebarDialog";
import AddItemSidebarContent from "components/common/roadmapKanbanDialog/AddItemSidebarContent";
import { applyFilters } from "utils/filtering";
import useFilterLogic from "hooks/useFilterLogic";

const FeatureRoadmapsKanbanView = ({
  groupedData,
  roadmap,
  isRoadmapDetailColumn = false,
  filterOptions,
}) => {
  const dispatch = useDispatch();

  const { features } = useSelector((state) => state.features);
  const [selectedFeatures, setSelectedFeatures] = useState(new Set());
  const { groupByOption } = useSelector((state) => state.features);
  const { statuses } = useSelector((state) => state.company);
  const allFilterOptions = [...filterOptions];
  const [activeAddDropdown, setActiveAddDropdown] = useState(null);
  const [featureStatuses, setFeatureStatuses] = useState([]);
  const [localGroupByOption, setLocalGroupByOption] = useState(null);
  const [filters, setFilters] = useState({});

  const updateFeatureFilter = ({ key, value }) => {
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters };

      if (!newFilters[key]) {
        newFilters[key] = [value];
      } else if (newFilters[key].includes(value)) {
        newFilters[key] = newFilters[key].filter((v) => v !== value);
      } else {
        newFilters[key] = [...newFilters[key], value];
      }

      if (newFilters[key].length === 0) {
        delete newFilters[key];
      }

      return newFilters;
    });
  };

  const topbarFunctions = {
    updateFilter: (filter) => updateFeatureFilter(filter),
    clearFilters: () => setFilters({}),
  };

  const {
    selectedFilterOption,
    subFilterDropdownOptions,
    setSelectedFilterOption,
    setSubFilterDropdownOptions,
    handleFilterOptionClick,
    handleSubFilterOptionClick,
    handleResetFilterClick,
    handleRemoveFilter,
  } = useFilterLogic(topbarFunctions);

  const resetFilterRelatedOptions = () => {
    setSelectedFilterOption(null);
    setSubFilterDropdownOptions(null);
  };

  useEffect(() => {
    setFeatureStatuses(statuses.feature);
  }, [statuses]);

  const statusArray = useMemo(
    () => generateStatusArray(featureStatuses),
    [featureStatuses]
  );

  useEffect(() => {
    setLocalGroupByOption(groupByOption);
  }, [groupByOption]);

  const allFeatures = (features ?? []).filter(
    (feature) =>
      roadmap?.products?.some(
        (product) => product._id === feature?.product?._id
      ) &&
      !roadmap?.releases?.some(
        (release) => release._id === feature?.release?._id
      )
  );
  const filteredFeatures = applyFilters(allFeatures, filters);

  const handleFeatureSelect = (feature) => {
    setSelectedFeatures((prevSelected) => {
      const newSelected = new Set(prevSelected);
      newSelected.has(feature._id)
        ? newSelected.delete(feature._id)
        : newSelected.add(feature._id);
      return newSelected;
    });
  };

  const removeSelectedFeatures = () => {
    setSelectedFeatures(new Set());
  };

  const updateFeatures = (selectedFeatures) => {
    const updateToReleaseId = activeAddDropdown._id;
    (selectedFeatures ?? []).map((feature) => {
      dispatch(
        updateFeatureData(feature, { release: updateToReleaseId }, "release")
      );
    });
    removeSelectedFeatures();
  };

  const handleOnDragEnd = (result) => {
    const { source, destination, draggableId } = result;
    if (!destination || source.droppableId === destination.droppableId) return;

    if (!roadmap && groupByOption === "default") {
      const updateToStatusId = destination.droppableId;

      dispatch(
        updateFeatureData(draggableId, { status: updateToStatusId }, "status")
      );
    }

    if (
      (roadmap && groupByOption === "default") ||
      groupByOption === "release"
    ) {
      const updateToReleaseId = destination.droppableId;

      dispatch(
        updateFeatureData(
          draggableId,
          { release: updateToReleaseId },
          "release"
        )
      );
    }
    if (groupByOption === "product") {
      const updateToProductId = destination.droppableId;

      dispatch(
        updateFeatureData(
          draggableId,
          { product: updateToProductId },
          "product"
        )
      );
    }
  };

  return (
    <div
      className={`h-full overflow-x-auto ${
        isRoadmapDetailColumn && "bg-gray-50"
      }`}
    >
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <div className="flex gap-x-5 p-4">
          {localGroupByOption === null ||
          localGroupByOption !== groupByOption ? (
            <div className="flex items-center justify-center h-[600px] w-full">
              <Loader />
            </div>
          ) : (
            Object.entries(groupedData)?.map(([groupId, groupData]) => (
              <FeatureKanbanColumn
                key={groupId}
                groupByOption={groupByOption}
                group={{
                  _id: groupId,
                  label: groupData.label,
                  color: groupData.color,
                }}
                itemsForGroup={groupData.items}
                statusArray={statusArray}
                isRoadmapDetailColumn={isRoadmapDetailColumn}
                roadmapTypeIcon={React.createElement(fieldIconMapping.release)}
                AddItemsSidebar={
                  <AddItemSidebarDialog
                    isOpen={activeAddDropdown?._id === groupId}
                    setIsOpen={setActiveAddDropdown}
                    sidebarComponent={
                      <AddItemSidebarContent
                        items={filteredFeatures}
                        filterOptions={allFilterOptions}
                        filters={filters}
                        selectedFilterOption={selectedFilterOption}
                        subFilterDropdownOptions={subFilterDropdownOptions}
                        setSelectedFilterOption={setSelectedFilterOption}
                        setSubFilterDropdownOptions={
                          setSubFilterDropdownOptions
                        }
                        handleFilterOptionClick={handleFilterOptionClick}
                        handleSubFilterOptionClick={handleSubFilterOptionClick}
                        handleResetFilterClick={handleResetFilterClick}
                        handleRemoveFilter={handleRemoveFilter}
                        handleItemSelect={handleFeatureSelect}
                        selectedItems={selectedFeatures}
                        removeSelectedItems={removeSelectedFeatures}
                        updateItems={(features) => updateFeatures(features)}
                        activeAddDropdownLabel={activeAddDropdown?.label}
                        resetFilterRelatedOptions={resetFilterRelatedOptions}
                        iconColor="text-features-icon"
                        icon={fieldIconMapping.feature}
                        placeholder="Search Features..."
                        type="feature"
                      />
                    }
                    handleResetFilters={handleResetFilterClick}
                  />
                }
                activeAddDropdown={activeAddDropdown}
                setActiveAddDropdown={setActiveAddDropdown}
              />
            ))
          )}
        </div>
      </DragDropContext>
    </div>
  );
};

export default FeatureRoadmapsKanbanView;
