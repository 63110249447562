import React from "react";

const Feedback = () => {
  return (
    <div className=" flex justify-center items-center h-[80%] w-full ">
      <p className="text-lg text-gray-500">No feedback to show</p>
    </div>
  );
};

export default Feedback;
