/* eslint-disable react/prop-types */
import React from "react";
import IconText from "components/common/IconText";
import { fieldIconMapping } from "utils";

const KanbanScoreSection = ({ item }) => {
  return (
    <IconText
      icon={React.createElement(fieldIconMapping.score)}
      text={
        item?.score?.type === "Value/Effort"
          ? item?.score?.value_effort_score?.toFixed(1)
          : item?.score?.type === "RICE"
          ? item?.score?.rice_score?.toFixed(1)
          : item?.score?.type === "Impact/Effort" &&
            item?.score?.impact_effort_score?.toFixed(1)
      }
      hoverable={false}
      gap_x="gap-x-1"
      textSize="text-xs"
      textColor="text-title_50"
      px=""
    />
  );
};

export default KanbanScoreSection;
