import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "reduxStore/slices/authSlice";
import Loader from "../common/Loader";
import {
  requestPasswordReset,
  resetPassword,
  verifyOtp,
} from "../../reduxStore/operations/authAPI";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [step, setStep] = useState(1);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth);

  const handleSendOtp = (e) => {
    e.preventDefault();
    dispatch(setLoading(true));
    dispatch(requestPasswordReset(email, setStep));
  };

  const handleVerifyOTP = (e) => {
    e.preventDefault();
    dispatch(setLoading(true));
    dispatch(verifyOtp(email, otp, setStep));
  };

  const isStrongPassword = (password) => {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&^_#])[A-Za-z\d@$!%*?&^_#]{8,}$/;
    return regex.test(password);
  };

  const handleResetPassword = (e) => {
    e.preventDefault();
    if (!isStrongPassword(newPassword)) {
      toast.error(
        "Password require to contain at least 8 characters including one Capital letter, one small letter and one special character."
      );
      return;
    }
    if (newPassword !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }
    dispatch(setLoading(true));
    dispatch(resetPassword(email, newPassword, setStep, navigate));
  };

  return (
    <div className="flex flex-col gap-10 items-center justify-center min-h-screen bg-gray-100">
      <div className="relative bg-white p-8 rounded shadow-md w-full max-w-sm">
        <div className="absolute top-[-140px] right-0 w-full flex items-center justify-center">
          <img src="/logo.png" alt="logo" className="w-[200px] h-[100px]" />
        </div>

        {step === 1 && (
          <>
            <h2 className="text-2xl mb-6 text-center">Reset Password</h2>
            <form onSubmit={handleSendOtp}>
              <div className="mb-4">
                <label className="block text-gray-700">Email</label>
                <input
                  type="email"
                  className="w-full p-2 border border-gray-300 rounded mt-1"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                  required
                />
              </div>
              <button
                type="submit"
                className="w-full bg-blue-500 text-white p-2 rounded mt-[6.5rem] hover:bg-blue-600"
              >
                {loading ? <Loader size="20" color="white" /> : "Send OTP"}
              </button>
            </form>
          </>
        )}

        {step === 2 && (
          <>
            <h2 className="text-2xl mb-6 text-center">Reset Password</h2>
            <form onSubmit={handleVerifyOTP}>
              <div className="mb-4">
                <label className="block text-gray-700">OTP</label>
                <input
                  type="number"
                  className="w-full p-2 border border-gray-300 rounded mt-1"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  placeholder="OTP"
                  required
                />
              </div>
              <button
                type="submit"
                className="w-full bg-blue-500 text-white p-2 rounded mt-[6.5rem] hover:bg-blue-600"
              >
                {loading ? <Loader size="20" color="white" /> : "Verify OTP"}
              </button>
            </form>
          </>
        )}

        {step === 3 && (
          <>
            <h2 className="text-2xl mb-6 text-center">Reset Password</h2>
            <form onSubmit={handleResetPassword}>
              <div className="mb-4">
                <label className="block text-gray-700">New Password</label>
                <input
                  type="password"
                  className="w-full p-2 border border-gray-300 rounded mt-1"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  placeholder="New Password"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Confirm Password</label>
                <input
                  type="password"
                  className="w-full p-2 border border-gray-300 rounded mt-1"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Confirm Password"
                  required
                />
              </div>
              <button
                type="submit"
                className="w-full bg-blue-500 text-white p-2 rounded mt-4 hover:bg-blue-600"
              >
                {loading ? (
                  <Loader size="20" color="white" />
                ) : (
                  "Reset Password"
                )}
              </button>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
