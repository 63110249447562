/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { AngleSmallDown, AngleSmallUp } from "react-flaticons";
import IconText from "components/common/IconText";

const KanbanModuleSection = ({
  items,
  icon,
  truncateLength,
  iconColor,
  textColor,
  onItemClick,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className={`${!items ? "" : "rounded"}`}>
      {/* Display items conditionally */}
      {(isExpanded ? items : items?.slice(0, 1))?.map((item, index) => (
        <div
          key={item._id}
          className="flex items-center justify-between hover:bg-button-hover rounded"
          onMouseEnter={() => setIsHovered(index)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={(e) => {
            e.stopPropagation();
            onItemClick(item);
          }}
        >
          <IconText
            icon={React.createElement(icon)}
            text={item.title}
            iconSize="14"
            truncateLength={items.length > 1 ? truncateLength : 40}
            textColor={textColor}
            textSize="text-xs"
            iconColor={iconColor}
            gap_x="gap-x-2"
            px="px-1"
            hoverable={false}
          />

          {/* Show dropdown or collapse button based on the expanded state */}
          {index === 0 && items.length > 1 && (
            <div className="flex items-center">
              {!isExpanded ? (
                <div className="flex items-center">
                  <div className="bg-white rounded-full">
                    <div className="text-xs text-title_50 bg-button-active_10 p-0.5 rounded-full">
                      {`+${items.length - 1}`}
                    </div>
                  </div>
                  <div
                    className={`mx-1 p-1 hover:bg-topbar-button-hover rounded ${
                      isHovered === index ? "" : "invisible"
                    }`}
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsExpanded(true); // Expand the list
                    }}
                  >
                    <AngleSmallDown size={12} color="black" />
                  </div>
                </div>
              ) : (
                isExpanded &&
                isHovered === index && (
                  <div
                    className="mx-1 p-1 hover:bg-topbar-button-hover rounded"
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsExpanded(false); // Collapse the list
                    }}
                  >
                    <AngleSmallUp size={12} color="black" />
                  </div>
                )
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default KanbanModuleSection;
