/* eslint-disable react/prop-types */
import React from "react";
import IconText from "../../IconText";
import { fieldIconMapping } from "utils";

const AddRoadmapTypeSelection = ({
  forRoadmap,
  setForRoadmap,
  onNextClick,
  resetSelectedColumns,
}) => {
  const text = {
    release:
      "Plan and organize upcoming product releases and track progress efficiently",
    strategy:
      "Outline goals and key initiatives to align product vision with business objectives.",
    ideas:
      "Prioritize tasks by what's happening now, what's coming next, and what's planned for later.",
  };
  return (
    <>
      <div className="text-primary">Choose a type:</div>
      <div className="flex items-center gap-x-2">
        <div
          className={`cursor-pointer border rounded ${
            forRoadmap === "Release"
              ? "bg-button-active_10"
              : "hover:bg-button-hover"
          }`}
        >
          <IconText
            icon={React.createElement(fieldIconMapping.release)}
            text="Release"
            hoverable={forRoadmap !== "Release"}
            iconColor={forRoadmap === "Release" ? "#000000" : "#A9A9A9"}
            onClick={() => {
              if (forRoadmap !== "Release") {
                setForRoadmap("Release");
                resetSelectedColumns();
              }
            }}
            textColor="text-active_50"
            textSize="text-xs"
            gap_x="gap-x-1.5"
          />
        </div>
        <div
          className={`cursor-pointer border rounded ${
            forRoadmap === "Strategy"
              ? "bg-button-active_10"
              : "hover:bg-button-hover"
          }`}
        >
          <IconText
            icon={React.createElement(fieldIconMapping.objective)}
            text="Strategy"
            hoverable={forRoadmap !== "Strategy"}
            iconColor={forRoadmap === "Strategy" ? "#000000" : "#A9A9A9"}
            onClick={() => {
              if (forRoadmap !== "Strategy") {
                setForRoadmap("Strategy");
                resetSelectedColumns();
              }
            }}
            textColor="text-active_50"
            textSize="text-xs"
            gap_x="gap-x-1.5"
          />
        </div>
        <div
          className={`cursor-pointer border rounded ${
            forRoadmap === "Ideas"
              ? "bg-button-active_10"
              : "hover:bg-button-hover"
          }`}
        >
          <IconText
            icon={React.createElement(fieldIconMapping.idea)}
            text="Ideas"
            hoverable={forRoadmap !== "Ideas"}
            iconColor={forRoadmap === "Ideas" ? "#000000" : "#A9A9A9"}
            onClick={() => {
              if (forRoadmap !== "Ideas") {
                setForRoadmap("Ideas");
                resetSelectedColumns();
              }
            }}
            textColor="text-active_50"
            textSize="text-xs"
            gap_x="gap-x-1.5"
          />
        </div>
      </div>
      <div className="text-primary">{text[forRoadmap.toLowerCase()]}</div>
      <hr />
      <button
        className="w-fit ml-auto p-1.5 bg-add-popup-button rounded text-white text-sm"
        onClick={() => onNextClick()}
      >
        Next
      </button>
    </>
  );
};

export default AddRoadmapTypeSelection;
