import Sidebar from "components/core/Sidebar";
import Topbar from "components/core/topbar/Topbar";
import Loader from "components/common/Loader";
import { Minus } from "react-flaticons";
import { setRoadmapActiveProperties } from "reduxStore/slices/roadmapsSlice";
import { objectiveHealths } from "utils/objectiveUtils";
import { capitalizeFirstLetter, fieldIconMapping } from "utils";
import RoadmapsComponent from "components/roadmaps/RoadmapsComponent";
import { useDispatch, useSelector } from "react-redux";
import { roadmapTopbarButtonFunctions } from "utils/topbarButtonFunctions";
import {
  RiProgress5Line,
  RiProgress8Fill,
  RiProgress8Line,
} from "react-icons/ri";
import { TbCircleDashed } from "react-icons/tb";
import React, { useEffect, useState } from "react";
import IconWrapper from "components/common/IconWrapper";
import { roadmapTopbarTabFunctions } from "../../utils/topbarTabFunctions";
import { useNavigate } from "react-router-dom";

const Roadmaps = () => {
  const { statuses, tags, timeFrames } = useSelector((state) => state.company);
  const [roadmapStatuses, setRoadmapStatuses] = useState([]);
  const [searchBarTerm, setSearchbarTerm] = useState("");

  useEffect(() => {
    setRoadmapStatuses(statuses.roadmap);
  }, [statuses]);

  const { viewType, isLoading, isViewsLoading } = useSelector(
    (state) => state.roadmaps
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const topbarFunctions = roadmapTopbarButtonFunctions(dispatch, navigate);
  const topbarTabFunctions = roadmapTopbarTabFunctions(dispatch, navigate);
  const setActiveProperties = (
    sortOption,
    selectedColumns,
    groupByOption,
    filters
  ) => {
    dispatch(
      setRoadmapActiveProperties({
        sortOption,
        selectedColumns,
        groupByOption,
        filters,
      })
    );
  };

  const statusParked =
    (roadmapStatuses ?? []).filter((item) => item.type === "parked") ?? [];
  const statusToDo =
    (roadmapStatuses ?? []).filter((item) => item.type === "to_do") ?? [];
  const statusInProgress =
    (roadmapStatuses ?? []).filter((item) => item.type === "in_progress") ?? [];
  const statusDone =
    (roadmapStatuses ?? []).filter((item) => item.type === "done") ?? [];

  const tabs = [
    {
      label: "All",
      filters: {},
      selectedColumns: ["health", "status", "tags"],
      groupByOption: "default",
      sortOption: { order: "asc", field: "created" },
    },
  ];

  const groupByDropdownOptions = [
    { label: "Default", icon: Minus },
    ...(viewType !== "kanban"
      ? [{ label: "Status", icon: fieldIconMapping["status"] }]
      : []),
    { label: "Health", icon: fieldIconMapping["health"] },
    { label: "Tags", icon: fieldIconMapping["tags"] },
    { label: "For", icon: fieldIconMapping["for"] },
  ];

  const getFilteredObjectives = () => {
    const predefinedFields = [
      "health",
      "status",
      "columns",
      "tags",
      "assignee",
      "for",
    ];

    const filteredOptions = predefinedFields.map((field) => ({
      label: capitalizeFirstLetter(field),
      icon: fieldIconMapping[field] || Minus,
    }));

    return [...filteredOptions];
  };

  const showButtonDropdownOptions = getFilteredObjectives();

  const getFilterOptions = (timeFrames, tags) => [
    {
      label: "Status",
      icon: fieldIconMapping.status,
      subOptions: [
        ...statusParked.map((item) => ({
          ...item,
          iconColor: item.color,
          icon: TbCircleDashed,
        })),
        ...statusToDo.map((item) => ({
          ...item,
          iconColor: item.color,
          icon: RiProgress8Fill,
        })),
        ...statusInProgress.map((item) => ({
          ...item,
          iconColor: item.color,
          icon: RiProgress5Line,
        })),
        ...statusDone.map((item) => ({
          ...item,
          iconColor: item.color,
          icon: RiProgress8Line,
        })),
      ],
    },
    {
      label: "Health",
      icon: fieldIconMapping.health,
      subOptions: objectiveHealths().map((option) => ({
        ...option,
        iconColor: option.color,
        withBackgroundColor: false,
      })),
    },
    { label: "Assignee", icon: fieldIconMapping.assignee },
    {
      label: "Tags",
      icon: fieldIconMapping.tags,
      subOptions: tags ?? [],
    },
    {
      label: "For",
      icon: fieldIconMapping.for,
      subOptions: ["Strategy", "Release", "Ideas"].map((type) => ({
        _id: type,
        label: type,
      })),
    },
  ];

  const filterOptions = getFilterOptions(timeFrames, tags);

  // const sortOptions = ["Default", "Status", "Health", "Assignee"].map(
  //   (label) => ({ label })
  // );
  const sortOptions = [
    { label: "Created", icon: Minus },
    { label: "Status", icon: fieldIconMapping.status },
    { label: "Health", icon: fieldIconMapping.health },
    { label: "Assignee", icon: fieldIconMapping.assignee },
  ];

  return (
    <div className="flex font-inter w-screen h-screen tracking-wide cursor-default">
      <Sidebar />
      {isLoading && isViewsLoading ? (
        <div className="absolute inset-0 flex items-center justify-center">
          <Loader />
        </div>
      ) : (
        <div className="flex flex-col min-w-[80%] w-[100%]">
          <Topbar
            title="Roadmaps"
            titleIcon={
              <IconWrapper
                icon={React.createElement(fieldIconMapping.roadmap)}
                iconColor="text-roadmaps-icon"
              />
            }
            tabs={tabs}
            with_different_view_option={false}
            setActiveProperties={setActiveProperties}
            groupByDropdownOptions={groupByDropdownOptions}
            topbarFunctions={topbarFunctions}
            topbarTabFunctions={topbarTabFunctions}
            sortOptions={sortOptions}
            showButtonDropdownOptions={showButtonDropdownOptions}
            filterOptions={filterOptions}
            searchTerm={searchBarTerm}
            onSearchChange={(value) => setSearchbarTerm(value)}
            activeState={"roadmaps"}
          />
          <RoadmapsComponent searchTerm={searchBarTerm} />
        </div>
      )}
    </div>
  );
};

export default Roadmaps;
