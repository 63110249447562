import React from "react";
import FeatureKanbanCard from "./FeatureKanbanCard";
import KanbanColumn from "components/common/kanban/KanbanColumn";

const FeatureKanbanColumn = (props) => {
  return (
    <KanbanColumn
      {...props}
      renderCardComponent={({
        item,
        products,
        innerRef,
        draggableProps,
        dragHandleProps,
        statusArray,
      }) => (
        <>
          <FeatureKanbanCard
            feature={item}
            products={products}
            innerRef={innerRef}
            draggableProps={draggableProps}
            dragHandleProps={dragHandleProps}
            statusArray={statusArray}
            setActiveFeatureCard={props.setActiveFeatureCard} // If you need to pass this prop
          />
        </>
      )}
    />
  );
};

export default FeatureKanbanColumn;
