/* eslint-disable react/prop-types */
import { useDispatch, useSelector } from "react-redux";
import { useDataProcessing } from "hooks/useDataProcessing";
import { useEffect, useMemo, useState } from "react";
import Loader from "components/common/Loader";
import ObjectiveListView from "./views/list/ObjectiveListView";
import ObjectiveKanbanView from "./views/kanban/ObjectiveKanbanView";
import { handleGroupSelect } from "utils/listUtils";
import MasterObjectiveSelectionPopup from "../common/masterSelectionPopup/MasterObjectiveSelectionPopup";
import { setObjectiveViewType } from "../../reduxStore/slices/objectiveSlice";
import { useLocation } from "react-router-dom";

const ObjectiveComponent = ({ searchTerm = "" }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const urlViewType = searchParams.get("viewType");

  const { objectives, groupByOption, selectedColumns, viewType } = useSelector(
    (state) => state.objectives
  );

  const filteredObjectives = useMemo(() => {
    return (objectives ?? []).filter((objective) =>
      objective.title.toLowerCase().includes(searchTerm.trim().toLowerCase())
    );
  }, [objectives, searchTerm]);

  const { groupedData, loading } = useDataProcessing(
    filteredObjectives,
    "objective"
  );
  const [collapsedGroups, setCollapsedGroups] = useState({});
  const [selectedObjectives, setSelectedObjectives] = useState(new Set());

  const toggleGroupCollapse = (group) => {
    setCollapsedGroups((prev) => ({
      ...prev,
      [group]: !prev[group],
    }));
  };

  const handleObjectiveSelect = (objective) => {
    setSelectedObjectives((prevSelected) => {
      const newSelected = new Set(prevSelected);
      newSelected.has(objective._id)
        ? newSelected.delete(objective._id)
        : newSelected.add(objective._id);
      return newSelected;
    });
  };

  useEffect(() => {
    if (urlViewType === viewType) {
      dispatch(
        setObjectiveViewType(
          urlViewType === "kanban" ? "kanban" : "classiclist"
        )
      );
    }
  }, [dispatch, urlViewType, viewType]);

  const handleLocalGroupSelect = (group) =>
    handleGroupSelect(group, setSelectedObjectives, groupedData);

  return (
    <div className="relative overflow-scroll bg-white shadow w-full h-full">
      {loading ? (
        <div className="flex items-center justify-center h-full">
          <Loader />
        </div>
      ) : (
        <>
          {viewType === "classiclist" && (
            <ObjectiveListView
              groupedData={groupedData}
              selectedColumns={selectedColumns}
              collapsedGroups={collapsedGroups}
              toggleGroupCollapse={toggleGroupCollapse}
              selectedObjectives={selectedObjectives}
              handleObjectiveSelect={handleObjectiveSelect}
              handleGroupSelect={handleLocalGroupSelect}
              groupByOption={groupByOption}
            />
          )}
          {viewType === "kanban" && (
            <ObjectiveKanbanView groupedData={groupedData} roadmap={null} />
          )}
        </>
      )}
      {selectedObjectives.size > 0 && (
        <MasterObjectiveSelectionPopup
          selectedObjectives={selectedObjectives}
          resetSelectedObjectives={() => setSelectedObjectives(new Set())}
        />
      )}
    </div>
  );
};

export default ObjectiveComponent;
