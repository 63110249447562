import { createSlice } from "@reduxjs/toolkit";

// Initial state with values extracted from cookies
const token = localStorage.getItem("jwt");
const user = JSON.parse(localStorage.getItem("user")) || {};

// Define the initial state object
const initialState = {
  loading: false,
  token: token ?? null,
  email: user.email ?? null,
  userId: user._id ?? null,
  companyId: user.company ?? null,
};

// Create a slice for authentication
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setToken(state, action) {
      state.token = action.payload;
    },
    setEmail(state, action) {
      state.email = action.payload;
    },
    setUserId(state, action) {
      state.userId = action.payload;
    },
    setCompanyId(state, action) {
      state.companyId = action.payload;
    },
  },
});

// Export actions to use in components
export const { setLoading, setToken, setEmail, setUserId, setCompanyId } =
  authSlice.actions;

// Export the reducer to be included in the store
export const authReducer = authSlice.reducer;
