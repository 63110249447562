import { toSnakeCase } from ".";

export const filterGroupedData = (
  groupedData = {},
  filters = {},
  groupBy,
  viewType,
  roadmap
) => {
  


  if (
    groupBy === "default" &&
    (viewType === "classiclist" || viewType === "modernlist"|| viewType === "list")
  ) {
    return Object.keys(groupedData).reduce((acc, groupKey) => {
      acc[groupKey] = {
        items: applyFilters(groupedData[groupKey], filters, roadmap),
      };
      return acc;
    }, {});
  }

  return Object.keys(groupedData).reduce((acc, groupKey) => {
    const group = groupedData[groupKey];
    if (shouldIncludeGroup(groupKey, filters, groupBy)) {
      acc[groupKey] = {
        label: group.label,
        color: group.color,
        items: applyFilters(group.items, filters, roadmap),
      };
    }

    return acc;
  }, {});
};

const shouldIncludeGroup = (groupKey, filters, groupBy) => {
  const groupFilterKeys = Object.keys(filters).filter((key) => key === groupBy);
  return groupFilterKeys.every((filterKey) => {
    return filters[filterKey]?.some((filter) => {
      if (
        (groupKey === "no-release" && filter.label === "No Release") ||
        (groupKey === "no-product" && filter.label === "No Product")
      ) {
        return true;
      }
      return filter?._id == groupKey;
    });
  });
};

export const applyFilters = (items = [], filters = {}, roadmap) => {
  return items.filter((item) => {
    return Object.keys(filters).every((key) => {
      const filterValues = filters[key];

      if (key === "tags" || key === "accounts") {
        return filterValues.some((filter) =>
          item[key]?.some((value) => value._id === filter._id)
        );
      } else if (key === "release" || key === "product" || key === "status") {
        return filterValues.some((filter) => {
          if (filter.label === "No Release" || filter.label === "No Product") {
            return item[key] === null;
          }
          return item[key]?._id === filter._id;
        });
      } else if (key === "time_frame") {
        if (roadmap?.for === "Ideas") {
          return true;
        }
        return filterValues.some(
          (filter) => item?.timeFrame?._id === filter._id
        );
      } else if (key === "progress") {
        return filterValues.some(
          (filter) =>
            (filter.label === "< 20" && item[key] < 20) ||
            (filter.label === ">= 20 and < 50" &&
              item[key] >= 20 &&
              item[key] < 50) ||
            (filter.label === ">= 50 and < 80" &&
              item[key] >= 50 &&
              item[key] < 80) ||
            (filter.label === ">= 80 and < 100" &&
              item[key] >= 80 &&
              item[key] < 100) ||
            (filter.label === "100" && item[key] === 100)
        );
      } else if (key === "health") {
        return filterValues.some(
          (filter) => item[key] === toSnakeCase(filter.label)
        );
      } else if (key === "importance") {
        return filterValues.some((filter) => filter._id === item[key]);
      } else if (key === "key_result") {
        return filterValues.some((filter) =>
          item.keyResults?.some((item) => item === filter._id)
        );
      } else if (key === "objective" || key === "roadmap") {
        return filterValues.some((filter) =>
          item[key + "s"]?.some((item) => item === filter._id)
        );
      } else if (key === "for") {
        return filterValues.some((filter) => item[key] === filter.label);
      }
      return filterValues.some((filter) =>
        item[key + "s"]?.some((item) => item === filter._id)
      );
    });
  });
};
