/* eslint-disable react/prop-types */
import DropdownItem from "components/common/DropdownItem";
import { SortDown } from "react-flaticons";
import { LiaSortSolid } from "react-icons/lia";

const SortOptionsDropdown = ({
  sortOptions,
  selectedSortOption,
  displaySortButtonDropdownOptions,
  toggleSortButtonDropdown,
  handleSortOptionClick,
  sortOrder,
}) => {
  const handleClick = (event) => {
    event.stopPropagation();
    toggleSortButtonDropdown();
  };

  return (
    <div
      className="relative flex-1    z-10 cursor-pointer rounded"
      onClick={handleClick}
    >
      <div className="flex justify-between items-center pr-1 ml-1 ">
        {/* <h2 className="  text-sm  text-nowrap  text-center cursor-pointer  p-1.5 ">
          Sort by
        </h2>
        <span className="rounded text-nowrap ml-0.5 p-1.5 hover:bg-topbar-button-hover">
          {selectedSortOption.label}
        </span> */}
        <div
          className={`relative text-sm text-nowrap  flex cursor-pointer rounded p-1.5 hover:bg-topbar-button-hover ${
            displaySortButtonDropdownOptions
              ? " text-title_50 bg-button-active_10 "
              : ""
          } `}
        >
          Sort by
          <span
            className={` ${
              !displaySortButtonDropdownOptions
                ? "   bg-topbar-button-active "
                : ""
            } text-xs   flex gap-1  items-center text-title_50 rounded  px-1 ml-1`}
          >
            {/* {selectedSortOption.label === "Created"  &&
             (sortOrder === "asc" ? (
                <SortDown size={12} />
              ) : (
                <SortDown size={12} className="rotate-180" />
              ))
              } */}
            {sortOrder === "asc" ? (
              <SortDown size={12} />
            ) : (
              <SortDown size={12} className="rotate-180" />
            )}
            {selectedSortOption.label ==="Default"?"Created":selectedSortOption.label}{" "}
          </span>
        </div>
      </div>
      {displaySortButtonDropdownOptions && (
        <div
          className="absolute  w-[10rem] right-[-3px] mt-[3px]  bg-white rounded-lg py-2 px-1 mx-1 border-2 shadow-lg
        top-full"
        >
          {(sortOptions ?? []).map((option, index) => (
            <DropdownItem
              key={index}
              option={option}
              handleOptionClick={() => handleSortOptionClick(option)}
              py="py-1.5"
              px="pl-2"
              textSize="text-sm"
              iconSize={13}
              rightIcon={
                option.label !== "Default" ? <LiaSortSolid size={14} /> : null
              }
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default SortOptionsDropdown;
