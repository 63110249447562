import { Button, Input, Select, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getJiraIssues,
  importJiraIssues,
} from "../../../reduxStore/operations/jiraAPI";
import { getAllFeatures } from "../../../reduxStore/operations/featuresAPI";
import { toast } from "react-toastify";

const { Search } = Input;
const JiraModalDetails = () => {
  const { companyId } = useSelector((state) => state.auth);
  const { jiraProjects: projects } = useSelector((state) => state.integrations);
  const [isSelectLoading, setIsSelectLoading] = useState(false);
  const [isProductLoading, setIsProductLoading] = useState(false);
  const [isImportLoading, setIsImportLoading] = useState(false);
  const [productList, setProductList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedProject, setSelectedProject] = useState();
  const [issues, setIssues] = useState([]);
  const [filteredIssues, setFilteredIssues] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { products } = useSelector((state) => state.company);
  const dispatch = useDispatch();
  const columns = [
    { title: "ID", dataIndex: "key", width: 100 },
    { title: "Summary", dataIndex: ["fields", "summary"] },
    {
      title: "Status",
      dataIndex: ["fields", "status", "name"],
      width: 150,
      render: (_, record) => <Tag>{record.fields.status.name}</Tag>,
    },
  ];

  const projectSubmitHandler = async () => {
    setIsButtonLoading(true);
    const jql = `project=${selectedProject} AND type = Epic`;
    const returnedIssues = await dispatch(getJiraIssues(companyId, jql));
    setIssues(returnedIssues?.issues);
    setFilteredIssues(returnedIssues?.issues);
    setIsButtonLoading(false);
  };

  const projectSelectHandler = (value) => {
    setSelectedProject(value);
  };

  const searchHandler = () => {
    const updatedIssues = issues.filter(
      (issue) =>
        issue.fields.summary.includes(searchText) ||
        issue.key.includes(searchText)
    );
    setFilteredIssues(updatedIssues);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const importClickHandler = async () => {
    setIsImportLoading(true);
    const selectedJiraIssues = filteredIssues.filter((issue) =>
      selectedRowKeys.includes(issue.key)
    );
    const res = await dispatch(
      importJiraIssues(companyId, selectedProduct, selectedJiraIssues)
    );
    if (res) {
      await projectSubmitHandler();
      await dispatch(getAllFeatures());
      toast.success("Tickets imported successfully");
    }
    setSelectedRowKeys([]);
    setIsImportLoading(false);
  };

  useEffect(() => {
    setIsProductLoading(true);
    const formattedProducts = products.map((product) => {
      return {
        label: product.label,
        value: product._id,
      };
    });
    setProductList(formattedProducts);
    setIsProductLoading(false);
  }, []);

  return (
    <div className="mt-5">
      <div className="flex items-center gap-4 w-full">
        <Select
          value={selectedProject}
          options={projects}
          className="flex-grow"
          onSelect={projectSelectHandler}
          disabled={isSelectLoading}
          loading={isSelectLoading}
          placeholder="Select a project"
        />
        <Button
          color="primary"
          variant="outlined"
          disabled={isButtonLoading || isSelectLoading || !selectedProject}
          loading={isButtonLoading}
          onClick={projectSubmitHandler}
        >
          Find Epics
        </Button>
      </div>
      <div>
        {/* <Search
          className="mt-4"
          value={searchText}
          placeholder="Search epics"
          onChange={(e) => setSearchText(e.target.value)}
          onSearch={searchHandler}
        /> */}
        <Table
          size="small"
          loading={isButtonLoading}
          rowSelection={{
            selectedRowKeys,
            onChange: onSelectChange,
          }}
          className="mt-4"
          dataSource={filteredIssues}
          columns={columns}
          pagination={{
            position: ["none", "none"],
          }}
          scroll={{ y: 250 }}
        />
        <div className="flex gap-4 mt-4">
          <Select
            loading={isProductLoading}
            disabled={isProductLoading}
            value={selectedProduct}
            placeholder="Select product"
            options={productList}
            onSelect={(value) => setSelectedProduct(value)}
            className="flex-grow"
          />
          <Button
            disabled={
              selectedRowKeys.length === 0 ||
              !selectedProduct ||
              isImportLoading
            }
            loading={isImportLoading}
            type="primary"
            onClick={importClickHandler}
          >
            Import
          </Button>
        </div>
      </div>
    </div>
  );
};

export default JiraModalDetails;
