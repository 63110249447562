import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getCookie } from "utils/cookieUtils";
import { signUp } from "reduxStore/operations/authAPI";
import { setLoading } from "reduxStore/slices/authSlice";
import Loader from "../common/Loader";

const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessages, setErrorMessages] = useState({});
  const [step, setStep] = useState(1);

  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isStrongPassword = (password) => {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&^_#])[A-Za-z\d@$!%*?&^_#]{8,}$/;
    return regex.test(password);
  };

  const isValidEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validateForm = () => {
    let errors = {};

    if (!email) {
      errors.email = "Email is required.";
    } else if (!isValidEmail(email)) {
      errors.email = "Enter a valid email.";
    }
    if (!password) {
      errors.password = "Password is required.";
    } else if (!isStrongPassword(password)) {
      errors.password =
        "Password require to contain at least 8 characters including one Capital letter, one small letter and one special character [@$!%*?&^_#].";
    }

    setErrorMessages(errors);

    return Object.keys(errors).length === 0; // Return true if no errors
  };

  const handleRegister = (e) => {
    e.preventDefault();
    if (validateForm()) {
      dispatch(setLoading(true));
      dispatch(signUp(email, password, setStep));
    }
  };

  useEffect(() => {
    const token = getCookie("jwt");
    if (token) {
      navigate("/"); // Redirect to home page if user is already logged in
    }
  }, [navigate]);

  return (
    <div className="flex flex-col gap-4 items-center justify-center min-h-screen bg-gray-100">
      <div className=" relative bg-white p-8 rounded shadow-md w-full max-w-sm">
        <div className="  absolute top-[-120px] right-0 w-full  flex items-center justify-center  ">
          <img
            src="/logo.png  "
            alt="logo"
            className=" w-0 h-0  xl:w-[200px] xl:h-[100px] "
          />{" "}
        </div>
        {step === 1 && (
          <>
            <h2 className="text-2xl mb-6 text-center">Create your account</h2>
            <form onSubmit={handleRegister}>
              {/* Email Field */}
              <div className="mb-4">
                <label className="block text-gray-700">Email</label>
                <input
                  type="email"
                  className={`w-full p-2 border ${
                    errorMessages.email ? "border-red-500" : "border-gray-300"
                  } rounded mt-1`}
                  value={email}
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
                {errorMessages.email && (
                  <p className="text-red-500 text-sm mt-1">
                    {errorMessages.email}
                  </p>
                )}
              </div>

              {/* Password Field */}
              <div className="mb-4">
                <label className="block text-gray-700">Password</label>
                <input
                  type="password"
                  className={`w-full p-2 border ${
                    errorMessages.password
                      ? "border-red-500"
                      : "border-gray-300"
                  } rounded mt-1`}
                  value={password}
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                {errorMessages.password && (
                  <p className="text-red-500 text-sm mt-1">
                    {errorMessages.password}
                  </p>
                )}
              </div>
              <p className=" text-[14px] pt-2 ">
                By signing up, you agree to our{" "}
                <a
                  className=" text-blue-500 hover:underline "
                  href="https://www.laneapp.co/termsofuse"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  Terms of Use
                </a>{" "}
                and{" "}
                <a
                  className=" text-blue-500 hover:underline "
                  href="https://www.laneapp.co/privacypolicy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy.
                </a>
              </p>
              {/* Submit Button */}
              <button
                type="submit"
                className="w-full bg-blue-500 text-white p-2 rounded mt-4 hover:bg-blue-600"
              >
                {loading ? <Loader size="20" color="white" /> : "Sign Up"}
              </button>
            </form>

            {/* Already have an account */}
            <p className="text-center text-gray-600 mt-4">
              Already have an account?{" "}
              <Link to="/" className="text-blue-500">
                Sign In
              </Link>
            </p>
          </>
        )}

        {step === 2 && (
          <>
            <p className="text-gray-600 text-center text-[1.1rem] mb-10">
              We’ve sent a magic link to:{" "}
              <span className="underline decoration-solid decoration-1">
                {email}
              </span>
            </p>

            <p className="text-gray-600 text-center text-[1rem] mb-16">
              <i>(It might take upto 5 minutes - check spam if needed)</i>
            </p>

            <p className="text-gray-600 text-center text-[1.1rem]">
              Click the link to confirm your email and continue setting up your
              account.🙌
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default Register;
