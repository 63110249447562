import {
  appendStatus,
  setStatuses,
  updateStatus,
  removeStatus,
} from "../slices/companySlice";
import { apiConnector } from "api/apiconnector"; // Ensure you have an apiConnector function to handle API requests
import { statusEndPoints } from "api/api"; // Import your API endpoints
import {
  updateObjectiveStatus,
  replaceObjectiveStatus,
} from "../slices/objectiveSlice";
import {
  updateRoadmapStatus,
  replaceRoadmapStatus,
} from "../slices/roadmapsSlice";
import {
  updateFeatureStatus,
  replaceFeatureStatus,
} from "../slices/featureSlice";
import { updateIdeaStatus, replaceIdeaStatus } from "../slices/ideaSlice";
import {
  updateKeyResultStatus,
  replaceKeyResultStatus,
} from "../slices/keyResultSlice";

const { GET_STATUSES, ADD_STATUS, EDIT_STATUS, DELETE_STATUS } =
  statusEndPoints;

export const getStatuses = () => {
  return async (dispatch) => {
    try {
      const { data } = await apiConnector("GET", GET_STATUSES);
      dispatch(setStatuses(data));
    } catch (error) {
      console.error("Error fetching statuses:", error);
    }
  };
};

export const addStatuses = (newStatus) => {
  return async (dispatch) => {
    try {
      const { data } = await apiConnector("POST", ADD_STATUS, newStatus);
      dispatch(appendStatus(data));
    } catch (error) {
      console.error("Error adding statuses:", error);
    }
  };
};

export const deleteStatuses = (statusId, replaceId, module) => {
  return async (dispatch) => {
    try {
      const { data } = await apiConnector("DELETE", DELETE_STATUS(statusId), {
        replaceId,
        module,
      });

      const { replaceStatus } = data;
      switch (module) {
        case "feature":
          dispatch(replaceFeatureStatus({ statusId, replaceStatus }));
          break;

        case "idea":
          dispatch(replaceIdeaStatus({ statusId, replaceStatus }));
          break;

        case "roadmap":
          dispatch(replaceRoadmapStatus({ statusId, replaceStatus }));
          break;

        case "objective":
          dispatch(replaceObjectiveStatus({ statusId, replaceStatus }));
          break;

        case "key_result":
          dispatch(replaceKeyResultStatus({ statusId, replaceStatus }));
          break;

        default:
          console.error("Unknown module:", module);
      }

      dispatch(removeStatus({ statusId, module }));
    } catch (error) {
      console.error("Error updateing statuses:", error);
    }
  };
};

export const updateStatuses = (statusId, updatedValue) => {
  return async (dispatch) => {
    try {
      const { data } = await apiConnector(
        "PUT",
        EDIT_STATUS(statusId),
        updatedValue
      );
      console.log(data);
      // dispatch(setObjectives(null));
      // dispatch(setRoadmaps(null));
      // dispatch(setFeatures(null));
      // dispatch(setIdeas(null));
      // dispatch(setKeyResults(null));

      const { status } = data;

      switch (status.module) {
        case "feature":
          dispatch(updateFeatureStatus(status));
          break;

        case "idea":
          dispatch(updateIdeaStatus(status));
          break;

        case "roadmap":
          dispatch(updateRoadmapStatus(status));
          break;

        case "objective":
          dispatch(updateObjectiveStatus(status));
          break;

        case "key_result":
          dispatch(updateKeyResultStatus(status));
          break;

        default:
          console.error("Unknown module:", status.module);
      }

      dispatch(updateStatus(data));
    } catch (error) {
      console.error("Error updateing statuses:", error);
    }
  };
};
