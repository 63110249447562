/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { MenuDots } from "react-flaticons";

import IconText from "components/common/IconText";

import EditDeleteDropdown from "./topbar/dropdowns/EditDeleteDropdown";

import {
  deleteView,
  removeViewFromPersonal,
} from "reduxStore/operations/viewsAPI";
import { setFeatureViewType } from "reduxStore/slices/featureSlice";
import { setIdeaViewType } from "reduxStore/slices/ideaSlice";
import { setObjectiveViewType } from "reduxStore/slices/objectiveSlice";

import { fieldIconMapping } from "utils";
import ViewNameInput from "./topbar/topbarTabs/topbarTabscomponents/ViewNameInput";
import { editView } from "reduxStore/operations/viewsAPI";
import { updateParticularRoadmapView } from "../../reduxStore/operations/viewsAPI";

const PersonalView = ({ view, isSearch=false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const editViewDropdownRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
  const [editViewDropdownId, setEditViewDropdownId] = useState(null);
  const [editingViewId, setEditingViewId] = useState(null);
  const [viewNameInput, setViewNameInput] = useState("");
  const searchParams = new URLSearchParams(location.search);
  const viewname = searchParams.get("view");
  const isPersonalActive =
    view._id === viewname ||
    (view.module === "roadmapId" &&
      pathname.includes("/roadmaps/") &&
      pathname.split("/roadmaps/")[1] === view.roadmapId);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        editViewDropdownRef.current &&
        !editViewDropdownRef.current.contains(event.target)
      ) {
        setEditViewDropdownId(null);
        setIsHovered(false);
        setEditingViewId(null);
        setViewNameInput("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handlePersonelViewClick = () => {
    setEditViewDropdownId(null);
    setEditingViewId(null);
    setIsHovered(false);
    setViewNameInput("");
    dispatch(
      setFeatureViewType(view?.viewType === "list" ? "classiclist" : "kanban")
    );
    dispatch(
      setIdeaViewType(view?.viewType === "list" ? "modernlist" : "kanban")
    );
    dispatch(setObjectiveViewType("classiclist"));
    if (view.module === "roadmapId") {
      navigate(`/roadmaps/${view.roadmapId}`);
    } else {
      navigate(
        `/${view?.module + "s"}?viewType=${view?.viewType}&view=${view._id}`
      );
    }
  };

  return (
    <div
      className={`flex items-center justify-between cursor-pointer hover:bg-topbar-button-hover rounded ${
        isPersonalActive ? "bg-topbar-button-hover" : ""
      } `}
      onMouseEnter={() =>
        editingViewId !== view._id &&
        editViewDropdownId !== view._id &&
        setIsHovered(true)
      }
      onMouseLeave={() =>
        editingViewId !== view._id &&
        editViewDropdownId !== view._id &&
        setIsHovered(false)
      }
      onClick={() => {
        handlePersonelViewClick();
      }}
      ref={editViewDropdownRef}
    >
      {editingViewId === view._id ? (
        <div className="p-0.5">
          <ViewNameInput
            viewNameInput={viewNameInput}
            handleViewNameChange={(value) => setViewNameInput(value)}
            handleAddViewClick={() => {
              if (view.module === "roadmapId") {
                dispatch(
                  updateParticularRoadmapView(
                    editingViewId,
                    {
                      label: viewNameInput,
                    },
                    view.roadmapId
                  )
                );
              } else {
                dispatch(
                  editView(
                    editingViewId,
                    view.module,
                    view.viewType,
                    viewNameInput,
                    view.sortOption,
                    view.filters,
                    view.selectedColumns,
                    view.groupByOption
                  )
                );
              }
              setViewNameInput("");
              setEditingViewId(null);
              setEditViewDropdownId(null);
            }}
            handleCancelClick={(e) => {
              e.stopPropagation();
              setViewNameInput("");
              setEditingViewId(null);
              setEditViewDropdownId(null);
            }}
          />
        </div>
      ) : (
        <IconText
          icon={
            view.module === "roadmapId"
              ? React.createElement(fieldIconMapping.roadmap)
              : React.createElement(fieldIconMapping[view?.module])
          }
          text={view.label}
          truncateLength={18}
          py="py-1"
          hoverColor={"hover:bg-topbar-button-hover"}
          isASidebarComponent={true}
          isTextActive={isPersonalActive}
          iconColor={isPersonalActive}
        />
      )}
      {isHovered && !isSearch  && (
        <div
          className={`relative p-1.5 mr-1 ${
            editViewDropdownId === view._id
              ? "bg-button-active_10"
              : "hover:bg-button-hover"
          } cursor-pointer rounded-full`}
          onClick={(e) => {
            e.stopPropagation();
            if (editViewDropdownId === view._id) {
              setEditViewDropdownId(null);
            } else {
              setEditViewDropdownId(view._id);
            }
          }}
        >
          <MenuDots size={12} />
          {editViewDropdownId === view._id && (
            <div className="absolute bg-white top-full border-2 rounded-lg right-0 shadow-lg z-10">
              <EditDeleteDropdown
                handleDeleteView={async () => {
                  await dispatch(removeViewFromPersonal(view._id));
                  dispatch(
                    deleteView(view._id, view.module, view.viewType, navigate)
                  );
                }}
                handlePersonalClick={() => {
                  dispatch(removeViewFromPersonal(view._id));
                }}
                handleEditView={() => {
                  setEditingViewId(view._id);
                  setViewNameInput(view.label);
                  setIsHovered(false);
                }}
                isAPersonalView={true}
                roadmapId={view.roadmapId}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PersonalView;
