import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  keyResults: [],
  activeKeyResultId: null,
  showKeyResultDetailViewDrawer: false,
  detailKeyResults: [],
};

const keyResultSlice = createSlice({
  name: "keyResult",
  initialState,
  reducers: {
    setKeyResults(state, action) {
      state.keyResults = action.payload;
    },
    setDetailKeyResults(state, action) {
      state.detailKeyResults = action.payload;
    },
    updateKeyResult(state, action) {
      const { updatedKeyResult, isUpdated } = action.payload;

      const index = state.keyResults.findIndex(
        (keyResult) => keyResult._id === updatedKeyResult._id
      );

      if (index !== -1) {
        if (isUpdated === "features") {
          state.keyResults[index].features = updatedKeyResult.features;
        } else if (isUpdated === "ideas") {
          state.keyResults[index].ideas = updatedKeyResult.ideas;
        } else if (isUpdated === "tags") {
          state.keyResults[index].tags = updatedKeyResult.tags;
        } else {
          state.keyResults[index] = updatedKeyResult;
        }
      }

      const detailIndex = state.detailKeyResults.findIndex(
        (keyResult) => keyResult._id === updatedKeyResult._id
      );

      if (detailIndex !== -1) {
        if (isUpdated === "features") {
          state.detailKeyResults[detailIndex].features =
            updatedKeyResult.features;
        } else if (isUpdated === "ideas") {
          state.detailKeyResults[detailIndex].ideas = updatedKeyResult.ideas;
        } else if (isUpdated === "tags") {
          state.detailKeyResults[detailIndex].tags = updatedKeyResult.tags;
        } else {
          state.detailKeyResults[detailIndex] = updatedKeyResult;
        }
      }
    },
    appendKeyResult(state, action) {
      const newKeyResult = action.payload;
      // state.keyResults.unshift(newKeyResult);
      state.keyResults.push(newKeyResult);
    },
    removeKeyResult(state, action) {
      const deletedKeyResultId = action.payload;
      const index = state.keyResults.findIndex(
        (keyResult) => keyResult._id === deletedKeyResultId
      );
      if (index !== -1) {
        state.keyResults.splice(index, 1);
      }
    },
    setActiveKeyResultId(state, action) {
      state.activeKeyResultId = action.payload;
    },
    setKeyResultShowDetailViewDrawer(state, action) {
      state.showKeyResultDetailViewDrawer = action.payload;
    },
    updateKeyResultStatus(state, action) {
      const updatedStatus = action.payload;

      state.keyResults?.forEach((key_result) => {
        if (key_result?.status?._id === updatedStatus._id) {
          key_result.status = updatedStatus;
        }
      });
    },
    replaceKeyResultStatus(state, action) {
      const { statusId, replaceStatus } = action.payload;

      state.keyResults?.forEach((key_result) => {
        if (key_result?.status?._id === statusId) {
          key_result.status = replaceStatus;
        }
      });
    },
  },
});

export const {
  setKeyResults,
  setDetailKeyResults,
  updateKeyResult,
  appendKeyResult,
  removeKeyResult,
  setActiveKeyResultId,
  setKeyResultShowDetailViewDrawer,
  updateKeyResultStatus,
  replaceKeyResultStatus,
} = keyResultSlice.actions;

export const keyResultReducer = keyResultSlice.reducer;
