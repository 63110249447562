/* eslint-disable react/prop-types */
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fieldIconMapping } from "utils";
import {
  setActiveIdeaId,
  setIdeaShowDetailViewDrawer,
  setDetailViewIdeas,
} from "reduxStore/slices/ideaSlice";
import KanbanModuleSection from "./KanbanModuleSection";

const KanbanIdeasSection = ({ ideas }) => {
  const dispatch = useDispatch();
  const { ideas: allIdeas } = useSelector((state) => state.ideas);

  const filteredIdeas = useMemo(() => {
    return (allIdeas ?? []).filter((idea) => (ideas ?? []).includes(idea._id));
  }, [ideas, allIdeas]);

  const handleIdeaClick = (idea) => {
    dispatch(setActiveIdeaId(idea._id));
    dispatch(setIdeaShowDetailViewDrawer(true));
    dispatch(setDetailViewIdeas(filteredIdeas));
  };

  return (
    <KanbanModuleSection
      items={filteredIdeas}
      icon={fieldIconMapping.idea}
      truncateLength={filteredIdeas?.length > 1 ? 28 : 40}
      iconColor="text-ideas-icon"
      textColor="text-title_50"
      onItemClick={handleIdeaClick}
    />
  );
};

export default KanbanIdeasSection;
