import axios from "axios";

// Create an instance of Axios with the withCredentials option set to true
export const axiosInstance = axios.create({
  withCredentials: true,
});

// Request interceptor
axiosInstance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    // config.baseURL = `http://localhost:3000/api/v1`;
    // config.baseURL = `${import.meta.env.VITE_API_BASE_URL}/api/v1`;

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  //If response received
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    if (error.response) {
      const errorResponseData = error.response.data;
      // Do something with response error
      if (error.response.status === 401) {
        // Handle unauthorized error (e.g. redirect to login page)
        const expirationDate = new Date();
        expirationDate.setTime(expirationDate.getTime() - 24 * 60 * 60 * 1000); // Set expiration date to past time (24 hours ago)
        document.cookie = `jwt=; expires=${expirationDate.toUTCString()}; path=/`;
        localStorage.removeItem("jwt");
        localStorage.removeItem("user");
        window.location.href = window.location.origin + "/signin";
        console.log("Unauthorized");
      } else {
        // Handle other errors

        // Can show error toast message from this one common place
        // toast.error(errorResponseData.message);

        console.error("Error from axios intercepter:", error);
      }
      return Promise.reject(error);
    }
  }
);

/**
 * Function to make API requests using Axios & this function is reusable...
 *
 * @param {string} method - The HTTP method for the request (e.g., 'GET', 'POST', 'PATCH', 'DELETE').
 * @param {string} url - The URL for the API endpoint. which are stored in api.js
 * @param {object} bodyData - The request body data (if any).
 * @param {object} headers - The headers for the request (if any).
 * @param {object} params - The query parameters for the request (if any).
 * @returns {Promise} - A Promise that resolves with the Axios response.  <-- returned value
 */

export const apiConnector = async (method, url, bodyData, headers, params) => {
  try {
    // Create a variable to store the Axios response
    const axiosResponse = await axiosInstance({
      // returns a promise
      method: method,
      url: url,
      data: bodyData || null,
      headers: headers || null,
      params: params || null,
    });

    // Return the Axios response
    return axiosResponse;
  } catch (error) {
    // Handle errors if the request fails
    console.error("API request error:", error);
    throw error; // Re-throw the error for the calling code to handle
  }
};
