/* eslint-disable react/prop-types */
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { transformFiltersToArray } from "utils";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddedActiveTabItem from "./topbarTabscomponents/AddedActiveTabItem";
import { addViewToPersonal } from "reduxStore/operations/viewsAPI";
import { removeViewFromPersonal } from "reduxStore/operations/viewsAPI";

const RoadmapIdTopbarTabs = ({
  tabs,
  setActiveProperties,
  activeState,
  showSaveViewOption = true,
  topbarTabFunctions,
  roadmapId,
}) => {
  const dispatch = useDispatch();
  const { selectedColumns, filters, groupByOption, sortOption } = useSelector(
    (state) => state[activeState]
  );
  const { personalViews } = useSelector((state) => state.personalViews);

  const allTab = tabs?.[0];

  const [state, setState] = useState({
    activeTab: allTab,
    viewNameInput: "",
    isViewNameInputVisible: false,
    activeDropdownIndex: null,
    editingViewId: null,
  });

  const {
    activeTab,
    viewNameInput,
    isViewNameInputVisible,
    activeDropdownIndex,
    editingViewId,
  } = state;

  const [hasChanges, setHasChanges] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    setActiveProperties(
      activeTab?.sortOption,
      activeTab?.selectedColumns,
      activeTab?.groupByOption,
      activeTab?.filters
    );
  }, [activeTab]);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      activeTab: allTab,
    }));
  }, [allTab]);

  useEffect(() => {
    if (activeTab !== null) {
      const currentSettings = {
        filters: transformFiltersToArray(activeTab?.filters || []),
        groupByOption: activeTab?.groupByOption,
        sortOption: activeTab?.sortOption,
        selectedColumns: activeTab?.selectedColumns,
      };

      const reduxSettings = {
        filters: transformFiltersToArray(filters),
        groupByOption: groupByOption,
        sortOption: sortOption,
        selectedColumns: selectedColumns,
      };

      const hasChanges = !(
        JSON.stringify(currentSettings.filters) ===
          JSON.stringify(reduxSettings.filters) &&
        currentSettings.groupByOption === reduxSettings.groupByOption &&
        currentSettings.sortOption?.field === reduxSettings.sortOption?.field &&
        currentSettings.sortOption?.order === reduxSettings.sortOption?.order &&
        JSON.stringify(currentSettings.selectedColumns) ===
          JSON.stringify(reduxSettings.selectedColumns)
      );
      setHasChanges(hasChanges);
    } else {
      setHasChanges(false);
    }
  }, [groupByOption, filters, sortOption, selectedColumns]);

  const handleMenuDotsClick = (index) => {
    setState((prevState) => ({
      ...prevState,
      activeDropdownIndex:
        prevState.activeDropdownIndex === index ? null : index,
      isViewNameInputVisible: false,
      editingViewId: null,
    }));
  };

  const handleEditView = () => {
    const viewToEdit = allTab;
    setState((prevState) => ({
      ...prevState,
      viewNameInput: viewToEdit.label,
      isViewNameInputVisible: true,
      editingViewId: viewToEdit._id,
    }));
  };

  const handleAddViewClick = (viewId, label) => {
    if ((label && label.length < 3) || (!label && viewNameInput.length < 3)) {
      return toast.error("View name must be at least 3 characters long.");
    }
    const filtersData = transformFiltersToArray(filters);
    if (viewId || editingViewId) {
      topbarTabFunctions.editView(
        allTab._id,
        null,
        label ? label : viewNameInput,
        sortOption,
        filtersData,
        selectedColumns,
        groupByOption,
        roadmapId
      );
    }
    setState((prevState) => ({
      ...prevState,
      viewNameInput: "",
      isViewNameInputVisible: false,
      isMoreViewsDropdownVisible: false,
      activeDropdownIndex: null,
      editingViewId: null,
    }));
  };

  const handlePersonalClick = () => {
    if (personalViews.find((view) => view._id === allTab._id)) {
      dispatch(removeViewFromPersonal(allTab._id));
    } else {
      dispatch(addViewToPersonal(allTab._id));
    }
  };

  const handleInputCrossClick = () => {
    setState((prevState) => ({
      ...prevState,
      isViewNameInputVisible: false,
      viewNameInput: "",
      editingViewId: null,
      activeDropdownIndex: null,
    }));
  };

  const handleViewNameChange = (value) => {
    setState((prevState) => ({
      ...prevState,
      viewNameInput: value,
    }));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setState((prevState) => ({
          ...prevState,
          editingViewId: null,
          viewNameInput: "",
          isViewNameInputVisible: false,
          activeDropdownIndex: null,
        }));
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="flex items-center gap-x-6" ref={dropdownRef}>
      <AddedActiveTabItem
        tab={allTab}
        index={0}
        activeTab={activeTab}
        activeDropdownIndex={activeDropdownIndex}
        handleMenuDotsClick={handleMenuDotsClick}
        handleEditView={handleEditView}
        editingViewId={editingViewId}
        setState={setState}
        handleAddViewClick={handleAddViewClick}
        handlePersonalClick={handlePersonalClick}
        viewNameInput={viewNameInput}
        personalViews={personalViews}
        handleCancelClick={handleInputCrossClick}
        handleViewNameChange={(value) => handleViewNameChange(value)}
        roadmapId={roadmapId}
      />
      {showSaveViewOption && hasChanges && !isViewNameInputVisible && (
        <div
          className="flex rounded justify-between items-center cursor-pointer overflow-hidden bg-blue-500 text-white w-fit"
          onClick={() => {
            handleAddViewClick(activeTab._id, activeTab.label);
          }}
        >
          <div className="p-1.5 hover:bg-blue-600">Update View</div>
        </div>
      )}
    </div>
  );
};

export default RoadmapIdTopbarTabs;
