import { useEffect, useState } from "react";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import SettingsTopbar from "../components/settings/SettingsTopbar";
import { toSnakeCase } from "../utils";
import { User } from "react-flaticons";

const Profile = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const tabs = [
    { label: "User Profile", path: "profile" },
    { label: "Security", path: "profile/security" },
  ];

  const getInitialTab = () => {
    const currentPath = location.pathname.split("/").pop();
    const foundTab = tabs.find((tab) => toSnakeCase(tab.label) === currentPath);
    return foundTab || tabs[0];
  };

  const [activeTab, setActiveTab] = useState(getInitialTab());

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    const newPath = toSnakeCase(activeTab.path);
    navigate(`/settings/${newPath}`);
  }, [activeTab, navigate]);

  return (
    <>
      <SettingsTopbar
        title="Profile"
        tabs={tabs}
        activeTab={activeTab}
        handleTabClick={handleTabClick}
        icon={<User />}
      />
      <Outlet />
    </>
  );
};

export default Profile;
