/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { Calendar } from "react-flaticons";
import { DateRange } from "react-date-range";
import { formatDate } from "utils/timeUtils";

const CalendarSection = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  setIsCalendarOpen,
  isCalendarActiveCondition,
}) => {
  // Initialize state with null or undefined dates
  const initialStartDate = startDate ? new Date(startDate) : null;
  const initialEndDate = endDate ? new Date(endDate) : null;

  const [state, setState] = useState([
    {
      startDate: initialStartDate,
      endDate: initialEndDate,
      key: "selection",
    },
  ]);

  useEffect(() => {
    // Update state when startDate or endDate changes
    setState([
      {
        startDate: startDate ? new Date(startDate) : null,
        endDate: endDate ? new Date(endDate) : null,
        key: "selection",
      },
    ]);
  }, [startDate, endDate]);

  const handleDateChange = (item) => {
    // Update state and call setStartDate and setEndDate
    setState([item.selection]);
    setStartDate(item.selection.startDate);
    setEndDate(item.selection.endDate);
  };

  // Handle cases where dates might be null
  const startDateFormatted = startDate ? formatDate(new Date(startDate)) : "";
  const endDateFormatted = endDate ? formatDate(new Date(endDate)) : "";

  return (
    <div
      className="flex items-center gap-x-3 cursor-pointer"
      onClick={() => {
        setIsCalendarOpen(!isCalendarActiveCondition);
      }}
    >
      <div
        className={
          isCalendarActiveCondition
            ? "ml-2 cursor-pointer relative bg-button-active_10 rounded p-1"
            : "cursor-pointer relative hover:bg-button-hover rounded p-1 ml-2"
        }
      >
        <Calendar color="#A9A9A9" />
        {isCalendarActiveCondition && (
          <div
            className="absolute top-full mt-1 bg-white z-10 border rounded left-0"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <DateRange
              editableDateInputs
              onChange={handleDateChange}
              moveRangeOnFirstSelection={false}
              ranges={state}
              showDateDisplay={false}
            />
          </div>
        )}
      </div>
      <div className="flex items-center justify-between p-2 border rounded w-60">
        <div className="flex-1 text-left">{startDateFormatted}</div>
        <span>-</span>
        <div className="flex-1 text-right">{endDateFormatted}</div>
      </div>
    </div>
  );
};

export default CalendarSection;
