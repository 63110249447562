import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import SettingsTopbar from "../SettingsTopbar";
import { Outlet } from "react-router-dom";
import { toSnakeCase } from "utils";
import { Following } from "react-flaticons";

const SettingsCustomers = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const tabs = [
    { label: "Accounts" },
  ];

  const getInitialTab = () => {
    const currentPath = location.pathname.split("/").pop();
    const foundTab = tabs.find((tab) => toSnakeCase(tab.label) === currentPath);
    return foundTab ? foundTab : tabs[0];
  };

  const [activeTab, setActiveTab] = useState(getInitialTab());

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    if (activeTab.label === "Accounts") {
      navigate("");
    } else {
      navigate(`${toSnakeCase(activeTab.label)}`);
    }
  }, [activeTab, navigate]);

  return (
    <>
      <SettingsTopbar
        tabs={tabs}
        activeTab={activeTab}
        handleTabClick={handleTabClick}
        isAccounts={true} 
        title="Accounts"
        icon={<Following />}
      />
      <div className="relative overflow-scroll bg-white shadow w-full h-full">
        <div className=" pt-5 px-2 w-full h-full overflow-auto">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default SettingsCustomers;
