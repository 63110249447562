import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fieldIconMapping, colors } from "utils";
import Loader from "components/common/Loader";
import IconWrapper from "components/common/IconWrapper";
import ColorDropdown from "./ColorDropdown";
import EditInputForm from "./EditInputForm";
import AddInputForm from "./AddInputForm";
import {
  addProductToCompany,
  deleteProductOfCompany,
  editProductInCompany,
} from "reduxStore/operations/productAPI";
import CustomizationsLabel from "../../common/CustomizationsLabel";

const ProductField = () => {
  const addRef = useRef(null);
  const editRefs = useRef([]);

  const dispatch = useDispatch();

  const { products } = useSelector((state) => state.company);

  const [hex, setHex] = useState(colors[0]);
  const [isColorDropdownOpen, setIsColorDropdownOpen] = useState(false);
  const [activeEditId, setActiveEditId] = useState(null);
  const [editLabel, setEditLabel] = useState("");
  const [orignalEditLabel, setOrignalEditLabel] = useState("");
  const [orignalEditProductColor, setOrignalEditProductColor] = useState("");
  const [isAddInputOpen, setIsAddInputOpen] = useState(false);
  const [addNewProductLabel, setAddNewProductLabel] = useState("");

  const resetEditOptions = () => {
    setIsColorDropdownOpen(false);
    setActiveEditId(null);
    setEditLabel("");
    setOrignalEditLabel("");
    setOrignalEditProductColor("");
  };
  const resetAddOptions = () => {
    setAddNewProductLabel("");
    setIsAddInputOpen(false);
  };

  const addButtonClick = () => {
    setAddNewProductLabel("");
    setIsAddInputOpen(true);
    setHex(colors[0]);
  };

  const editButtonClick = (
    id,
    productValue,
    productColor,
    shouldOpenColorDropdown
  ) => {
    if (activeEditId === null) {
      setHex(productColor);
    } else {
      setHex(hex);
    }
    setIsColorDropdownOpen(shouldOpenColorDropdown);
    setActiveEditId(id);
    if (editLabel !== "") {
      setEditLabel(editLabel);
    } else {
      setEditLabel(productValue);
    }
    setOrignalEditLabel(productValue);
    setOrignalEditProductColor(productColor);
  };

  const handleAddProduct = () => {
    if (addNewProductLabel.trim() !== "") {
      dispatch(addProductToCompany(addNewProductLabel.trim(), hex));
    }
    resetAddOptions();
  };

  const handleEditProduct = () => {
    if (editLabel.trim() === "") {
      return;
    } else if (
      orignalEditLabel === editLabel.trim() &&
      hex === orignalEditProductColor
    ) {
      resetEditOptions();
    } else {
      dispatch(editProductInCompany(activeEditId, editLabel.trim(), hex));
      resetEditOptions();
    }
  };

  const handleDeleteProduct = (id) => {
    dispatch(deleteProductOfCompany(id || activeEditId));
    resetEditOptions();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isAddInputOpen &&
        addRef.current &&
        !addRef.current.contains(event.target)
      ) {
        handleAddProduct();
        resetEditOptions();
      } else if (
        activeEditId !== null &&
        editRefs.current[activeEditId] &&
        !editRefs.current[activeEditId].contains(event.target)
      ) {
        handleEditProduct();
        resetAddOptions();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isAddInputOpen, addNewProductLabel, activeEditId, editLabel, hex]);

  return (
    <div className="text-sm text-title_10 cursor-default">
      {!products ? (
        <div className="flex items-center justify-center h-[600px]">
          <Loader />
        </div>
      ) : (
        <div>
          <div className="mb-5 font-semibold">Product</div>
          <div className="flex flex-col gap-y-2">
            {products.length > 0 ? (
              products.map((product) => (
                <div
                  key={product._id}
                  className={`flex items-center text-primary text-sm gap-x-2 w-fit`}
                  ref={(el) => (editRefs.current[product._id] = el)}
                >
                  <ColorDropdown
                    icon={React.createElement(fieldIconMapping["product"])}
                    iconColor={
                      activeEditId === product._id ? hex : product.color
                    }
                    clickable={true}
                    hex={hex}
                    setHex={setHex}
                    onClick={() => {
                      !isColorDropdownOpen
                        ? editButtonClick(
                            product._id,
                            product.label,
                            product.color,
                            true
                          )
                        : setIsColorDropdownOpen(false);
                    }}
                    openDropdownCondition={
                      isColorDropdownOpen && activeEditId === product._id
                    }
                  />
                  {activeEditId === product._id ? (
                    <EditInputForm
                      inputLabel={editLabel}
                      setInputLabel={(value) => setEditLabel(value)}
                      onEditInputEnter={() => handleEditProduct()}
                      onInputButtonClick={setIsColorDropdownOpen}
                      inputFocusCondition={!isColorDropdownOpen}
                      handleRemoveOptionClick={() => handleDeleteProduct()}
                    />
                  ) : (
                    // <div
                    //   className={`p-2 border rounded w-48 cursor-text`}
                    //   onClick={() => {
                    //     editButtonClick(
                    //       product._id,
                    //       product.label,
                    //       product.color,
                    //       false
                    //     );
                    //   }}
                    // >
                    //   {product.label}
                    // </div>
                    <CustomizationsLabel
                      label={product.label}
                      onClick={editButtonClick}
                      handleDeleteItem={handleDeleteProduct}
                      id={product._id}
                      showDelete={true}
                      color={product.color}
                      editBoolean={false}
                    />
                  )}
                </div>
              ))
            ) : (
              <div className="flex items-center gap-x-3">
                <div>
                  <IconWrapper
                    icon={React.createElement(fieldIconMapping.product)}
                    size="14"
                  />
                </div>
                <div className="p-2 border rounded w-48 cursor-not-allowed bg-row-background">
                  No Product
                </div>
              </div>
            )}
            {isAddInputOpen ? (
              <div className="flex items-center gap-x-2 w-fit" ref={addRef}>
                <AddInputForm
                  icon={React.createElement(fieldIconMapping["product"])}
                  iconColor={hex}
                  hex={hex}
                  setHex={setHex}
                  onIconClick={() =>
                    setIsColorDropdownOpen(!isColorDropdownOpen)
                  }
                  openIconDropdownCondition={isColorDropdownOpen}
                  inputLabel={addNewProductLabel}
                  setInputLabel={(value) => setAddNewProductLabel(value)}
                  onInputEnter={() => handleAddProduct()}
                  handleCloseOptionClick={resetAddOptions}
                />
              </div>
            ) : (
              <div
                className="text-primary cursor-pointer hover:bg-button-hover p-1 w-fit rounded"
                onClick={() => {
                  addButtonClick();
                }}
              >
                + Add
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductField;
