/* eslint-disable react/prop-types */
import { Home, PlusSmall, Settings, User } from "react-flaticons";
import Breadcrumb from "../common/Breadcrumb";
import AccountPopupOverlay from "components/common/addNewPopupOverlays/AccountPopupOverlay";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { addNewAccount } from "../../reduxStore/operations/accountAPI";
import IconText from "../common/IconText";

const SettingsTopbar = ({
  tabs = null,
  activeTab,
  handleTabClick,
  customTab = "",
  isAccounts = false,
  crumb,
  title = "",
  icon = null,
}) => {
  const [isAddOpen, setIsAddOpen] = useState(false);
  const dispatch = useDispatch();
  const handleAddAccount = (account) => {
    dispatch(addNewAccount(account));
  };
  const crumbs = [
    { icon: Home, name: "Home", path: "/" },
    { icon: Settings, name: "Settings", path: "/settings" },
  ];
  if (crumb) {
    crumbs.push(crumb);
  }
  return (
    <div className="flex flex-col pt-6 px-4 h-fit border-b border-gray-200 shadow-sm text-gray-500 sticky top-0 bg-white z-40 text-sm     ">
      <div>
        <IconText
          icon={icon || <User />}
          iconSize={12}
          text={title}
          hoverable={false}
          isASidebarComponent={false}
          px="px-0"
          py="py-0"
          isTextActive={true}
          isIconActive={true}
        />
        {/* <Breadcrumb crumbs={crumbs} /> */}
      </div>
      <div className=" relative  flex mt-[46px] gap-x-8  ml-2 ">
        {tabs?.map((tab, index) => (
          <div key={index} className="pb-2">
            <span
              className={`text-sm cursor-pointer ${
                activeTab.label === tab.label
                  ? "border-b-2 border-topbar-tab-active text-title_50  pb-2    font-[500]"
                  : "  "
              }`}
              onClick={() => handleTabClick(tab)}
            >
              {tab.label}
            </span>
          </div>
        ))}
        {customTab.length !== 0 && (
          <h2 className="text-sm text-center cursor-pointer border-b-2 border-topbar-tab-active pb-[6px]   text-title_50 font-[500] ">
            {customTab}
          </h2>
        )}

        {/* This is for to add account on the topbar, "isAccounts" value send from file named SettingsCustomers.jsx " */}
        {isAddOpen && (
          <AccountPopupOverlay
            type="add"
            onClose={() => setIsAddOpen(false)}
            onSubmit={handleAddAccount}
          />
        )}
        {isAccounts && (
          <button
            onClick={() => setIsAddOpen(true)}
            className=" absolute right-4 text-sm bottom-1 flex justify-center items-center gap-1 p-2 bg-blue-500 text-white  mb-2 ml-auto  rounded hover:bg-blue-600"
          >
            <span>
              <PlusSmall size={20} />
            </span>
            <span>Add Account</span>
          </button>
        )}
      </div>
    </div>
  );
};

export default SettingsTopbar;
