/* eslint-disable react/prop-types */
import React from "react";
import { rgbColor } from "utils";
import { CrossSmall } from "react-flaticons";
import DropdownMenu from "components/common/detailView/dropdown/DropdownMenu";

const ProductButton = ({
  products,
  updatedProduct,
  dropdownState,
  removeSelection,
  toggleDropdown,
  handleOptionClick,
}) => {
  return (
    <button
      className={`relative flex items-center gap-x-1 border border-gray-300 ${
        dropdownState.isProductDropdownOpen
          ? "bg-button-active_10"
          : "hover:bg-row-background"
      }  p-1.5 rounded-md`}
      onClick={() => toggleDropdown("isProductDropdownOpen")}
    >
      {updatedProduct ? (
        <div
          className="rounded px-1"
          style={{
            backgroundColor: rgbColor(updatedProduct.color, 0.2),
          }}
        >
          {updatedProduct?.label}
        </div>
      ) : (
        "Change Product"
      )}
      {updatedProduct && (
        <div
          className="cursor-pointer hover:bg-button-hover p-0.5 rounded-full"
          onClick={(e) => {
            e.stopPropagation();
            removeSelection("product");
          }}
        >
          <CrossSmall size={12} />
        </div>
      )}
      <DropdownMenu
        options={products ?? []}
        isOpen={dropdownState.isProductDropdownOpen}
        position="bottom-full w-32 right-0"
        handleOptionClick={(option) => handleOptionClick("product", option)}
      />
    </button>
  );
};

export default ProductButton;
