export const getKeyResultProgress = (ideas = [], features = []) => {
  const ideaProgress = ideas.reduce((acc, idea) => {
    if (idea.status?.type === "done") {
      return acc + 1;
    }
    return acc;
  }, 0);

  const featureProgress = features.reduce((acc, feature) => {
    if (feature.status?.type === "done") {
      return acc + 1;
    }
    return acc;
  }, 0);

  const totalIdeas = ideas.length;
  const totalFeatures = features.length;

  const totalKeyResultProgress =
    totalFeatures > 0 || totalIdeas > 0
      ? ((ideaProgress + featureProgress) / (totalFeatures + totalIdeas)) * 100
      : 0;

  return totalKeyResultProgress;
};

export const getKeyResultBasedObjectiveProgress = (
  keyResults = [],
  ideas = [],
  features = []
) => {
  if (keyResults.length === 0) return 0;

  const totalProgress = keyResults.reduce((acc, keyResult) => {
    const keyResultIdeas = ideas.filter((idea) =>
      keyResult.ideas.includes(idea._id)
    );
    const keyResultFeatures = features.filter((feature) =>
      keyResult.features.includes(feature._id)
    );

    const keyResultProgress = getKeyResultProgress(
      keyResultIdeas,
      keyResultFeatures
    );

    return acc + keyResultProgress;
  }, 0);

  const averageProgress = totalProgress / keyResults.length;

  return averageProgress;
};

export const getInitiativeBasedObjectiveProgress = (
  objective = { ideas: [], features: [] },
  ideas = [],
  features = []
) => {
  const { ideas: objectiveIdeas = [], features: objectiveFeatures = [] } =
    objective;

  // Filter ideas and features relevant to the objective
  const relatedIdeas = ideas.filter((idea) =>
    objectiveIdeas.includes(idea._id)
  );

  const relatedFeatures = features.filter((feature) =>
    objectiveFeatures.includes(feature._id)
  );

  // Calculate progress using getKeyResultProgress (since it works for both ideas and features)
  const initiativeProgress = getKeyResultProgress(
    relatedIdeas,
    relatedFeatures
  );

  return initiativeProgress;
};
