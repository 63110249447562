import React from "react";
import { Link, useLocation } from "react-router-dom";
import IconText from "../common/IconText";
import { fieldIconMapping } from "../../utils";
import IconWrapper from "../common/IconWrapper";

const InsightsTopbar = () => {
  const { pathname } = useLocation();

  // Helper function to check if the tab is active
  let isActiveTab = (path) => pathname === path;

  const titleIcon = (
    <IconWrapper
      icon={React.createElement(fieldIconMapping.insights)}
      iconColor="text-yellow-400  "
    />
  );

  return (
    <div className="flex flex-col pt-4 px-6 h-fit border-b border-gray-200 shadow-sm text-gray-500 sticky  top-0 bg-white z-40 text-sm">
      <div>
        <IconText
          icon={titleIcon}
          iconSize={14}
          text={"Insights"}
          hoverable={false}
          isASidebarComponent={false}
          px=""
        />
      </div>
      <div className="mt-7  text-sm flex items-center gap-x-6">
        <Link
          className={`pb-2  ${
            isActiveTab("/insights")
              ? " border-topbar-tab-active text-title_50 font-[500] border-b-2 "
              : ""
          }`}
          to="/insights"
        >
          Insights
        </Link>
        <Link
          className={`pb-2     ${
            isActiveTab("/insights/feedback")
              ? " border-topbar-tab-active text-title_50 font-[500] border-b-2 "
              : ""
          }`}
          to="/insights/feedback"
        >
          Feedback
        </Link>
      </div>
    </div>
  );
};

export default InsightsTopbar;
