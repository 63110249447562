/* eslint-disable react/prop-types */
import React, { useMemo, useState } from "react";
import { fieldIconMapping, truncateText } from "utils";
import IconText from "components/common/IconText";
import ProfileIcon from "components/common/ProfileIcon";
import Progress from "components/common/Progress";
import { useDispatch, useSelector } from "react-redux";
import { CrossSmall, MenuDots } from "react-flaticons";
import { removeObjectiveKeyResults } from "reduxStore/operations/objectivesAPI";
import {
  setActiveKeyResultId,
  setKeyResultShowDetailViewDrawer,
} from "reduxStore/slices/keyResultSlice";
import { getKeyResultProgress } from "utils/progressUtils";
import { getHealthBgColor } from "utils/objectiveUtils";
import { useLocation } from "react-router-dom";
import { resetActiveIds } from "utils/resetActiveIds";
import EditDeleteDropdown from "../../../core/topbar/dropdowns/EditDeleteDropdown";

const ObjectiveDetailViewKeyResultRowComponent = ({ objective }) => {
  const dispatch = useDispatch();
  const { features } = useSelector((state) => state.features);
  const { keyResults } = useSelector((state) => state.keyResults);
  const { ideas } = useSelector((state) => state.ideas);
  const { pathname } = useLocation();
  const [dotDropDown, setDropDown] = useState(false);
  const { activeKeyResultId } = useSelector((state) => state.keyResults);

  const objectiveKeyResults = useMemo(() => {
    return (keyResults ?? []).filter((keyResult) =>
      (objective?.keyResults ?? []).includes(keyResult._id)
    );
  }, [objective?.keyResults, keyResults, activeKeyResultId]);

  const HandleItemRemove = (krId) => {
    dispatch(removeObjectiveKeyResults(objective._id, [keyResult._id || krId]));
  };

  return (
    <>
      {(objectiveKeyResults ?? []).map((keyResult) => {
        const keyResultIdeas = (ideas ?? []).filter((idea) =>
          (keyResult?.ideas ?? []).includes(idea._id)
        );
        const keyResultFeatures = (features ?? []).filter((feature) =>
          (keyResult?.features ?? []).includes(feature._id)
        );

        const keyResultProgress = getKeyResultProgress(
          keyResultIdeas,
          keyResultFeatures
        );

        return (
          <div
            key={keyResult._id}
            className="flex items-center justify-between p-1 hover:bg-row-background rounded cursor-pointer group"
            onClick={() => {
              // if (pathname.includes("/roadmaps")) {
              //   resetActiveIds({ dispatch });
              // }
              dispatch(setActiveKeyResultId(null));
              dispatch(setKeyResultShowDetailViewDrawer(true));
              dispatch(setActiveKeyResultId(keyResult._id));
            }}
          >
            <IconText
              icon={React.createElement(fieldIconMapping.key_results)}
              iconColor={"text-key_results-icon"}
              text={truncateText(keyResult.title, 40)}
              hoverable={false}
              px=""
              py=""
              gap_x="gap-x-2"
            />
            <div className="flex items-center gap-x-2">
              {/* Use the calculated progress */}
              <Progress
                value={keyResultProgress}
                color={getHealthBgColor(keyResult?.health)}
              />
              <ProfileIcon name={keyResult?.assignee?.name ?? "N A"} />
              <div
                className="hover:bg-button-hover rounded-full p-1 opacity-0 group-hover:opacity-100 transition-opacity relative "
                onMouseEnter={() => {
                  setDropDown(true);
                }}
                onMouseLeave={() => {
                  setDropDown(false);
                }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <MenuDots size={12} />
                {dotDropDown && (
                  <div className=" absolute right-[-15px] top-7 bg-white rounded-lg shadow-sm ">
                    <EditDeleteDropdown
                      isKr={true}
                      krId={keyResult._id}
                      HandleItemRemove={HandleItemRemove}
                    />
                  </div>
                )}
                {/* <CrossSmall size={14} /> */}
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ObjectiveDetailViewKeyResultRowComponent;
