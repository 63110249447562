import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { updateUserProfile } from "../reduxStore/operations/userAPI";
import { Pencil } from "react-flaticons";
import { updateUserProfile } from "../../../reduxStore/operations/userAPI";

const User = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);

  const [isEditing, setIsEditing] = useState(false);

  const [editableUser, setEditableUser] = useState({
    name: "",
    company: "",
  });

  useEffect(() => {
    if (user) {
      setEditableUser({
        name: user.name,
        company: user.company.company_name,
      });
    }
  }, [user]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditableUser((prev) => ({ ...prev, [name]: value }));
  };

  const toggleEditMode = () => {
    setIsEditing((prev) => !prev);
  };

  const handleUpdate = () => {
    dispatch(
      updateUserProfile({
        name: editableUser.name,
        company_name: editableUser.company,
      })
    );
    setIsEditing(false);
  };

  // Handle discard changes
  const handleDiscard = () => {
    // Revert to original user data
    setEditableUser({
      name: user.name,
      company: user.company.company_name,
    });
    setIsEditing(false);
  };

  return (
    <div className="flex items-start justify-start ">
      {user ? (
        <div className="bg-white p-6 max-w-md w-full       ">
          <div className=" relative ">
            {!isEditing && (
              <button
                onClick={toggleEditMode}
                className="  absolute right-4 top-[-10px] self-end rounded p-[6px] hover:bg-button-hover hover:text-gray-500 "
              >
                <Pencil size={16} className=" text-gray-400  " />
              </button>
            )}
          </div>

          <div className="mb-4">
            <div className="flex items-center gap-2">
              <span className="text-title font-semibold  min-w-36">Name: </span>
              {isEditing ? (
                <input
                  type="text"
                  name="name"
                  value={editableUser.name}
                  onChange={handleInputChange}
                  className="border rounded p-2 w-full"
                />
              ) : (
                <p className="text-gray-700">{user.name}</p>
              )}
            </div>
          </div>
          <div className="mb-4">
            <div className="flex items-center gap-2">
              <span className="text-title font-semibold  min-w-36">
                Email:{" "}
              </span>
              <p className="text-gray-700">{user.email} </p>
            </div>
          </div>
          <div className="mb-4">
            <div className="flex items-center gap-2">
              <span className="text-title font-semibold  min-w-36">
                Company:{" "}
              </span>
              {isEditing ? (
                <input
                  type="text"
                  name="company"
                  value={editableUser.company}
                  onChange={handleInputChange}
                  className="border rounded p-2 w-full"
                />
              ) : (
                <p className="text-gray-700">{user.company.company_name}</p>
              )}
            </div>
          </div>
          {/* Buttons */}
          {isEditing && (
            <div className="flex gap-2 pt-4 justify-end">
              <button
                onClick={handleDiscard}
                className="bg-gray-200 px-4 py-2 rounded hover:bg-gray-300"
              >
                Discard
              </button>
              <button
                onClick={handleUpdate}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              >
                Update
              </button>
            </div>
          )}
        </div>
      ) : (
        <div className="text-center text-gray-700">Loading user details...</div>
      )}
    </div>
  );
};

export default User;
