/* eslint-disable react/prop-types */
import React from "react";
import { truncateText } from "utils";

const TagsRowSection = ({ tags, dropdownState, toggleDropdown }) => {
  return (
    <div
      className={`gap-2 -ml-0.5 my-0.5 ${
        tags?.length > 0
          ? "flex flex-wrap items-center p-1"
          : " pl-2 pr-14 py-2.5"
      } rounded ${
        dropdownState.isTagDropdownOpen
          ? "bg-gray-200"
          // ? "bg-button-active_10"
          : "hover:bg-button-hover"
      } cursor-pointer rounded`}
      onClick={(e) => {
        e.stopPropagation();
        toggleDropdown();
      }}
    >
      {(tags ?? []).slice(0, 2).map((tag, idx) => (
        <div
          key={idx}
          className="rounded bg-tags-active p-1 text-xs"
          title={tags.length > 1 && tag?.label?.length > 5 && tag.label}
        >
          {tags.length > 1 ? truncateText(tag?.label ?? "", 5) : tag.label}
        </div>
      )) ?? ""}
      {tags?.length > 2 && <div className="text-xs">+{tags.length - 2}</div>}
    </div>
  );
};

export default TagsRowSection;
