export const handleGroupSelect = (group, setSelectedItems, groupedData) => {
  setSelectedItems((prevSelected) => {
    const newSelected = new Set(prevSelected);
    const allSelected = groupedData[group].items.every((item) =>
      newSelected.has(item._id)
    );

    if (allSelected) {
      // Deselect all items in the group
      groupedData[group].items.forEach((item) => {
        newSelected.delete(item._id);
      });
    } else {
      // Select all items in the group
      groupedData[group].items.forEach((item) => {
        newSelected.add(item._id);
      });
    }

    return newSelected;
  });
};
