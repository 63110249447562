/* eslint-disable react/prop-types */
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import FeatureListView from "components/features/views/list/FeatureListView";
import FeatureRoadmapsKanbanView from "./FeatureRoadmapsKanbanView";
import { useDataProcessing } from "hooks/useDataProcessing";
import Loader from "components/common/Loader";
import { handleGroupSelect } from "utils/listUtils";
import { setDetailViewFeatures } from "reduxStore/slices/featureSlice";

function FeaturesRoadmapsComponent({
  searchTerm = "",
  roadmap = null,
  filterOptions,
  filteredReleases,
}) {
  const dispatch = useDispatch();
  const {
    features,
    groupByOption,
    selectedColumns,
    viewType,
    showDetailViewDrawer,
  } = useSelector((state) => state.features);

  const filteredFeatures = useMemo(() => {
    return (features ?? [])
      .filter((item) =>
        item.title.toLowerCase().includes(searchTerm.trim().toLowerCase())
      )
      .filter(
        (item) =>
          roadmap?.products?.some(
            (product) => product._id === item?.product?._id ?? ""
          ) &&
          (filteredReleases ?? []).some(
            (release) => release._id === item?.release?._id ?? ""
          )
      );
  }, [features, filteredReleases, roadmap?.products, searchTerm]);

  const { groupedData, loading } = useDataProcessing(
    filteredFeatures,
    "feature",
    roadmap
  );

  const [collapsedGroups, setCollapsedGroups] = useState({});
  const [selectedFeatures, setSelectedFeatures] = useState(new Set());

  const toggleGroupCollapse = (group) => {
    setCollapsedGroups((prev) => ({
      ...prev,
      [group]: !prev[group],
    }));
  };

  const handleFeatureSelect = (feature) => {
    setSelectedFeatures((prevSelected) => {
      const newSelected = new Set(prevSelected);
      newSelected.has(feature._id)
        ? newSelected.delete(feature._id)
        : newSelected.add(feature._id);
      return newSelected;
    });
  };

  const handleLocalGroupSelect = (group) =>
    handleGroupSelect(group, setSelectedFeatures, groupedData);

  useEffect(() => {
    dispatch(setDetailViewFeatures(filteredFeatures));
  }, [showDetailViewDrawer, filteredFeatures]);

  return (
    <div className="relative overflow-scroll bg-white shadow w-full h-full">
      {loading ? (
        <div className="flex items-center justify-center h-full">
          <Loader />
        </div>
      ) : (
        <>
          {viewType === "classiclist" && (
            <FeatureListView
              groupedData={groupedData}
              selectedColumns={selectedColumns}
              collapsedGroups={collapsedGroups}
              toggleGroupCollapse={toggleGroupCollapse}
              selectedFeatures={selectedFeatures}
              handleFeatureSelect={handleFeatureSelect}
              handleGroupSelect={handleLocalGroupSelect}
              groupByOption={groupByOption}
            />
          )}
          {viewType === "kanban" && (
            <FeatureRoadmapsKanbanView
              groupedData={groupedData}
              roadmap={roadmap}
              isRoadmapDetailColumn={true}
              filterOptions={filterOptions}
            />
          )}
        </>
      )}
    </div>
  );
}

export default FeaturesRoadmapsComponent;
