/* eslint-disable react/prop-types */
import React from "react";
import { rgbColor } from "utils";

const ProductRowSection = ({ item, dropdownState, toggleDropdown }) => {
  return (
    <div
      className={`${item?.label ? "p-1 w-fit" : "w-fit"} rounded  ${
        dropdownState.isProductDropdownOpen
          ? "bg-gray-200"
          // ? "bg-button-active_10"
          : "hover:bg-button-hover"
      }`}
      onClick={(e) => {
        e.stopPropagation();
        toggleDropdown();
      }}
    >
      <div className={item?.color ? "rounded w-fit bg-white" : ""}>
        <div
          className="p-1 rounded"
          style={{
            backgroundColor: item?.color ? rgbColor(item.color, 0.2) : "",
          }}
        >
          {item?.label ?? "No Product"}
        </div>
      </div>
    </div>
  );
};

export default ProductRowSection;
