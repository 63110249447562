// import React, { useEffect, useMemo } from "react";
// import DetailViewDrawer from "components/common/detailView/DetailViewDrawer";

// import RoadmapDetailViewComponent from "components/roadmaps/detailView/RoadmapDetailViewComponent";
// import RoadmapDetailViewRightSidebar from "components/roadmaps/detailView/RoadmapDetailViewRightSidebar";

// import ObjectiveDetailViewComponent from "components/objectives/detailView/ObjectiveDetailViewComponent";
// import ObjectiveDetailViewRightSidebar from "components/objectives/detailView/ObjectiveDetailViewRightSidebar";

// import KeyResultDetailViewComponent from "components/keyResults/detailView/KeyResultDetailViewComponent";
// import KeyResultDetailViewRightSidebar from "components/keyResults/detailView/KeyResultDetailViewRightSidebar";

// import IdeaDetailViewComponent from "components/ideas/detailView/IdeaDetailViewComponent";
// import IdeaDetailViewRightSidebar from "components/ideas/detailView/IdeaDetailViewRightSidebar";

// import FeatureDetailViewComponent from "components/features/detailView/FeatureDetailViewComponent";
// import FeatureDetailViewRightSidebar from "components/features/detailView/FeatureDetailViewRightSidebar";

// import {
//   setActiveObjectiveId,
//   setObjectiveShowDetailViewDrawer,
// } from "reduxStore/slices/objectiveSlice";
// import {
//   setActiveRoadmapId,
//   setRoadmapShowDetailViewDrawer,
// } from "reduxStore/slices/roadmapsSlice";
// import {
//   setActiveFeatureId,
//   setFeatureShowDetailViewDrawer,
// } from "reduxStore/slices/featureSlice";
// import {
//   setActiveIdeaId,
//   setIdeaShowDetailViewDrawer,
// } from "reduxStore/slices/ideaSlice";
// import {
//   setActiveKeyResultId,
//   setKeyResultShowDetailViewDrawer,
// } from "reduxStore/slices/keyResultSlice";
// import { useDispatch, useSelector } from "react-redux";
// import { setDetailKeyResults } from "../../reduxStore/slices/keyResultSlice";

// const AllDetailView = () => {
//   const {
//     objectives,
//     activeObjectiveId,
//     showDetailViewDrawer: showObjectiveDetailViewDrawer,
//   } = useSelector((state) => state.objectives);

//   const {
//     roadmaps,
//     showDetailViewDrawer: showRoadmapDetailViewDrawer,
//     activeRoadmapId,
//   } = useSelector((state) => state.roadmaps);

//   const {
//     ideas,
//     showDetailViewDrawer: showIdeaDetailViewDrawer,
//     activeIdeaId,
//   } = useSelector((state) => state.ideas);

//   const { detailKeyResults, showKeyResultDetailViewDrawer, activeKeyResultId } =
//     useSelector((state) => state.keyResults);
//   const {
//     detailViewFeatures,
//     showDetailViewDrawer: showFeatureDetailViewDrawer,
//     activeFeatureId,
//   } = useSelector((state) => state.features);

//   return (
//     <>
//       <DetailViewDrawer
//         showDetailViewDrawer={showRoadmapDetailViewDrawer}
//         activeItemId={activeRoadmapId}
//         items={roadmaps}
//         setShowDetailViewDrawerAction={setRoadmapShowDetailViewDrawer}
//         setActiveItemIdAction={setActiveRoadmapId}
//         DetailViewComponent={RoadmapDetailViewComponent}
//         RightSidebarComponent={RoadmapDetailViewRightSidebar}
//         shouldNavigate={false}
//       />
//       <DetailViewDrawer
//         showDetailViewDrawer={showObjectiveDetailViewDrawer}
//         activeItemId={activeObjectiveId}
//         items={objectives}
//         setShowDetailViewDrawerAction={setObjectiveShowDetailViewDrawer}
//         setActiveItemIdAction={setActiveObjectiveId}
//         DetailViewComponent={ObjectiveDetailViewComponent}
//         RightSidebarComponent={ObjectiveDetailViewRightSidebar}
//         shouldNavigate={false}
//       />
//       <DetailViewDrawer
//         showDetailViewDrawer={showFeatureDetailViewDrawer}
//         activeItemId={activeFeatureId}
//         items={detailViewFeatures}
//         setShowDetailViewDrawerAction={setFeatureShowDetailViewDrawer}
//         setActiveItemIdAction={setActiveFeatureId}
//         DetailViewComponent={FeatureDetailViewComponent}
//         RightSidebarComponent={FeatureDetailViewRightSidebar}
//       />
//       <DetailViewDrawer
//         showDetailViewDrawer={showIdeaDetailViewDrawer}
//         activeItemId={activeIdeaId}
//         items={ideas}
//         setShowDetailViewDrawerAction={setIdeaShowDetailViewDrawer}
//         setActiveItemIdAction={setActiveIdeaId}
//         DetailViewComponent={IdeaDetailViewComponent}
//         RightSidebarComponent={IdeaDetailViewRightSidebar}
//       />
//       <DetailViewDrawer
//         showDetailViewDrawer={showKeyResultDetailViewDrawer}
//         activeItemId={activeKeyResultId}
//         items={detailKeyResults}
//         setShowDetailViewDrawerAction={setKeyResultShowDetailViewDrawer}
//         setActiveItemIdAction={setActiveKeyResultId}
//         DetailViewComponent={KeyResultDetailViewComponent}
//         RightSidebarComponent={KeyResultDetailViewRightSidebar}
//       />
//     </>
//   );
// };

// export default AllDetailView;

import React, { useEffect, useMemo } from "react";
import DetailViewDrawer from "components/common/detailView/DetailViewDrawer";

import RoadmapDetailViewComponent from "components/roadmaps/detailView/RoadmapDetailViewComponent";
import RoadmapDetailViewRightSidebar from "components/roadmaps/detailView/RoadmapDetailViewRightSidebar";

import ObjectiveDetailViewComponent from "components/objectives/detailView/ObjectiveDetailViewComponent";
import ObjectiveDetailViewRightSidebar from "components/objectives/detailView/ObjectiveDetailViewRightSidebar";

import KeyResultDetailViewComponent from "components/keyResults/detailView/KeyResultDetailViewComponent";
import KeyResultDetailViewRightSidebar from "components/keyResults/detailView/KeyResultDetailViewRightSidebar";

import IdeaDetailViewComponent from "components/ideas/detailView/IdeaDetailViewComponent";
import IdeaDetailViewRightSidebar from "components/ideas/detailView/IdeaDetailViewRightSidebar";

import FeatureDetailViewComponent from "components/features/detailView/FeatureDetailViewComponent";
import FeatureDetailViewRightSidebar from "components/features/detailView/FeatureDetailViewRightSidebar";

import {
  setActiveObjectiveId,
  setObjectiveShowDetailViewDrawer,
} from "reduxStore/slices/objectiveSlice";
import {
  setActiveRoadmapId,
  setRoadmapShowDetailViewDrawer,
} from "reduxStore/slices/roadmapsSlice";
import {
  setActiveFeatureId,
  setFeatureShowDetailViewDrawer,
} from "reduxStore/slices/featureSlice";
import {
  setActiveIdeaId,
  setIdeaShowDetailViewDrawer,
} from "reduxStore/slices/ideaSlice";
import {
  setActiveKeyResultId,
  setKeyResultShowDetailViewDrawer,
} from "reduxStore/slices/keyResultSlice";
import { useDispatch, useSelector } from "react-redux";
import { setDetailKeyResults } from "../../reduxStore/slices/keyResultSlice";

const AllDetailView = () => {
  const dispatch = useDispatch();

  const {
    objectives,
    activeObjectiveId,
    showDetailViewDrawer: showObjectiveDetailViewDrawer,
  } = useSelector((state) => state.objectives);

  const {
    roadmaps,
    showDetailViewDrawer: showRoadmapDetailViewDrawer,
    activeRoadmapId,
  } = useSelector((state) => state.roadmaps);

  const {
    ideas,
    showDetailViewDrawer: showIdeaDetailViewDrawer,
    activeIdeaId,
  } = useSelector((state) => state.ideas);

  const { detailKeyResults, showKeyResultDetailViewDrawer, activeKeyResultId } =
    useSelector((state) => state.keyResults);

  const { keyResults } = useSelector((state) => state.keyResults);

  // Memoize key results based on the active objective ID
  const objective = objectives.find((obj) => obj._id === activeObjectiveId);
  const objectiveKeyResults = useMemo(() => {
    if (objective) {
      return (keyResults ?? []).filter((keyResult) =>
        (objective.keyResults ?? []).includes(keyResult._id)
      );
    }
    return [];
  }, [objective, keyResults]);

  useEffect(() => {
    if (objectiveKeyResults) {
      dispatch(setDetailKeyResults(objectiveKeyResults));
    }
  }, [objectiveKeyResults, dispatch]);

  const {
    detailViewFeatures,
    showDetailViewDrawer: showFeatureDetailViewDrawer,
    activeFeatureId,
  } = useSelector((state) => state.features);

  return (
    <>
      <DetailViewDrawer
        showDetailViewDrawer={showRoadmapDetailViewDrawer}
        activeItemId={activeRoadmapId}
        items={roadmaps}
        setShowDetailViewDrawerAction={setRoadmapShowDetailViewDrawer}
        setActiveItemIdAction={setActiveRoadmapId}
        DetailViewComponent={RoadmapDetailViewComponent}
        RightSidebarComponent={RoadmapDetailViewRightSidebar}
        shouldNavigate={false}
      />
      <DetailViewDrawer
        showDetailViewDrawer={showObjectiveDetailViewDrawer}
        activeItemId={activeObjectiveId}
        items={objectives}
        setShowDetailViewDrawerAction={setObjectiveShowDetailViewDrawer}
        setActiveItemIdAction={setActiveObjectiveId}
        DetailViewComponent={ObjectiveDetailViewComponent}
        RightSidebarComponent={ObjectiveDetailViewRightSidebar}
        shouldNavigate={false}
      />
      <DetailViewDrawer
        showDetailViewDrawer={showFeatureDetailViewDrawer}
        activeItemId={activeFeatureId}
        items={detailViewFeatures}
        setShowDetailViewDrawerAction={setFeatureShowDetailViewDrawer}
        setActiveItemIdAction={setActiveFeatureId}
        DetailViewComponent={FeatureDetailViewComponent}
        RightSidebarComponent={FeatureDetailViewRightSidebar}
      />
      <DetailViewDrawer
        showDetailViewDrawer={showIdeaDetailViewDrawer}
        activeItemId={activeIdeaId}
        items={ideas}
        setShowDetailViewDrawerAction={setIdeaShowDetailViewDrawer}
        setActiveItemIdAction={setActiveIdeaId}
        DetailViewComponent={IdeaDetailViewComponent}
        RightSidebarComponent={IdeaDetailViewRightSidebar}
      />
      <DetailViewDrawer
        showDetailViewDrawer={showKeyResultDetailViewDrawer}
        activeItemId={activeKeyResultId}
        items={detailKeyResults}
        setShowDetailViewDrawerAction={setKeyResultShowDetailViewDrawer}
        setActiveItemIdAction={setActiveKeyResultId}
        DetailViewComponent={KeyResultDetailViewComponent}
        RightSidebarComponent={KeyResultDetailViewRightSidebar}
      />
    </>
  );
};

export default AllDetailView;
