/* eslint-disable react/prop-types */
import IdeasRowComponent from "components/ideas/views/list/classic/IdeasRowComponent";
import FeaturesRowComponent from "components/features/views/list/FeaturesRowComponent";
import { useSelector } from "react-redux";
import { useMemo } from "react";

const InitiativeBasedRows = ({ objective, selectedColumns }) => {
  const statusIndex = selectedColumns.indexOf("status");
  const { ideas } = useSelector((state) => state.ideas);
  const { features } = useSelector((state) => state.features);

  const objectiveIdeas = useMemo(() => {
    return (ideas ?? []).filter((idea) =>
      (objective?.ideas ?? []).includes(idea._id)
    );
  }, [ideas, objective?.ideas]);

  const objectiveFeatures = useMemo(() => {
    return (features ?? []).filter((feature) =>
      (objective?.features ?? []).includes(feature._id)
    );
  }, [features, objective?.features]);
  return (
    <>
      {(objectiveIdeas ?? []).map((idea) => (
        <IdeasRowComponent
          key={idea._id}
          idea={idea}
          selectedColumns={
            statusIndex !== -1
              ? Array.from({ length: statusIndex }).concat("status")
              : []
          }
          pl="pl-12"
          py="py-1"
          withCheckboxOption={false}
        />
      ))}
      {(objectiveFeatures ?? []).map((feature) => (
        <FeaturesRowComponent
          key={feature._id}
          feature={feature}
          selectedColumns={
            statusIndex !== -1
              ? Array.from({ length: statusIndex }).concat("status")
              : []
          }
          pl="pl-12"
          py="py-1"
          withCheckboxOption={false}
        />
      ))}
    </>
  );
};

export default InitiativeBasedRows;
