/* eslint-disable react/prop-types */
import React, { useMemo } from "react";
import IconText from "components/common/IconText";
import { fieldIconMapping, truncateText } from "utils";
import ReleaseSection from "components/common/detailView/detailViewRightSidebarSections/ReleaseSection";
import { useDispatch, useSelector } from "react-redux";
import TimeFrameSection from "components/common/detailView/detailViewRightSidebarSections/TimeFrameSection";
import { updateFeatureData } from "reduxStore/operations/featuresAPI";
import { useNavigate } from "react-router-dom";
import { updateObjectiveData } from "reduxStore/operations/objectivesAPI";
import { resetActiveIds } from "utils/resetActiveIds";

const RoadmapsSection = ({
  item,
  roadmaps,
  with_timeFrame = false,
  with_release = false,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { releases, timeFrames } = useSelector((state) => state.company);
  const { roadmaps: allRoadmaps } = useSelector((state) => state.roadmaps);
  const filteredRoadmaps = useMemo(() => {
    return (allRoadmaps ?? []).filter((roadmap) =>
      (roadmaps ?? []).includes(roadmap._id)
    );
  }, [roadmaps, allRoadmaps]);

  return (
    <>
      {(filteredRoadmaps ?? []).map((roadmap) => (
        <div
          key={roadmap._id}
          className="flex justify-between items-center text-sm py-1 px-1.5 hover:bg-row-background rounded cursor-pointer -mt-1 mr-5"
          onClick={() => {
            resetActiveIds({ dispatch });
            navigate(`/roadmaps/${roadmap._id}`);
          }}
        >
          <IconText
            icon={React.createElement(fieldIconMapping.roadmap)}
            text={truncateText(roadmap.title, 25)}
            gap_x="gap-x-2"
            hoverable={false}
            textSize="text-sm"
            textColor="text-title_50"
            iconColor="text-roadmaps-icon"
            iconSize={12}
            py=""
            px=""
          />
          {with_release && (
            <ReleaseSection
              itemLabel={item.release?.label ?? ""}
              releases={releases}
              handleReleaseOptionClick={(option) =>
                dispatch(
                  updateFeatureData(
                    item._id,
                    { release: option._id },
                    "release"
                  )
                )
              }
              with_title={false}
              dropdownMenuPosition={"top-full right-0 "}
            />
          )}
          {with_timeFrame && (
            <TimeFrameSection
              item={{
                ...item.timeFrame,
                icon: React.createElement(fieldIconMapping.timeFrames),
              }}
              timeFrames={timeFrames}
              handleTimeFrameOptionClick={(option) => {
                dispatch(
                  updateObjectiveData(item._id, { timeFrame: option._id })
                );
              }}
              position="gap-x-1.5"
              with_title={false}
            />
          )}
        </div>
      ))}
    </>
  );
};

export default RoadmapsSection;
