/* eslint-disable react/prop-types */
import React from "react";
import IdeaKanbanCard from "./IdeaKanbanCard";
import KanbanColumn from "../../../common/kanban/KanbanColumn";

const IdeaKanbanColumn = (props) => {
  return (
    <KanbanColumn
      {...props}
      renderCardComponent={({
        item,
        products,
        innerRef,
        draggableProps,
        dragHandleProps,
        statusArray,
        handleRemoveIdea
      }) => (
        <IdeaKanbanCard
          idea={item}
          products={products}
          innerRef={innerRef}
          draggableProps={draggableProps}
          dragHandleProps={dragHandleProps}
          statusArray={statusArray}
          handleRemoveIdea={handleRemoveIdea}
          // setActiveIdeaCard={props.setActiveIdeaCard} // If you need to pass this prop
        />
      )}
    />
  );
};

export default IdeaKanbanColumn;
