import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../../components/core/Sidebar";
import InsightsTopbar from "../../components/insights/InsightsTopbar";

const Insights = () => {
  return (
    <div className="flex font-inter w-screen h-screen tracking-wide">
      <Sidebar />
      <div className="flex flex-col min-w-[80%] w-[100%]">
        <InsightsTopbar />
        <Outlet />
      </div>
    </div>
  );
};

export default Insights;
