import { fieldIconMapping, toSnakeCase } from ".";
import {
  groupByHealth,
  groupByStatus,
  groupByTag,
  groupByTimeFrame,
} from "./grouping";

export const groupDataByObjective = (
  data = [],
  groupBy,
  statuses,
  timeFrames,
  tags
) => {
  const groupByFunc = {
    status: () => groupByStatus(data, statuses),
    health: () => groupByHealth(data),
    tags: () => groupByTag(data, tags),
    time_frame: () => groupByTimeFrame(data, timeFrames),
    default: () => ({ all: data }),
  };
  return (groupByFunc[groupBy] || groupByFunc.default)();
};

export const objectiveTypes = () => {
  return [
    { label: "Execution driven", _id: "Initiative_based" },
    { label: "Outcome driven", _id: "Key_result_based" },
  ];
};

export const objectiveHealths = () => {
  return [
    {
      _id: "on_track",
      label: "On track",
      color: "#84DF81",
      withBackgroundColor: false,
      icon: fieldIconMapping.timeFrames,
    },
    {
      _id: "off_track",
      label: "Off track",
      color: "#FF9D66",
      withBackgroundColor: false,
      icon: fieldIconMapping.timeFrames,
    },
    {
      _id: "at_risk",
      label: "At risk",
      color: "#FF6678",
      withBackgroundColor: false,
      icon: fieldIconMapping.timeFrames,
    },
  ];
};

export const getHealthBgColor = (label) => {
  const health = objectiveHealths().find(
    (health) => toSnakeCase(health.label) === toSnakeCase(label)
  );
  return health?.color ?? "";
};
