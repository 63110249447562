import { toast } from "react-toastify";
import { ideaEndPoints } from "api/api";
import { apiConnector } from "api/apiconnector";
import {
  appendIdea,
  removeIdea,
  removeIdeas,
  setActiveIdeaId,
  setIdeas,
  setIdeaShowDetailViewDrawer,
  setIsIdeaLoading,
  updateIdea,
  updateIdeas,
  addIdeaFeature,
} from "../slices/ideaSlice";
import {
  addFeatureIdea,
  removeFeatureIdea,
  appendFeature,
} from "../slices/featureSlice";
import React from "react";
import CustomToast from "../../components/common/CustomToast";

const {
  ADD_IDEA,
  GET_ALL_IDEAS,
  UPDATE_IDEA_NON_ENTITY,
  UPDATE_IDEA_PRIORITIZATION,
  UPDATE_IDEA_FEATURES,
  UPDATE_MULTIPLE_IDEAS,
  DELETE_IDEA,
  UPDATE_IDEA_TAGS,
  ADD_IDEA_TO_ACCOUNTS,
  DELETE_IDEAS,
  CONVERT_IDEA_TO_FEATURE,
} = ideaEndPoints;

export const createIdea = (
  title,
  status,
  product,
  importance,
  accounts,
  score
) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector("POST", ADD_IDEA(), {
        title,
        status,
        product,
        importance,
        accounts,
        score,
      });

      if (response.status === 201 && response.data) {
        dispatch(appendIdea(response.data.newIdea));
        toast(
          React.createElement(CustomToast, {
            linkAddress: "/ideas",
            title,
            module: "Idea",
            idModule: response.data.newIdea,
          })
        );
      } else {
        toast.error("Failed to add idea");
      }
    } catch (error) {
      console.error("Error adding idea:", error);
      toast.error("Error adding idea");
    }
  };
};

export const getAllIdeas = (page = 1, limit = 100) => {
  return async (dispatch) => {
    try {
      dispatch(setIsIdeaLoading(true));
      const queryParams = new URLSearchParams({ page, limit }).toString();
      const response = await apiConnector(
        "GET",
        `${GET_ALL_IDEAS()}?${queryParams}`
      );
      if (response.status === 200 && response.data) {
        dispatch(setIdeas(response.data.ideas));
      } else {
        toast.error("Failed to fetch ideas");
      }
    } catch (error) {
      console.error("Error fetching ideas:", error);
      toast.error("Error fetching ideas");
    }
  };
};

export const updateIdeaData = (
  ideaId,
  updatedData,
  idea,
  isUpdated,
  restOption
) => {
  return async (dispatch) => {
    let newIdea = {};
    if (idea && restOption) {
      // Creating a deep copy of the objective to avoid mutating the original object
      newIdea = JSON.parse(JSON.stringify(idea));
      switch (isUpdated) {
        case "status":
          newIdea.status = restOption;
          break;
        case "product":
          newIdea.product = restOption;
          break;
        case "importance":
          newIdea.importance = restOption;
          break;
        default:
          break;
      }

      dispatch(updateIdea({ updatedIdea: newIdea }));
    }
    try {
      const response = await apiConnector(
        "PATCH",
        UPDATE_IDEA_NON_ENTITY(ideaId),
        updatedData
      );

      if (response.status === 200 && response.data) {
        dispatch(updateIdea({ updatedIdea: response.data.updatedIdea }));
        // toast.success("Idea updated successfully");
      } else {
        dispatch(updateIdea({ updatedIdea: idea }));
      }
    } catch (error) {
      dispatch(updateIdea({ updatedIdea: idea }));
      toast.error(`Error updating idea: ${error.message}`);
    }
  };
};

export const updateIdeasData = (ideas, update, isUpdatedArray) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector("PUT", UPDATE_MULTIPLE_IDEAS(), {
        ideas,
        update,
      });
      if (response.status === 200) {
        dispatch(
          updateIdeas({
            updatedIdeas: response.data.ideas,
            isUpdatedArray: isUpdatedArray,
          })
        );
      }
    } catch (error) {
      console.log(error);
      toast.error(`Error Updating Ideas: ${error.message}`);
    }
  };
};

export const updateIdeaScore = (
  ideaId,
  type,
  value,
  effort,
  impact,
  reach,
  confidence
) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector(
        "PATCH",
        UPDATE_IDEA_PRIORITIZATION(ideaId),
        { type, value, effort, impact, reach, confidence }
      );
      if (response.status === 200) {
        dispatch(
          updateIdea({
            updatedIdea: response.data.idea,
            isUpdated: "score",
          })
        );
      }
    } catch (error) {
      console.log(error);
      toast.error(`Error Updating Score: ${error.message}`);
    }
  };
};

export const updateIdeaFeatures = (ideaId, features) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector(
        "PATCH",
        UPDATE_IDEA_FEATURES(ideaId),
        features
      );

      if (response.status === 200 && response.data) {
        dispatch(
          updateIdea({
            updatedIdea: response.data.updatedIdea,
            isUpdated: "features",
          })
        );
        dispatch(addFeatureIdea({ ideaId, features: features.features }));
        // toast.success("Idea updated successfully");
      }
    } catch (error) {
      toast.error(`Error updating idea: ${error.message}`);
    }
  };
};

export const removeIdeaFeatures = (ideaId, features) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector(
        "DELETE",
        UPDATE_IDEA_FEATURES(ideaId),
        features
      );

      if (response.status === 200 && response.data) {
        dispatch(
          updateIdea({
            updatedIdea: response.data.updatedIdea,
            isUpdated: "features",
          })
        );
        dispatch(removeFeatureIdea({ ideaId, features: features.features }));
        // toast.success("Idea updated successfully");
      }
    } catch (error) {
      console.log(error);
      toast.error(`Error updating idea: ${error.message}`);
    }
  };
};

export const deleteIdea = (ideaId, navigate) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector("DELETE", DELETE_IDEA(ideaId));

      if (response.status === 200 && response.data) {
        dispatch(setActiveIdeaId(null));
        dispatch(setIdeaShowDetailViewDrawer(false));
        dispatch(removeIdea(ideaId));
        toast.success("Idea deleted successfully");
        navigate("/ideas");
      }
    } catch (error) {
      toast.error(`Error deleting idea: ${error.message}`);
    }
  };
};

export const updateIdeaTags = (option, idea) => async (dispatch) => {
  try {
    let newIdea = {};
    if (idea) {
      // Creating a deep copy of the objective to avoid mutating the original object
      newIdea = JSON.parse(JSON.stringify(idea));
      newIdea.tags = option.fullTags;
      dispatch(updateIdea({ updatedIdea: newIdea, isUpdated: "tags" }));
    }
    const { data } = await apiConnector(
      "PATCH",
      UPDATE_IDEA_TAGS(option.itemId),
      {
        tags: option.tags,
      }
    );

    dispatch(updateIdea({ updatedIdea: data.idea, isUpdated: "tags" }));

    // toast.success("tags added successfully");
  } catch (error) {
    console.error("Error adding tag:", error);
    dispatch(updateIdea({ updatedIdea: idea, isUpdated: "tags" }));
    toast.error("Error adding tag:");
  }
};

export const updateIdeaAccounts = (option, idea) => async (dispatch) => {
  try {
    let newIdea = {};
    if (idea) {
      // Creating a deep copy of the objective to avoid mutating the original object
      newIdea = JSON.parse(JSON.stringify(idea));
      newIdea.accounts = option.fullAccounts;
      dispatch(updateIdea({ updatedIdea: newIdea, isUpdated: "accounts" }));
    }
    const { data } = await apiConnector(
      "PATCH",
      ADD_IDEA_TO_ACCOUNTS(option.itemId),
      {
        accounts: option.accounts,
      }
    );

    dispatch(updateIdea({ updatedIdea: data.idea, isUpdated: "accounts" }));
    // toast.success("accounts added successfully");
  } catch (error) {
    console.error("Error adding accounts:", error);
    toast.error("Error updating accounts:");
    dispatch(updateIdea({ updatedIdea: idea, isUpdated: "accounts" }));
  }
};

export const deleteIdeas = (ideas) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector("DELETE", DELETE_IDEAS(), { ideas });

      if (response.status === 200 && response.data) {
        dispatch(setActiveIdeaId(null));
        dispatch(setIdeaShowDetailViewDrawer(false));
        dispatch(removeIdeas({ ideaIds: ideas }));
        toast.success("Ideas deleted successfully");
      }
    } catch (error) {
      toast.error(`Error deleting idea: ${error.message}`);
    }
  };
};

export const convertIdeaToFeature = (ideaId) => async (dispatch) => {
  try {
    const { data } = await apiConnector(
      "PATCH",
      CONVERT_IDEA_TO_FEATURE(ideaId)
    );
    dispatch(addIdeaFeature({ featureId: data.feature._id, ideas: [ideaId] }));
    dispatch(appendFeature(data.feature));
    toast.success(data.message);
  } catch (error) {
    toast.error(
      error?.response?.data?.message ?? "An error occurred. Please try again."
    );
  }
};
