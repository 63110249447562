/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
import { Dialog, DialogPanel } from "@headlessui/react";
import { CrossSmall } from "react-flaticons";

const AddItemSidebarDialog = ({
  isOpen,
  setIsOpen,
  sidebarComponent,
  handleResetFilters,
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (!open) {
      setIsOpen("");
      handleResetFilters();
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={setOpen} className="relative z-50">
      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
            <DialogPanel
              transition
              className="pointer-events-auto relative w-screen max-w-xl transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
            >
              <div className="flex flex-col bg-white shadow-2xl tracking-wide font-inter h-full">
                <div className="pt-4 pl-6">
                  <div
                    className="hover:bg-button-hover rounded p-1 cursor-pointer w-fit"
                    onClick={() => {
                      setIsOpen("");
                      handleResetFilters();
                    }}
                  >
                    <CrossSmall size={16} />
                  </div>
                </div>
                {sidebarComponent}
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default AddItemSidebarDialog;
