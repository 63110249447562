import {
  groupByAccount,
  groupByImportance,
  groupByObjective,
  groupByProduct,
  groupByStatus,
  groupByTag,
} from "./grouping";

export const groupDataByIdea = (
  data = [],
  groupBy,
  statuses = {},
  products,
  timeFrames,
  objectives,
  tags,
  accounts
) => {
  const groupByFunc = {
    status: () => groupByStatus(data, statuses),
    product: () => groupByProduct(data, products),
    objective: () => groupByObjective(data, objectives),
    tags: () => groupByTag(data, tags),
    accounts: () => groupByAccount(data, accounts),
    time_frame: () => groupByTimeFrame(data, timeFrames),
    importance: () => groupByImportance(data),
    default: () => ({ items: data }),
  };
  return (groupByFunc[groupBy] || groupByFunc.default)();
};

const groupByTimeFrame = (data, timeFrames) => {
  const groupedData = (timeFrames || []).reduce((acc, timeFrame) => {
    const matchingIdeas = data
      .filter((item) => item.timeFrame === timeFrame._id)
      .map((item) => item.idea);
    acc[timeFrame._id] = {
      label: timeFrame.label,
      color: timeFrame.color,
      items: matchingIdeas,
    };

    return acc;
  }, {});

  const noTimeFrameIdeas = data
    .filter((item) => !item.timeFrame)
    .map((item) => item.idea);

  if (noTimeFrameIdeas.length > 0) {
    groupedData["No Time-frame"] = {
      label: "No Time-frame",
      items: noTimeFrameIdeas,
    };
  }

  return groupedData;
};
