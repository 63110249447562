export const loadBrevoSDK = () => {
  if (!document.getElementById("brevo-sdk")) {
    const script = document.createElement("script");
    script.src = "https://cdn.brevo.com/js/sdk-loader.js";
    script.id = "brevo-sdk";
    script.async = true;

    document.head.appendChild(script);
    console.log("first page loaded brevo");

    script.onload = () => {
      window.Brevo = window.Brevo || [];
      window.Brevo.push([
        "init",
        {
          client_key: import.meta.env.VITE_BREVO_CLIENT_KEY,
        },
      ]);
      console.log("Brevo SDK initialized");
    };
  } else {
    console.log("Brevo SDK already loaded");
  }
};
