/* eslint-disable react/prop-types */
import DropdownItem from "components/common/DropdownItem";
import IconText from "components/common/IconText";
import { Plus } from "react-flaticons";
import CheckboxDropdownItem from "../../CheckboxDropdownItem";

const DropdownMenu = ({
  options,
  isOpen,
  position,
  handleOptionClick,
  withAddOption = false,
  handleAddOptionClick,
  multiSelect = false,
  selectedItems = new Set(),
}) => {
  if (!isOpen) return [];

  return (
    <div
      className={`absolute bg-white border-2 rounded-lg z-10 p-2 cursor-default shadow-lg max-h-[320px] overflow-auto custom-scrollbar ${position}`}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {withAddOption && options?.length === 0 && (
        <IconText
          icon={<Plus />}
          text="Add"
          iconSize="10"
          onClick={handleAddOptionClick}
          textSize="text-xs"
          px="px-1"
          py="py-1.5"
          gap_x="gap-x-2"
        />
      )}
      {(multiSelect
        ? (options ?? []).sort((a, b) => {
            const isSelectedA = selectedItems.has(a._id);
            const isSelectedB = selectedItems.has(b._id);
            return isSelectedA === isSelectedB ? 0 : isSelectedA ? -1 : 1;
          })
        : options
      ).map((option, index) => (
        <div
          key={index}
          className={`${index !== options.length - 1 ? "mb-2" : ""}`}
        >
          {!multiSelect ? (
            <DropdownItem
              option={option}
              py="py-1"
              textSize="text-xs"
              px="px-1"
              gap_x="gap-x-1.5"
              hoverColor="hover:bg-topbar-button-hover"
              textColor="text-title_50"
              iconSize={12}
              iconColor={option?.color ?? ""}
              dropDownBgColor={
                (option?.withBackgroundColor ?? true) && option?.color
                  ? option.color
                  : "" ?? ""
              }
              handleOptionClick={handleOptionClick}
            />
          ) : (
            <CheckboxDropdownItem
              option={option}
              handleOptionClick={handleOptionClick}
              selected={selectedItems.has(option._id)}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default DropdownMenu;
