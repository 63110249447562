/* eslint-disable react/prop-types */
import React from "react";
import { CrossSmall } from "react-flaticons";
import DropdownMenu from "components/common/detailView/dropdown/DropdownMenu";
import { objectiveHealths } from "utils/objectiveUtils";

const HealthButton = ({
  updatedHealth,
  handleOptionClick,
  dropdownState,
  toggleDropdown,
  removeSelection,
}) => {
  return (
    <button
      className={`relative flex items-center gap-x-1 border border-gray-300 
      ${
        dropdownState.isHealthDropdownOpen
          ? "bg-button-active_10"
          : "hover:bg-row-background"
      } 
       p-1.5 rounded-md`}
      onClick={() => toggleDropdown("isHealthDropdownOpen")}
    >
      {updatedHealth?.label || "Change Health"}
      {updatedHealth && (
        <div
          className="cursor-pointer hover:bg-button-hover p-0.5 rounded-full"
          onClick={(e) => {
            e.stopPropagation();
            removeSelection("health");
          }}
        >
          <CrossSmall size={12} />
        </div>
      )}
      <DropdownMenu
        options={objectiveHealths() ?? []}
        isOpen={dropdownState.isHealthDropdownOpen}
        position="bottom-full w-32 right-0"
        handleOptionClick={(option) => handleOptionClick("health", option)}
      />
    </button>
  );
};

export default HealthButton;
