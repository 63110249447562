import { apiConnector } from "api/apiconnector"; // Ensure you have an apiConnector function to handle API requests
import { jiraEndpoints } from "api/api"; // Import your API endpoints
import { toast } from "react-toastify";

const {
  FETCH_JIRA_ISSUES,
  FETCH_JIRA_ISSUE,
  FETCH_JIRA_PROJECTS,
  IMPORT_JIRA_ISSUES,
  PUSH_JIRA_ISSUE,
  BULK_PUSH_JIRA_ISSUES,
} = jiraEndpoints;

export const getJiraIssues = (companyId, jql) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector(
        "GET",
        FETCH_JIRA_ISSUES(),
        null,
        null,
        { companyId, jql }
      );
      if (response.status === 200) {
        console.log(response.data);
        return response.data;
      }
    } catch (error) {
      toast.error(
        error.response.data.message ?? "Error in fetching jira issues"
      );
    }
  };
};

export const getJiraIssue = (companyId, featureId, jiraIssueKey) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector(
        "GET",
        FETCH_JIRA_ISSUE(jiraIssueKey),
        null,
        null,
        { companyId, featureId }
      );
      if (response.status === 200) {
        return true;
      }
    } catch (error) {
      toast.error(
        error.response.data.message ?? "Error in fetching jira issue"
      );
    }
  };
};

export const getJiraProjects = (companyId) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector(
        "GET",
        FETCH_JIRA_PROJECTS(),
        null,
        null,
        { companyId }
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      toast.error(
        error.response.data.message ?? "Error in fetching jira projects"
      );
    }
  };
};

export const pushJiraIssue = (
  companyId,
  featureId,
  title,
  description,
  projectKey
) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector(
        "POST",
        PUSH_JIRA_ISSUE(),
        { featureId, title, description, projectKey },
        null,
        { companyId }
      );
      if (response.status === 200) {
        console.log(response.data);
        toast.success(response.data.message ?? "Ticket pushed successfully");
      }
    } catch (error) {
      toast.error(
        error.response.data.message ?? "Error in fetching jira issues"
      );
    }
  };
};

export const bulkPushJiraIssues = (companyId, featureIds, projectKey) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector(
        "POST",
        BULK_PUSH_JIRA_ISSUES(),
        { featureIds, projectKey },
        null,
        { companyId }
      );
      if (response.status === 200) {
        toast.success(response.data.message ?? "Tickets pushed successfully");
        return response.data;
      }
    } catch (error) {
      toast.error(
        error.response.data.message ?? "Error in fetching jira issues"
      );
    }
  };
};

export const importJiraIssues = (companyId, product, jiraIssues) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector(
        "POST",
        IMPORT_JIRA_ISSUES(),
        { companyId, product, jiraIssues },
        null
      );
      if (response.status === 200) {
        console.log(response.data);
        return true;
      }
    } catch (error) {
      toast.error(
        error.response.data.message ?? "Error in fetching jira issues"
      );
    }
  };
};
