import Sidebar from "../components/core/Sidebar";
import Topbar from "../components/core/topbar/Topbar";
import { HourglassEnd } from "react-flaticons";
import TasksComponent from "../components/tasks/TasksComponent";

const Tasks = () => {
  const tabs = [{ label: "Current" }, { label: "Parked" }, { label: "All" }];
  return (
    <div className="flex font-inter">
      <Sidebar />
      <div className="flex flex-col w-full">
        <Topbar
          title="Tasks"
          titleIcon={<HourglassEnd className="text-tasks" size={14} />}
          tabs={tabs}
        />
        <TasksComponent />
      </div>
    </div>
  );
};

export default Tasks;
