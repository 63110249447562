/* eslint-disable react/prop-types */
import React from "react";

const ReleaseRowSection = ({ release, dropdownState, toggleDropdown }) => {
  return (
    <div
      className={`rounded w-fit p-1 hover:bg-gray-200  ${
        // className={`rounded w-fit p-1 hover:bg-button-hover   ${
        dropdownState.isReleaseDropdownOpen ? "bg-button-active_10" : ""
      }`}
      onClick={(e) => {
        e.stopPropagation();
        toggleDropdown("isReleaseDropdownOpen");
      }}
    >
      {release ?? "No Release"}
    </div>
  );
};

export default ReleaseRowSection;
