/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from "react";
import IconText from "components/common/IconText";
import IconWrapper from "components/common/IconWrapper";
import { fieldIconMapping, rgbColor } from "utils";
import { getStatusIcon } from "utils/status";
import DropdownMenu from "../dropdown/DropdownMenu";

const StatusSection = ({
  item,
  statusArray,
  handleStatusOptionClick,
  position = "gap-x-8",
  with_title = true,
  with_label = true,
  dropdownMenuWidth = "w-32",
  dropdownMenuPosition = "top-full",
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef();

  const handleLocalStatusOptionClick = (option) => {
    handleStatusOptionClick(option);
    setIsDropdownOpen(false);
  };
  const toggleDropdown = (e) => {
    e.stopPropagation();
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={`flex ${position} items-center text-title_50   `}>
      {with_title && (
        <IconText
          icon={fieldIconMapping.status}
          text="Status"
          iconSize={16}
          hoverable={false}
          iconColor="#696969"
          gap_x="gap-x-2"
        />
      )}
      {statusArray !== null && (
        <div className="relative cursor-pointer" ref={dropdownRef}>
          <div
            className={`p-1 w-fit rounded hover:bg-gray-200 ${
              isDropdownOpen && "bg-button-active_10"
            }`}
            onClick={toggleDropdown}
          >
            <div className="rounded w-fit bg-white">
              {with_label ? (
                <IconText
                  icon={getStatusIcon(item?.label ?? "", statusArray)}
                  text={item?.label ?? ""}
                  gap_x="gap-x-1"
                  px="px-1"
                  py="py-1"
                  textSize="text-xs"
                  textColor="text-title_50"
                  iconColor={item?.color ?? ""}
                  iconTextBgColor={rgbColor(item?.color ?? "", 0.2)}
                />
              ) : (
                <div
                  className="p-1 rounded"
                  style={{
                    backgroundColor: rgbColor(item?.color ?? "", 0.2),
                  }}
                >
                  <IconWrapper
                    icon={getStatusIcon(item?.label, statusArray)}
                    iconColor={item?.color ?? ""}
                  />
                </div>
              )}
            </div>
          </div>

          {isDropdownOpen && (
            <DropdownMenu
              options={statusArray ?? []}
              isOpen={true}
              position={`${dropdownMenuPosition} ${dropdownMenuWidth}`}
              handleOptionClick={handleLocalStatusOptionClick}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default StatusSection;
