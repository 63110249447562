/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import IconText from "components/common/IconText";
import { fieldIconMapping, rgbColor } from "utils";
import { useSelector } from "react-redux";
import DropdownMenu from "components/common/detailView/dropdown/DropdownMenu";

const RoadmapColumnsSection = ({ roadmap, handleColumnsOptionClick }) => {
  const { releases, timeFrames } = useSelector((state) => state.company);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef();
  const [selectColumns, setSelectColumns] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState(new Set());
  const initalColumns =
    roadmap?.for === "Release"
      ? new Set(roadmap?.releases.map((item) => item._id))
      : new Set(roadmap?.timeFrames?.map((item) => item._id));

  useEffect(() => {
    setSelectColumns(
      roadmap?.for === "Release" ? roadmap?.releases : roadmap?.timeFrames ?? []
    );
    setSelectedColumns(
      roadmap?.for === "Release"
        ? new Set(roadmap?.releases.map((item) => item._id))
        : new Set(roadmap?.timeFrames?.map((item) => item._id))
    );
  }, [roadmap]);

  const onColumnClick = (option) => {
    const columnId = option._id;
    const updatedColumns = new Set(selectedColumns);

    if (updatedColumns.has(columnId)) {
      updatedColumns.delete(columnId);
      setSelectColumns((prevColumns) =>
        prevColumns.filter((column) => column._id !== columnId)
      );
    } else {
      updatedColumns.add(columnId);
      setSelectColumns((prevColumns) => [...prevColumns, option]);
    }

    setSelectedColumns(updatedColumns);
  };

  const toggleDropdown = (e) => {
    e.stopPropagation();
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);

        if (
          !areSetsEqual(initalColumns, selectedColumns) &&
          selectedColumns.size !== 0
        ) {
          handleColumnsOptionClick({
            items: Array.from(selectedColumns),
            itemId: roadmap?._id,
          });
        } else {
          setSelectedColumns(initalColumns);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectedColumns]);

  const areSetsEqual = (setA, setB) => {
    if (setA.size !== setB.size) return false;
    for (let item of setA) if (!setB.has(item)) return false;
    return true;
  };

  return (
    <div className="flex items-start gap-x-5 text-sm text-primary ">
      <IconText
        icon={React.createElement(fieldIconMapping.columns)}
        text="Columns"
        gap_x="gap-x-2"
        hoverable={false}
      />
      <div
        className={`relative flex flex-wrap rounded cursor-pointer text-xs gap-2 p-1 w-full ${
          isDropdownOpen ? "bg-button-active_10" : "hover:bg-button-hover"
        }`}
        onClick={toggleDropdown}
        ref={dropdownRef}
      >
        {(selectColumns ?? []).map((column) => (
          <div
            key={column._id}
            className={
              roadmap.for === "Release"
                ? "rounded bg-tags-active p-1"
                : "bg-white rounded"
            }
          >
            <div
              className={
                roadmap.for !== "Release"
                  ? "rounded p-1 text-xs text-title_50"
                  : "text-xs text-title_50"
              }
              style={{ backgroundColor: rgbColor(column?.color ?? "", 0.2) }}
            >
              {column.label}
            </div>
          </div>
        ))}
        {isDropdownOpen && (
          <DropdownMenu
            options={(roadmap.for === "Release" ? releases : timeFrames)?.map(
              (item) => ({
                _id: item._id,
                label: item.label,
                icon:
                  roadmap.for !== "Release"
                    ? fieldIconMapping.timeFrames
                    : fieldIconMapping.release,
                iconColor: roadmap.for !== "Release" ? item.color : undefined,
              })
            )}
            isOpen={isDropdownOpen}
            position="top-full left-0"
            iconSize={12}
            handleOptionClick={onColumnClick}
            multiSelect={true}
            selectedItems={selectedColumns}
          />
        )}
      </div>
    </div>
  );
};

export default RoadmapColumnsSection;
