import React, { useState } from "react";
import { Search } from "react-flaticons";
import { useSelector } from "react-redux";
import PersonalView from "../PersonalView";

const SearchDropdown = () => {
  const { personalViews } = useSelector((state) => state.personalViews);
  const [query, setQuery] = useState(""); // State to hold the input value
  const [filteredViews, setFilteredViews] = useState(personalViews); // State to hold filtered views
  const [isFocused, setIsFocused] = useState(false); // State to track focus of the input

  // Handle input change
  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setQuery(inputValue);
    // Filter views based on input value
    const filtered = personalViews.filter((view) =>
      view.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilteredViews(filtered);
  };

  return (
    <div className="relative">
      <div className="flex items-center gap-1 w-full px-2">
        {/* Search Icon */}
        <Search
          size={14}
          className={`text-primary ${isFocused ? "text-title_50" : ""}`} // Change color when focused
        />
        <input
          type="text"
          value={query}
          onChange={handleInputChange}
          placeholder="Search view"
          className="text-sm w-10/12 p-0.5 pl-1.5 outline-none bg-sidebar-background focus:bg-white  border   border-sidebar-background focus:border-gray-300 rounded-md"
          onFocus={() => setIsFocused(true)} // Set focused state to true on input focus
          onBlur={() => setIsFocused(false)} // Set focused state to false when input loses focus
        />
      </div>

      {/* Dropdown - only shows if there's text in the input */}
      {query && filteredViews.length > 0 && (
        <div className="absolute z-50 bg-white w-[13rem] rounded-lg py-2 px-1 mx-1 border-2 shadow-lg">
          <ul className="max-h-60 overflow-y-auto" onClick={() => setQuery("")}>
            {filteredViews.map((view) => (
              <PersonalView key={view._id} view={view} py="py-1"  isSearch={true} />
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SearchDropdown;
