/* eslint-disable react/prop-types */
import React, { useMemo } from "react";
import { fieldIconMapping } from "utils";
import { useNavigate } from "react-router-dom";
import KanbanModuleSection from "./KanbanModuleSection";
import { useSelector } from "react-redux";

const KanbanRoadmapsSection = ({ roadmaps }) => {
  const navigate = useNavigate();
  const { roadmaps: allRoadmaps } = useSelector((state) => state.roadmaps);
  const filteredRoadmaps = useMemo(() => {
    return (allRoadmaps ?? []).filter((roadmap) =>
      (roadmaps ?? []).includes(roadmap._id)
    );
  }, [roadmaps, allRoadmaps]);

  const handleRoadmapClick = (roadmap) => {
    navigate(`/roadmaps/${roadmap._id}`);
  };

  return (
    <KanbanModuleSection
      items={filteredRoadmaps}
      icon={fieldIconMapping.roadmap}
      truncateLength={filteredRoadmaps?.length > 1 ? 28 : 40}
      iconColor="text-roadmaps-icon"
      textColor="text-title_50"
      onItemClick={handleRoadmapClick}
    />
  );
};

export default KanbanRoadmapsSection;
